import http from './http-common';

class CompanyService {
  getAll() {
    return http.get('/company');
  }
  getYarnContractCompanies() {
    return http.get('/company/yarnContractCompanies');
  }

  getYarnVendors() {
    return http.get(`/company/1`);
  }

  getYarnVendorsForDropDown() {
    return http.get(`/company/1/dropdown`);
  }

  getYarnCustomers() {
    return http.get(`/company/2`);
  }

  getYarnCustomersForDropDown() {
    return http.get(`/company/2/dropdown`);
  }
getBankForDropDown() {
    return http.get(`/company/11/dropdown`);
  }

  getWeavingVendors() {
    return http.get(`/company/3`);
  }

  getWeavingVendorsForDropDown() {
    return http.get(`/company/3/dropdown`);
  }

  getFabricPurchaseVendors() {
    return http.get(`/company/4`);
  }

  getFabricPurchaseVendorsForDropDown() {
    return http.get(`/company/4/dropdown`);
  }

  getFabricLocalSaleCustomers() {
    return http.get(`/company/5`);
  }

  getFabricLocalSaleCustomersForDropDown() {
    return http.get(`/company/5/dropdown`);
  }

  getExportSaleCustomers() {
    return http.get(`/company/6`);
  }

  getExportSaleCustomersForDropDown() {
    return http.get(`/company/6/dropdown`);
  }

  getAccessoriesVendors() {
    return http.get(`/company/7`);
  }

  getAccessoriesVendorsForDropDown() {
    return http.get(`/company/7/dropdown`);
  }

  getBrokers() {
    return http.get(`/company/8`);
  }

  getBrokersForDropDown() {
    return http.get(`/company/8/dropdown`);
  }

  // getFabricBrokers() {
  //   return http.get(`/company/9`);
  // }

  // getFabricBrokersForDropDown() {
  //   return http.get(`/company/9/dropdown`);
  // }

  getRejectionCustomersForDropDown() {
    return http.get(`/company/9/dropdown`);
  }

  getCompanyTypeId(typeId) {
    return http.get(`/company/${typeId}`);
  }

  getCompanyTypeIdForDropDown(typeId) {
    return http.get(`/company/${typeId}/dropdown`);
  }

  getContractPersonByCompanyID(CompanyId) {
    return http.get(`/company/contractPerson/${CompanyId}`);
  }
  getChartOfAccountForCompany() {
    return http.get(`/company/for/ChartOfAccountForCompany`);
  }

  create(typeId, data) {
    console.log('company insert typeId:', typeId);
    console.log('company insert value:', data);
    return http.post(`/company/${typeId}/add`, data);
    // return http.post(`/company/${typeId}/add`, value);    
  }
update(companyID, data) {
    // console.log('company insert typeId:', typeId);
    console.log('company insert value:', data);
    return http.put(`/company/${companyID}`, data);
    // return http.post(`/company/${typeId}/add`, value);    
  }
}

export default new CompanyService();
