import React, { useEffect, useRef,useState } from 'react';
import './setup-add.scss';
import SetupService from '../../services/setup.service';
import {
  GridComponent,
  Inject,
  Toolbar,
  ToolbarItems,
  Edit,
  EditSettingsModel,
  Page,
  Sort,
  Grid,
  FilterSettingsModel,
  Filter,
  ColumnDirective,
  ColumnsDirective,
  DialogEditEventArgs,
} from '@syncfusion/ej2-react-grids';
import SecurityService from '../security/security.service';
import AuditTableInput from '../audit-table/audit-table';
import CheckDuplicate from '../check-duplicate/check-duplicate';
import { TextBoxComponent } from '@syncfusion/ej2-react-inputs';
import HardDelete from '../hard-delete/hard-delete';

let selectedRows  = []
let setupData = []
let descValue = ''
export default function SetupAdd(props) {
  const [setup, setSetup] = useState([]);
  const [title, setTitle] = useState('');
  const [table, setTable] = useState('');
  const [showDialogs, setShowDialogs] = useState();
  const [deletingProps, setDeletingProps] = useState();
  const [tableName, setTableName] = useState('');
  const [tableID, setTableID] = useState('');
  const [value, setValue] = useState('');
  const isDuplicate = useRef(false);  
  const UserId = SecurityService.getLoggedInUserId();


  useEffect(()=>{
    setupData = setup
  
  },[setup])
  
  const CheckDup =  ( )=>{
    duplicateState =  CheckDuplicate.withCombination(setupData,['value'],{value : descriptionRef.current.value})
    return duplicateState;
  
  }
  
  let setupGrid: Grid;
  var descriptionRef =  useRef();
  var duplicateState = false
  const setHeading = () => {
    const type = props.type;
    let title: string;
    let table: string;
    let tableId : string;
    let tablenName: string;
    switch (type) {
      case 'Broker':
        title = 'Broker';
        table = 'Brokers'
        tableId = ''
        tablenName = ''
        break;
      case 'UnitOfMeasurement':
        title = 'Unit Of Measurement';
        table = 'UnitOfMeasurement';
        tableId = 'UnitOfMeasurementID';
        tablenName = 'UnitOfMeasurement';

        break;
      case 'YarnCount':
        title = 'Yarn Count';
        table = 'YarnCount';
        tableId = 'YarnCountID';
        tablenName = 'YarnCount';
        break;
      case 'YarnQuality':
        title = 'Yarn Quality';
        table = 'YarnQuality';
        tableId = 'YarnQualityID';
        tablenName = 'YarnQuality';
        break;
      case 'AccountCategory':
        title = 'Account Category';
        table = 'AccountCategory';
        tableId = 'AccountCategoryID';
        tablenName = 'AccountCategory';
        break;
      case 'YarnType':
        title = 'Yarn Type';
        table = 'YarnType';
        tableId = 'YarnTypeID';
        tablenName = 'YarnType';
        break;
      case 'YarnBrand':
        title = 'Yarn Brand';
        table = 'YarnBrand';
        tableId = 'BrandID';
        tablenName = 'BrandName';
        break;
      case 'YarnGodown':
        title = 'Yarn Godown';
        table = 'Godown';
        tableId = 'GodownID';
        tablenName = 'GodownName';
        break;
      case 'ImportLC':
        title = 'Import LC';
        table = 'ImportLC';
        tableId = 'ImportLCID';
        tablenName = 'ImportLCNo';
        break;
      case 'HSCode':
        title = 'HS Code';
        table = 'HSCode';
        tableId = 'HSCodeID';
        tablenName = 'HSCodeNo';
        break;
      case 'FabricType':
        title = 'Fabric Type';
        table = 'FabricType';
        tableId = 'FabricTypeID';
        tablenName = 'FabricTypeDesc';
        break;
        case 'Department':
          title = 'Department';
          table = 'Department';
          tableId = 'DepartmentID';
          tablenName = 'DepartmentName';
          break;
        case 'Port':
          title = 'Port';
          table = 'Port';
          tableId = 'PortID';
          tablenName = 'PortName';
          break;
        case 'FabricVersion':
          title = 'Fabric Version';
          table = 'FabricVersion';
          tableId = 'FabricVersionID';
          tablenName = 'FabricVersion';
          break;
        case 'RejectionCategory':
          title = 'RejectionCategory';
          table = 'Rejection Category';
          tableId = 'RejectionCategoryID';
          tablenName = 'RejectionCategory';
          break;
        case 'FabricDesign':
          title = 'Fabric Design';
          table = 'FabricDesign';
          tableId = 'FabricDesignID';
          tablenName = 'FabricDesign';
          break;
      case 'FabricAdjustmentType':
        title = 'Fabric Adjustment Type';
        table = 'FabricAdjustmentType';
        tableId = 'FabricAdjustmentTypeID';
        tablenName = 'FabricAdjustmentType';
        break;
      case 'FabricYarnDescription':
        title = 'Fabric Yarn Description';
        table = 'FabricYarnDescription';
        tableId = 'FabricYarnDescID';
        tablenName = 'FabricYarnDesc';
        break;
      case 'FabricCategory':
        title = 'Fabric Category';
        table = 'FabricCategory';
        tableId = 'FabricCategoryID';
        tablenName = 'FabricCategoryDesc';
        break;
      case 'FabricThreadCount':
        title = 'Fabric Thread Count';
        table = 'FabricThreadCount';
        tableId = 'FabricThreadCountID';
        tablenName = 'FabricThreadCountDesc';
        break;
      case 'Vendors':
        title = 'Vendors';
        table = 'Company';
        tableId = 'CompanyID';
        tablenName = 'CompanyName';
        break;
      case 'CompanyType':
        title = 'Company Type';
        table = 'CompanyType';
        tableId = 'CompanyTypeID';
        tablenName = 'CompanyType';
        break;
      case 'Currency':
        title = 'Currency';
        table = 'Currency';
        tableId = 'CurrencyID';
        tablenName = 'CurrencyName';
        break;
      case 'ImportExpenses':
        title = 'ImportExpenses';
        table = 'ImportExpenses';
        tableId = 'ImportExpensesID';
        tablenName = 'ImportExpenses';
        break;
      case 'FabricPacking':
        title = 'Fabric Packing';
        table = 'FabricPacking';
        tableId = 'FabricPackingID';
        tablenName = 'FabricPacking';
        break;
      case 'DeliveryStatus':
        title = 'Delivery Status';
        table = 'DeliveryStatus';
        tableId = 'DeliveryStatusID';
        tablenName = 'DeliveryStatus';
        break;
      case 'ConversionContractType':
        title = 'Conversion Contract Type';
        table = 'ConversionContractType';
        tableId = 'ConversionContractTypeID';
        tablenName = 'ConversionContractType';
        break;
      case 'BrokerageType':
        title = 'Brokerage Type';
        table = 'BrokerageType';
        tableId = 'BrokerageTypeID';
        tablenName = 'BrokerageType';
        break;
      case 'ProcurementType':
        title = 'Procurement Type';
        table = 'ProcurementType';
        tableId = 'ProcurementTypeID';
        tablenName = 'ProcurementType';
        break;
      case 'AccessoryCategory':
        title = 'Accessory Category';
        table = 'AccessoryCategory';
        tableId = 'AccessoryCategoryID';
        tablenName = 'AccessoryCategory';
        break;
      case 'AccountType':
        title = 'AccountType';
        table = 'AccountType';
        tableId = 'AccountTypeID';
        tablenName = 'AccountType';
        break;
      case 'Unit':
        title = 'Unit';
        table = 'UnitOfMeasurement';
        tableId = 'UnitOfMeasurementID';
        tablenName = 'UnitOfMeasurement';
        break;
      case 'Color':
        title = 'Color';
        table = 'Color';
        tableId = 'ColorID';
        tablenName = 'Color';
        break;
      case 'ProductCategory':
        title = 'Product Category';
        table = 'ProductCategory';
        tableId = 'ProductCategoryID';
        tablenName = 'ProductCategory';
        break;
      case 'ProductDesc':
        title = 'Product Desc';
        table = 'ProductDesc';
        tableId = 'ProductDescID';
        tablenName = 'ProductDesc';
        break;
      case 'ProcessDesc':
        title = 'Process Desc';
        table = 'ProcessDesc';
        tableId = 'ProcessDescID';
        tablenName = 'ProcessDesc';
        break;
      case 'PaymentTerms':
        title = 'Payment Terms';
        table = 'PaymentTerms';
        tableId = 'PaymentTermsID';
        tablenName = 'PaymentTerms';
        break;
      default:
        title = '';
        table = 'UnitOfMeasurement';
        tableId = 'UnitOfMeasurementID';
        tablenName = 'UnitOfMeasurement';
        break;
    }
    setTitle(title);
    setTable(table);
    setTableID(tableId);
    setTableName(tablenName);
  };

  useEffect(() => {
    if (props.type !== '') {
      console.log('setup add type: ', props.type);
      if (setup.length === 0) {
        setHeading();
        getSetup(props.type);
      }
    }

  }, []);

  const getSetup = async (type) => {
    SetupService.getAll(type)
      .then((response) => {
        console.log('get all setup', response)
        setSetup(response.data.recordset);
      })
      .catch((e) => {
        console.log(e);
        setSetup({});
      });
  };

  const getRoute = () =>
    window.location.pathname
      .substr(1, window.location.pathname.length)
      .split('/');

  const toolbarOptions: ToolbarItems[] = [
    'Add',
    'Edit',
    {
      text: "Delete", tooltipText: "Delete", id: "Delete", disabled: ! SecurityService.canUserAccess(`${props.type}Delete`)
    },
    'Update',
    'Cancel',
  ];

  const toolbarClick = (args: ClickEventArgs) => {
    console.log('toolbar click: ', args);

    if (setupGrid && args.item.properties.id === 'Delete') {
      console.log('toolbar Delete');
      if (setupGrid.selectedRowIndex === -1) {
        // no rows selected
        console.log('Delete ', setupGrid.getSelectedRecords()[0]);
        // setDialogWarningVisibility(false);
        let obj={
          TableName:`ProcessDesc`,
          ColumnName:`ProcessDescID`,
          RowID:undefined,
          SelectedRow:false,
          UserID:UserId,
          // DeleteSecurity:`ProcessDescDelete`
          
        }
        setDeletingProps(obj)
        setShowDialogs(true)
       
      } else {
        console.log('delete: ', setupGrid.getSelectedRecords()[0]?.id)
        // deleteDialog.current.hide();
        let obj={
          TableName:table,
          ColumnName:tableID,
          RowID:setupGrid.getSelectedRecords()[0]?.id,
          SelectedRow:true,
          UserID:UserId,
          // DeleteSecurity:`FabricQualityDelete`,
          SelectedRowData :{
           [tableName] :  setupGrid.getSelectedRecords()[0]?.value
              }
          
          
          
          }
          setDeletingProps(obj)
        
        // deleteDialog.current.show();
        setShowDialogs(true)
      }
    }
  };

  const editSettings: EditSettingsModel = {
    allowEditing: SecurityService.canUserAccess(`${props.type}Edit`),
    allowAdding: SecurityService.canUserAccess(`${props.type}Add`),
    allowDeleting: SecurityService.canUserAccess(`${props.type}Delete`),
    mode: 'Normal',
    showDeleteConfirmDialog: true,
  };

  const filterOptions: FilterSettingsModel = {
    type: 'Excel',
  };

  const sortingOptions: SortSettingsModel = {
    columns: [{ field: 'id', direction: 'Descending' }]
  }

  const actionComplete = (args: DialogEditEventArgs) => {
    console.log('action complete: ', args);
    if (args.form) {
      if (args.requestType === 'beginEdit' || args.requestType === 'add') {
        /** Add Validation Rules */
        // args.form.ej2_instances[0].addRules('id', { disabled: true });

        // args.form.ej2_instances[0].addRules('OrderDate', {
        //   required: true,
        //   date: true,
        // });
      }

      if (args.requestType === 'save' && args.action == 'add') {
        SetupService.insert(props.type, {...args.data , value:descValue}).then((response) => {
          console.log('response: ', response);
          AuditTableInput.auditAdd(table,table,tableID,{ [tableName]:args.data.value})

          setSetup(response.data.recordset);
          // refresh grid to show new record
          // getSetup(props.type);
        });
      }
      if (args.requestType === 'save' && args.action == 'edit') {
        SetupService.update(props.type, {...args.data , value:descValue}).then((response) => {
          console.log('response: ', response);
          // AuditTableInput.auditAdd(props.type,props.type,`${props.type}ID`,{ value:args.data.value})
          AuditTableInput.auditEdit([tableName],selectedRows,{[tableName] :descValue},{table},args.data.id)
          getSetup(props.type);
          // refresh grid to show new record
          // getSetup(props.type);
        });
      }
    }
  };

  // const onSave =() => {
  //   SetupService.insert('YarnBrand',57)
  //   .then((response) => {
  //       console.log('new value has been added: ', response);
  //     //  setShowAddNewDialog(false);
  //   })
  //   .catch((error) => {
  //       // add to existing Yarn Brand
  //     console.log(error);
  //     // setShowAddNewDialog(false);
  //   });
  // }

  const SetupGrid = ({ restrictPageSize = 12 }) => (
    <GridComponent
      ref={(grid) => (setupGrid = grid)}
      id='setupGrid'
      dataSource={setup}
      editSettings={editSettings}
      toolbar={toolbarOptions}
      pageSettings={{ pageCount: 4, pageSizes: true, pageSize: restrictPageSize }}
      filterSettings={filterOptions}
      actionComplete={actionComplete}
      toolbarClick={toolbarClick}
      allowPaging={true}
      allowSorting={true}
      allowFiltering={true}
      sortSettings={sortingOptions}
      idMapping='id'
      rowSelected={
        (args)=>{
          console.log(args.data)
          selectedRows = args.data
          
                    setTimeout(() => {
            console.log('ss')
          }, 200);
        }
      }
    >
      <ColumnsDirective>
        <ColumnDirective
          headerText='ID'
          field='id'
          width='130'
          textAlign='Left'
          // isPrimaryKey={true}
          allowEditing={false}
        // enabled={false}
        />
        <ColumnDirective
          headerText='Description'
          field='value'
          width='250'
          
          editTemplate={(props) =>{
            console.log(props)
            return (
            
            <TextBoxComponent  
            name='value'
            ref={descriptionRef}
            placeholder='Description'
           value={props.value}
            
           
           id='description'
          
           created= {()=>{
          
           setTimeout(() => {
            return descriptionRef.current.element.focus()
           }, 200);
           }}
           onChange={(e)=>{
            console.log(e)

            // let inputColumnName = setupType();
          let     userFeed ={value : e.value}
            console.log(setupData,['value'],userFeed)
            const check =CheckDuplicate.withCombination(setupData,['value'],userFeed)
              
            if(check){
              isDuplicate.current = true;
              let me = document.querySelector('#description');
              console.log(me)
              me.style.border= '3px solid red'
              
              var setupGridUpdateButton = document.querySelector('#setupGrid_update').parentElement;
              console.log(setupGridUpdateButton)
setupGridUpdateButton.style.display = 'none';
// setupGridUpdateButton.style.transform = 'scale(0)';
            }else{
              console.log(e)
              descValue = e.value

              isDuplicate.current = false;
             
              let me = document.querySelector('#description');
              console.log(me)
              me.style.border= '2px solid #5CC95E'
              var setupGridUpdateButton = document.querySelector('#setupGrid_update').parentElement;
              setupGridUpdateButton.style.display = 'inline-flex';
              
// setupGridUpdateButton.style.display = 'block';
//                     var setupGridUpdateButton = document.getElementById('setupGrid_update');
// setupGridUpdateButton.style.visibility = 'visible';
// setupGridUpdateButton.style.transform = 'scale(1)';
            }

           }}
           blur={()=>{
            // console.log(check)
            console.log(CheckDup())
            if(CheckDup()  == true){
              console.log(isDuplicate)
              let me = document.querySelector('#description');
              isDuplicate.current = true;
             console.log(document.querySelector('#setupGrid_update'))
             
            //  setupGridUpdateButton.style.visibility = 'hidden';
            var setupGridUpdateButton = document.querySelector('#setupGrid_update').parentElement;
            setupGridUpdateButton.style.display = 'none';
            //  setupGridUpdateButton.style.transform = 'scale(0)';
                  console.log(me)
                  me.style.border= '3px solid red'
                  // me.targe

            //  return  descriptionRef.current.element.focus()
            }else{
                isDuplicate.current = false;
                
// setupGridUpdateButton.style.visibility = 'visible';
var setupGridUpdateButton = document.querySelector('#setupGrid_update').parentElement;
setupGridUpdateButton.style.display = 'inline-flex';
// setupGridUpdateButton.style.transform = 'scale(1)';
              let me = document.querySelector('#description');
              console.log(me)
              me.style.border= '2px solid #5CC95E'
            }

           }}
            
               />
          )}}
        />
      </ColumnsDirective>
      <Inject
        services={[
          Edit,
          Toolbar,
          Page,
          Sort,
          Filter,
        ]}
      />
    </GridComponent>
  );

  return (
    (props.isDialog) ? (
      <div className='setup-add-container'  onKeyDown={(e)=>{
        console.log(e)
        if(e.keyCode == 13){
          e.stopPropagation()
  
        }
      }}>
          {showDialogs ?
          <HardDelete
            {...deletingProps} 
            showResult={(e)=>{
                                console.log(e)

                                setShowDialogs(false)
                                if(e == false){
                                  setShowDialogs(false)
                                  
                                  getSetup(props.type);
                                }
              }} 
                ></HardDelete>
                  :null}
        <SetupGrid restrictPageSize={5} />
      </div>
    ) :
      (
        <div className='setup-add-container' onKeyDown={(e)=>{
          if(isDuplicate.current ==  true){
            e.stopPropagation()
          }
        }}>
          {/* <div className='row'>
          <TextBoxComponent
            className='e-input'
            type='text'
            placeholder='Yarn Brand'
            name='BarndName'
            onChange={onChange}
            floatLabelType='Auto'
          />
        </div>
        <div>
          <button id="btnSave" className="e-control e-btn e-primary" onClick={()=> props.newValue(brandName)}>Save</button>
        </div>
        <div>
        </div> */}
          {/* <button id="btnSave" className="e-control e-btn e-primary" onClick={onSave}>Save</button> */}
          {/* <button onClick={()=> props.changeWord('Child')}>Change title</button>  */}
          {showDialogs ?
          <HardDelete
            {...deletingProps} 
            showResult={(e)=>{
                                console.log(e)

                                setShowDialogs(false)
                                if(e == false){
                                  setShowDialogs(false)
                                  
                                  getSetup(props.type);
                                }
              }} 
                ></HardDelete>
                  :null}
          <SetupGrid />
        </div>
      )
  );
}

// import React, { Component } from 'react';
// import SetupService from '../../services/setup.service';

// import './setup-edit.scss';



// export default class SetupAdd extends Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       currentSetup: {
//         description: '',
//       },
//       isSuccess: '',
//       message: '',
//       heading: '',
//       action: '',
//     };
//   }

//   componentDidMount = () => {
//     // this.getSetupById(this.route[1], this.props.match.params.id);
//     // this.setState({
//     //   heading: this.props.location.state.action,
//     // });
//   };

//   onChangeSetup = (e) => {
//     this.setState({
//       currentSetup: {
//         value: e.target.value,
//       },
//     });
//   };

//   setTitle = (route) => {
//     let title: string;
//     switch (route) {
//       case 'Broker':
//         title = 'Broker';
//         break;Broker
//       case 'BrokerID':
//         title = 'Broker Count';
//         break;
//       case 'YarnQuality':
//         title = 'Yarn Quality';
//         break;
//       case 'YarnType':
//         title = 'Yarn Type';
//         break;
//       case 'YarnBrand':
//         title = 'Yarn Brand';
//         break;
//       case 'YarnGodown':
//         title = 'Yarn Godown';
//         break;

//       case 'ImportLC':
//         title = 'Import LC';
//         break;

//       case 'HSCode':
//         title = 'HS Code';
//         break;

//       case 'FabricType':
//         title = 'Fabric Type';
//         break;

//       case 'FabricYarnDescription':
//         title = 'Fabric Yarn Description';
//         break;

//       case 'FabricCategory':
//         title = 'Fabric Category';
//         break;

//       case 'FabricThreadCount':
//         title = 'Fabric Thread Count';
//         break;

//       case 'Vendors':
//         title = 'Vendors';
//         break;

//       case 'CompanyType':
//         title = 'Company Type';
//         break;

//       case 'Currency':
//         title = 'Currency';
//         break;

//       case 'ImportExpenses':
//         title = 'ImportExpenses';
//         break;

//       default:
//         break;
//     }
//     return title;
//   };

//   addSetup = () => {
//     SetupService.insert(this.route[1], this.state.currentSetup)
//       .then((response) => {
//         if (response.status === 200) {
//           this.setState({
//             isSuccess: 'true',
//             message: `<strong>Success! </strong> ${this.setupType} has been added.`,
//           });
//           this.redirect();
//         }
//       })
//       .catch((error) => {
//         this.setState({
//           isSuccess: 'false',
//           message: `<strong>Failure! </strong> An error has occured while updating ${this.setupType}.`,
//         });
//         console.log(error);
//       });
//   };

//   redirect = () => {
//     setTimeout(() => {
//       this.props.history.push('/Setup/' + this.route[1]);
//     }, 2000);
//   };

//   route = window.location.pathname
//     .substr(1, window.location.pathname.length)
//     .split('/');

//   setupType = this.setTitle(this.route[1]);

//   render() {
//     const { currentSetup } = this.state;
//     return (
//       <div className='setup-container'>
//         <div className='list row'>
//           <div className='col-md-6'>
//             <h4 style={{ textAlign:'center', width: '80%' }}> Add - {this.setupType}</h4>
//           </div>
//         </div>
//         {this.state.isSuccess === 'true' ? (
//           <div className='alert alert-success' id='success-alert'>
//             <button type='button' className='close' data-dismiss='alert'>
//               x
//             </button>
//             {this.state.message}
//           </div>
//         ) : null}
//         {this.state.isSuccess === 'false' ? (
//           <div className='alert alert-danger' id='danger-alert'>
//             <button type='button' className='close' data-dismiss='alert'>
//               x
//             </button>
//             {this.state.message}
//           </div>
//         ) : null}

//         <form>
//           <div className='form-group'>
//             {/* <label htmlFor='godown'>ID</label>
//             <input
//                 type='text'
//                 className='form-control'
//                 id='id'
//                 value={currentSetup.id}
//                 readOnly
//             /> */}
//             <label htmlFor='godown'>Description</label>
//             <input
//               type='text'
//               className='form-control'
//               id='description'
//               value={currentSetup.value}
//               onChange={this.onChangeSetup}
//             />
//           </div>
//         </form>
//         <button className='m-3 btn btn-sm btn-warning' onClick={this.addSetup}>
//           Add
//         </button>
//       </div>
//     );
//   }
// }
