
import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { Page, Text, View, Document, StyleSheet, PDFViewer } from '@react-pdf/renderer';


// Create styles
const styles = StyleSheet.create({
  page: {
    padding: 15,
    // paddingBottom:40
  },
  header: {
    textAlign: 'center',
    fontSize: 18,
    marginBottom: 5,
  },
  section: {
    marginBottom: 10,
  },
  table: {
    display: 'table',
    width: 'auto',
    borderStyle: 'solid',
    borderWidth: 1,
    borderRightWidth: 1,
    borderBottomWidth: 0,
    marginTop: '5px'
  },
  tableRow: {
    // margin: 'auto',
    flexDirection: 'row',
  },
  tableCol: {
    width: '25%',
    borderBottomStyle: 'solid',
    borderBottomWidth: 1,
    borderLeftWidth: 0,
    borderRightWidth: 0,
    borderTopWidth: 0,
  },
  tableCell: {
    margin: 5,
    fontSize: 10,
  },
  footer: {
    textAlign: 'center',
    fontSize: 12,
    marginTop: 50,
    display: 'flex',
    width: '100%',
    justifyContent: 'space-around',
    flexDirection: 'row',
  },
  boxes: {
    border: '1px solid #000',
    padding: '10px 20px',
    fontWeight: 'bold',
  },
});

// Calculate totals


// Create Document Component
const Report = ({data ,fromDate, toDate }) => {
console.log(data)

  return (
    // <PDFViewer width="100%" height="1000" fileName='Voucher-Report'>
      <Document title='Sale Report'>
        <Page style={styles.page}>
          <Text style={styles.header}>HAP INTERNATIONAL LIMITED - MOZAMBIQUE</Text>
          <Text style={{...styles.header , fontSize:'15px'}}>SALE BOOK</Text>

          <View style={{ ...styles.tableRow, margin: '0px' }}>
            <View style={{ ...styles.tableCol, width: '20%', borderWidth: 0 }}>
              <Text style={{ ...styles.tableCell, fontWeight: 'bold' }}>From</Text>
            </View>
            <View style={{ ...styles.tableCol, width: '80%', borderWidth: 0 }}>
              <Text style={styles.tableCell}>{fromDate}</Text>
            </View>
            </View>
            
          <View style={{ ...styles.tableRow, margin: '0px' }}>
            <View style={{ ...styles.tableCol, width: '20%', borderWidth: 0 }}>
              <Text style={{ ...styles.tableCell, fontWeight: 'bold' }}>To</Text>
            </View>
            <View style={{ ...styles.tableCol, width: '80%', borderWidth: 0 }}>
              <Text style={styles.tableCell}>{toDate}</Text>
            </View>
            
            
          </View>

          <View style={styles.table}>
            <View style={styles.tableRow} fixed>
              <View style={{ ...styles.tableCol, borderTopWidth:1 ,width: '10%' }}>
                <Text style={{...styles.tableCell,fontWeight:'bold' ,fontSize:'10px'}}>Date</Text>
              </View>
              <View style={{ ...styles.tableCol, borderTopWidth:1 ,width: '8%' }}>
                <Text style={{...styles.tableCell,fontWeight:'bold' ,fontSize:'10px'}}>Sno</Text>
              </View>
              <View style={{ ...styles.tableCol, borderTopWidth:1 ,width: '20%' }}>
                <Text style={{...styles.tableCell,fontWeight:'bold' ,fontSize:'10px'}}>Item Name</Text>
              </View>
              <View style={{ ...styles.tableCol, borderTopWidth:1 ,width: '12%' }}>
                <Text style={{...styles.tableCell,fontWeight:'bold' ,fontSize:'10px'}}>Brand</Text>
              </View>
              <View style={{ ...styles.tableCol, borderTopWidth:1 ,width: '10%' }}>
                <Text style={{...styles.tableCell,fontWeight:'bold' ,fontSize:'10px'}}>Qty</Text>
              </View>
              <View style={{ ...styles.tableCol, borderTopWidth:1 ,width: '8%' }}>
                <Text style={{...styles.tableCell,fontWeight:'bold' ,fontSize:'10px'}}>Rate $</Text>
              </View>
              <View style={{ ...styles.tableCol, borderTopWidth:1 ,width: '12%' }}>
                <Text style={{...styles.tableCell,fontWeight:'bold' ,fontSize:'10px'}}>Amount $</Text>
              </View>
              <View style={{ ...styles.tableCol, borderTopWidth:1 ,width: '8%' }}>
                <Text style={{...styles.tableCell,fontWeight:'bold' ,fontSize:'10px'}}>Rate
                Meticas</Text>
              </View>
              <View style={{ ...styles.tableCol, borderTopWidth:1 ,width: '12%' }}>
                <Text style={{...styles.tableCell,fontWeight:'bold' ,fontSize:'10px'}}>Amount
                Meticas</Text>
              </View>
            </View>
            {Object.keys(data.groupedData).map((Group, GroupKey) =>{
            return(<>
            <View key={GroupKey} style={{ marginbottom:'8px',width: '100%', backgroundColor:'gray',padding: '5px', textAlign: 'center',fontSize:'12px' , fontWeight:'bold' }} >
              <Text style={{fontWeight:'bold'}}>{Group}</Text>
            </View>
            {data.groupedData[Group].items.map((item, index) => {
              
              return (
                <View style={{...styles.tableRow, marginTop:'2px'}} key={index}>
              <View style={{ ...styles.tableCol, width: '10%' }}>
                <Text style={styles.tableCell}>{moment(item.InvoiceDate).format('DD-MMM-YY')}</Text>
              </View>
              <View style={{ ...styles.tableCol, width: '8%' }}>
                <Text style={styles.tableCell}>{item.InvoiceID}</Text>
              </View>
              <View style={{ ...styles.tableCol, width: '20%' }}>
                <Text style={styles.tableCell}>{item.AccountTitle}</Text>
              </View>
              <View style={{ ...styles.tableCol, width: '10%' }}>
                <Text style={styles.tableCell}>{item.BrandName}</Text>
              </View>
              <View style={{ ...styles.tableCol, width: '10%' }}>
                <Text style={{...styles.tableCell , textAlign:'right'}}>{item?.InvoiceQuantity.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}</Text>
              </View>
              <View style={{ ...styles.tableCol, width: '9%' }}>
                <Text style={{...styles.tableCell , textAlign:'right'}}>{item?.InvoiceRateUSD}</Text>
              </View>
              <View style={{ ...styles.tableCol, width: '12%' }}>
                <Text style={{...styles.tableCell , textAlign:'right'}}>{item?.InvoiceAmountUSD.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}</Text>
              </View>
              <View style={{ ...styles.tableCol, width: '9%' }}>
                <Text style={{...styles.tableCell , textAlign:'right'}}>{item?.InvoiceRateLocalCurrency.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Text>
              </View>
              <View style={{ ...styles.tableCol, width: '12%' }}>
                <Text style={{...styles.tableCell , textAlign:'right'}}>{item?.InvoiceAmountLocalCurrency.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}</Text>
              </View>
              </View>
          );
            })}
            <View style={styles.tableRow}>
              <View style={{ ...styles.tableCol, width: '38%' }}>
                <Text style={{ ...styles.tableCell, fontWeight: 'bold', borderRightWidth: 'transparent' }}>Total</Text>
              </View>
              <View style={{ ...styles.tableCol, width: '20%', textAlign: 'right' }}>
                <Text style={{ ...styles.tableCell, fontWeight: 'bold' }}>{data.groupedData[Group].totals.InvoiceQuantity.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}</Text>
              </View>
              <View style={{ ...styles.tableCol, width: '21%', textAlign: 'right' }}>
                <Text style={{ ...styles.tableCell, fontWeight: 'bold' }}>{data.groupedData[Group].totals.InvoiceAmountUSD.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}</Text>
              </View>
              <View style={{ ...styles.tableCol, width: '21%', textAlign: 'right' }}>
                <Text style={{ ...styles.tableCell, fontWeight: 'bold' }}>{data.groupedData[Group].totals.InvoiceAmountLocalCurrency.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}</Text>
              </View>
            </View>
             </>
             
         )})}

            
          </View>

          </Page>
      </Document>
    // </PDFViewer>
  );
};

const SaleReport = (props) => {

  return (
    <>
          <Report data={props.DataSource} fromDate={props.FromDate} toDate={props.ToDate}/> 
          </>
  );
};

export default SaleReport;
