import React , {useEffect , useState} from 'react';
import { TextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { AutoCompleteComponent } from '@syncfusion/ej2-react-dropdowns';
import { RadioButtonComponent} from '@syncfusion/ej2-react-buttons'; // Import necessary components from Syncfusion
import listTempleteService from '../../services/list-templete.service';
import SecurityService from '../security/security.service';
import { useDispatch } from 'react-redux';
import './template-code.scss'
import {  gridColumnsList, gridSortingOptions , getprintedByUserName} from '../../store/slices/list-tempate'

const TemplateCode = ({ 
  isToggled ,
  ColumnProps ,
  getListService ,
  getColumns , 
  InputListSecurity ,
  visibleSortPassFields ,
  visibleSortPass ,
  groupingArray ,
  defaultSortColumnName ,
  defaultSortColumnType,
  isHaveFilterColumn =  true
}) => {

    const dispatch = useDispatch()

    const [templateDropdownList, setTemplateDropdownList] = useState('');
    const [loadingDropDown, setLoadingDropDown] = useState('');
    const [templateList, setTemplateList] = useState();
    const userId = SecurityService.getLoggedInUserId();

    // 1=  PrintedByUserName use IN toolbar pdf and excel export
    const [printedByUserName, setPrintedByUserName] = useState();
    
    // 2=  visibleFields use IN render grid columns with all details
    const [visibleFields, setVisibleFields] = useState(ColumnProps);
    
    // 3=  visibleSortFields use IN sorted columns name of grid 
    const [visibleSortFields, setVisibleSortFields] = useState([defaultSortColumnName]);
    
    // 4=  visibleSort use IN sorted type of grid 
    const [visibleSort, setVisibleSort] = useState([defaultSortColumnType]);

    // 5= sortingOptions  use IN  sortingOptions of Grid
    const [sortingOptions, setSortingOptions] = useState({
      columns: [{ field: defaultSortColumnName, direction: defaultSortColumnType }]
    });

    // 6= selectedOrientation use IN orientation of pdf Export in grid 
    var selectedOrientation = 'Landscape'
    
    let headerCheck = false;
    // 7= securityItemID use IN get Template list by securityItemID of input
    const [securityItemID, setSecurityItemID] = useState();
    
    // 8= listTemplatedropDownValue use IN template drop down value
    const [listTemplatedropDownValue, setListTemplatedropDownValue] = useState('');

    // 9= listTemplatedropDownID use IN template drop down ID
    const [listTemplatedropDownID, setListTemplatedropDownID] = useState(0);
     
    // 10= groupColumn use IN render grid columns with all details
      const [groupColumn, setGroupColumn] =useState([])

      const ddlFields: object = { value: 'value' };

    useEffect(() => {
        SecurityService.getUserAccessList(userId).then((res)=>{
            console.log(res)
         let securityItemID =    res.data.recordset.filter(res=> res.ItemName == InputListSecurity)
            console.log(res.data.recordset[0].UserName)
            setSecurityItemID(securityItemID[0].SecurityItemID)
            setPrintedByUserName(res.data.recordset[0].UserName)
            dispatch(getprintedByUserName(res.data.recordset[0].UserName))
            getListTemplate(securityItemID[0].SecurityItemID)
           });
          //  react redux code in useEffect

          dispatch(gridColumnsList(ColumnProps))
          dispatch(gridSortingOptions({
            columns: [{ field: defaultSortColumnName, direction: defaultSortColumnType }]
          }))
    }, []);

    useEffect(() => {
        const updatedSortingOptions = {
          columns: visibleSortFields.map((field, index) => ({
            field: field,
            direction: visibleSort[index]
          }))
        };
        setSortingOptions(updatedSortingOptions);
        dispatch(gridSortingOptions(updatedSortingOptions))
      }, [visibleFields, visibleSort]);
     
    const getListTemplate = (id)=>{
        try{
        listTempleteService.getAllBySecurityItemID(id).then((res)=>{
          console.log(res)
          setLoadingDropDown(false)
          let data = [...res.data.recordset]
          const listForDropdown = data.map((val)=>{return {id: val.ListTempleteID, value:val.TempleteName}})
          console.log(listForDropdown)
          setTemplateDropdownList(listForDropdown)
          setTemplateList(res.data.recordset)
          console.log(templateList)
         })
        } catch (error) {
          setLoadingDropDown(false)
    
          
        }
      }

// SEND TO GRID COLUMNS DETAIL 
const getVisibleColumn = (e) =>{

    let filterTemplate = templateList.filter((val)=>{ return val.ListTempleteID == e})
    console.log(visibleFields)
    console.log(ColumnProps)
    console.log(filterTemplate)
  // visiblity
    const visibleColumns = filterTemplate.map((res) => {
      let templeteColumnsHeaderText = res.TempleteColumnsHeaderText.split(',')
      let templeteColumnsWidth = res.TempleteColumnsWidth.split(',')
      let templeteColumnsTextAlign = res.TempleteColumnsTextAlign.split(',')
      let templeteColumnsHeaderTextAlign = res.TempleteColumnsHeaderTextAlign.split(',')
      console.log(res);
      if (res.TempleteColumns.includes(',')) {
          return res.TempleteColumns.split(',').map((item,index) => {
              return {
                  field: item.trim(),
                  headerText: templeteColumnsHeaderText[index],
                  width: templeteColumnsWidth[index],
                  textAlign: templeteColumnsTextAlign[index],
                  headerTextAlign: templeteColumnsHeaderTextAlign[index],
                  other: ColumnProps.find(obj => obj.field == item.trim()).other
              };
          });
      } else {
          return [{
              field: res.TempleteColumns.trim(),
              headerText: templeteColumnsHeaderText[templeteColumnsHeaderText.length],
              width: templeteColumnsWidth[templeteColumnsHeaderTextAlign.length],
              textAlign: templeteColumnsTextAlign[templeteColumnsHeaderTextAlign.length],
              headerTextAlign: templeteColumnsHeaderTextAlign[templeteColumnsHeaderTextAlign.length],
              other: ColumnProps.find(obj => obj.field === res.TempleteColumns.trim()).other
            }];
      }
  });
  if(visibleColumns[0].some(res=>res.field == 'IsDeleted')){

    console.log(visibleColumns);
    setVisibleFields(visibleColumns[0]) 
    dispatch(gridColumnsList(visibleColumns[0]))
  }else if(isHaveFilterColumn == true){
    console.log(visibleColumns);
    
    setVisibleFields([...visibleColumns[0],
      { field: "IsDeleted", headerText: 'Cancel', width: '130px', textAlign: 'right', headerTextAlign: 'center', other: [{'visible' :false}] }
    ]) 
    dispatch(gridColumnsList([...visibleColumns[0],
      { field: "IsDeleted", headerText: 'Cancel', width: '130px', textAlign: 'right', headerTextAlign: 'center', other: [{'visible' :false}] }
    ]))
  }else{
    console.log(visibleColumns);
    setVisibleFields(visibleColumns[0]) 
    dispatch(gridColumnsList(visibleColumns[0]))
  }
  // [
  //   {  field: "ProductFinishSizeID",headerText:'ID',width:'80px',textAlign:'right' ,headerTextAlign:'center',other:[{'format':'n0'}]},
  //   {  field: "FinishSize",headerText:'Finish Size',width:'110px',textAlign:'left' ,headerTextAlign:'center',other:[]},
  //   {  field: "ProductDesc",headerText:'Product Desc',width:'140px',textAlign:'left' ,headerTextAlign:'center',other:[]},
  //   {  field: "IsDeleted",headerText:'Cancel',width:'100px',textAlign:'right' ,headerTextAlign:'center',other:[]},
  // ]
      
  // Sorting columns 
  const visibleSortField =filterTemplate.map((res)=>{return res.SortedByColumn.includes(',')
  ? res.SortedByColumn.split(',').map(item => item.trim())
  : [res.SortedByColumn]})
  console.log([...visibleSortField][0])
    setVisibleSortFields([...visibleSortField][0]);
    
    // Sorting types
    const visibleSrt =filterTemplate.map((res)=>{return res.SortingType.includes(',')
    ? res.SortingType.split(',').map(item => item.trim())
    : [res.SortingType]})
    console.log(visibleSrt[0])
    setVisibleSort(visibleSrt[0]);

// pdf orientation
    let templateOrientation = filterTemplate[0].PageAlignment;
    setTimeout(() => {
      console.log(document.getElementsByClassName('orientation'),templateOrientation)
      if(templateOrientation ==  'Landscape'){
        document.getElementsByClassName('orientation')[1].checked = true;
         selectedOrientation = templateOrientation
        }else{
          document.getElementsByClassName('orientation')[0].checked = true;
          selectedOrientation = templateOrientation
        }
      }, 200);
  
      document.getElementById('reportName').value = filterTemplate[0].ReportName

    console.log(templateList)
    setTimeout(() => {
      let groups = templateList.filter((val)=>{ return val.ListTempleteID == e}).map((res)=>{return res.GroupedByColumn.includes(',')
      ? res.GroupedByColumn.split(',').map(item => item.trim())
      : [res.GroupedByColumn]})
      console.log(groups)
      setGroupColumn(groups[0])
      getListService() 
  }, 330);
  
  }

  


// SAVE Template
  const saveTemplate = () => {
    const visibleFalseColumns =ColumnProps.filter(col => col.other && col.other[0] && col.other[0].visible !== undefined && col.other[0].visible === false);
     console.log(visibleFalseColumns) 
    const  visibleTrueColumns = getColumns().filter((col) => col.visible);
    const visibleColumns =[...visibleTrueColumns, ...visibleFalseColumns]
    let TempleteName = document.getElementById('templateName').value
    let reportName = document.getElementById('reportName').value;
    console.log(TempleteName)
    let getOrientation = document.getElementsByClassName('orientation')
     selectedOrientation = getOrientation[0]?.checked ==  true ? 'Portrait':'Landscape'
    console.log(selectedOrientation) 
    const template = {
      TempleteName,
      TempleteColumns: visibleColumns.map((col,index) => col.headerText == 'Commands'? col.headerText : col.field),
      TempleteColumnsHeaderText: visibleColumns.map((col) => col.headerText),
      TempleteColumnsHeaderTextAlign: visibleColumns.map((col) => col.headerTextAlign),
      TempleteColumnsTextAlign: visibleColumns.map((col) => col.textAlign),
      TempleteColumnsWidth: visibleColumns.map((col) => col.width),
      UserID:userId,
      SecurityItemID:securityItemID,
      SortedByColumn: visibleSortPassFields.current.filter(res => res != '' && res != undefined),
      SortingType: visibleSortPass.current.filter(res => res != '' && res != undefined),
      PageAlignment:selectedOrientation,
      ReportName: reportName,
      GroupedByColumn:groupingArray,
      GroupingType:	[]
    };
    console.log('saving template', template)

    listTempleteService.addListTemplete(template).then((res)=>{
      getListTemplate(securityItemID)
      getListService()
      document.getElementById('templateName').value = ''
      console.log(res)
    })
    
  };
  
  


    //   CHANGE
    const listTemplateChange = (e)=>{
        console.log(e)
        if(e.itemData  == null){
          getListService()
          setVisibleFields(ColumnProps)
          dispatch(gridColumnsList(ColumnProps))
          setVisibleSortFields([defaultSortColumnName]);
    setVisibleSort([defaultSortColumnType]);
    // setGroupColumn([])
    getListService()
        }else{
        setListTemplatedropDownValue(e.itemData.value)
        if(e?.e?.srcElement?.id){
      if(e?.itemData?.id){
        setListTemplatedropDownID(e?.itemData.id)
      getVisibleColumn(e?.itemData?.id)
    }else{
      setListTemplatedropDownID(0
        )
        getListService()
      setVisibleFields(ColumnProps)
      dispatch(gridColumnsList(ColumnProps))
      // setGroupColumn([])
    
    }
        }
      }}
      
      const [existValue, setExistValue] =useState(false)
  return (
    <div className={`template-list ${isToggled ? 'active' : ''}`}>
      {loadingDropDown ? (
        <div>... Loading Product Desc </div>
      ) : (
        <div className='drop-down-div'>
          <AutoCompleteComponent
            id="listTemplate" 
            dataSource={templateDropdownList}
            placeholder="Select template" 
            fields={ddlFields}
            floatLabelType='Auto' 
            highlight={true}
            select={listTemplateChange}
            change={listTemplateChange}
            style={{width:'275px'}}
            value={listTemplatedropDownValue}
            blur={(e)=>{
              if(listTemplatedropDownID === 0){
                // setListTemplatedropDownValue('')
              }
            }}
          />
          <RadioButtonComponent
            label="Portrait" 
            name="orientation"
            value="portrait"
            className='orientation'
            checked={selectedOrientation === 'Portrait'}
            // change={handleOrientationChange}
            style={{marginLeft:'10px'}}
          />
          <RadioButtonComponent
            label="Landscape"
            name="orientation"
            className='orientation'
            value="landscape"
            checked={selectedOrientation === 'Landscape'}
            // change={handleOrientationChange}
          />
          {
                           existValue == true? 
                        <div className='' id= 'check-contact-nos'>
                        <div className='tip-content' id='tip-content-irtiza'>Template name already exists!
                        </div></div>:null
                        }
        </div>
      )}
      <div className='template-save'>
        <TextBoxComponent
          type="text"
          placeholder="Template Name"
          id='templateName'
          name='TemplateName'
          change={(e)=>{
            console.log(e.value)
            console.log(templateDropdownList)
            let existValues = templateDropdownList.some(res=> {
              console.log((e.value).toUpperCase() )
              console.log(  (res.value).toUpperCase())
              return (e.value).toUpperCase() ==  (res.value).toUpperCase()})
            console.log(existValues)
            setExistValue(existValues)
            
          }}
          blur={(e)=>{
            console.log(existValue)
            if(existValue == true){

              const TemplateNameInput = document.querySelector('input[name="TemplateName"]');
              TemplateNameInput.focus()
              TemplateNameInput.style.border = '2px solid red'
              setTimeout(() => {
                TemplateNameInput.style.border = 'none'
              }, 400);
            }
            }}
        />
          
        <button onClick={(e) => { e.preventDefault(); saveTemplate() }} id='save-btn'> Save Template</button>
        <TextBoxComponent
          type="text"
          placeholder="Report Name"
          id='reportName'
        />
        <div id='header-checkbox'>
          <RadioButtonComponent
            label="only data"
            name="headerCheck"
            value="headerOff"
            className='headerCheck'
            checked={headerCheck === false}
            // change={handleOrientationChange}
            style={{marginLeft:'10px'}}
          />
          <RadioButtonComponent
            label="with format"
            name="headerCheck"
            className='headerCheck'
            value="headerOn"
            checked={headerCheck === true}
            // change={handleOrientationChange}
          />
        </div>
      </div>
      <div className='template-save'>
        {/* Additional code for 'template-save' section can be added here if needed */}
      </div>
    </div>
  );
};

export default TemplateCode;
