import http from '../../services/http-common';

class InvoiceService {
    getInvoiceForList(id,type,listType) {
        return http.get(`/Invoice/list/${id}/${type}/${listType}`);
    }
    getNewInvoiceID(id,type) {
        return http.get(`/Invoice/newInvoiceID/${id}/${type}`);
    }
    getAccountBill(type) {
        return http.get(`/Invoice/accountBill/${type}`);
    }
    getGridDataSource(shopid,brandid) {
        return http.get(`/Invoice/gridDataSource/${shopid}/${brandid}`);
    }
    getUnitOfMeasurement(id) {
        return http.get(`/Invoice/unitOfMeasurement/${id}`);
    }
    addInvoice(data) {
        return http.post(`/Invoice/add`, data);
    }
    addGridInvoice(data) {
        return http.post(`/Invoice/grid/add`, data);
    }
    editInvoice(data) {
        return http.put(`/Invoice/edit`, data);
    }
    editGridInvoice(data) {
        return http.put(`/Invoice/grid/edit`, data);
    }
    getScratechGridDataSource(data) {
        return http.post(`/Invoice/scratech/list`, data);
    }
    getMultiDropDownSource(ReportType ) {
        console.log(ReportType)

            let ReportTypeSplit = ReportType.split(',')
            console.log(ReportTypeSplit)
        let data ={
            ReportType :ReportTypeSplit[0]
           ,MainInvoiceID :ReportTypeSplit[1]
           ,FromDate:ReportTypeSplit[2]
           ,ToDate:ReportTypeSplit[3]
           ,BrandID:ReportTypeSplit[4]
           ,ProductID:ReportTypeSplit[5]
           ,ShopID:ReportTypeSplit[6]
        }
        console.log(data);
     
        return http.post(`/Invoice/product/multidropdown`, data);
    }
    
    // getReportDataSource(data) {
    //     return http.post(`/Invoice/Inventory/Report`, data);
    // }

}

export default new InvoiceService();