import { React } from 'react';
import securityService from './security.service';
import AccessDenied from './access-denied/access-denied';

import CurrencyTransferList from '../currency-transfer/currency-transfer';
import InvoiceEvaluationList from '../invoice-evaluation/invoice-evaluation';
import InvoiceSummaryList from '../invoice/invoice-summary';
import InvoiceDetailList from '../invoice/invoice-detail';
import ShopTransferSummaryList from '../shop-transfer/shop-transfer-summary';
import ShopTransferDetailList from '../shop-transfer/shop-transfer-detail';
import VoucherSummaryList from '../voucher/voucher-summary';
import VoucherDetailList from '../voucher/voucher-detail';
import AccountTransferList from '../account-transfer/account-transfer';
import BrandList from '../brand/brand';
import BrandVendorList from '../brand-vendor/brand-vendor';
import UnitOfMeasurementList from '../unit-of-measurement/unit-of-measurement';
import CurrencyList from '../currency/currency';
import EvaluationRateList from '../evaluation-rate/evaluation-rate';
import ProductList from '../product/product';
import ProductTransferList from '../product-transfer/product-transfer';
import ShopList from '../shop/shop';
import SystemLockList from '../system-lock/system-lock';
import DesignationList from '../designation/designation';
import UsersList from '../users/users-list';
import ChartOfAccountList from '../chart-of-account/chart-of-account-list';
import ExportToExcelList from '../reports/voucher/voucher-xslx';
import ChangePasswordList from "../change-password/change-password";
import ChangeAccessList from '../security/change-access/change-access';
import Inventory from '../reports/inventory/inventory';
import InvoiceCosting from '../reports/invoice-costing/invoice-costing';
import StockReportValue from '../reports/stock-report-with-value/stock-report-with-value';
import Voucher from '../reports/voucher/voucher';
import UserReplicateList from "../user-replicate/user-replicate";
import ChartOfAccountReport from "../reports/chart-of-account/chart-of-account";
import SendEmailList from "../reports/send-email/send-email";
import TestInputList from "../test-input/test-input-list";


export default function SecureComponent(props) {
    const canUserAccess = securityService.canUserAccess(props.name);
    if (!canUserAccess) {
        // return <AccessDenied {...props} />

        switch (props.name) {
            case 'StockShopWise':
                    return <Inventory {...props} /> 
                case 'InvoiceCosting':
                    return <InvoiceCosting {...props} /> 
                case 'StockWithOutValue':
                    return <StockReportValue {...props} /> 
                case 'StockWithValue':
                    return <StockReportValue {...props} /> 
                case 'CashPaymentPreview':
                    return <Voucher {...props} /> 
                case 'CashReceiptPreview':
                    return <Voucher {...props} /> 
                case 'JournalVoucherPreview':
                    return <Voucher {...props} /> 
            case 'ExportToExcelList':
                return <ExportToExcelList {...props} />
            case 'BrandVendorList':
                    return <BrandVendorList {...props} />
                   
                    case 'ChartOfAccountReport':
                        return <ChartOfAccountReport  {...props}/>
                    case 'SendEmailList':
                        return <SendEmailList  {...props}/>
                    case 'TestInputList':
                        return <TestInputList  {...props}/>
                default:
                return <AccessDenied {...props} />
            break;
    }
    }
    else {
        switch (props.name) {
            case 'CurrencyTransferList':
                return <CurrencyTransferList {...props} />
            case 'InvoiceEvaluationList':
                return <InvoiceEvaluationList {...props} />
            case 'PurchaseInvoiceList':
                if(props.location.pathname.split('/')[3] == 'Summary'){
                    return <InvoiceSummaryList {...props} />
                }else{
                    return <InvoiceDetailList {...props} />
                }
            case 'SaleInvoiceList':
                if(props.location.pathname.split('/')[3] == 'Summary'){
                    return <InvoiceSummaryList {...props} />
                }else{
                    return <InvoiceDetailList {...props} />
                }
            case 'ShopTransferList':
                if(props.location.pathname.split('/')[2] == 'Summary'){
                    return <ShopTransferSummaryList {...props} />
                }else{
                    return <ShopTransferDetailList {...props} />
                }    
            // return <ShopTransferList {...props} />
            case 'ReceiptVoucherList':
                if(props.location.pathname.split('/')[3] == 'Summary'){
                    return <VoucherSummaryList {...props} />
                }else{
                    return <VoucherDetailList {...props} />
                }  
                // return <VoucherList {...props} />
            case 'PaymentVoucherList':
                if(props.location.pathname.split('/')[3] == 'Summary'){
                    return <VoucherSummaryList {...props} />
                }else{
                    return <VoucherDetailList {...props} />
                }  
                // return <VoucherList {...props} />
            case 'JournalVoucherList':
                if(props.location.pathname.split('/')[3] == 'Summary'){
                    return <VoucherSummaryList {...props} />
                }else{
                    return <VoucherDetailList {...props} />
                } 
            case 'AccountTransferList':
                return <AccountTransferList {...props} />
            case 'BrandList':
                return <BrandList {...props} />
            case 'BrandVendorList':
                return <BrandVendorList {...props} />
            case 'UnitOfMeasurementList':
                return <UnitOfMeasurementList {...props} />
            case 'CurrencyList':
                return <CurrencyList {...props} />
            case 'EvaluationRateList':
                return <EvaluationRateList {...props} />
            case 'ProductList':
                return <ProductList {...props} />
            case 'ProductTransferList':
                return <ProductTransferList {...props} />
            case 'ShopList':
                return <ShopList {...props} />
            case 'SystemLockList':
                return <SystemLockList {...props} />
                case 'ChangePasswordList':
                    return <ChangePasswordList {...props}/>
                    case 'DesignationList':
                    return <DesignationList {...props} />
                case 'UsersList':
                    return <UsersList {...props} />
                case 'ChartOfAccountList':
                    return <ChartOfAccountList {...props} /> 
                case 'ChangeAccessList':
                    return <ChangeAccessList {...props} /> 
                    case 'UserReplicateList':
                        return <UserReplicateList  {...props}/>
                // return <VoucherList {...props} />
                
                // return <VoucherList {...props} />
            break;
        }
    }
}
