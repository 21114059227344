import React ,{useRef}from 'react';
import './invoice-evaluation.scss'
import IrtizaDynamicInputList from '../irtiza-dynamic-input/irtiza-dynamic-input-list';
import moment from 'moment'
import SecurityService from '../security/security.service';
import InvoiceEvaluationService from '../invoice-evaluation/invoice-evaluation.service';
import InvoiceEvaluationAdd from './invoice-evaluation-add';
import AuditTableInput from '../audit-table/audit-table'


const InvoiceEvaluationInput = (props) => {
    let columns =[
        {  field: "InvoiceEvaluationID",headerText:'ID',width:'90px',textAlign:'right' ,headerTextAlign:'center',other:[]},
        {  field: "InvoiceNo",headerText:'Invoice Number',width:'160px',textAlign:'left' ,headerTextAlign:'center',other:[]},
        {  field: "CurrencyName",headerText:'Currency Name',width:'135px',textAlign:'left' ,headerTextAlign:'center',other:[]},
        {  field: "ExchangeRate",headerText:'Exchange Rate',width:'110px',textAlign:'right' ,headerTextAlign:'center',other:[{'format':'n1'}]},
        {  field: "HawalaCommissionPercent",headerText:'Hawala Commission %',width:'120px',textAlign:'right' ,headerTextAlign:'center',other:[{'format':'n2'}]},
        {  field: "CurrencyNameHawala",headerText:'Currency',width:'135px',textAlign:'left' ,headerTextAlign:'center',other:[{'visible':false}]},
        {  field: "AccountTitleHawalaDr",headerText:'Hawala A/C Dr',width:'150px',textAlign:'left' ,headerTextAlign:'center',other:[]},
        {  field: "AccountTitleHawalaCr",headerText:'Hawala A/C Cr',width:'150px',textAlign:'left' ,headerTextAlign:'center',other:[]}
       ]

       const listActionComplete = (grid,args ,getListService ,selectedRow )=>{
        console.log(args )
        console.log(getListService )
        console.log(selectedRow )
        if (args.requestType === 'beginEdit' || args.requestType === 'add') {
          }
        if (args.requestType == 'save' || args.requestType == 'cancel' ) {
          getListService()

        }

        if (args.requestType == 'save' && args.action == 'add') {
          console.log(args)
            InvoiceEvaluationService.insert({...args.data, CreatedByUserID:SecurityService.getLoggedInUserId()}).then((res)=>{
              console.log(res)
              
              getListService()
              let AddData = {
                InvoiceNo:args.data.InvoiceNo,
                CurrencyName:args.data.CurrencyName,
                ExchangeRate:args.data.ExchangeRate,
                HawalaCommissionPercent:args.data.HawalaCommissionPercent,  
                CurrencyNameHawala:args.data.CurrencyNameHawala,
                AccountTitleHawalaDr:args.data.AccountTitleHawalaDr,
                AccountTitleHawalaCr:args.data.AccountTitleHawalaCr 
              }
              AuditTableInput.auditAdd(`InvoiceEvaluation`,`InvoiceEvaluation`,`InvoiceEvaluationID`,AddData)
              })
          
        }
        if (args.requestType == 'save' && args.action == 'edit') {
          console.log(args)
            InvoiceEvaluationService.update({...args.data, UpdatedByUserID:SecurityService.getLoggedInUserId()}).then((res)=>{
              console.log(res)
              
              getListService()
              let EditData = {
                InvoiceNo:args.data.InvoiceNo,
                CurrencyName:args.data.CurrencyName,
                ExchangeRate:args.data.ExchangeRate,
                HawalaCommissionPercent:args.data.HawalaCommissionPercent,  
                CurrencyNameHawala:args.data.CurrencyNameHawala,
                AccountTitleHawalaDr:args.data.AccountTitleHawalaDr,
                AccountTitleHawalaCr:args.data.AccountTitleHawalaCr 
              }
                        let totalColumns = Object.keys(EditData);
                        console.log(totalColumns)
                        AuditTableInput.auditEdit(totalColumns,selectedRow,EditData,`InvoiceEvaluation`,args.data.InvoiceEvaluationID)
              })
          
        }
       }
       //   OPTIONAL PARAMETERS PLEASE DON'T PASS ANY TIME
    //    ***************************************************************************************************************
    //    ***************************************************************************************************************
    //    ***************************************************************************************************************
    //    ***************************************************************************************************************

        // Defines the  boolean
        // @default true
    let  allowTemplate = true
    let  allowGrouping = true
    let  allowExcelExport =true
    let  allowPdfExport = true
    let  showColumnChooser = true
    let  allowPaging = false
    let  allowDataBound = false
    let  allowGridSum = false
       
        // Defines the  string
        // @default InvoiceEvaluation${typeMode} 
        let ListSecurity='InvoiceEvaluationList'
        let AddSecurity='InvoiceEvaluationAdd'
        let EditSecurity='InvoiceEvaluationEdit'
        let DeleteSecurity='InvoiceEvaluationDelete'
       
       // Defines the  ['Add','Edit','Delete','Update','Cancel','PDFExport','ExcelExport','Search','ColumnChooser'] | 'All'
       //  @default 'All'
      let toolbarOptions ='All';
      const toolbarClicking =  async (args)=>{
        console.log(args)
      }
       // Defines the 'Descending' | 'Ascending'
       //  @default 'Descending'
      let DefaultSortedColumnSort ='Descending';

       /* Defines the [{
                    TableName:'InvoiceEvaluation' ,
                    TableID:'InvoiceEvaluationID',
                   // Optional if Delete is Soft // TableSecurity:'InvoiceEvaluationDelete',
                }]*/
    //  @default [] 
    //   let listDelete =  [{ TableName:'InvoiceEvaluation' ,TableID:'InvoiceEvaluationID', Optional // TableSecurity:'InvoiceEvaluationDelete'}]
    let listDelete = [];
       
    // Defines 'Hard' | 'Soft' 
    // @Default 'Hard'
    let deleteType = 'Hard'


/* Defines the [   {field:'IsDeleted',
OPTIONAL HAI operator:'equal' or more check syncfusion documentation
        value:'No'},...more
        ] | []
      @default [] */
    //   let filterColumns = [{ field: 'IsDeleted', value: 'No' }];
      let filterColumns = [];

      /* Defines the [ColumnNames] | []
      @default [] */
    //   let groupColumns = ['IsDeleted','AccountTitleHawalaDr'];
      let groupColumns = [];

/* Defines the 'Dialog' | 'Normal'
      @default 'Dialog' */
      let gridEditSettingMode = 'Dialog';

/* Defines the '<p></p>' | Can give HTML | AND REACT FUNCTION LIKE HeaderTemplate
      @default '<p></p>' */
      let gridEditSettingHeaderTemplate = '<p></p>';

      /* Defines the  [ColumnNames] | []
      @default [] */
           //   let gridSumColumns = ['HawalaCommissionPercent'];
      let gridSumColumns = [];


    return (
      // InvoiceEvaluationID	
      // MainInvoiceID	
      // InvoiceNo
      // CurrencyID
      // CurrencyName	
      // ExchangeRate	
      // HawalaCommissionPercent	
      // CurrencyIDHawala
      // CurrencyNameHawala
      // AccountIDHawalaDr	
      // AccountTitleHawalaDr	
      // AccountIDHawalaCr
      // AccountTitleHawalaCr
        <IrtizaDynamicInputList
        Id='InvoiceEvaluation'
        ListTitle='Invoice Evaluation  - List'
        ColumnsList={columns}
        GridDataSource= {[
                    {
                        DataSourceType: 'Function',
                        DataSource:  ()=> InvoiceEvaluationService.getInvoiceEvaluation()
                        }]}
        DefaultSortedColumnName={'InvoiceEvaluationID'}
        GridDialog={[
            {
                DialogType:'Self',
                Dialog:<InvoiceEvaluationAdd param='12'/>
                // Dialog:[
                //   {
                //     Title:'Invoice Evaluation',
                //     Inputs:[
                //       {field:{id:'MainInvoiceID',value:'InvoiceNo'},type:'dropdown',dataSource:{type:'Route',Route:'Invoice'},defaultValues:null},
                //       {field:{id:'CurrencyID',value:'CurrencyName'},type:'dropdown',dataSource:{type:'Route',Route:'CurrencyName'},defaultValues:null},
                //       {field:'ExchangeRate'	,type:'float',defaultValues:null},
                //       {field:'HawalaCommissionPercent'	,type:'float',defaultValues:null},
                //       {field:{id:'CurrencyIDHawala',value:'CurrencyNameHawala	'},type:'dropdown',dataSource:{type:'Route',Route:'CurrencyName'},defaultValues:null},
                //       {field:{id:'MainInvoiceID',value:'InvoiceNo'},type:'dropdown',dataSource:{type:'Route',Route:'Invoice'},defaultValues:null},
                //       {field:{id:'MainInvoiceID',value:'InvoiceNo'},type:'dropdown',dataSource:{type:'Route',Route:'Invoice'},defaultValues:null}
                //     ]
                //   }
                // ]

            }
        ]}
        ListActionComplete={listActionComplete}
        ToolbarClicking ={toolbarClicking}

        //   OPTIONAL PARAMETERS PLEASE DON'T PASS ANY TIME
            // AllowTemplate={allowTemplate}
            // ToolbarOption={toolbarOptions}
            // AllowGrouping={allowGrouping}
            // AllowExcelExport={allowExcelExport}
            // AllowPdfExport={allowPdfExport} 
            // ShowColumnChooser={showColumnChooser}
            // AllowPaging={allowPaging}
            // AllowDataBound={allowDataBound}
            // ListSecurity={ListSecurity}
            // AddSecurity={AddSecurity}
            // EditSecurity={EditSecurity}
            // DeleteSecurity={DeleteSecurity}
            // DefaultSortedColumnSort={DefaultSortedColumnSort}
            // FilterColumns={filterColumns} /* IT USED WHEN GIVING FILTER IN GRID LIKE IsDeleted Column or more */
            // GroupColumns={groupColumns} 
            // ListDelete={listDelete}
            // DeleteType={deleteType} 
            // GridEditSettingMode={gridEditSettingMode}
            // GridEditSettingHeaderTemplate={gridEditSettingHeaderTemplate}
            // GridSumColumns={gridSumColumns}
            // AllowGridSum={allowGridSum}
            {...props}
            ></IrtizaDynamicInputList>
            
            );
}



export default InvoiceEvaluationInput;
