import http from '../../services/http-common';

class ThreeInputService {
  getThreeInputsForList(data) {
    return http.post('/threeInputs/list',data);
  }
  addMultipleInputs(data) {
    return http.post('/threeInputs/add',data);
  }
  editMultipleInputs(data) {
    return http.put('/threeInputs/update',data);
  }
  getMultipleInputByQuery(data) {
    return http.post('/threeInputs/query',data);
  }
  getMultipleInputByCondition(data) {
    return http.post('/threeInputs/list/byCondition',data);
  }
  
}

export default new ThreeInputService();
