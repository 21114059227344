import React ,{useRef ,useEffect}from 'react';
import './users-list.scss'
import IrtizaDynamicInputList from '../irtiza-dynamic-input/irtiza-dynamic-input-list';
import moment from 'moment'
import SecurityService from '../security/security.service';
import UsersService from '../../services/users.service';
import UsersAdd from './users-add';
import AuditTableInput from '../audit-table/audit-table'
import { useDispatch, useSelector } from 'react-redux';
import { domainEmail } from '../../store/slices/user-input';

const UserInput = (props) => {
  const dispatch = useDispatch()
  const reduxData = useSelector(state =>{return state.userInput})
useEffect(()=>{
  getListDynamic()

},[])
  const getListDynamic = async () => {
    UsersService.getCompanyEmail()
      .then((response) => {
        console.log(response)
      //  domainEmails = response.data.recordset[0].DomainEmail
      dispatch(domainEmail(response.data.recordset[0].DomainEmail))
      })
      .catch((e) => {
        console.log(e);
      });
  };
// ShopID	
// ShopName	
// LocalCurrencyOpening	
// SerialNo
    let columns =[
        {  field: "UserID",headerText:'ID',width:'100px',textAlign:'right' ,headerTextAlign:'center',other:[]},
        {  field: "UserName",headerText:'User Name',width:'130px',textAlign:'left' ,headerTextAlign:'center',other:[]},
        {  field: "ShopName",headerText:'Shop Name',width:'130px',textAlign:'left' ,headerTextAlign:'center',other:[]},
        
      ]

       const listActionComplete = (grid,args ,getListService ,selectedRow,setDataSourceState )=>{
        console.log(args )
        console.log(getListService )
        console.log(selectedRow )
        if (args.requestType === 'beginEdit' || args.requestType === 'add') {
          }
          // if (args.requestType == 'save' || args.requestType == 'cancel' ) {
          //   getListService()
  
          // }
          if(args.requestType == 'save'){
           
            let data = {
              UserID:args.data.UserID,
              UserName:args.data.UserName,
              UserPassword:args.data.UserPassword,
              DesignationID:1,
              IsTempPassword:0,
              Email:`${args.data.UserName.replace(/\s+/g, '')}@${reduxData.DomainEmail}`.toUpperCase(),
              CreationDate:moment().format('DD-MMM-YY'),
              CreatedByUserID:SecurityService.getLoggedInUserId(),
              UpdationDate:moment().format('DD-MMM-YY'),
              UpdatedByUserID:SecurityService.getLoggedInUserId(),
              AdminEmailPassword:'-',
              FullName:'-',
              ShopID:args.data.ShopID
              
            }
            console.log(data)
          if(args.action == 'add'){
            UsersService.add(data).then((res)=>{
              console.log(res)
              setDataSourceState(res.data.recordset)
              // let AddData = {
              //   UnitOfMeasurementID:args.data.UnitOfMeasurementID,
              //   UnitOfMeasurement:args.data.UnitOfMeasurement,
              // }
              // AuditTableInput.auditAdd(`UnitOfMeasurement`,`UnitOfMeasurement`,`UnitOfMeasurementID`,AddData)
            })
          }
          if(args.action == 'edit'){
            UsersService.edit(data , data.UserID).then((res)=>{
              setDataSourceState(res.data.recordset)
  
              // let EditData = {
              //     UnitOfMeasurementID:args.data.UnitOfMeasurementID,
              //     UnitOfMeasurement:args.data.UnitOfMeasurement
              // }
              // let totalColumns = Object.keys(EditData);
              //             console.log(totalColumns)
              //             AuditTableInput.auditEdit(totalColumns,selectedRow,EditData,`UnitOfMeasurement`,args.data.MainUnitOfMeasurementID)
            })
          }
          }
          
       }
       //   OPTIONAL PARAMETERS PLEASE DON'T PASS ANY TIME
    //    ***************************************************************************************************************
    //    ***************************************************************************************************************
    //    ***************************************************************************************************************
    //    ***************************************************************************************************************

        // Defines the  boolean
        // @default true
    let  allowTemplate = true
    let  allowGrouping = true
    let  allowExcelExport =true
    let  allowPdfExport = true
    let  showColumnChooser = true
    let  allowPaging = false
    let  allowDataBound = true
    let  allowGridSum = true
    let  isDialog = props.IsDialog == true ? true : false

       
        // Defines the  string
        // @default User${typeMode} 
        let ListSecurity='ShopTransferList'
        let AddSecurity='ShopTransferAdd'
        let EditSecurity='ShopTransferEdit'
        let DeleteSecurity='ShopTransferDelete'
       
       // Defines the  ['Add','Edit','Delete','Update','Cancel','PDFExport','ExcelExport','Search','ColumnChooser'] | 'All'
       //  @default 'All'
      let toolbarOptions ='All';

      const toolbarClicking =  async (args)=>{
        console.log(args)
      }
      
       // Defines the 'Descending' | 'Ascending'
       //  @default 'Descending'
      let DefaultSortedColumnSort ='Descending';

       /* Defines the [{
                    TableName:'User' ,
                    TableID:'ShopID',
                   // Optional if Delete is Soft // TableSecurity:'ShopDelete',
                }]*/
    //  @default [] 
    //   let listDelete =  [{ TableName:'User' ,TableID:'ShopID', Optional // TableSecurity:'ShopDelete'}]
    let listDelete = [];
       
    // Defines 'Hard' | 'Soft' 
    // @Default 'Hard'
    let deleteType = 'Soft'


/* Defines the [   {field:'IsDeleted',
OPTIONAL HAI operator:'equal' or more check syncfusion documentation
        value:'No'},...more
        ] | []
      @default [] */
    //   let filterColumns = [{ field: 'IsDeleted', value: 'No' }];
      let filterColumns = [];

      /* Defines the [ColumnNames] | []
      @default [] */
    //   let groupColumns = ['IsDeleted','AccountTitleHawalaDr'];
      let groupColumns = [];

/* Defines the 'Dialog' | 'Normal'
      @default 'Dialog' */
      let gridEditSettingMode = 'Dialog';

/* Defines the '<p></p>' | Can give HTML | AND REACT FUNCTION LIKE HeaderTemplate
      @default '<p></p>' */
      let gridEditSettingHeaderTemplate = '<p></p>';

      /* Defines the  [ColumnNames] | []
      @default [] */
           //   let gridSumColumns = ['HawalaCommissionPercent'];
      let gridSumColumns = [];


    return (
        <IrtizaDynamicInputList
        Id='Users'
        ListTitle='Users - List'
        ColumnsList={columns}
        GridDataSource= {[
                    {
                        DataSourceType: 'Function',
                        DataSource:  ()=>  UsersService.getAll()
                        }]}
        DefaultSortedColumnName={'UserID'}
        GridDialog={[
            {
                DialogType:'Self',
                Dialog:<UsersAdd  />
                // Dialog:[
                //   {
                //     Title:'Invoice Evaluation',
                //     Inputs:[
                //       {field:{id:'MainInvoiceID',value:'InvoiceNo'},type:'dropdown',dataSource:{type:'Route',Route:'Invoice'},defaultValues:null},
                //       {field:{id:'ShopID',value:'ShopName'},type:'dropdown',dataSource:{type:'Route',Route:'ShopName'},defaultValues:null},
                //       {field:'ExchangeRate'	,type:'float',defaultValues:null},
                //       {field:'HawalaCommissionPercent'	,type:'float',defaultValues:null},
                //       {field:{id:'ShopIDHawala',value:'ShopNameHawala	'},type:'dropdown',dataSource:{type:'Route',Route:'ShopName'},defaultValues:null},
                //       {field:{id:'MainInvoiceID',value:'InvoiceNo'},type:'dropdown',dataSource:{type:'Route',Route:'Invoice'},defaultValues:null},
                //       {field:{id:'MainInvoiceID',value:'InvoiceNo'},type:'dropdown',dataSource:{type:'Route',Route:'Invoice'},defaultValues:null}
                //     ]
                //   }
                // ]

            }
        ]}
        ListActionComplete={listActionComplete}
        ToolbarClicking ={toolbarClicking}

        //   OPTIONAL PARAMETERS PLEASE DON'T PASS ANY TIME
            // AllowTemplate={allowTemplate}
            // ToolbarOption={toolbarOptions}
            // AllowGrouping={allowGrouping}
            // AllowExcelExport={allowExcelExport}
            // AllowPdfExport={allowPdfExport} 
            // ShowColumnChooser={showColumnChooser}
            // AllowPaging={allowPaging}
            AllowDataBound={allowDataBound}
            // ListSecurity={ListSecurity}
            // AddSecurity={AddSecurity}
            // EditSecurity={EditSecurity}
            // DeleteSecurity={DeleteSecurity}
            // DefaultSortedColumnSort={DefaultSortedColumnSort}
            // FilterColumns={filterColumns} /* IT USED WHEN GIVING FILTER IN GRID LIKE IsDeleted Column or more */
            // GroupColumns={groupColumns} 
            // ListDelete={listDelete}
            DeleteType={deleteType} 
            // GridEditSettingMode={gridEditSettingMode}
            // GridEditSettingHeaderTemplate={gridEditSettingHeaderTemplate}
            // GridSumColumns={gridSumColumns}
            // AllowGridSum={allowGridSum}
            IsDialog={isDialog}
            {...props}
            ></IrtizaDynamicInputList>
            
            );
}



export default UserInput;
