import React, { useEffect, useState, useMemo } from 'react';
import moment from 'moment';
import DropdownService from '../../irtiza-dynamic-input/dropdown-api.service';
import invoiceService from '../../invoice/invoice.service';
import { Page, Text, View, Document, StyleSheet, PDFViewer } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  page: {
    padding: 20,
  },
  header: {
    textAlign: 'center',
    fontSize: 18,
    marginBottom: 20,
  },
  section: {
    marginBottom: 10,
  },
  table: {
    display: 'table',
    width: 'auto',
    borderStyle: 'solid',
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
    marginTop: '5px'
  },
  tableRow: {
    
    flexDirection: 'row',
  },
  tableCol: {
    width: '25%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableCell: {
    margin: 5,
    fontSize: 10,
  },
  footer: {
    textAlign: 'center',
    fontSize: 12,
    marginTop: 50,
    display: 'flex',
    width: '100%',
    justifyContent: 'space-around',
    flexDirection: 'row',
  },
  boxes: {
    border: '1px solid #000',
    padding: '10px 20px',
    fontWeight: 'bold',
  },
  title: {
    fontSize: 16,
    fontStyle:'italic',
    marginBottom: 10,
    textAlign: 'center',
    fontWeight:'bold'

  }
});

const Report = React.memo(({data ,fromDate, toDate }) => {
  
console.log(data)
  
  

  return(

    
    <Document title={'Inventory With Profit Loss'}>
    {/* <Page style={styles.page} orientation={'landscape'} size={'A4'}> */}
    <Page style={styles.page} orientation={'potrait'} size={'A4'}>
    <Text style={styles.title}>HAP INTERNATIONAL LIMITED - MOZAMBIQUE</Text>
    <Text style={{...styles.title, marginBottom:'4px'}}>TRAIL BALANCE - $</Text>

<View style={{ ...styles.tableRow}}>

      <View style={{ ...styles.tableCol, width: '10%' ,borderWidth: 0 }}>
        <Text style={{ ...styles.tableCell, fontWeight: 'bold' ,fontSize:12}}>From</Text>
      </View>
      <View style={{ ...styles.tableCol, width: '35%' ,borderWidth: 0 ,textAlign:'right'}}>
        <Text style={{...styles.tableCell ,  fontSize:12}}>{moment(fromDate).format('dddd, MMMM D, YYYY')}</Text>
      </View>
      <View style={{ ...styles.tableCol, width: '10%' ,borderWidth: 0 ,textAlign:'right'}}></View>
      <View style={{ ...styles.tableCol, width: '10%' ,borderWidth: 0 }}>
        <Text style={{ ...styles.tableCell , fontSize:12, fontWeight: 'bold' }}>To</Text>
      </View>
      <View style={{ ...styles.tableCol, width: '35%', borderWidth: 0 ,textAlign:'right'}}>
        <Text style={{...styles.tableCell , fontSize:12}}>{moment(toDate).format('dddd, MMMM D, YYYY')}</Text>
      </View>
      </View>
      
      {/* <View style={styles.table}> */}
        <View style={{ ...styles.tableRow,fontSize:'11px',marginBottom:'5px' }} fixed>

        <View style={{ ...styles.tableCol , borderTopWidth: 1, width: '11%' }}><Text style={{...styles.tableCell ,  textAlign:'center',marginVertical:'auto'}}>Acc. Cod</Text></View>
        <View style={{ ...styles.tableCol , borderTopWidth: 1, width: '30%' }}><Text style={{...styles.tableCell ,  textAlign:'center',marginVertical:'auto' }}>Description</Text></View>
        <View style={{ ...styles.tableCol , borderTopWidth: 1, width: '14%' }}><Text style={{...styles.tableCell ,  textAlign:'center',marginVertical:'auto' }}>Op. Bal</Text></View>
        <View style={{ ...styles.tableCol , borderTopWidth: 1, width: '14%' }}><Text style={{...styles.tableCell ,  textAlign:'center',marginVertical:'auto' }}>Debit</Text></View>
        <View style={{ ...styles.tableCol , borderTopWidth: 1, width: '14%' }}><Text style={{...styles.tableCell ,  textAlign:'center',marginVertical:'auto' }}>Credit</Text></View>
        <View style={{ ...styles.tableCol , borderTopWidth: 1, width: '14%' }}><Text style={{...styles.tableCell ,  textAlign:'center',marginVertical:'auto' }}>Balance</Text></View>
          </View>
          {/* </View> */}


          {Object.keys(data.groupedData).map((group, groupKey) => { 
  console.log(data);
  console.log(data.groupedData[group]);

  return ( 
    <View key={groupKey}> 
      <View style={{ width: '100%', padding: '5px', textAlign: 'left',  fontSize: '16px', fontWeight: 'bold' }}>
        <Text>{group}</Text>
      </View>

      {Object.keys(data.groupedData[group].levels).map((level2, level2Key) => {
        return ( 
          <View key={level2Key}> 
            <View style={{ width: '100%', padding: '5px', textAlign: 'left',  fontSize: '14px', fontWeight: 'bold' }}>
              <Text>{level2}</Text>
            </View>

            {Object.keys(data.groupedData[group].levels[level2].levels).map((brandGroup, brandGroupKey) => {
              return (
                <React.Fragment key={brandGroupKey}>
                  <View style={{ width: '100%', padding: '5px', textAlign: 'left', fontSize: '12px', fontWeight: 'bold',  }}>
                    <Text>{brandGroup}</Text>
                  </View>

                  {data.groupedData[group].levels[level2].levels[brandGroup].items.map((product, key) => (
                    <View style={{...styles.tableRow,borderBottom:'dashed',borderBottomWidth:1}} key={key}>
                      <View style={{ ...styles.tableCol,border:'none' ,width: '11%' }}><Text style={styles.tableCell}>{product.Level4AccountCode}</Text></View>
                      <View style={{ ...styles.tableCol,border:'none' ,width: '30%' }}><Text style={styles.tableCell}>{product.Level4AccountTitle}</Text></View>
                      <View style={{ ...styles.tableCol,border:'none' ,width: '14%' }}><Text style={{...styles.tableCell,textAlign:'right'}}>{product.OpeningBalanceFC ?  product.OpeningBalanceFC.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 }) : 0}</Text></View>
                      <View style={{ ...styles.tableCol,border:'none' ,width: '14%' }}><Text style={{...styles.tableCell,textAlign:'right'}}>{product.DurationDebitFC ?  product.DurationDebitFC.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 }) : 0}</Text></View>
                      <View style={{ ...styles.tableCol,border:'none' ,width: '14%' }}><Text style={{...styles.tableCell,textAlign:'right'}}>{product.DurationCreditFC ?  product.DurationCreditFC.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 }) : 0}</Text></View>
                      <View style={{ ...styles.tableCol,border:'none' ,width: '14%' }}><Text style={{...styles.tableCell,textAlign:'right'}}>{product.ClosingBalanceFC ?  product.ClosingBalanceFC.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 }) : 0}</Text></View>
                    </View>
                  ))}

                  {/* Uncomment if needed */}
                  <View style={{ ...styles.tableRow }}>
                    
                      <View style={{ ...styles.tableCol,border:'none' ,width: '40%' }}><Text style={{...styles.tableCell,textAlign:'left'}}>TOTAL</Text></View>
                      <View style={{ ...styles.tableCol,border:'none' ,width: '14%' }}><Text style={{...styles.tableCell,textAlign:'right'}}>{data.groupedData[group].levels[level2].levels[brandGroup].totals.OpeningBalanceFC ?  data.groupedData[group].levels[level2].levels[brandGroup].totals.OpeningBalanceFC.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 }) : 0}</Text></View> 
                      <View style={{ ...styles.tableCol,border:'none' ,width: '14%' }}><Text style={{...styles.tableCell,textAlign:'right'}}>{data.groupedData[group].levels[level2].levels[brandGroup].totals.DurationDebitFC ?  data.groupedData[group].levels[level2].levels[brandGroup].totals.DurationDebitFC.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 }) : 0}</Text></View>
                      <View style={{ ...styles.tableCol,border:'none' ,width: '14%' }}><Text style={{...styles.tableCell,textAlign:'right'}}>{data.groupedData[group].levels[level2].levels[brandGroup].totals.DurationCreditFC ?  data.groupedData[group].levels[level2].levels[brandGroup].totals.DurationCreditFC.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 }) : 0}</Text></View>
                       <View style={{ ...styles.tableCol,border:'none' ,width: '14%' }}><Text style={{...styles.tableCell,textAlign:'right'}}>{data.groupedData[group].levels[level2].levels[brandGroup].totals.ClosingBalanceFC ?  data.groupedData[group].levels[level2].levels[brandGroup].totals.ClosingBalanceFC.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 }) : 0}</Text></View>
                    </View>
                </React.Fragment>
              );
            })}

           
          </View>
        );
      })}
    </View>
  );
  
})}

<View style={{ ...styles.tableRow }}>
                    
                    <View style={{ ...styles.tableCol,border:'none' ,width: '40%' }}><Text style={{...styles.tableCell,textAlign:'left'}}>REPORT TOTAL</Text></View>
                    <View style={{ ...styles.tableCol,border:'none' ,width: '14%' }}><Text style={{...styles.tableCell,textAlign:'right'}}>{data.finalTotals.OpeningBalanceFC ?  data.finalTotals.OpeningBalanceFC.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 }) : 0}</Text></View> 
                    <View style={{ ...styles.tableCol,border:'none' ,width: '14%' }}><Text style={{...styles.tableCell,textAlign:'right'}}>{data.finalTotals.DurationDebitFC ?  data.finalTotals.DurationDebitFC.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 }) : 0}</Text></View>
                    <View style={{ ...styles.tableCol,border:'none' ,width: '14%' }}><Text style={{...styles.tableCell,textAlign:'right'}}>{data.finalTotals.DurationCreditFC ?  data.finalTotals.DurationCreditFC.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 }) : 0}</Text></View>
                     <View style={{ ...styles.tableCol,border:'none' ,width: '14%' }}><Text style={{...styles.tableCell,textAlign:'right'}}>{data.finalTotals.ClosingBalanceFC ?  data.finalTotals.ClosingBalanceFC.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 }) : 0}</Text></View>
                  </View>

          {/* {Object.keys(data.groupedData).map((group, groupKey) =>{
            console.log(data.groupedData)
            console.log(group)
            return(<>
            <View style={{ width: '100%', padding: '5px', textAlign: 'left',fontSize:'15px' , fontWeight:'bold', }}>
              <Text>{group}</Text>
            </View>
             {data.groupedData[group].items.map((product,key) => (
              <View style={{...styles.tableRow ,borderBottom:'dashed',borderBottomWidth:1 }} key={key}>
                <View style={{ ...styles.tableCol,border:'none', width: '30%' }}><Text style={styles.tableCell}>{product.Level4AccountTitle}</Text></View>
                <View style={{ ...styles.tableCol,border:'none', width: '10%' }}><Text style={styles.tableCell}>{product.CurrencyName}</Text></View>

                <View style={{ ...styles.tableCol,border:'none', width: '20%' }}><View style={{ ...styles.tableRow,fontSize:'11px' }} >
                <View style={{ ...styles.tableCol,border:'none', width: '50%' ,textAlign: 'right'}}><Text style={styles.tableCell}>{product.OpeningAmountDebit.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}</Text></View>
                <View style={{ ...styles.tableCol,border:'none', width: '50%' ,textAlign: 'right'}}><Text style={styles.tableCell}>{product.OpeningAmountCredit.toLocaleString('en-US', { minimumFractionDigits:0, maximumFractionDigits: 0 })}</Text></View>
                </View></View>
             
                <View style={{ ...styles.tableCol,border:'none', width: '20%' }}><View style={{ ...styles.tableRow,fontSize:'11px' }} >
                <View style={{ ...styles.tableCol,border:'none', width: '50%' ,textAlign: 'right'}}><Text style={styles.tableCell}>{product.DurationAmountDebit.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}</Text></View>
                <View style={{ ...styles.tableCol,border:'none', width: '50%' ,textAlign: 'right'}}><Text style={styles.tableCell}>{product.DurationAmountCredit.toLocaleString('en-US', { minimumFractionDigits:0, maximumFractionDigits: 0 })}</Text></View>
                </View></View>

                <View style={{ ...styles.tableCol,border:'none', width: '20%' }}><View style={{ ...styles.tableRow,fontSize:'11px' }} >
                <View style={{ ...styles.tableCol,border:'none', width: '50%' ,textAlign: 'right'}}><Text style={styles.tableCell}>{product.ClosingAmountDebit.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}</Text></View>
                <View style={{ ...styles.tableCol,border:'none', width: '50%' ,textAlign: 'right'}}><Text style={styles.tableCell}>{product.ClosingAmountCredit.toLocaleString('en-US', { minimumFractionDigits:0, maximumFractionDigits: 0 })}</Text></View>
                </View></View>
                
                </View>
            ))}
            <View style={{ ...styles.tableRow,}}>
              <View style={{ ...styles.tableCol, border:'none', width: '40%', padding: 0  }}><Text style={{...styles.tableCell ,fontSize:'12px'}}> TOTAL</Text></View>
              
              <View style={{ ...styles.tableCol, border:'none', width: '20%' }}><View style={{ ...styles.tableRow,fontSize:'11px' }} >
                <View style={{ ...styles.tableCol, border:'none', width: '50%' ,textAlign: 'right'}}><Text style={{...styles.tableCell ,fontSize:'11px'}}>{data.groupedData[group].totals.OpeningAmountDebit.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}</Text></View>
                <View style={{ ...styles.tableCol, border:'none', width: '50%' ,textAlign: 'right'}}><Text style={{...styles.tableCell ,fontSize:'11px'}}>{data.groupedData[group].totals.OpeningAmountCredit.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}</Text></View>
                </View></View>
              
              <View style={{ ...styles.tableCol, border:'none', width: '20%' }}><View style={{ ...styles.tableRow,fontSize:'11px' }} >
                <View style={{ ...styles.tableCol, border:'none', width: '50%' ,textAlign: 'right'}}><Text style={{...styles.tableCell ,fontSize:'11px'}}>{data.groupedData[group].totals.DurationAmountDebit.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}</Text></View>
                <View style={{ ...styles.tableCol, border:'none', width: '50%' ,textAlign: 'right'}}><Text style={{...styles.tableCell ,fontSize:'11px'}}>{data.groupedData[group].totals.DurationAmountCredit.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}</Text></View>
                </View></View>
              
              <View style={{ ...styles.tableCol, border:'none', width: '20%' }}><View style={{ ...styles.tableRow,fontSize:'11px' }} >
                <View style={{ ...styles.tableCol, border:'none', width: '50%' ,textAlign: 'right'}}><Text style={{...styles.tableCell ,fontSize:'11px'}}>{data.groupedData[group].totals.ClosingAmountDebit.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}</Text></View>
                <View style={{ ...styles.tableCol, border:'none', width: '50%' ,textAlign: 'right'}}><Text style={{...styles.tableCell ,fontSize:'11px'}}>{data.groupedData[group].totals.ClosingAmountCredit.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}</Text></View>
                </View></View>
                
              
            </View>
          </>
         )})} */}





        

        </Page>
        </Document>
)});

const ReceivablePayable = (props) => {
  
  return (
    <>
          <Report data={props.DataSource} fromDate={props.FromDate} toDate={props.ToDate}/> 
     </>
  );
};

export default ReceivablePayable;
