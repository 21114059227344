import http from './http-common';

class SetupService {
  getAll(type) {
    console.log('setup service: ', type);
    return http.get(`/setup/${type}`);
  }

  getForList(type) {
    console.log('setup service: ', type);
    return http.get(`/setup/list/${type}`);
  }

  get(type, id) {
    return http.get(`/setup/${type}/${id}`);
  }

  update(type, data) {
    return http.put(`/setup/${type}/update`, data);
  }

  delete(type, id) {
    return http.delete(`/setup/${type}/${id}`);
  }

  insert(type, value) {
    console.log('setup insert type:', type);
    console.log('setup insert value:', value);
    return http.post(`/setup/${type}/insert`, value);
  }
}

export default new SetupService();
