

// import React, { useState, useEffect } from 'react';
// import moment from 'moment';
// import shopVoucherService from '../../voucher/voucher.service';
// import { Page, Text, View, Document, StyleSheet, PDFViewer ,PDFDownloadLink } from '@react-pdf/renderer';

// // Create styles
// const styles = StyleSheet.create({
//   page: {
//     padding: 30,
//   },
//   header: {
//     textAlign: 'center',
//     fontSize: 18,
//     marginBottom: 20,
//   },
//   section: {
//     marginBottom: 10,
    
//   },
//   table: {
//     display: 'table',
//     width: 'auto',
//     borderStyle: 'solid',
//     borderWidth: 1,
//     borderRightWidth: 0,
//     borderBottomWidth: 0,
//     marginTop:'5px'
//   },
//   tableRow: {
//     margin: 'auto',
//     flexDirection: 'row',
//   },
//   tableCol: {
//     width: '25%',
//     borderStyle: 'solid',
//     borderWidth: 1,
//     borderLeftWidth: 0,
//     borderTopWidth: 0,
//   },
//   tableCell: {
//     margin: 5,
//     fontSize: 10,
//   },
//   footer: {
//     textAlign: 'center',
//     fontSize: 12,
//     marginTop: 50,
//     display: 'flex',
//     width: '100%',
//     justifyContent: 'space-around',
//     flexDirection: 'row',
//   },
//   boxes: {
//     border: '1px solid #000',
//     padding: '10px 20px',
//     fontWeight: 'bold',
//   },
// });

// // Calculate totals
// const calculateTotals = (data) => {
//   let totalDebit = 0;
//   let totalCredit = 0;

//   data.forEach(item => {
//     totalDebit += parseFloat(item.Debit);
//     totalCredit += parseFloat(item.Credit);
//   });

//   return {
//     totalDebit: totalDebit.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
//     totalCredit: totalCredit.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
//   };
// };

// // Create Document Component
// const Report = ({ data ,type }) => {
//   const { totalDebit, totalCredit } = calculateTotals(data);

//   return (
//     <PDFViewer width="100%" height="1000" fileName='Voucher-Report'>
//       <Document title='Voucher' >
//         <Page style={styles.page}>
//           <Text style={styles.header}>ABC COMPANY</Text>
//           <Text style={styles.header}>{type == 'CP' ?  `CASH PAYMENT` : type == 'J' ? `JOURNAL VOUCHER` : `CASH RECEIPT` }</Text>

//           <View style={{ ...styles.tableRow, margin: '0px' }}>
//             <View style={{ ...styles.tableCol, width: '10%', borderWidth: 0 }}>
//               <Text style={{ ...styles.tableCell, fontWeight: 'bold' }}>Date:</Text>
//             </View>
//             <View style={{ ...styles.tableCol, width: '40%', borderWidth: 0 }}>
//               <Text style={styles.tableCell}>{moment(data[0]?.VoucherDate).format('DD-MMM-YY')}</Text>
//             </View>
//             <View style={{ ...styles.tableCol, width: '10%', borderWidth: 0 }}>
//               <Text style={{ ...styles.tableCell, fontWeight: 'bold' }}>Vno:</Text>
//             </View>
//             <View style={{ ...styles.tableCol, width: '30%', borderWidth: 0 }}>
//               <Text style={styles.tableCell}>{data[0]?.VoucherID}</Text>
//             </View>
//           </View>

//           <View style={styles.table}>
//             <View style={styles.tableRow} fixed>
//               <View style={{ ...styles.tableCol, width: '10%' }}>
//                 <Text style={styles.tableCell}>SNo.</Text>
//               </View>
//               <View style={{ ...styles.tableCol, width: '40%' }}>
//                 <Text style={styles.tableCell}>Particulars</Text>
//               </View>
//               <View style={{ ...styles.tableCol, width: '25%' }}>
//                 <Text style={styles.tableCell}>Debit</Text>
//               </View>
//               <View style={{ ...styles.tableCol, width: '25%' }}>
//                 <Text style={styles.tableCell}>Credit</Text>
//               </View>
//             </View>

//             {data.map((item, index) => {
//               let Sno = index + 1;
//               return (
//                 <View style={styles.tableRow} key={index}>
//                   <View style={{ ...styles.tableCol, width: '10%' }}>
//                     <Text style={styles.tableCell}>{Sno}</Text>
//                   </View>
//                   <View style={{ ...styles.tableCol, width: '40%' }}>
//                     <Text style={styles.tableCell}>{item.AccountTitle}</Text>
//                   </View>
//                   <View style={{ ...styles.tableCol, width: '25%', textAlign: 'right' }}>
//                     <Text style={styles.tableCell}>{item.Debit.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Text>
//                   </View>
//                   <View style={{ ...styles.tableCol, width: '25%', textAlign: 'right' }}>
//                     <Text style={styles.tableCell}>{item.Credit.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Text>
//                   </View>
//                 </View>
//               );
//             })}

//             <View style={styles.tableRow}>
//               <View style={{ ...styles.tableCol, width: '50%' }}>
//                 <Text style={{ ...styles.tableCell, fontWeight: 'bold', borderRightWidth: 'transparent' }}>TOTAL</Text>
//               </View>
//               <View style={{ ...styles.tableCol, width: '25%', textAlign: 'right' }}>
//                 <Text style={{ ...styles.tableCell, fontWeight: 'bold' }}>{totalDebit}</Text>
//               </View>
//               <View style={{ ...styles.tableCol, width: '25%', textAlign: 'right' }}>
//                 <Text style={{ ...styles.tableCell, fontWeight: 'bold' }}>{totalCredit}</Text>
//               </View>
//             </View>
//           </View>

//           <View style={styles.footer}>
//             <Text style={styles.boxes}>Prepared by</Text>
//             <Text style={styles.boxes}>Accountant</Text>
//             <Text style={styles.boxes}>Authorized by</Text>
//             <Text style={styles.boxes}>Received by</Text>
//           </View>
//         </Page>
//       </Document>
//     </PDFViewer>
//   );
// };

// // Main component to render the PDF in a viewer
// const Voucher = (props) => {
//   console.log(props)
//   const [dataSource, setDataSource] = useState([]);

//   useEffect(() => {
//     if(document.getElementById('pushmenu-bar')){
//       document.getElementById('pushmenu-bar').click()
//     }
//     reportDataSource();

//     return () => {};
//   }, []);

//   const reportDataSource = async () => {
//     let data = {
//       FromDate: '01-JAN-01',
//       // ToDate: moment().format('DD-MMM-YY'),
//       ToDate: moment(props.match.params.VoucherDate).format('DD-MMM-YY'),
//       // VoucherType: props.match.params.VoucherType,
//       VoucherType: 'CP',
//       // MainVoucherID: props.match.params.MainVoucherID,
//       MainVoucherID: 0,
//       // VoucherID: props.match.params.VoucherID,
//       VoucherID: 0,
//       // AccountIDCashBank: props.match.params.AccountIDCashBank,
//       AccountIDCashBank: 0,
//       // CurrencyID: props.match.params.CurrencyID,
//       CurrencyID: 0,
//       // MainVoiceID: props.match.params.MainInvoiceID,
//       MainVoiceID: 0,
//       // ShopID: props.match.params.ShopID,
//       ShopID: 0,
//       // VoucherCategory: props.match.params.VoucherCategory,
//       VoucherCategory: '',
//       // AccountID: props.match.params.AccountID,
//       AccountID: 0,
//       // VoucherDetailID: props.match.params.VoucherDetailID,
//       VoucherDetailID: 0,
//     };
//     console.log(data);
//     let response = await shopVoucherService.getGridDataSource(data);
//     console.log(response);
//     setDataSource(response.data.recordset);
//   };

//   return (
//     // <div className='content-wrapper'>
//     //   <Report data={dataSource} />
//     // </div>
//     <div className='content-wrapper'>
//     {/* <PDFDownloadLink
//       document={<Report data={dataSource} />}
//       fileName="Custom_Report_Name.pdf"
//     >
//       {({ blob, url, loading, error }) =>
//         loading ? 'Loading document...' : 'Download PDF'
//       }
//     </PDFDownloadLink> */}
//     {/* <div style={{ height: '1000px' }}> */}
//         <Report data={dataSource} type={ props.match.params.VoucherType} />
//     {/* </div> */}
//   </div>
//   );
// };

// export default Voucher;

import React, { useState, useEffect } from 'react';
import moment from 'moment';
import shopVoucherService from '../../voucher/voucher.service';
import { Page, Text, View, Document, StyleSheet, PDFViewer } from '@react-pdf/renderer';


// Create styles
const styles = StyleSheet.create({
  page: {
    padding: 30,
  },
  header: {
    textAlign: 'center',
    fontSize: 18,
    marginBottom: 20,
  },
  section: {
    marginBottom: 10,
  },
  table: {
    display: 'table',
    width: 'auto',
    borderStyle: 'solid',
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
    marginTop: '5px'
  },
  tableRow: {
    margin: 'auto',
    flexDirection: 'row',
  },
  tableCol: {
    width: '25%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableCell: {
    margin: 5,
    fontSize: 10,
  },
  footer: {
    textAlign: 'center',
    fontSize: 12,
    marginTop: 50,
    display: 'flex',
    width: '100%',
    justifyContent: 'space-around',
    flexDirection: 'row',
  },
  boxes: {
    border: '1px solid #000',
    padding: '10px 20px',
    fontWeight: 'bold',
  },
});

// Calculate totals
const calculateTotals = (data) => {
  let totalDebit = 0;
  let totalCredit = 0;

  data.forEach(item => {
    totalDebit += parseFloat(item.Debit);
    totalCredit += parseFloat(item.Credit);
  });

  return {
    totalDebit: totalDebit.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
    totalCredit: totalCredit.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
  };
};

// Create Document Component
const Report = ({ data ,type }) => {
  const { totalDebit, totalCredit } = calculateTotals(data);

  return (
    // <PDFViewer width="100%" height="1000" fileName='Voucher-Report'>
      <Document title='Voucher'>
        <Page style={styles.page}>
          <Text style={styles.header}>ABC COMPANY</Text>
          <Text style={styles.header}>{type === 'CP' ?  `CASH PAYMENT` : type === 'J' ? `JOURNAL VOUCHER` : `CASH RECEIPT`}</Text>

          <View style={{ ...styles.tableRow, margin: '0px' }}>
            <View style={{ ...styles.tableCol, width: '10%', borderWidth: 0 }}>
              <Text style={{ ...styles.tableCell, fontWeight: 'bold' }}>Date:</Text>
            </View>
            <View style={{ ...styles.tableCol, width: '40%', borderWidth: 0 }}>
              <Text style={styles.tableCell}>{moment(data[0]?.VoucherDate).format('DD-MMM-YY')}</Text>
            </View>
            <View style={{ ...styles.tableCol, width: '10%', borderWidth: 0 }}>
              <Text style={{ ...styles.tableCell, fontWeight: 'bold' }}>Vno:</Text>
            </View>
            <View style={{ ...styles.tableCol, width: '30%', borderWidth: 0 }}>
              <Text style={styles.tableCell}>{data[0]?.VoucherID}</Text>
            </View>
          </View>

          <View style={styles.table}>
            <View style={styles.tableRow} fixed>
              <View style={{ ...styles.tableCol, width: '10%' }}>
                <Text style={styles.tableCell}>SNo.</Text>
              </View>
              <View style={{ ...styles.tableCol, width: '40%' }}>
                <Text style={styles.tableCell}>Particulars</Text>
              </View>
              <View style={{ ...styles.tableCol, width: '25%' }}>
                <Text style={styles.tableCell}>Debit</Text>
              </View>
              <View style={{ ...styles.tableCol, width: '25%' }}>
                <Text style={styles.tableCell}>Credit</Text>
              </View>
            </View>

            {data.map((item, index) => {
              let Sno = index + 1;
              return (
                <View style={styles.tableRow} key={index}>
                  <View style={{ ...styles.tableCol, width: '10%' }}>
                    <Text style={styles.tableCell}>{Sno}</Text>
                  </View>
                  <View style={{ ...styles.tableCol, width: '40%' }}>
                    <Text style={styles.tableCell}>{item.AccountTitle}</Text>
                  </View>
                  <View style={{ ...styles.tableCol, width: '25%', textAlign: 'right' }}>
                    <Text style={styles.tableCell}>{item.Debit.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Text>
                  </View>
                  <View style={{ ...styles.tableCol, width: '25%', textAlign: 'right' }}>
                    <Text style={styles.tableCell}>{item.Credit.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Text>
                  </View>
                </View>
              );
            })}

            <View style={styles.tableRow}>
              <View style={{ ...styles.tableCol, width: '50%' }}>
                <Text style={{ ...styles.tableCell, fontWeight: 'bold', borderRightWidth: 'transparent' }}>TOTAL</Text>
              </View>
              <View style={{ ...styles.tableCol, width: '25%', textAlign: 'right' }}>
                <Text style={{ ...styles.tableCell, fontWeight: 'bold' }}>{totalDebit}</Text>
              </View>
              <View style={{ ...styles.tableCol, width: '25%', textAlign: 'right' }}>
                <Text style={{ ...styles.tableCell, fontWeight: 'bold' }}>{totalCredit}</Text>
              </View>
            </View>
          </View>

          <View style={styles.footer}>
            <Text style={styles.boxes}>Prepared by</Text>
            <Text style={styles.boxes}>Accountant</Text>
            <Text style={styles.boxes}>Authorized by</Text>
            <Text style={styles.boxes}>Received by</Text>
          </View>
        </Page>
      </Document>
    // </PDFViewer>
  );
};

// Main component to render the PDF in a viewer and export to Excel
const Voucher = (props) => {
  // const [dataSource, setDataSource] = useState([]);

  // useEffect(() => {
  //   if(document.getElementById('pushmenu-bar')){
  //     document.getElementById('pushmenu-bar').click()
  //   }
  //   reportDataSource();

  //   return () => {};
  // }, []);

  // const reportDataSource = async () => {
  //   let data = {
  //     FromDate: '01-JAN-01',
  //     ToDate: moment(props.match.params.VoucherDate).format('DD-MMM-YY'),
  //     VoucherType: 'CP',
  //     MainVoucherID:11081,
  //     VoucherID: 0,
  //     AccountIDCashBank: 0,
  //     CurrencyID: 0,
  //     MainVoiceID: 0,
  //     ShopID: 0,
  //     VoucherCategory: '',
  //     AccountID: 0,
  //     VoucherDetailID: 0,
  //   };
  //   let response = await shopVoucherService.getGridDataSource(data);
  //   setDataSource(response.data.recordset);
  // }

  return (
    // <div className='content-wrapper'>
      
    //   <Report data={dataSource} type={props.match.params.VoucherType} />
    // </div>
    <>
      
      <Report data={props.DataSource} type={props.VoucherType} />
    </>
  );
};

export default Voucher;
