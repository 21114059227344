import http from '../../services/http-common';

class ProductService {
    getProductForList() {
    return http.get(`/Product/list`);
    }
insert(data) {
    return http.post(`/Product/insert`,data);
}
update(data) {
    return http.put(`/Product/update`,data);
}
}

export default new ProductService();