import http from '../../services/http-common';

class DesignationService {
    getDesignationForList() {
    return http.get(`/setup/Designation`);
    }
    insert(data) {
        return http.post(`/setup/Designation/insert`,data);
    }
    update(data) {
        return http.put(`/setup/Designation/update`,data);
    }
}

export default new DesignationService();