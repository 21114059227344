import React ,{useRef}from 'react';
import './invoice.scss'
import IrtizaDynamicInputList from '../irtiza-dynamic-input/irtiza-dynamic-input-list';
import moment from 'moment'
import SecurityService from '../security/security.service';
import InvoiceService from './invoice.service';
import InvoiceAdd from './invoice-add';
import AuditTableInput from '../audit-table/audit-table'




const InvoiceInput = (props) => {
  console.log(props)
  let inputType = props.location.pathname.split('/')[2]
      console.log(inputType)
  
    let columns = inputType == 'Sale' || SecurityService.getLoggedInUserId() == '0' ? [

      // { field: "InvoiceDetailID", headerText: 'ID', width: '120px', textAlign: 'right', headerTextAlign: 'center', other: [] },
      { field: "MainInvoiceID", headerText: 'Main Invoice ID', width: '120px', textAlign: 'right', headerTextAlign: 'center', other: [] },
      { field: "InvoiceID", headerText: 'Invoice ID', width: '120px', textAlign: 'right', headerTextAlign: 'center', other: [] },
      { field: "InvoiceDate", headerText: 'Invoice Date', width: '130px', textAlign: 'left', headerTextAlign: 'center', other: [] },
      { field: "InvoiceNo", headerText: 'Invoice No', width: '160px', textAlign: 'left', headerTextAlign: 'center', other: [] },
      { field: "AccountTitle", headerText: 'Account Title', width: '150px', textAlign: 'left', headerTextAlign: 'center', other: [] },
      { field: "AccountTitleBill", headerText: 'Account Title Bill', width: '150px', textAlign: 'left', headerTextAlign: 'center', other: [] },
      { field: "CurrencyName", headerText: 'Currency', width: '135px', textAlign: 'left', headerTextAlign: 'center', other: [] },
      { field: "ShopName", headerText: 'Shop Name', width: '140px', textAlign: 'left', headerTextAlign: 'center', other: [] },
      { field: "AdjustmentAmountFC", headerText: 'Adjustment Amount', width: '140px', textAlign: 'right', headerTextAlign: 'center', other: [{'format':'n0',visible:SecurityService.getLoggedInUserId() == '0' && inputType == 'Purchase'}] },
      { field: "FreightAmountFC", headerText: 'Freight Amount', width: '140px', textAlign: 'right', headerTextAlign: 'center', other: [{'format':'n0',visible:SecurityService.getLoggedInUserId() == '0' && inputType == 'Purchase'}] },

      { field: "Remarks", headerText: 'Remarks', width: '200px', textAlign: 'left', headerTextAlign: 'center', other: [] },
      // { field: "BrandName", headerText: 'Brand Name', width: '130px', textAlign: 'left', headerTextAlign: 'center', other: [] },
      // { field: "ProductName", headerText: 'Product Name', width: '140px', textAlign: 'left', headerTextAlign: 'center', other: [] },
      { field: "TotalInvoiceQuantity", headerText: 'Total Invoice Quantity', width: '130px', textAlign: 'right', headerTextAlign: 'center', other: [{'format':'n0'}] },
      // { field: "InvoiceRate", headerText: 'Invoice Rate', width: '120px', textAlign: 'right', headerTextAlign: 'center', other: [{'format':'n2'}] },
      { field: "TotalInvoiceAmount", headerText: 'Total Invoice Amount', width: '130px', textAlign: 'right', headerTextAlign: 'center', other: [{'format':'n0'}] },
      // { field: "UnitOfMeasurement", headerText: 'Unit of Measurement', width: '160px', textAlign: 'left', headerTextAlign: 'center', other: [] },
      // { field: "WeightPerBale", headerText: 'Weight Per Bale', width: '120px', textAlign: 'right', headerTextAlign: 'center', other: [{'format':'n0'}] },
      // { field: "TotalInvoiceWeight", headerText: 'Total Invoice Weight', width: '120px', textAlign: 'right', headerTextAlign: 'center', other: [{'format':'n0'}] },
      ]: 
      // MainInvoiceID ,InvoiceID ,InvoiceDate ,InvoiceNo ,AccountTitle ,AccountTitleBill ,CurrencyName	,ShopName ,Remarks	,BrandName ,ProductName ,InvoiceQuantity ,UnitOfMeasurement	,InvoiceDetailID	DetailKey
      [
        // { field: "InvoiceDetailID", headerText: 'ID', width: '120px', textAlign: 'right', headerTextAlign: 'center', other: [] },
        { field: "MainInvoiceID", headerText: 'Main Invoice ID', width: '120px', textAlign: 'right', headerTextAlign: 'center', other: [] },
        { field: "InvoiceID", headerText: 'Invoice ID', width: '120px', textAlign: 'right', headerTextAlign: 'center', other: [] },
        { field: "InvoiceDate", headerText: 'Invoice Date', width: '130px', textAlign: 'left', headerTextAlign: 'center', other: [] },
        { field: "InvoiceNo", headerText: 'Invoice No', width: '160px', textAlign: 'left', headerTextAlign: 'center', other: [] },
        { field: "AccountTitle", headerText: 'Account Title', width: '150px', textAlign: 'left', headerTextAlign: 'center', other: [] },
        { field: "AccountTitleBill", headerText: 'Account Title Bill', width: '150px', textAlign: 'left', headerTextAlign: 'center', other: [] },
        { field: "CurrencyName", headerText: 'Currency', width: '135px', textAlign: 'left', headerTextAlign: 'center', other: [] },
        { field: "ShopName", headerText: 'Shop Name', width: '140px', textAlign: 'left', headerTextAlign: 'center', other: [] },
        { field: "Remarks", headerText: 'Remarks', width: '200px', textAlign: 'left', headerTextAlign: 'center', other: [] },
        // { field: "BrandName", headerText: 'Brand Name', width: '130px', textAlign: 'left', headerTextAlign: 'center', other: [] },
        // { field: "ProductName", headerText: 'Product Name', width: '140px', textAlign: 'left', headerTextAlign: 'center', other: [] },
        { field: "TotalInvoiceQuantity", headerText: 'Total Invoice Quantity', width: '130px', textAlign: 'right', headerTextAlign: 'center', other: [{'format':'n0'}] },
        // { field: "UnitOfMeasurement", headerText: 'Unit of Measurement', width: '160px', textAlign: 'left', headerTextAlign: 'center', other: [] },
        ]

       
       const listActionComplete =  async (grid ,args ,getListService ,selectedRow,setDataSourceState )=>{
        console.log(args )
        console.log(getListService )
        console.log(selectedRow )
        if (args.requestType === 'beginEdit' || args.requestType === 'add') {
          }
        if (args.requestType == 'save' || args.requestType == 'cancel' ) {
          setDataSourceState([])
          getListService()

        }

        if (args.requestType == 'save' && args.data.MainInvoiceID) {
          console.log(args.data)
          function convertToMozambiqueTime(dateString) {
            // Parse the date string with the GMT offset
            // const gmtDate = moment(dateString, "ddd MMM DD YYYY HH:mm:ss [GMT]ZZ");
            
            // // Convert the parsed date to Mozambique time zone (Africa/Maputo)
            // const dateInMozambique = gmtDate.tz("Africa/Maputo").format('DD-MMM-YY');
            
            // return dateInMozambique;

            const gmtDate = moment(dateString, "DD-MMM-YY");

            // Convert the parsed date to Mozambique time zone (Africa/Maputo)
            const dateInMozambique = gmtDate.tz("Africa/Maputo").format('DD-MMM-YY');
        
            return dateInMozambique;
        }
          const response = await InvoiceService.editInvoice({
            ...args.data ,
            InvoiceDate:convertToMozambiqueTime(args.data.InvoiceDate),
            ShopID:SecurityService.getLoggedInShopID()});
            console.log(response)
            getListService()

        }
          
       }
       //   OPTIONAL PARAMETERS PLEASE DON'T PASS ANY TIME
    //    ***************************************************************************************************************
    //    ***************************************************************************************************************
    //    ***************************************************************************************************************
    //    ***************************************************************************************************************

        // Defines the  boolean
        // @default true
    let  allowTemplate = true
    let  allowGrouping = true
    let  allowExcelExport =true
    let  allowPdfExport = true
    let  showColumnChooser = true
    let  allowPaging = false
    let  allowDataBound = false
    let  allowGridSum = true
       
        // Defines the  string
        // @default Invoice${typeMode} 
        let ListSecurity=props.location.pathname.split('/')[2] == 'Purchase' ? 'PurchaseInvoiceList' : 'SaleInvoiceList'
        let AddSecurity= props.location.pathname.split('/')[2] == 'Purchase' ? 'PurchaseInvoiceAdd':'SaleInvoiceAdd'
        let EditSecurity= props.location.pathname.split('/')[2] == 'Purchase' ? 'PurchaseInvoiceEdit':'SaleInvoiceEdit'
        let DeleteSecurity= props.location.pathname.split('/')[2] == 'Purchase' ? 'PurchaseInvoiceDelete':'SaleInvoiceDelete'
       
       // Defines the  ['Add','Edit','Delete','Update','Cancel','PDFExport','ExcelExport','Search','ColumnChooser'] | 'All'
       //  @default 'All'
      let toolbarOptions ='All';

      
      const toolbarClicking =  async (args)=>{
        console.log(args)
       }
       // Defines the 'Descending' | 'Ascending'
       //  @default 'Descending'
      let DefaultSortedColumnSort ='Descending';

       /* Defines the [{
                    TableName:'Invoice' ,
                    TableID:'InvoiceID',
                   // Optional if Delete is Soft // TableSecurity:'InvoiceDelete',
                }]*/
    //  @default [] 
    //   let listDelete =  [{ TableName:'Invoice' ,TableID:'InvoiceID', Optional // TableSecurity:'InvoiceDelete'}]
    let listDelete = [];
       
    // Defines 'Hard' | 'Soft' 
    // @Default 'Hard'
    let deleteType = 'Hard'


/* Defines the [   {field:'IsDeleted',
OPTIONAL HAI operator:'equal' or more check syncfusion documentation
        value:'No'},...more
        ] | []
      @default [] */
    //   let filterColumns = [{ field: 'IsDeleted', value: 'No' }];
      let filterColumns = [];

      /* Defines the [ColumnNames] | []
      @default [] */
    //   let groupColumns = ['IsDeleted','AccountTitleHawalaDr'];
      let groupColumns = [];

/* Defines the 'Dialog' | 'Normal'
      @default 'Dialog' */
      let gridEditSettingMode = 'Dialog';

/* Defines the '<p></p>' | Can give HTML | AND REACT FUNCTION LIKE HeaderTemplate
      @default '<p></p>' */
      let gridEditSettingHeaderTemplate = '<p></p>';

      /* Defines the  [ColumnNames] | []
      @default [] */
           //   let gridSumColumns = ['HawalaCommissionPercent'];
      let gridSumColumns = [];

    console.log({id:SecurityService.getLoggedInUserId() == '0' ? 0 : SecurityService.getLoggedInShopID() , 
      type: inputType == 'Purchase' ? 'RI' : 'II'})
    return (
      
        <IrtizaDynamicInputList
        Id='Invoice'
        ListTitle={`Invoice  ${inputType}  ${props.location.pathname.split('/')[3] } - List`}
        ColumnsList={columns}
        GridDataSource= {[
                    {
                        DataSourceType: 'Function',
                        DataSource:  ()=> InvoiceService.getInvoiceForList(SecurityService.getLoggedInUserId() == 0 ? SecurityService.getLoggedInUserId() : SecurityService.getLoggedInShopID() ,  props.location.pathname.split('/')[2] == 'Purchase' ? 'RI' : 'II', props.location.pathname.split('/')[3]),
                        DataSourceIndexName:props.location.pathname.split('/')[2] == 'Purchase' ? 'InvoicePurchaseSummary':'InvoiceSaleSummary'
                        }]}
        DefaultSortedColumnName={'MainInvoiceID'}
        GridDialog={[
            {
                DialogType:'Self',
                
                // Dialog:<InvoiceAdd Type={inputType} ShopID={SecurityService.getLoggedInUserId() == 0 ? SecurityService.getLoggedInUserId() : SecurityService.getLoggedInShopID()} UserID={SecurityService.getLoggedInUserId() }/>
                Dialog:<InvoiceAdd Type={inputType} ShopID={SecurityService.getLoggedInShopID()} UserID={SecurityService.getLoggedInUserId() }/>
                // Dialog:[
                //   {
                //     Title:'Invoice ',
                //     Inputs:[
                //       {field:{id:'MainInvoiceID',value:'InvoiceNo'},type:'dropdown',dataSource:{type:'Route',Route:'Invoice'},defaultValues:null},
                //       {field:{id:'CurrencyID',value:'CurrencyName'},type:'dropdown',dataSource:{type:'Route',Route:'CurrencyName'},defaultValues:null},
                //       {field:'ExchangeRate'	,type:'float',defaultValues:null},
                //       {field:'HawalaCommissionPercent'	,type:'float',defaultValues:null},
                //       {field:{id:'CurrencyIDHawala',value:'CurrencyNameHawala	'},type:'dropdown',dataSource:{type:'Route',Route:'CurrencyName'},defaultValues:null},
                //       {field:{id:'MainInvoiceID',value:'InvoiceNo'},type:'dropdown',dataSource:{type:'Route',Route:'Invoice'},defaultValues:null},
                //       {field:{id:'MainInvoiceID',value:'InvoiceNo'},type:'dropdown',dataSource:{type:'Route',Route:'Invoice'},defaultValues:null}
                //     ]
                //   }
                // ]

            }
        ]}
        ListActionComplete={listActionComplete}
        ToolbarClicking ={toolbarClicking}
        //   OPTIONAL PARAMETERS PLEASE DON'T PASS ANY TIME
            // AllowTemplate={allowTemplate}
            // ToolbarOption={toolbarOptions}
            // AllowGrouping={allowGrouping}
            // AllowExcelExport={allowExcelExport}
            // AllowPdfExport={allowPdfExport} 
            // ShowColumnChooser={showColumnChooser}
            // AllowPaging={allowPaging}
            // AllowDataBound={allowDataBound}
            ListSecurity={ListSecurity}
            AddSecurity={AddSecurity}
            EditSecurity={EditSecurity}
            DeleteSecurity={DeleteSecurity}
            // DefaultSortedColumnSort={DefaultSortedColumnSort}
            // FilterColumns={filterColumns} /* IT USED WHEN GIVING FILTER IN GRID LIKE IsDeleted Column or more */
            // GroupColumns={groupColumns} 
            // ListDelete={listDelete}
            // DeleteType={deleteType} 
            // GridEditSettingMode={gridEditSettingMode}
            // GridEditSettingHeaderTemplate={gridEditSettingHeaderTemplate}
            // GridSumColumns={gridSumColumns}
            AllowGridSum={allowGridSum}
          
            {...props}
            ></IrtizaDynamicInputList>
            
            );
}



export default InvoiceInput;
