import http from '../../services/http-common';

class ProductTransferService {
    getForList(data) {
    return http.get('/ProductTransfer/list',data);
  }
  productTransfer(data) {
    return http.post('/ProductTransfer',data);
  }
}

export default new ProductTransferService();
