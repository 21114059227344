import React, { useEffect, useRef, useState } from 'react';
import { Formik } from 'formik';
import './chart-of-account.scss';
import * as Yup from 'yup';
import { makeStyles } from '@material-ui/core/styles';
import { Container, Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import YarnCountService from '../../services/yarn-count.service';
import CompanyService from '../../services/company.service'
import { NumericTextBoxComponent, TextBoxComponent } from '@syncfusion/ej2-react-inputs';
// import ChartOfAccount from './chart-of-account';
// import transporterService from '../../services/transporter.service';
// import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
// import { DatePickerComponent } from '@syncfusion/ej2-react-calendars';
import HardDelete from '../hard-delete/hard-delete';

import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
// import { Add, Edit } from '@syncfusion/ej2-icons';
// import * as icons from '@syncfusion/ej2-icons';

import { DropDownListComponent, AutoCompleteComponent } from '@syncfusion/ej2-react-dropdowns';
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import SetupAdd from '../setup/setup-add';
import SetupList from '../setup/setup-list';
import SecurityService from "../security/security.service";
import moment from 'moment';
import CheckDuplicate from '../check-duplicate/check-duplicate'
import SetupService from '../../services/setup.service';
import { CheckBoxComponent } from '@syncfusion/ej2-react-buttons';
import chartOfAccountService from '../../services/chart-of-account.service';
import { a } from '@react-spring/web';
import { setDisplayValue } from '@syncfusion/ej2-react-grids';
import  imgSrc from './spinner.gif';
import ProfileInput from './profile-input'
var propsAccountCode = ''
export default function ChartOfAccountAdd(props) {
    console.log(props)
  const [accountID, setAccountID] = useState(0);
  const [accountTitle, setAccountTitle] = useState();
  const [accountCode, setAccountCode] = useState('');
  const [transactional, setTransactional] = useState();
  const [inactive, setInactive] = useState();
  const [aging, setAging] = useState(false);
  const [openingBalance, setOpeningBalance] = useState(0);
  const [creditDays, setCreditDays] = useState(0);
  const [creditAmountLimit, setCreditAmountLimit] = useState(0);
  const [creditAmountLimitDisabled, setCreditAmountLimitDisabled] = useState(false);
  const [creditDaysDisabled, setCreditDaysDisabled] = useState(false);
  const [openingBalanceDisabled, setOpeningBalanceDisabled] = useState(false);

  const [accountTypeID, setAccountTypeID] = useState();
  const [accountTypeValue, setAccountTypeValue] = useState();
  const [accountTypeDatasource, setAccountTypeDatasource] = useState();
  const [loadingAccountType, setLoadingAccountType] = useState();
  // company
  const [companyID, setCompanyID] = useState(1);
  const [companyValue, setCompanyValue] = useState();
  const [companyDatasource, setCompanyDatasource] = useState();
  const [loadingCompany, setLoadingCompany] = useState();

  const [accountCategoryID, setAccountCategoryID] = useState(1);
  const [accountCategoryValue, setAccountCategoryValue] = useState();
  const [accountCategoryDatasource, setAccountCategoryDatasource] = useState();
  const [loadingAccountCategory, setLoadingAccountCategory] = useState();

// profile use state
const [contactPerson , setContactPerson] = useState('-');
const [officeAddress , setOfficeAddress] = useState('-');
const [landlineNumber , setLandlineNumber] = useState('-');
const [cellNumber , setCellNumber] = useState('-');
const [STRN, setSTRN] = useState('-');
const [NTN, setNTN] = useState('-');
const [emailAddress, setEmailAddress] = useState('-');

  const [holdForEdit, setHoldForEdit] = useState(props.TreeViewValue);
  const [actionType, setActionType] = useState('');
  const [addNewClicked, setAddNewClicked] = useState(false);
  const [showAddNewDialog, setShowAddNewDialog] = useState(false);
  const [dialogType, setDialogType] = useState('');
  const [isReadOnly, setIsReadOnly] = useState(true);
  const [isShowProfile, setIsShowProfile] = useState(false);
  const [showProfileDialog, setShowProfileDialog] = useState(false);
  const [treeViewValues, setTreeViewValues] = useState('');

  const [checkDuplicate, setCheckDuplicate] = useState(false);
  const [checkDuplicateValue, setCheckDuplicateValue] = useState('');
  const [companyName, setCompanyName] = useState(props.CompanyName);
  const [shortName, setShortName] = useState(props.ShortName);
  const [referenceName, setReferenceName] = useState(props.ReferenceName);
  const [officialName, setOfficialName] = useState(props.OfficialName);
  
  const [companyAddress, setCompanyAddress] = useState(props.CompanyAddress);
  const [mobileNo1, setMobileNo1] = useState(props.MobileNo1);
  const [mobileNo2, setMobileNo2] = useState(props.MobileNo2);
  const [emailAddress1, setEmailAddress1] = useState(props.EmailAddress1);
  const [emailAddress2, setEmailAddress2] = useState(props.EmailAddress2);
  const [landLineNo1, setLandLineNo1] = useState(props.LandlineNo1);
  const [landLineNo2, setLandLineNo2] = useState(props.LandlineNo2);
  
  const [addDisabled, setAddDisabled] = useState(true);
  const [editDisabled, setEditDisabled] = useState(true);
  const [addActionBegin, setAddActionBegin] = useState(false);
  const [editActionBegin, setEditActionBegin] = useState(false);
  const [deleteActionBegin, setDeleteActionBegin] = useState(true);
  const [saveActionBegin, setSaveActionBegin] = useState(true);
  const [cancelActionBegin, setCancelActionBegin] = useState(true);
  const [codeGenerateLoading, setCodeGenerateLoading] = useState(false);
  const [showDialogs, setShowDialogs] = useState();
    const [deletingProps, setDeletingProps] = useState();
const accountTitleRef = useRef(null)
const accountTypeRef = useRef(null)
const companyRef = useRef(null)
const accountCategoryRef = useRef(null)

const [accountCodes, setAccountCodes] = useState([]);
useEffect(  () => {
      console.log('call')
  chartOfAccountService.getList()
  .then((response) => (setAccountCodes(response.data.recordset)  ))
  .catch((e) => console.log(e));
},[actionType != 'Edit'])


useEffect(() => {
  // document.querySelector('#AccessoryTransferGrid_dialogEdit_wrapper > .e-footer-content button.e-control.e-btn.e-lib.e-primary.e-flat').style.visibility='hidden';
  if(cancelActionBegin == true){
  setActionType('')
  setTimeout(()=>setCheckDuplicate(false),500)
  setIsReadOnly(true)
  setIsShowProfile(false)
    if(props.TreeViewValue !== undefined){
      setTreeViewValues(props.TreeViewValue)
      setEditActionBegin(false)
      setAddActionBegin(false)
      setAddDisabled(false)
      setEditDisabled(false)
      setSaveActionBegin(true)
      setCancelActionBegin(true)
      
   console.log(props.TreeViewValue)
   console.log(props.dataList)
   let selectedRowData = props.dataList.filter(obj => obj.AccountTitleShort.toUpperCase() == props.TreeViewValue.toUpperCase());
   if(selectedRowData !==  undefined){
    setDeleteActionBegin(false)
    selectedRowData.map((res)=>{
        console.log(res)
        console.log(res.AccountCode.length)
        propsAccountCode = res.AccountCode;
        setAccountID(res.AccountID)
        setAccountTypeValue(res.AccountType)
        setAccountTypeID(res.AccountTypeID)
        setCompanyID(res.CompanyID)
        setCompanyValue(res.CompanyValue)
        setCreditAmountLimit(res.CreditAmountLimit)
           setCreditDays(res.CreditDays)
           setOpeningBalance(res.OpeningBalance)
          //  setInactive(res.Inactive == 'YES' ? true : false)
           setInactive(res.Inactive )
           setTransactional(res.AccountCode.length == 12 ? true : false )
           setAccountTitle(res.AccountTitleShort)
           setAccountCode(res.AccountCode)
           setAccountCategoryID(res.AccountCategoryID)
           setAccountCategoryValue(res.AccountCategory)
          //  setAging(res.Aging == 'YES' ?  true : false)
           
           setHoldForEdit(res.AccountTitleShort)
            setContactPerson(res.ContactPerson)
            setOfficeAddress(res.OfficeAddress)
            setLandlineNumber(res.LandlineNumber)
            setCellNumber(res.CellNumber)
            setSTRN(res.STRN)
            setNTN(res.NTN)
            setEmailAddress(res.EmailAddress)
            setAging(false)
          //  chartOfAccountService.getAgingCheck({AccountCode : res.AccountCode}).then((ress)=>{
          //  })
           
    })
    //    console.log(selectedRowData[0].AccountTitleShort)
    //    let selectedObject = selectedRowData[0]
    //    setCreditAmountLimit(selectedRowData[0].creditAmountLimit)
    //    setCreditDays(selectedRowData[0].creditDays)
    //    setOpeningBalance(selectedRowData[0].openingBalance)
   } 


    }
  }else{
    
  }
 
}, [props.TreeViewValue]);

  useEffect(() => {
  setTimeout(() => {
    
    
    if(accountTitle && checkDuplicate == true){
      console.log(props.dataList)
      let check = false
      if(actionType == 'Edit'){
let array = props.dataList.filter(obj => obj.AccountTitleShort !== accountTitle);
        console.log(array)
          check = CheckDuplicate.withOutCombination(array,['AccountTitleShort'],{
            AccountTitleShort: accountTitle
          
          })
//  check = false
      }else if(actionType == 'Add'){

           check = CheckDuplicate.withOutCombination(props.dataList,['AccountTitleShort'],{
            AccountTitleShort: accountTitle
          
          })
      }
       
      console.log(check)
      
      if(!check){
        // document.querySelector('#AccessoryTransferGrid_dialogEdit_wrapper > .e-footer-content button.e-control.e-btn.e-lib.e-primary.e-flat').style.visibility='visible';
        setCheckDuplicateValue('')
      }else{
        // document.querySelector('#AccessoryTransferGrid_dialogEdit_wrapper > .e-footer-content button.e-control.e-btn.e-lib.e-primary.e-flat').style.visibility='hidden';
        setCheckDuplicateValue('Duplicate Value Detected!')
    }
   }
  }, 500);
  }, [accountTitle ]);

  const useStyles = makeStyles((theme) => ({
    fromWrapper: {
      marginTop: theme.spacing(5),
      marginBottom: theme.spacing(8),
    },
  }));

  useEffect(() => {
    // getYarnCompanies();
    // getYarnBrands();
    // getProcurementTypes();
    // getCurrencies();
    // getDeliveryStatus();
    // getPaymentTerms();
    // getImportLCs();
    // getFullYarnCounts();
    setTimeout(() => {
      
      // return document.querySelector('#AccessoryTransferGrid_dialogEdit_wrapper > .e-footer-content button.e-control.e-btn.e-lib .e-flat').innerHTML = `<p>EXIT</p>`;
    }, 1000);
    if(props.isAdd ==  false){
    document.querySelector('#AccessoryTransferGrid_dialogEdit_wrapper > .e-footer-content button.e-control.e-btn.e-lib.e-primary.e-flat').style.visibility='hidden';
    }
    getAccountType('AccountType');
    // getAccountType('AccountCategory');
    getCompany()
  }, []);


  const getCompany = async () => {
    CompanyService.getYarnContractCompanies()
      .then((response) => {
       console.log(response.data.recordset)
       setCompanyDatasource(response.data.recordset)
      })
      .catch((e) => {
        console.log(e);
        
      });
  };

  const getAccountType = async (type) => {
    SetupService.getForList(type)
      .then((response) => {

        console.log(response.data.recordset)
        if(type ==  'AccountCategory'){
          setAccountCategoryDatasource(response.data.recordset)
        }else{

          setAccountTypeDatasource(response.data.recordset);
        }
        
      })
      .catch((e) => {
        console.log(e);
        if(type ==  'AccountCategory'){
          setAccountCategoryDatasource({})
        }else{

          setAccountTypeDatasource({});
        }
        
      });
  };

  const INITIAL_FORM_STATE = {
    // TransporterId: '',
    YarnCount: '',
    WarpWastagePercent: 0,
    WeftWastagePercent: 0,
  };

  const FORM_VALIDATION = Yup.object().shape({
    // OrderId: Yup.string().required('Required'),
    YarnCount: Yup.string().required('Required'),
    WarpWastagePercent: Yup.string().required('Required'),
    WeftWastagePercent: Yup.string().required('Required'),
  });

  
  const fields: object = { text: 'value', value: 'id' };
  const ddlFields: object = { value: 'value' };
  const classes = useStyles();

  const minDate: Date = new Date(moment().startOf('day'));

//   Transactional	Inactive	AccountTypeID	OpeningBalance	CreditDays	CreditAmountLimit OpeningBalance

const AccountTypeChange = (e)=>{
    console.log(e)
    setAccountTypeValue(e?.itemData?.value ?e?.itemData?.value :"");
    if (e?.e?.srcElement?.id) {
      console.log('company change: ', e?.itemData?.id);
      // const inputId = e?.e?.srcElement.id;
      // if(inputId === 'CompanyName'){
      setAccountTypeID(e?.itemData?.id);
      
      // }
    }
}

const CompanyChange = (e)=>{
    console.log(e)
    setCompanyValue(e?.itemData?.value ?e?.itemData?.value :"");
    if (e?.e?.srcElement?.id) {
      console.log('company change: ', e?.itemData?.id);
      // const inputId = e?.e?.srcElement.id;
      // if(inputId === 'CompanyName'){
      setCompanyID(e?.itemData?.id);
      
      // }
    }
}
const AccountCategoryChange = (e)=>{
    console.log(e)
    setAccountCategoryValue(e?.itemData?.value ?e?.itemData?.value :"");
    if (e?.e?.srcElement?.id) {
      console.log('company change: ', e?.itemData?.id);
      // const inputId = e?.e?.srcElement.id;
      // if(inputId === 'CompanyName'){
      setAccountCategoryID(e?.itemData?.id);
      
      // }
    }
}



const onAddNewClick = (type) => {
    console.log('type: ', type);
    if(type !== ''){
      setAddNewClicked(true)
      setShowAddNewDialog(false);
      setDialogType(type);
      setTimeout(() => {
        setShowAddNewDialog(true);
      },100)
    }
  };
  
  const header = () => {
    if(showAddNewDialog) {
      return <div>Add New {dialogType.match(/[A-Z]+[a-z]+/g).join(" ")} </div>;
    }
    else {
      return (<></>);
    }
  };

  const onClose = () => {
    setAddNewClicked(false)
    setShowAddNewDialog(false);
    switch(dialogType){
      case 'AccountCategory':
        getAccountType('AccountCategory');
      break;
      case 'YarnCount':
        // getYarnCount();
      break;
      case 'YarnType':
        // getYarnType();
      break;
      case 'HSCode':
        // getHSCode();
      break;
      default:
        break;
    } 
  }

  const footerTemplate = () => {
    if(showAddNewDialog){
      return (<div>
        <button id="btnSave" className="e-control e-btn e-primary" data-ripple="true" onClick={onClose}>Close</button>
      </div>);
    }
    else{<h3 style={{ textAlign:'center', width: '80%' }}>Conversion Purchase Contract - Add</h3>
       return (<></>);
    }
  };

  const contentTemplate = (props) => {
    console.log('props: ', props);
    if(showAddNewDialog){
      console.log('dialog template');
      if (dialogType === 'YarnCount'){
        return(
        //   <YarnCountList isDialog={true}/>
        <div></div>
        )
      } else if (dialogType === 'YarnType'){
        return(
        //   <YarnTypeList isDialog={true}/>
        <div></div>

        )
      } else {
        return (
          <div>
            {/* <SetupList type={dialogType} isDialog={true} */}
            {/* />  */}
            {/* <SetupAdd onChange={value => setWord(value)}*/}
            <SetupAdd type={dialogType} isDialog={true}  />
          </div>
        );  
      }
    }
    else {
      return (
        <></>
      );
    }
  };

  const AddNewSecurity = (addNewType) => {
    var res =""
      switch(SecurityService.canUserAccess(`${addNewType}List`)){
      case true:
        //('true')
       return res = 'ADD NEW';
      default:
        break;
    } 
   return res
  }

  
  const onAddClick = (e) => {
    console.log(e.target.outerText)
    e.preventDefault()

    setActionType('Add')
    setDeleteActionBegin(true)
    setAddDisabled(true)
    console.log(accountTitle)
    let result = props.dataList.filter(obj => obj.AccountTitleShort.toUpperCase() == accountTitle.toUpperCase())
    
    console.log(result)
    if(result.length > 0){
      setCodeGenerateLoading(true)
      setTimeout(()=>setCheckDuplicate(true),500)
      
      chartOfAccountService.generateChartOfAccountCode(result[0].AccountCode , e.target.outerText).then((res)=>{
    setCodeGenerateLoading(false)
    setCancelActionBegin(false)
    setSaveActionBegin(false)
    setEditActionBegin(true)
        console.log(res)
        if(res.data.recordsets.length > 0){
          console.log(res.data.recordsets[0][0].NewAccountCode)
          setAccountCode(res.data.recordsets[0][0].NewAccountCode)
          setIsReadOnly(false)
          setAccountTypeValue('')
          setAccountTypeID('')
          setCompanyID('')
        setCompanyValue('')
          setCreditAmountLimit('0')
             setCreditDays('0')
             setOpeningBalance('0')
             setInactive('')
             let codeStore = res.data.recordsets[0][0].NewAccountCode;
             setTransactional(codeStore.length == 12 ? true : false)
             setAccountTitle('')
             setCreditAmountLimitDisabled(codeStore.length == 12 ? false : true)
             setOpeningBalanceDisabled(codeStore.length == 12 ? false : true)
             setCreditDaysDisabled(codeStore.length == 12 ? false : true)

          
             setAccountCategoryID('')
             setAccountCategoryValue('')
            //  chartOfAccountService.getAgingCheck({AccountCode : res.AccountCode}).then((ress)=>{
            //   setAging(ress.data.recordset[0].IsAgingAccount == '1' ? true : false)
            //  })
             accountTitleRef.current.element.focus()
        }else{
          setIsReadOnly(true)
        }
      })
    }


    

    // Add your logic for the "ADD" button click here
    console.log("ADD button clicked");
  };
  const userId = SecurityService.getLoggedInUserId();
const onDeleteClick = async (e)=>{
  e.preventDefault()
  console.log(accountCode)
  console.log(accountTitle)
  let selectedRowData = props.dataList.filter(obj => obj.AccountTitleShort.toUpperCase() == accountTitle.toUpperCase());
  console.log(selectedRowData)
   if(selectedRowData !==  undefined){
    if (selectedRowData.length == 0) {
      // no rows selected
      // console.log('Delete ', chartOfAccountGrid.getSelectedRecords()[0]);
      // setDialogWarningVisibility(false);
      let obj={
        TableName:`FullYarnCount`,
        ColumnName:`FullYarnCountID`,
        RowID:undefined,
        SelectedRow:false
        
      }
      setDeletingProps(obj)
      // setShowDialogs(true)
      // setWarningMessage("Please select a row first!");
      // warningDialog.current.show();

    } else {
      // console.log('delete: ', chartOfAccountGrid.getSelectedRecords())
      
      // deleteDialog.current.hide();
      let obj={
        TableName:`ChartOfAccount`,
        ColumnName:`AccountID`,
        RowID:selectedRowData[0]['AccountID'],
        SelectedRow:true,
    //     SelectedRowData:{
    //       AccountCode: chartOfAccountGrid.getSelectedRecords()[0].AccountCode,
    //       AccountTitleShort:  chartOfAccountGrid.getSelectedRecords()[0].AccountTitleShort,
        
    // }

        
  }
        setDeletingProps(obj)
      
      // deleteDialog.current.show();
      setShowDialogs(true)
    }
   }
  
}
  const onSaveClick = async (e) => {
    e.preventDefault()
    console.log(addActionBegin)
    console.log(editActionBegin)
   
    if(editActionBegin == false ||  actionType == 'Edit'){
      console.log('edit mode')
      const data = {
        AccountCode:accountCode
       ,AccountTitle:accountTitle
       ,AccountTitleShort:accountTitle
       ,Transactional:transactional == false ? '0':'1'
       ,Inactive:inactive == false ? '0':'1'
       ,AccountTypeID:accountTypeID
       ,OpeningBalance:openingBalance
       ,CreditDays:creditDays
       ,CreditAmountLimit:creditAmountLimit
       ,ContactPerson:contactPerson
       ,OfficeAddress:officeAddress
       ,LandlineNumber:landlineNumber
       ,CellNumber:cellNumber
       ,NTN:NTN 
       ,STRN:STRN
       ,EmailAddress:emailAddress
       ,AccountCategoryID:1
       ,CompanyID:1
      //  ,Aging:aging == false ? '0':'1'
       ,Aging:'0'
      ,   ApprovedByDate: moment().format('YYYY-MM-DD'),
         CreatedByUserID: userId,
     }
     console.log('args: ', data)
     chartOfAccountService.update(data)
         .then((response) => {
           setCodeGenerateLoading(false)  
          props.callTreeView()
          // onCancelClick()          
          setTreeViewValues(props.TreeViewValue)
          setEditActionBegin(false)
          setAddActionBegin(false)
          setAddDisabled(false)
setEditDisabled(false)
          setActionType('')
          setEditDisabled(false)
          setSaveActionBegin(true)
          setCancelActionBegin(true)
setDeleteActionBegin(false)
          setIsReadOnly(true)
          setTimeout(()=>{
            return  console.log(props.dataList)
          },300)
          // onCancelClick()          
          //  getAccessoryTransfer()
          //    chartOfAccountGrid.refresh();
         });
    }else{
      console.log('add mode')
      const data = {
        AccountCode:accountCode
       ,AccountTitle:accountTitle
       ,AccountTitleShort:accountTitle
       ,Transactional:transactional == false ? '0':'1'
       ,Inactive:inactive == false ? '0':'1'
       ,AccountTypeID:accountTypeID
       ,OpeningBalance:openingBalance
       ,CreditDays:creditDays
       ,CreditAmountLimit:creditAmountLimit
       ,ContactPerson:contactPerson
       ,OfficeAddress:officeAddress
       ,LandlineNumber:landlineNumber
       ,CellNumber:cellNumber
       ,NTN:NTN 
       ,STRN:STRN
       ,EmailAddress:emailAddress
       ,CompanyID:1
       ,AccountCategoryID:1
      //  ,Aging:aging == false ? '0':'1'
       ,Aging:'0'
      ,   ApprovedByDate: moment().format('YYYY-MM-DD'),
         CreatedByUserID: userId,
        //  TransactionDate: moment(transactionDate).format('YYYY-MM-DD')
     }
     console.log('args: ', data)
     chartOfAccountService.create(data)
         .then((response) => {
           setCodeGenerateLoading(false)
           
         props.callTreeView()
          
          onCancelClick()          
          //  getAccessoryTransfer()
          //    chartOfAccountGrid.refresh();
         });
    }
  }

const onCancelClick = (e) => {
e?.preventDefault()
console.log(propsAccountCode)
setDeleteActionBegin(false)
setActionType('')
  setTimeout(()=>setCheckDuplicate(false),500)
  setIsReadOnly(true)
  setIsShowProfile(false)
    if(props.TreeViewValue !== undefined){
      setCodeGenerateLoading(false)
      setTreeViewValues(props.TreeViewValue)
      setEditActionBegin(false)
      setAddActionBegin(false)
      setAddDisabled(false)
      setActionType('')
      setEditDisabled(false)
      setSaveActionBegin(true)
      setCancelActionBegin(true)
      setIsReadOnly(true)
      
   console.log(props.TreeViewValue)
   console.log(props.dataList)
   let selectedRowData = props.dataList.filter(obj => obj.AccountTitleShort.toUpperCase() == props.TreeViewValue.toUpperCase());
   if(selectedRowData !==  undefined){

    selectedRowData.map((res)=>{
        console.log(res)
        console.log(res.AccountCode.length)
        propsAccountCode = res.AccountCode;
        setAccountTypeValue(res.AccountType)
        setAccountTypeID(res.AccountTypeID)
        setCompanyID(res.CompanyID)
        setCompanyValue(res.CompanyValue)
        setCreditAmountLimit(res.CreditAmountLimit)
           setCreditDays(res.CreditDays)
           setOpeningBalance(res.OpeningBalance)
          //  setInactive(res.Inactive == 'YES' ? true : false)
           setInactive(res.Inactive)
           setTransactional(res.AccountCode.length == 12 ? true : false )
           setAccountTitle(res.AccountTitleShort)
           setAccountCode(res.AccountCode)
           setAccountCategoryID(res.AccountCategoryID)
           setAccountCategoryValue(res.AccountCategory)
          //  setAging(res.Aging == 'YES' ? true :  false) 
           setAging(res.Aging ) 
           setHoldForEdit(res.AccountTitleShort)
           setContactPerson(res.ContactPerson)
           setOfficeAddress(res.OfficeAddress)
           setLandlineNumber(res.LandlineNumber)
           setCellNumber(res.CellNumber)
           setSTRN(res.STRN)
           setNTN(res.NTN)
           setEmailAddress(res.EmailAddress)
           
           
    })
  }}
  }

  const onEditClick = async (e) => {
    e.preventDefault()
    setDeleteActionBegin(true)
   if(accountTitle.length > 0 ){
    setIsReadOnly(false)
   }
   
    // setTimeout(()=>setCheckDuplicate(false),500)
    // Add your logic for the "EDIT" button click here
    console.log("EDIT button clicked");

    if(actionType != 'dfdfdfdfAdd' || 1==1){
      setActionType('Edit')
    
console.log(accountCodes)
      let selectedRowData = accountCodes.filter(obj => obj.AccountTitleShort.toUpperCase() == props.TreeViewValue.toUpperCase());
      if(selectedRowData !==  undefined){
   
       selectedRowData.map((res)=>{
        setEditDisabled(true)
        
        setCancelActionBegin(false)
        setSaveActionBegin(false)
        setAddActionBegin(true)
           console.log(res)
           console.log(res.AccountTitleShort)
           setAccountTypeValue(res.AccountType)
           setAccountTypeID(res.AccountTypeID)
           setCompanyID(res.CompanyID)
        setCompanyValue(res.CompanyValue)
           setCreditAmountLimit(res.CreditAmountLimit)
              setCreditDays(res.CreditDays)
              setOpeningBalance(res.OpeningBalance)
              // setInactive(res.Inactive == 'YES' ? true : false)
              setInactive(res.Inactive)
              setTransactional(res.Transactional)
              setAccountTitle(res.AccountTitleShort)
              setAccountCode(res.AccountCode)
              setAccountCategoryID(res.AccountCategoryID)
              setAccountCategoryValue(res.AccountCategory)
              // setAging(res.Aging == "YES" ? true : false) 
              
              setHoldForEdit(res.AccountTitleShort)
              setContactPerson(res.ContactPerson)
            setOfficeAddress(res.OfficeAddress)
            setLandlineNumber(res.LandlineNumber)
            setCellNumber(res.CellNumber)
            setSTRN(res.STRN)
            setNTN(res.NTN)
            setEmailAddress(res.EmailAddress)
              setIsReadOnly(false)
              // chartOfAccountService.getAgingCheck({AccountCode : res.AccountCode}).then((ress)=>{
              //   setAging(ress.data.recordset[0].IsAgingAccount == '1' ? true : false)
              //  })
              accountTitleRef.current.element.select()
              accountTitleRef.current.element.focus()
           
       })
   
      
    }
  };
  };

  console.log(propsAccountCode.length)
// const [disabledEdit, setDisabledEdit] = useState(false);
//   const isEditDisabled = (mode)=>{
//   console.log(accountInString.length)
// if(|| mode == true ){
// return setDisabledEdit(true)
// }else{
// return setDisabledEdit(false)
// }
//   }
  let accountInString = propsAccountCode.toString()
  const  profiledialogClose = ()=>{
    setShowProfileDialog(false)
  }
const fetchValue = (e)=>{
  console.log(e)
  // if(e.OpeningBalance){
  //   setOpeningBalance(e.OpeningBalance)
  // }
  // if(e.LandlineNumber){
  //   setLandlineNumber(e.LandlineNumber)
  
  // }
  if(e.LandlineNumber){
    setLandlineNumber(e.LandlineNumber)
  
  }
  if(e.CreditDays){
    setCreditDays(e.CreditDays)
  
  }
  if(e.CreditAmountLimit){
    setCreditAmountLimit(e.CreditAmountLimit)
  
  }
  if(e.CellNumber){
    setCellNumber(e.CellNumber)
  
  }
  if(e.EmailAddress){
    setEmailAddress(e.EmailAddress)
  
  }
  if(e.STRN){
    setSTRN(e.STRN)
  
  }
  if(e.NTN){
    setNTN(e.NTN)
  
  }
  if(e.ContactPerson){
    setContactPerson(e.ContactPerson)
  
  }
  if(e.OfficeAddress){
    setOfficeAddress(e.OfficeAddress)
  
  }




    
}
  return (
    <div className='yarn-contract-contaiSSner exit-button-div'  onKeyDown={(e)=>{
      if(e.keyCode == 13){
        e.stopPropagation()

      }
    }}
      >

{/* <div>
            <DialogComponent
              width='1200px'
              
              // target='#dialog-targets'
              visible={showProfileDialog}
              close={profiledialogClose}
              content={(e) => {
                // Render your actual content here
                
                return (<div>
                  <ProfileInput profile={{
                    ContactPerson : actionType == 'Add' ?  '-' : contactPerson,
                    OfficeAddress :  actionType == 'Add' ?  '-' : officeAddress,
                    LandlineNumber :  actionType == 'Add' ?  '-' : landlineNumber,
                    CellNumber : actionType == 'Add' ?  '-' : cellNumber,
                    NTN : actionType == 'Add' ?  '-' : NTN,
                    STRN :  actionType == 'Add' ?  '-' :STRN,
                    EmailAddress : actionType == 'Add' ?  '-' : emailAddress,
                    CreditAmountLimit : actionType == 'Add' ?  '0' :  creditAmountLimit,
                    CreditDays : actionType == 'Add' ?  '0' :  creditDays,
                    OpeningBalance :  actionType == 'Add' ?  '0' : openingBalance,
                    OpeningBalanceDisabled :openingBalanceDisabled,
                    CreditDaysDisabled : creditDaysDisabled,
                    CreditAmountLimitDisabled : creditAmountLimitDisabled,
                    AccountID:accountID,
                    AccountCode:accountCode,
                    AccountTitle:accountTitle,

                  }} getValue={fetchValue}/>
                </div>);
              }}
              isModal={true}
              showCloseIcon={true}
              header={(e) => {
                // Render your actual header content here
                return (<div className='chart-of-account-heading' style={{width:'100%' , textAlign:'center',paddingTop:'0px'}}>Chart Of Account - Profile</div>);
              }}
              // zIndex={888888}
              height={'700px'}
              // footerTemplate={'<p></p>'}
              


            />
          </div> */}
        {checkDuplicateValue != '' ?(  <h4 className="duplicate " style={checkDuplicateValue != '' ? {transform:'scale(1)'}:{}}>{checkDuplicateValue}</h4>):null}

       <div className="toolbar">
      {addActionBegin == true ? <></> : SecurityService.canUserAccess('ChartOfAccountAdd') ? <ButtonComponent onClick={onAddClick} disabled={addDisabled} className='tooltip-btn' > ADD </ButtonComponent> : <></>}
      {addActionBegin == true ||  accountInString.length == '12' ? <></> : SecurityService.canUserAccess('ChartOfAccountAdd') ? <ButtonComponent onClick={onAddClick} disabled={addDisabled}  > INSERT </ButtonComponent> : <></>}
      {editActionBegin == false   && SecurityService.canUserAccess('ChartOfAccountAdd') ? <ButtonComponent onClick={onEditClick} disabled={editDisabled}  > EDIT </ButtonComponent> : <></>}
      {deleteActionBegin == false && 1 == 10 ? <ButtonComponent onClick={onDeleteClick}   > DELETE </ButtonComponent> : <></>}
      {saveActionBegin == false ? <ButtonComponent onClick={onSaveClick}  > SAVE </ButtonComponent> : <></>}
      {cancelActionBegin == false ? <ButtonComponent onClick={onCancelClick}  > CANCEL </ButtonComponent> : <></>}

    </div>
    {showDialogs ?
          <HardDelete
            {...deletingProps} 
            showResult={(e)=>{
                                console.log(e)

                                setShowDialogs(false)
                                if(e == false){
                                  setShowDialogs(false)
                                  // setProcessingDetails(
                                  //   processingDetails.filter(
                                  //     (item) => item?.FabricProcessingContractDetailID !== deletingID
                                  //   )
                                  // );

                                  //  fabricProcessingContractGrid.dataSource[args?.rowIndex] =   args?.data;
        // console.log("save: ", data);
        // FabricProcessingContractService.create(data);
        setTimeout(() => {
          
          // yarnCountGrid.refresh()
          setIsReadOnly(true)
          setAccountTypeValue('')
          setAccountCode('')
          setAccountTypeID('')
          setCompanyID('')
        setCompanyValue('')
          setCreditAmountLimit('')
             setCreditDays('')
             setOpeningBalance('')
             setInactive('')
             setTransactional('')
             setAccountTitle('')
          
             setAccountCategoryID('')
             setAccountCategoryValue('')
             setAging('')
          props.callTreeView()
          

        }, 200);
      // getProductCutSize(props.path.substring(1));

                                  // const type = getRoute()[1];
                                  // getProcessDesc(type);
                                }
              }} 
                ></HardDelete>
                  :null}
<div className='backgroundImg'></div>
<div className='App' id='dialog-target'>
          <DialogComponent
            width='800px'
            visible={showAddNewDialog}
            close={showAddNewDialog}
            content={contentTemplate}
            isModal={true}
            header={header}
            footerTemplate = {footerTemplate}
          />
        </div>
      <Grid>
       

        
        <Grid item xs={12}>
          <Container>
            <div >
              <Formik
                initialValues={{
                  ...INITIAL_FORM_STATE,
                }}
                validationSchema={FORM_VALIDATION}
                onSubmit={async (values) => {
                  console.log('form values: ', values);
                  return new Promise((res) => {
                    YarnCountService.create(values).then(
                      (response) => {
                        console.log('response: ', response);
                      }
                    );
                    setTimeout(res, 2500);
                  });
                }}
              >
                {({ values, errors, isSubmitting }) => (
                  <div>
                    {/* <pre> {JSON.stringify({ values, errors }, null, 4)} </pre> */}
        { codeGenerateLoading == false ? <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <TextBoxComponent
                          className='e-input'
                        //   type=''
                          placeholder='Account Code'
                          name='AccountCode'
                          floatLabelType='Auto'
                          value={accountCode}
                          readonly={true}
                          disabled={true}
                           

                          onChange={(e)=> {
                            setAccountCode(e.value)}}
                            onBlur={()=>{
                              console.log('ok')
                           
                            }}
                        />
                         <TextBoxComponent
                          className='e-input'
                          type='hidden'
                          placeholder='Account Code'
                          name='ActionType'
                          floatLabelType='Auto'
                          value={actionType}
                         />
                         <TextBoxComponent
                          className='e-input'
                          type='hidden'
                          name='ShowProfile'
                          floatLabelType='Auto'
                          value={isShowProfile}
                         />
                      </Grid>
                      <Grid item xs={6}>
                        <TextBoxComponent
                          className='e-input'
                          ref={accountTitleRef}
                        id='AccountTitle'
                          placeholder='Account Title'
                          name='AccountTitle'
                          floatLabelType='Auto'
                          value={accountTitle}
                            
                          
                          onChange={(e)=> {
                            console.log(e)
                            console.log(props.dataList)

                            setAccountTitle(e.value)

                          }}
                            onBlur={()=>{
                              console.log('ok')
                              let isDuplicateTitle = props.dataList.some(obj => obj.AccountTitleShort.toUpperCase() == accountTitle.toUpperCase());
                            console.log(isDuplicateTitle)
                              if(isDuplicateTitle && actionType == 'Add'){
                                document.getElementById("AccountTitle").parentElement.parentElement.firstChild.style.border ='3px solid red'
                                       setTimeout(() => {
                                        //  setProductItemTypeError('')
                                         document.getElementById("AccountTitle").parentElement.parentElement.firstChild.style.border ='1px solid #c8c8c8'
       
                                       }, 1000);
                              if (  actionType == 'Edit' || actionType == 'Add'){
                                if(addNewClicked == false ){
                                 accountTitleRef.current.element.focus()
                                }
                                
                                  
                                     }

                              }else{
                                // accountTypeRef.current.element.focus()
                              }
                           
                            }}
                            readOnly={actionType == 'Edit' ? false : isReadOnly }
                            readonly={actionType == 'Edit' ? false : isReadOnly }
                            // disabled={actionType == 'Edit' ? true : isReadOnly }
                        />
                      </Grid>
                      


                      {/* <Grid item xs={6}>
                        {loadingCompany ? (
                          <div>... Loading Account Type </div>
                        ) : (
                          <div>
                            <AutoCompleteComponent
                              id='Company'
                              dataSource={companyDatasource}
                              fields={ddlFields}
                              placeholder='Select Company'
                              floatLabelType='Auto'
                              highlight={true}
                              select={CompanyChange}
                              change={CompanyChange}
                              // value={importLCID}
                              value={companyValue}
                              readonly={isReadOnly}

                              ref={companyRef}
                              blur={()=>{
                                
                                if (companyID  == undefined  || companyRef.current.value == null || companyRef.current.value == '' || companyID == undefined || companyID  == 0) {
                                 if(companyValue != ''){
                                   let resstr = companyDatasource.some(e=>e.value ==  companyValue);
                                   console.log(resstr)
                                   if(resstr === false){
                                    //  setProductGroupErorr(`${yarnTypeValue} Value Not Find ` )
                                     document.getElementById("Company").parentElement.parentElement.firstChild.style.border ='3px solid red'
                                     setTimeout(() => {
                                      //  setProductItemTypeError('')
                                       document.getElementById("Company").parentElement.parentElement.firstChild.style.border ='1px solid #c8c8c8'
     
                                     }, 1000);
                                   }
                                 }
                                 if (addNewClicked == false){
                                  companyRef.current.element.focus()
                              
                                 }
                               }
                             }}
                            />
                            <TextBoxComponent
                              type='hidden'
                              name='CompanyID'
                              value={companyID}
                            />
                          </div>
                        )}
                      </Grid> */}
                        {/* <span className='add-new' onClick={()=>onAddNewClick('AccountType')}>
                        {AddNewSecurity('AccountType')} 
                        </span> */}
                      
                      {/* <Grid item xs={6}>
                        {loadingAccountCategory ? (
                          <div>... Loading Account Category </div>
                        ) : (
                          <div>
                            <AutoCompleteComponent
                              id='AccountCategory'
                              dataSource={accountCategoryDatasource}
                              fields={ddlFields}
                              placeholder='Select Account Category'
                              floatLabelType='Auto'
                              highlight={true}
                              select={AccountCategoryChange}
                              change={AccountCategoryChange}
                              // value={importLCID}
                              value={accountCategoryValue}
                              readonly={isReadOnly}

                              ref={accountCategoryRef}
                              blur={()=>{
                                
                                if (accountCategoryID  == undefined  || accountCategoryRef.current.value == null || accountCategoryRef.current.value == '' || accountCategoryID == undefined || accountCategoryID  == 0) {
                                 if(accountCategoryValue != ''){
                                   let resstr = accountCategoryDatasource.some(e=>e.value ==  accountCategoryValue);
                                   console.log(resstr)
                                   if(resstr === false){
                                    //  setProductGroupErorr(`${yarnTypeValue} Value Not Find ` )
                                     document.getElementById("AccountCategory").parentElement.parentElement.firstChild.style.border ='3px solid red'
                                     setTimeout(() => {
                                      //  setProductItemTypeError('')
                                       document.getElementById("AccountCategory").parentElement.parentElement.firstChild.style.border ='1px solid #c8c8c8'
     
                                     }, 1000);
                                   }
                                 }
                                 if (addNewClicked == false){
                                  accountCategoryRef.current.element.focus()
                              
                                 }
                               }
                             }}
                            />
                            <TextBoxComponent
                              type='hidden'
                              name='AccountCategoryID'
                              value={accountCategoryID}
                            />
                            <span className='add-new' onClick={()=>onAddNewClick('AccountCategory')}>
                            {AddNewSecurity('AccountCategory')} 
                            </span>
                          </div>
                        )}
                      </Grid> */}
                      <Grid item xs={6}>
                        {loadingAccountType ? (
                          <div>... Loading Account Type </div>
                        ) : (
                          <div>
                            <AutoCompleteComponent
                              id='AccountType'
                              dataSource={accountTypeDatasource}
                              fields={ddlFields}
                              placeholder='Select Account Type'
                              floatLabelType='Auto'
                              highlight={true}
                              select={AccountTypeChange}
                              change={AccountTypeChange}
                              // value={importLCID}
                              value={accountTypeValue}
                              readonly={isReadOnly}
                              focus={()=>{
                                if (accountTypeRef ) {accountTypeRef.current.showPopup();}
                              }}
                              ref={accountTypeRef}
                              blur={()=>{
                                
                                if (accountTypeID  == undefined  || accountTypeRef.current.value == null || accountTypeRef.current.value == '' || accountTypeID == undefined || accountTypeID  == 0) {
                                 if(accountTypeValue != ''){
                                   let resstr = accountTypeDatasource.some(e=>e.value ==  accountTypeValue);
                                   console.log(resstr)
                                   if(resstr === false){
                                    //  setProductGroupErorr(`${yarnTypeValue} Value Not Find ` )
                                     document.getElementById("AccountType").parentElement.parentElement.firstChild.style.border ='3px solid red'
                                     setTimeout(() => {
                                      //  setProductItemTypeError('')
                                       document.getElementById("AccountType").parentElement.parentElement.firstChild.style.border ='1px solid #c8c8c8'
     
                                     }, 1000);
                                   }
                                 }
                                 if (addNewClicked == false){
                                  accountTypeRef.current.element.focus()
                              
                                 }
                               }
                             }}
                            />
                            <TextBoxComponent
                              type='hidden'
                              name='AccountTypeID'
                              value={accountTypeID}
                            />
                            <span className='add-new' onClick={()=>onAddNewClick('AccountType')}>
                            {AddNewSecurity('AccountType')} 
                            </span>
                          </div>
                        )}
                      </Grid>
                      <Grid item xs={6}>
                        <NumericTextBoxComponent
                          className='e-input'
                        //   type=''
                          placeholder='Opening Balance'
                          name='OpeningBalance'
                          floatLabelType='Auto'
                          value={openingBalance}
                            showSpinButton={false}
                            // disabled={openingBalanceDisabled}
                            // ref={OpeningBalanceRef}
                          onChange={(e)=> {
                       
                            setOpeningBalance(e.value) }}
                            onBlur={()=>{
                              console.log('ok')
                           
                            }}
                            // readonly={isReadOnly}
                            // created={()=>{
                            //   if(props.profile.OpeningBalanceDisabled == false){
                            //   setTimeout(() => {
                                
                            //     OpeningBalanceRef?.current?.element?.focus()
                            //   }, 500);
                            //   }
                            // }}
                        />
                      </Grid> 
                      <Grid item xs={4} id='transactional'>
                        <div className='checkbox-cover-div-parent'>
                        <div className='cover-div'></div>
                        <CheckBoxComponent
                          className='e-input'
                        //   type=''
                          placeholder='Transactional'
                          name='Transactional'
                          showSpinButton={false}
                          floatLabelType='Auto'
                          value={transactional}
                          label='Transactional'
                          checked={transactional}
                          // disabled={isReadOnly}
                          // disabled={true}
                          enablePersistence={true}

                          

                          change={(e)=> {
                            console.log(e)
                            setTransactional(transactional)}}
                            onBlur={()=>{
                              console.log('ok')
                           
                            }}
                            readOnly={true}
                            
                            
                            
                        />
                        </div>
                      </Grid>
                      {/* {actionType == 'Add' || actionType == 'Edit' ? <div></div> :  <div className='cover-div'></div>}  */}
                      {/* <Grid item xs={4} id='aging'>
                      <div className='checkbox-cover-div-parent'>
                        <div className='cover-div'></div>
                        <CheckBoxComponent
                          className='e-input'
                        //   type=''
                          placeholder='Aging'
                          name='Aging'
                          
                          floatLabelType='Auto'
                          // value={aging}
                          label='Aging'
                          checked={aging}
                          // readOnly={actionType == 'Edit' ? false : isReadOnly }
                          // disabled={actionType == 'Edit' ? false : isReadOnly }
                          // disabled={actionType == 'Add' || actionType == 'Edit' ? false :  true}

                          change={(e)=> {
                            console.log(e)
                            setAging(e.checked)}}
                            onBlur={()=>{
                              console.log('ok')
                           
                            }}
                            // readOnly={isReadOnly}
                            
                            
                        />
                        </div>
                      </Grid> */}
                      <Grid item xs={4} id='inactive'>
                      <div className='checkbox-cover-div-parent'> 
                        {actionType == 'Add' || actionType == 'Edit' ?  <div></div> : <div className='cover-div'></div> } 
                        <CheckBoxComponent
                          className='e-input'
                        //   type=''
                          placeholder='Inactive'
                          name='Inactive'
                          floatLabelType='Auto'
                        //   value={inactive}
                          label={'In Active'}
                          // readOnly={ ? false : isReadOnly }
                          // disabled={actionType == 'Add' || actionType == 'Edit' ? false :  true}
                          checked={inactive}
                          showSpinButton={false}
                          change={(e)=> {
                       
                            setInactive(e.checked)}}
                            onBlur={()=>{
                              console.log('ok')
                           
                            }}
                        />
                        </div>
                      </Grid>
                      
                      
                      
                      
                      
                      
                      {/* <Grid item xs={4}  id='profile'></Grid> */}
                      
                      <Grid item xs={2}  id='profile'>
                        {accountInString.length == '12'  && 1 == 2?
                        <ButtonComponent
                         disabled={isReadOnly} 
                        //  disabled={props.TreeViewValue == undefined ? true  : false} 
// 
                        // '
                         onClick={(e)=>{
                          e.preventDefault()
                          setIsShowProfile(true)
                          setShowProfileDialog(true)
                         }}
                         >
                          Profile 
                        </ButtonComponent> : <></>}
                        </Grid>
                        {
                          isShowProfile  && 1 == 2? 
                       (<>
                         <Grid item xs={4}>
                          </Grid>
                         <Grid item xs={4}>
                        <TextBoxComponent
                          className='e-input'
                          placeholder='Contact Person'                          
                          name='ContactPerson'
                          floatLabelType='Auto'
                          value={contactPerson}
                            
                         onChange={(e)=> { setContactPerson(e.value)}}
                            onBlur={()=>{    console.log('ok')}}
                        />
                      </Grid>
                        <Grid item xs={4}>
                        <TextBoxComponent
                          className='e-input'
                          placeholder='Office Address'                          
                          name='OfficeAddress'
                          floatLabelType='Auto'
                          value={officeAddress}
                            
                         onChange={(e)=> { setOfficeAddress(e.value)}}
                            onBlur={()=>{    console.log('ok')}}
                        />
                      </Grid>
                        <Grid item xs={4}>
                        <TextBoxComponent
                          className='e-input'
                          placeholder='Landline Number'  
                          name='LandlineNumber'
                          floatLabelType='Auto'
                          value={landlineNumber}
                            showSpinButton={false}
                         onChange={(e)=> { setLandlineNumber(e.value)}}
                            onBlur={()=>{    console.log('ok')}}
                        />
                      </Grid>
                        <Grid item xs={4}>
                        <TextBoxComponent
                          className='e-input'
                          placeholder='CellNumber' 
                          name='CellNumber'
                          floatLabelType='Auto'
                          value={cellNumber}
                            
                         onChange={(e)=> { setCellNumber(e.value)}}
                            onBlur={()=>{    console.log('ok')}}
                        />
                      </Grid>
                        <Grid item xs={4}>
                        <TextBoxComponent
                          className='e-input'
                          placeholder='NTN'                          
                          name='NTN'
                          floatLabelType='Auto'
                          value={NTN}
                            showSpinButton={false}
                         onChange={(e)=> { setNTN(e.value)}}
                            onBlur={()=>{    console.log('ok')}}
                        />
                      </Grid>
                        <Grid item xs={4}>
                        <TextBoxComponent
                          className='e-input'
                          placeholder='STRN'                          
                          name='STRN'
                          floatLabelType='Auto'
                          value={STRN}
                          onChange={(e)=> { setSTRN(e.value)}}
                            onBlur={()=>{    console.log('ok')}}
                        />
                      </Grid>
                        <Grid item xs={4}>
                        <TextBoxComponent
                          className='e-input'
                          placeholder='EmailAddress'                          
                          name='EmailAddress'
                          floatLabelType='Auto'
                          value={emailAddress}
                          onChange={(e)=> { setEmailAddress(e.value)}}
                            onBlur={()=>{    console.log('ok')}}
                        />
                      </Grid> </>)
                          :
                         <Grid />
                        }
                    </Grid> :
                    

                      <div className='loader-div'> <img src={imgSrc} id='' /> LOADING...</div>
                    

                    }

                  </div>
                )}
              </Formik>
            </div>
          </Container>
        </Grid>
      </Grid>
                    {/* <button className='exit-button'>Exit</button> */}
    </div>
  );
}
