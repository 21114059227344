import http from './http-common';

class YarnCountService {
  getAll() {
    return http.get('/yarnCounts');
  }
  getYarnCountsForYarnContract() {
    return http.get('/yarnCounts/yarnCountsForYarnContract');
  }
  get(id) {
    return http.get(`/yarnCounts/${id}`);
  }

  create(data) {
    return http.post(`/yarnCounts/add`, data);
  }

  update(data) {
    return http.put(`/yarnCounts/update`, data);
  }

  delete(id) {
    return http.delete(`/yarnCounts/${id}`);
  }
}

export default new YarnCountService();
