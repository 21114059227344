// import React , {useState,useEffect}from 'react';
// import { DialogComponent } from "@syncfusion/ej2-react-popups";
import './check-duplicate.css'

class CheckDuplicate {
  withCombination(dataSource, filter, userFeed) {
   return dataSource.some(item =>
      Object.keys(userFeed).every(key =>
        String(item[key]).toLowerCase() === String(userFeed[key]).toLowerCase()
      )
    );
        }
        withOutCombination(dataSource, filter, userFeed) {
          return filter.some(key => dataSource.some(data => String(data[key]).toLowerCase() === String(userFeed[key]).toLowerCase()));
        }

//   CheckDuplicateUI = (props) =>{
//     const [showDialog, setShowDialog] = useState(true);
    
//     return(
// <DialogComponent
//         visible={showDialog}
//         width={'200px'}
//         header="ERROR !"
//        content="<div className='Duplicate-Content'>DUPLICATE VAlUE</div>"
//         className='duplicate'
//         showCloseIcon={false}
//         footerTemplate={props.Children}
//         isModal={true}
       
//         zIndex={99999}
//       ></DialogComponent>
//     )
//   }
}

export default new CheckDuplicate();

// import './check-duplicate.css'
// const CheckDuplicate = (props) => {
//     console.log('duplicate-props',props.props)
//     const {userFeed ,dataSource, fliterColumns,grid ,showBox} = {...props.props}
//     console.log(userFeed)
//     console.log(dataSource)
//     console.log(fliterColumns)
//     console.log(showBox)
//     const [showDialog, setShowDialog] = useState(true);
//     const [result, setResult] = useState(false);
    
//     useEffect(() => {
//       var result = withCombination(dataSource, fliterColumns, userFeed);
//     console.log(result); // Output: true
//     setResult(result)
//     props.getResult(result)
     
//     }, []);
  
//     function withCombination(dataSource, filter, userFeed) {
//       return filter.every(key => dataSource.some(data => data[key] === userFeed[key]));
//     }
    
    
//     useEffect(() => {
    
//       // props.getResult(false)
      
//       setTimeout(() => {
//         setShowDialog(true)
//       }, 2000);
      
//     }, [showDialog == false]);
  
//     return (
      
//       result == true ?
           
//    (  <DialogComponent
//         visible={showDialog}
//         width={'200px'}
//         header="ERROR !"
//        content="<div className='Duplicate-Content'>DUPLICATE VAlUE</div>"
//         className='duplicate'
//         showCloseIcon={false}
//         isModal={true}
//         onClose={() =>{ 
//         console.log('onclose')
     
//           setShowDialog(false)

        
//         }}
//         zIndex={99999}
//       ></DialogComponent>)
//       : null
      
//     );
// }

// export default CheckDuplicate;
