import React ,{useState , useEffect,useMemo, useRef} from 'react';
import AddNewDialog from './irtiza-dynamic-addnew';
import { DatePickerComponent } from '@syncfusion/ej2-react-calendars';
import { TextBoxComponent ,NumericTextBoxComponent} from '@syncfusion/ej2-react-inputs';
import moment from 'moment';


import { AutoCompleteComponent } from '@syncfusion/ej2-react-dropdowns';
import DropdownService from '../irtiza-dynamic-input/dropdown-api.service';

import {
  CheckBoxComponent,
  ButtonComponent,
} from "@syncfusion/ej2-react-buttons";

const DynamicDropDownInput = (props) => {
  console.log(props)
  const [loading, setLoading] = useState(false);
  const [dataSourceState, setDataSourceState] = useState([]);
  const [dropdownID, setDropdownID] = useState(props.value.id ? props.value.id : undefined);
  const [dropdownValue, setDropdownValue] = useState(props.value.value ?props.value.value : '');
  const [addNew, setAddNew] = useState(props.addNew ? props.addNew : {});
  
  const [addNewClicked, setAddNewClicked] = useState(false);
  const [allowThisUseEffect, setAllowThisUseEffect] = useState(false);

  useEffect(() => {
    console.log(props)
    if(allowThisUseEffect == true){
    setTimeout(()=>{

      setDropdownValue(props.value.value)
      setDropdownID(props.value.id)
    },1000)
  }
  }, [props.value.value]);
  const refs = useRef({});

  // Assign a dynamic ref based on the name prop
  if (!refs.current[props.name.value]) {
    refs.current[props.name.value] = React.createRef();
  }
  const dynamicRef = refs.current[props.name.value];
  console.log(dynamicRef)
  const ddlFields: object = { value: 'value' };

  useEffect(() => {
    if(props.dataSource.type == 'Route' ){
      console.log(props.dataSource.type);
      console.log(props.dataSource.route);
      getDropdownService(props.dataSource.route)
      setTimeout(()=>setAllowThisUseEffect(true),300)
    }
    else if(props.dataSource.type == 'Data' ){
      setDataSourceState(props.dataSource.data);
      if(props.isAdd == true && props.value.id != 'undefined'){
        let filterData = props.dataSource.data.filter(res=> res.id == props.value.id)
        console.log(filterData)
        setDropdownID(filterData[0].id)
        setDropdownValue(filterData[0].value)
        setTimeout(()=>setAllowThisUseEffect(true),300)
      }
    }
    
  }, []);

  const getDropdownService = async (type) => {
    try {
      setLoading(false)
      const response = await DropdownService.getAllForList(type);
      if (response.data || response.data.recordset) {
              setLoading(false)
              setDataSourceState(response.data.recordset);
              if(props.isAdd == true && props.value.id != 'undefined'  && props.value.id != null){
                let filterData = response.data.recordset.filter(res=>res.id == props.value.id)
                console.log(filterData)
                setDropdownID(filterData[0].id)
                setDropdownValue(filterData[0].value)
              }
       } else {
        setLoading(false)
        console.error("Invalid response structure:", response);
        setDataSourceState([])
      }
     } catch (error) {
      setLoading(false)
      
      setDataSourceState([])
    }
  };


  const handleInputFocus = (inputRef) => {if (inputRef ) {inputRef.current.showPopup();}};

  const handleChange = (e) =>{
    setDropdownID(e?.itemData?.id ? e?.itemData?.id : undefined)
    setDropdownValue(e?.itemData?.value ? e?.itemData?.value : '')
    if(e?.itemData?.id ){
    props.setInputProps((prevValues) => ({
      ...prevValues,
      [props.name.value]: {...prevValues[props.name.value] ,value :{id:e?.itemData?.id ? e?.itemData?.id : undefined , value:e?.itemData?.value ? e?.itemData?.value :''} }
    }));
  }else{
      props.setInputProps((prevValues) => ({
        ...prevValues,
        [props.name.value]: {...prevValues[props.name.value] ,value :{id:e?.itemData?.id ? e?.itemData?.id : undefined , value:e?.itemData?.value ? e?.itemData?.value :''} }
      }));

    }
  }

  const handleAddNewBlur = (id, ref, value, elementId, productArray, isHaveAddNew) => {
    if (id === 0 || ref.current.value == null || ref.current.value === '' || id === undefined || id === null) {
      if (value !== '') {
        const isValueInProductDesc = productArray.some((e) => e.value === value);
        if (!isValueInProductDesc) {
          const elem = document.getElementById(elementId).parentElement.parentElement.firstChild;
          elem.style.border = '3px solid red';
          setTimeout(() => {
            elem.style.border = '1px solid #c8c8c8';
          }, 1000);
        }
      }
      if (!isHaveAddNew) {
        ref.current.element.focus();
      }
    }
  };

  const onClosing = (e)=>{
console.log(e);
getDropdownService(props.dataSource.route)
const dynamicRef = refs.current[e];

console.log(dynamicRef.current)

dynamicRef.current.element.focus()

  }
  return (
    loading ? (
      <div>... Loading {props.name.value.replace(/([A-Z])/g, ' $1').trim()}</div>
    ) : (
      <div>
        <AutoCompleteComponent 
          id={props.name.value} 
          
          dataSource={dataSourceState}  
          placeholder={props.caption ? props.caption : `Select ${props.name.replace(/([A-Z])/g, ' $1').trim()}`}
          fields={ddlFields}
          floatLabelType='Auto' 
          highlight={true}
          // ={(data)=>{
          //   return (<span><span className='name'>{data.id}</span><span className='name'>{data.value}</span></span>);
          // }}
        
          created={(e)=>(props.onCreated ? props.onCreated(e, props.name.value) : null)}
        
          focus={(e) =>(props.onFocus ? props.onFocus(e, props.name.value) : null ,handleInputFocus(dynamicRef))}
        
          change={(e) => (props.onChange ? props.onChange({
            ...e?.itemData,
              id:e?.itemData?.id ? e?.itemData?.id : undefined,
              value:e?.itemData?.value ? e?.itemData?.value : ''
          }, props.name.value) : null,handleChange(e))}
        
          select={(e) => (props.onSelect ? props.onSelect({
            ...e?.itemData,
            id:e?.itemData?.id ? e?.itemData?.id : undefined,
            value:e?.itemData?.value ? e?.itemData?.value : '' 
        }, props.name.value): null,handleChange(e))}

        blur={() =>(
          props?.isControlBlur  == false? null :  handleAddNewBlur(dropdownID,dynamicRef,dropdownValue,props.name.value,dataSourceState,addNewClicked),
            props.onBlur ? props.onBlur({
              id:dropdownID,
              value:dropdownValue,
              dataSource:dataSourceState
            }, props.name.value) : null,console.log(dropdownID) )
          }
          value={dropdownValue}
          ref={dynamicRef}
          // disabled={props?.enabled   == undefined ? false : !props?.enabled}     
          // disabled={true}     
          enabled={props?.enabled   == undefined ? true : props?.enabled}
          />
        <TextBoxComponent
          className='e-input'
          type='hidden'
          name={props.name.id}
          floatLabelType='Auto'
          value={dropdownID}
        />
        {addNew.Route && addNew.Security?
        <AddNewDialog 
        Route={addNew.Route} 
        Security={addNew.Security} 
        onClosed={onClosing} 
        RefName={props.name.value}
        />
        :null}
      </div>
    )
  );
};



const DynamicDateInput = (props) => {
  console.log(`${props.name } props: ${props}`)

  const handleBlur = (e) => {
    console.log(e)
    props.setInputProps((prevValues) => ({
      ...prevValues,
      [props.name]: {...prevValues[props.name] ,value :e?.target?.value || prevValues[props.name].value}
    }));
  };
  const handleChange = (e) => {
    console.log(e)
    props.setInputProps((prevValues) => ({
      ...prevValues,
      [props.name]: {...prevValues[props.name] ,value :e?.value || prevValues[props.name].value }
    }));
  };

  return (
    <>
      <DatePickerComponent
        id={props.name}
        name={props.name}
        placeholder={props.caption ? props.caption : props.name.replace(/([A-Z])/g, ' $1').trim()}
        floatLabelType='Auto'
        format='dd-MMM-yy'
        created={(e)=>(props.onCreated ? props.onCreated(e, props.name) : null)}
        onBlur={(e)=>(props.onBlur ? props.onBlur(e.target.value, props.name) : null ,handleBlur(e))}
        focus={(e) => props.onFocus ? props.onFocus(e, props.name) : null}
        onChange={(e) => (props.onChange ? props.onChange(e.value, props.name) : null,handleChange(e))}
        value={props.value == 'currentDate'?  new Date() : props.value || ''}
        disabled={props?.enabled   == undefined ? false : !props?.enabled}     
      />
    </>
  );
};

const DynamicNumericInputs = (props) => {
  console.log(props)
  // const formatNumberWithCommas = (number) => {
  //   return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  // };
  const handleChange = (e) => {
    console.log(e)
    props.setInputProps((prevValues) => ({
      ...prevValues,
      [props.name]: {...prevValues[props.name] ,value :e?.value == "" ? prevValues[props.name].value :  e.value}
    }));
  };
  const handleBlur = (e) => {
    console.log(e)
    props.setInputProps((prevValues) => ({
      ...prevValues,
      [props.name]: {...prevValues[props.name] ,value :e?.target?.value == "" ?  parseFloat(0).toFixed(props.decimal || 0)  : parseFloat(e.target.value).toFixed(props.decimal || 0)}
    }));
  };
 
  const handleCreated = (e) => {
    console.log(e);
    // const value = parseFloat(props.value).toFixed(props.decimal || 0) || 0.0;
    const value = props.value || 0.0;
    props.setInputProps((prevValues) => ({
      ...prevValues,
      [props.name]: {
        ...prevValues[props.name],
        value: value,
        // formattedValue: formatNumberWithCommas(value)
      }
    }));
  };
  return (
    <>
      <TextBoxComponent
        className='e-input'
        type='number'
        placeholder={props.caption ? props.caption : props.name.replace(/([A-Z])/g, ' $1').trim()}
        // placeholder={props.caption ? props.caption :''}
        name={props.name}
      
        id={props.name}
        floatLabelType='Auto'
        value={props.value || 0.0}
        // value={props.formattedV/alue || 0.0}
 
        created={(e)=>(handleCreated(e) , props.onCreated ? props.onCreated(e, props.name) : null )}
        onBlur={(e)=>(handleBlur(e) , props.onBlur ? props.onBlur(e.target.value, props.name) : null)}
        focus={(e) => props.onFocus ? props.onFocus(e, props.name) : null}
        onChange={(e) => (handleChange(e) , props.onChange ? props.onChange(e.value, props.name) : null)}
        enabled={props?.enabled == false  ?  props?.enabled :    true}     
        // disabled={!props?.enabled  false}

        />
    </>
  );
};


// const DynamicNumericInput = (props) => {
//   const [inputType, setInputType] = useState('text');

//   const handleChange = (e) => {
//     console.log(e);
//     props.setInputProps((prevValues) => ({
//       ...prevValues,
//       [props.name]: {
//         ...prevValues[props.name],
//         value: e?.value === "" ? prevValues[props.name].value : e.value
//       }
//     }));
//   };

//   const handleBlur = (e) => {
//     console.log(e);
//     setInputType('text'); // Change back to text on blur
//     props.setInputProps((prevValues) => ({
//       ...prevValues,
//       [props.name]: {
//         ...prevValues[props.name],
//         value: e?.target?.value === "" ? parseFloat(0).toFixed(props.decimal || 0) : parseFloat(e.target.value).toFixed(props.decimal || 0)
//       }
//     }));
//   };

//   const handleFocus = (e) => {
//     console.log(e);
//     setInputType('number'); // Change to number on focus
//     if (props.onFocus) {
//       props.onFocus(e, props.name);
//     }
//   };

//   const handleCreated = (e) => {
//     console.log(e);
//     const value = props.value || 0.0;
//     props.setInputProps((prevValues) => ({
//       ...prevValues,
//       [props.name]: {
//         ...prevValues[props.name],
//         value: value,
//       }
//     }));
//   };

//   return (
//     <>
//       <TextBoxComponent
//         className='e-input'
//         type={inputType}
//         placeholder={props.caption ? props.caption : props.name.replace(/([A-Z])/g, ' $1').trim()}
//         name={props.name}
//         id={props.name}
//         floatLabelType='Auto'
//         value={inputType == 'text' ?  props.value.toLocaleString() || 0.0 : props.value || 0.0}
//         created={(e) => (handleCreated(e), props.onCreated ? props.onCreated(e, props.name) : null)}
//         onBlur={(e) => (handleBlur(e), props.onBlur ? props.onBlur(e.target.value, props.name) : null)}
//         onFocus={handleFocus}
//         onChange={(e) => (handleChange(e), props.onChange ? props.onChange(e.value, props.name) : null)}
//         enabled={props?.enabled === false ? props?.enabled : true}
//       />
//     </>
//   );
// };


const DynamicNumericInput = (props) => {
  console.log(props)
  const [inputType, setInputType] = useState('text');
  const [displayValue, setDisplayValue] = useState(props.value || 0.0);

  useEffect(() => {
    if (inputType === 'text') {
      console.log(props.value)
      setDisplayValue(props.value.toLocaleString());
    } else {
      setDisplayValue(props.value);
    }
  }, [props.value]);
  // }, [displayValue]);

  const formatNumberWithCommas = (number) => {
    let comma = number.toLocaleString();
    console.log(comma)
    return comma
  };

  const handleChange = (e) => {
    console.log(e);
    const value = e?.value === "" ? props.value : e.value;
    props.setInputProps((prevValues) => ({
      ...prevValues,
      [props.name]: {
        ...prevValues[props.name],
        value: value
      }
    }));
    setDisplayValue(value);
  };

  const handleBlur = (e) => {
    console.log(e);
    const formattedValue = e?.event.target?.value === "" ? parseFloat(0).toFixed(props.decimal || 0) : parseFloat(e.event.target.value).toFixed(props.decimal || 0);
    props.setInputProps((prevValues) => ({
      ...prevValues,
      [props.name]: {
        ...prevValues[props.name],
        value: formattedValue
      }
    }));
    console.log(formattedValue)
    setInputType('text'); // Change back to text on blur
    
    
    console.log(typeof formattedValue)
    console.log(formattedValue.replace(/\B(?=(\d{3})+(?!\d))/g, ","))
    setDisplayValue(formattedValue.replace(/\B(?=(\d{3})+(?!\d))/g, ","));
  };

  const handleFocus = (e) => {
    console.log(e);
    setInputType('number'); // Change to number on focus
    setDisplayValue(props.value);
    if (props.onFocus) {
      props.onFocus(e, props.name);
    }
    
    setTimeout(()=>document.getElementById(`${props.name}_display`).select(),100)
    
  };

  const handleCreated = (e) => {
    console.log(e);
    const value = props.value || 0.0;
    props.setInputProps((prevValues) => ({
      ...prevValues,
      [props.name]: {
        ...prevValues[props.name],
        value: value,
      }
    }));
    setDisplayValue(formatNumberWithCommas(value));
  };

  return (
    <>
      <TextBoxComponent
        className='e-input'
        type={inputType}
        placeholder={props.caption ? props.caption : props.name.replace(/([A-Z])/g, ' $1').trim()}
        name={`${props.name}_display`}
        id={`${props.name}_display`}
        floatLabelType='Auto'
        value={displayValue}
        created={(e) => (handleCreated(e), props.onCreated ? props.onCreated(e, props.name) : null)}
        blur={(e) => (handleBlur(e), props.onBlur ? props.onBlur(e.event.target.value, props.name) : null)}
        onFocus={handleFocus}
    
        onChange={(e) => (handleChange(e), props.onChange ? props.onChange(e.value, props.name) : null)}
        enabled={props?.enabled === false ? props?.enabled : true}
      />
  <TextBoxComponent
        name={props.name}
        id={props.name}
        type='hidden'
        value={props.value}

  />
    </>
  );
};
 


// const DynamicNumericInput = (props) => {
//   const [inputType, setInputType] = useState('text');
//   const [displayValue, setDisplayValue] = useState('');
//   const [actualValue, setActualValue] = useState(props.value || 0.0);

//   useEffect(() => {
//     if (inputType === 'text') {
//       setDisplayValue(formatNumberWithCommas(actualValue));
//     } else {
//       setDisplayValue(actualValue);
//     }
//   }, [inputType, actualValue]);

//   const formatNumberWithCommas = (number) => {
//     return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
//   };

//   const handleChange = (e) => {
//     const value = e?.value === "" ? 0 : e.value;
//     setActualValue(value);
//     props.setInputProps((prevValues) => ({
//       ...prevValues,
//       [props.name]: {
//         ...prevValues[props.name],
//         value: value
//       }
//     }));
//     setDisplayValue(value);
//   };

//   const handleBlur = (e) => {
//     setInputType('text');
//     setDisplayValue(''); // Hide value on blur
//     const value = e?.target?.value === "" ? 0 : parseFloat(e.target.value).toFixed(props.decimal || 0);
//     setActualValue(value);
//     props.setInputProps((prevValues) => ({
//       ...prevValues,
//       [props.name]: {
//         ...prevValues[props.name],
//         value: value
//       }
//     }));
//   };

//   const handleFocus = (e) => {
//     setInputType('number');
//     setDisplayValue(actualValue);
//     if (props.onFocus) {
//       props.onFocus(e, props.name);
//     }
//   };

//   const handleCreated = (e) => {
//     // let value = props.value < 1000 ? 0 : props.value;
//     console.log(props.value)
//     let value =  props.value || 0;
//     setActualValue(value);
//     props.setInputProps((prevValues) => ({
//       ...prevValues,
//       [props.name]: {
//         ...prevValues[props.name],
//         value: value,
//       }
//     }));
//     console.log(formatNumberWithCommas(value))
//     setDisplayValue(formatNumberWithCommas(value));
//   };

//   return (
//     <>
//       <TextBoxComponent
//         className='e-input'
//         type={inputType}
//         placeholder={props.caption ? props.caption : props.name.replace(/([A-Z])/g, ' $1').trim()}
//         name={props.name}
//         id={props.name}
//         floatLabelType='Auto'
//         value={displayValue}
//         created={(e) => (handleCreated(e), props.onCreated ? props.onCreated(e, props.name) : null)}
//         onBlur={(e) => (handleBlur(e), props.onBlur ? props.onBlur(e.target.value, props.name) : null)}
//         onFocus={handleFocus}
//         onChange={(e) => (handleChange(e), props.onChange ? props.onChange(e.value, props.name) : null)}
//         enabled={props?.enabled === false ? props?.enabled : true}
//       />
//     </>
//   );
// };
 

const DynamicTextInput = (props) => {
  console.log(props)
  const handleChange = (e) => {
    console.log(e)
    props.setInputProps((prevValues) => ({
      ...prevValues,
      [props.name]: {...prevValues[props.name] ,value :e?.value == "" ? prevValues[props.name].value :  e.value}
    }));
  };
  const handleBlur = (e) => {
    console.log(e)
    props.setInputProps((prevValues) => ({
      ...prevValues,
      [props.name]: {...prevValues[props.name] ,value :e?.target?.value == "" ?  "" : e.target.value}
    }));
  };
  const handleCreated = (e) => {
    console.log(e)
    props.setInputProps((prevValues) => ({
      ...prevValues,
      [props.name]: {...prevValues[props.name] ,value :props.value || ""}
    }));
  };
  return (
    <>
      <TextBoxComponent
        className='e-input'
        placeholder={props.caption ? props.caption : props.name.replace(/([A-Z])/g, ' $1').trim()}
        name={props.name}
        id={props.name}
        floatLabelType='Auto'
        type={props.type ? props.type : 'text'}
        value={props.value || ''}
        created={(e)=>(handleCreated(e) , props.onCreated ? props.onCreated(e, props.name) : null )}
        onBlur={(e)=>(handleBlur(e) , props.onBlur ? props.onBlur(e.target.value, props.name) : null)}
        focus={(e) => props.onFocus ? props.onFocus(e, props.name) : null}
        onChange={(e) => (handleChange(e) , props.onChange ? props.onChange(e.value, props.name) : null)}
        enabled={props?.enabled == false  ?  props?.enabled :    true}     
        // disabled={!props?.enabled  false}

        />
    </>
  );
};

const DynamicCheckBoxInput = (props) => {
  console.log(props)
  const handleChange = (e) => {
    console.log(e)
    props.setInputProps((prevValues) => ({
      ...prevValues,
      [props.name]: {...prevValues[props.name] ,value :e?.checked == true ? 1 : 0 }
    }));
  };
  // const handleBlur = (e) => {
  //   console.log(e)
  //   props.setInputProps((prevValues) => ({
  //     ...prevValues,
  //     [props.name]: {...prevValues[props.name] ,value :e.target.value == 'on' ? true : false}
  //   }));
  // };
  
  
  return (
    <>
    <CheckBoxComponent
        className="e-input"
        label={props.caption ? props.caption : props.name.replace(/([A-Z])/g, ' $1').trim()}
        floatLabelType="Auto"
        checked={props.value ==  true ? 1 : false}
        change={(e)=>(handleChange(e) , props.onChange ? props.onChange(e.checked == true ? 1:0, props.name) : null)}
        onBlur={(e)=>( console.log(e), props.onBlur ? props.onBlur(props.value , props.name) : null)}
        disabled={props?.enabled   == undefined ? false : !props?.enabled}     

            />
            <TextBoxComponent
              name={props.name}
              id={props.name}
              value={props.value}
              type='hidden'

            />
      
    </>
  );
};

const DynamicHiddenInput = (props) => {
  console.log(props)
  
  const handleCreated = (e) => {
    console.log(e)
    props.setInputProps((prevValues) => ({
      ...prevValues,
      [props.name]: {...prevValues[props.name] ,value :props.value}
    }));
  };
  return (
    <>
      <TextBoxComponent
        className='e-input'
        name={props.name}
        id={props.name}
        floatLabelType='Auto'
        value={props.value}
        created={(e)=>( handleCreated(),props.onCreated ? props.onCreated(e, props.name) : null )}
        type='hidden'
        
        />
    </>
  );
};

const DynamicButtonInput = (props) => {
  // Define the styles object
  const styles = {
    height: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-end',
  };

  // Log the props for debugging
  console.log(props);

  const handleClick = (e) =>{
    console.log(e)
    props.setInputProps((prevValues) => ({
      ...prevValues,
      [props.name]: {...prevValues[props.name] ,isClicked :true ,enabled:false }
    }));
  }
  // Return the component structure
  return (
    <div style={styles}>
      <ButtonComponent
        className="e-input"
        name={props.name}
        type='button'
        disabled={props.enabled == 'undefined' ? false : !props.enabled }
        id={props.name}
        onBlur={(e) => (e.preventDefault(),props.onBlur ? props.onBlur(props.name) : null)}
        onClick={(e) => (e.preventDefault(), handleClick(e) , props.onClick ? props.onClick(props.name) : null)}
      >
        {props.caption ? props.caption : props.name.replace(/([A-Z])/g, ' $1').trim()}
      </ButtonComponent>
    </div>
  );
};


const StructuredGenerate = ({ title , mainProps , Inputs }) => {
  console.log(Inputs)
  return (
    <div className='row'>
        <div  className={`col-sm-${12}`} style={{textAlign:'center'}}>
            <h1 style={{padding:'4px'}}>{title}</h1>
            </div>
      {Inputs.map((res, index) => {
        console.log(res)
        let Input = res.Input;
      //   if(res.Input.name == "DynamicHiddenInput"){
      // // return(<Input {...res.Props} {...mainProps} />)
      // return(
      //   <div key={index} className={`col-sm-${res.ColumnSize  == undefined  ? 0 :   res.ColumnSize}`}>
      //       <Input {...res.Props} {...mainProps} />
      //     </div>
      // )
      //   }else{
     return  (
          <div key={index} className={`col-sm-${res.ColumnSize  == undefined ? 4 :res.ColumnSize}`}>
            <Input {...res.Props} {...mainProps} />
          </div>

        )
      // }
      })}
    </div>
  );
};

export {
  StructuredGenerate,
  DynamicDropDownInput,
  DynamicDateInput,
  DynamicNumericInput,
  DynamicTextInput,
  DynamicCheckBoxInput,
  DynamicHiddenInput,
  DynamicButtonInput
};
