import http from '../../services/http-common';

class AccountTransferService {
    getForList(data) {
    return http.get('/AccountTransfer/list',data);
  }
  accountTransfer(data) {
    return http.post('/AccountTransfer',data);
  }
}

export default new AccountTransferService();
