import http from '../../services/http-common';

class BrandVendorService {
    getBrandVendorForList() {
    return http.get(`/setup/BrandVendor`);
    }
    insert(data) {
        return http.post(`/setup/BrandVendor/insert`,data);
    }
    update(data) {
        return http.put(`/setup/BrandVendor/update`,data);
    }
}

export default new BrandVendorService();