import  React , {useState, useEffect } from 'react';
 import './color-picker.scss';
 import userThemeService from '../../services/user-theme.service';
 import securityService from '../security/security.service';
import { getByDisplayValue } from '@testing-library/react';
import SvgColorTheme from './svg';
import Cookies from 'js-cookie';

export default function ColorPicker() {

     const [condition, setCondition] = useState(true);
     const [setting, SetSetting] = useState(true);
     const [textColor, settextColor] = useState(true);
      const [bgColor, setBgColor] = useState('');
       const [txColor, settxColor] = useState('');
       const [mode, setMode] = useState('');
    //  // pink
    //  const [bgColor, setBgColor] = useState('#e83e8c');
    //   const [txColor, settxColor] = useState('#000');
    //  orange
    //  const [bgColor, setBgColor] = useState('#ff8f01');
    //  const [txColor, settxColor] = useState('#333');
    // lightBlue
     //  const [bgColor, setBgColor] = useState('#008eff');
    //  const [txColor, settxColor] = useState('#333');
     // yellow
    //  const [bgColor, setBgColor] = useState('#fdc011');
    //  const [txColor, settxColor] = useState('#000');
     // blue
    //  const [bgColor, setBgColor] = useState('#3328a7');
    //  const [txColor, settxColor] = useState('#343a40');
    //  const [textColor2, settextColor2] = useState('#fff');
    //  const [textColor3, settextColor3] = useState('#fff');
    //  const [setting, setSetting] = useState(false);
    // const pickColor = (e)=>{
    //     console.log(e)
    //     settextColor(e.value)
    // }
    // const pickColor2 = (e)=>{
      //     console.log(e)
    //     settextColor2(e.value)
    // }
    // const pickColor3 = (e)=>{
      //     console.log(e)
    //     settextColor3(e.value)
    // }
    let styled=<style>{`
       .sidebar-dark-success .nav-sidebar>.nav-item>.nav-link.active, .sidebar-light-success .nav-sidebar>.nav-item>.nav-link.active {
        background-color: ${bgColor} !important;
        color: #fff;
        font-weight: bold;
      }
       .sidebar-dark-success .nav-sidebar>.nav-item>.nav-link.active, .sidebar-light-success .nav-sidebar>.nav-item>.nav-link {
        background-color: ${bgColor} !important;
        color: #fff;
        font-weight: bold;
      }
      .e-grid .e-groupdroparea {
        background-color: ${bgColor} !important;
        border-top-color: #eaeaea;
        text-transform: uppercase;
        font-family: Arial, Helvetica, sans-serif;
        color: #fff;
        font-weight: bold;
    }
    .e-grid .e-headercelldiv {
      border: 0 none;
      display: block;
      font-size: 15px;
      font-weight: bolder;
      height: 29px;
      line-height: 29px;
      margin: 0;
      padding: 0 1px 0;
      text-align: center;
      text-transform: uppercase;
      color: ${bgColor};
      font-family: Arial, Helvetica, sans-serif;
      /* word-spacing: 100vw; */
      -webkit-user-select: none;
      user-select: none;
  }
  .e-grid td.e-active {
    background: #d1ebff;
    text-transform: uppercase;
    font-family: Arial, Helvetica, sans-serif;
    color: ${bgColor};
}
  .e-grid td.e-active:hover {
    background: #d1ebff;
    text-transform: uppercase;
    font-family: Arial, Helvetica, sans-serif;
    color: ${bgColor};
}
  .e-altrow:hover {
  color: ${bgColor};
}
tr.e-row:hover {
  color: ${bgColor} !important;
}
.e-grid.e-gridhover tr[role=row]:not(.e-editedrow):hover .e-rowcell:not(.e-cellselectionbackground):not(.e-active):not(.e-updatedtd):not(.e-indentcell), .e-grid.e-gridhover tr[role=row]:hover .e-detailrowcollapse:not(.e-cellselectionbackground):not(.e-active):not(.e-updatedtd):not(.e-indentcell), .e-grid.e-gridhover tr[role=row]:hover .e-rowdragdrop:not(.e-cellselectionbackground):not(.e-active):not(.e-updatedtd):not(.e-indentcell), .e-grid.e-rtl .e-gridhover tr[role=row]:hover .e-rowdragdrop:not(.e-cellselectionbackground):not(.e-active):not(.e-updatedtd):not(.e-indentcell), .e-grid.e-gridhover tr[role=row]:hover .e-detailrowexpand:not(.e-cellselectionbackground):not(.e-active):not(.e-updatedtd):not(.e-indentcell) {
  background-color: #f4f4f4;
  text-transform: uppercase;
  font-family: Arial, Helvetica, sans-serif;
  color: ${bgColor};
}
.e-grid .e-icons {
  color: ${bgColor};
}
.e-grid .e-gridheader .e-icons:not(.e-icon-hide):not(.e-check):not(.e-stop) {
  color: ${bgColor};
}
.e-checkbox-wrapper .e-label, .e-css.e-checkbox-wrapper .e-label {
  text-transform: uppercase;
  font-family: Arial, Helvetica, sans-serif;
  color: ${bgColor};
}
.e-checkbox-wrapper .e-frame.e-check, .e-css.e-checkbox-wrapper .e-frame.e-check {
  background-color: ${bgColor};
  border-color: ${bgColor};
  color: #fff;
}
.e-input-group:active:hover:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled), .e-input-group.e-control-wrapper:active:hover:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled), .e-float-input:active:hover:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled), .e-float-input.e-control-wrapper:active:hover:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled), .e-float-input:active:hover:not(.e-input-group):not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled) input:not([disabled]), .e-float-input.e-control-wrapper:active:hover:not(.e-input-group):not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled) input:not([disabled]), .e-float-input:active:hover:not(.e-input-group):not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled) textarea:not([disabled]), .e-float-input.e-control-wrapper:active:hover:not(.e-input-group):not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled) textarea:not([disabled]) {
  border-color: ${bgColor};
}
.e-btn.e-flat.e-primary, .e-css.e-btn.e-flat.e-primary {
  background-color: ${bgColor};
  border-color: ${bgColor};
  text-transform: uppercase;
  font-family: Arial, Helvetica, sans-serif;
  color: #fff;
  font-weight: bold;
}
.e-btn.e-flat, .e-css.e-btn.e-flat {
  background-color: #f4f4f4;
  border-color: #f4f4f4;
  box-shadow: none;
  text-transform: uppercase;
  font-family: Arial, Helvetica, sans-serif;
  color: ${bgColor};
}
.e-btn.e-flat.e-primary:hover, .e-css.e-btn.e-flat.e-primary:hover {
  background-color: #fff;
  border-color: #fff;
  text-transform: uppercase;
  font-family: Arial, Helvetica, sans-serif;
  color: #000;
}
.e-input:focus:not(.e-success):not(.e-warning):not(.e-error), .e-float-input:not(.e-success):not(.e-warning):not(.e-error):not(.e-input-group) input:focus, .e-float-input:not(.e-success):not(.e-warning):not(.e-error):not(.e-input-group) textarea:focus, .e-float-input.e-control-wrapper:not(.e-success):not(.e-warning):not(.e-error):not(.e-input-group) input:focus, .e-float-input.e-control-wrapper:not(.e-success):not(.e-warning):not(.e-error):not(.e-input-group) textarea:focus, .e-float-input:not(.e-success):not(.e-warning):not(.e-error):not(.e-input-group).e-input-focus input, .e-float-input.e-control-wrapper:not(.e-success):not(.e-warning):not(.e-error):not(.e-input-group).e-input-focus input {
  border-color: ${bgColor};
}
.e-input:focus:not(.e-success):not(.e-warning):not(.e-error), .e-float-input:not(.e-success):not(.e-warning):not(.e-error):not(.e-input-group) input:focus, .e-float-input:not(.e-success):not(.e-warning):not(.e-error):not(.e-input-group) textarea:focus, .e-float-input.e-control-wrapper:not(.e-success):not(.e-warning):not(.e-error):not(.e-input-group) input:focus, .e-float-input.e-control-wrapper:not(.e-success):not(.e-warning):not(.e-error):not(.e-input-group) textarea:focus, .e-float-input:not(.e-success):not(.e-warning):not(.e-error):not(.e-input-group).e-input-focus input, .e-float-input.e-control-wrapper:not(.e-success):not(.e-warning):not(.e-error):not(.e-input-group).e-input-focus input {
  border-color: ${bgColor};
}
.e-input-focus{
  border-color: ${bgColor} !important;
}
.e-grid .e-filtered::before {
  color:  ${bgColor};
}
.e-pager {
  background-color: #fff;
  border-color: #eaeaea;
  text-transform: uppercase;
  font-family: Arial, Helvetica, sans-serif;
  color: ${bgColor};
  opacity: 1;
}
.e-pager .e-currentitem, .e-pager .e-currentitem:hover {
  background: #d3d3d3;
  text-transform: uppercase;
  /* font-family: Arial, Helvetica, sans-serif; */
  color: ${bgColor};
  opacity: 1;
}
.e-pager .e-numericitem {
  background: #fff;
  border-right-color: #eaeaea;
  text-transform: uppercase;
  font-family: Arial, Helvetica, sans-serif;
  color: ${bgColor};
}
.e-pager div.e-icons {
  color: ${bgColor};
}
.e-btn.e-flat:focus, .e-css.e-btn.e-flat:focus {
  background-color: #eaeaea;
  border-color: #eaeaea;
  text-transform: uppercase;
  font-family: Arial, Helvetica, sans-serif;
  color: ${bgColor};
  box-shadow: none;
}
.e-btn.e-flat:focus, .e-css.e-btn.e-flat:hover {
  background-color: #eaeaea;
  border-color: #eaeaea;
  text-transform: uppercase;
  font-family: Arial, Helvetica, sans-serif;
  color: ${bgColor} ;
  box-shadow: none;
}
.e-dialog .e-btn.e-dlg-closeicon-btn:hover span {
  color: ${bgColor};
}
.e-pervent-select{
  color: ${bgColor} ;
}
.e-grid .e-gridcontent tr.e-row:first-child .e-rowcell.e-dragborder,
.e-grid .e-gridcontent .e-rowcell.e-dragborder,
.e-grid .e-gridcontent .e-rowdragdrop.e-dragborder,
.e-grid .e-gridcontent .e-detailrowcollapse.e-dragborder, .e-grid .e-gridheader tr.e-row:first-child .e-rowcell.e-dragborder,
.e-grid .e-gridheader .e-rowcell.e-dragborder,
.e-grid .e-gridheader .e-rowdragdrop.e-dragborder,
.e-grid .e-gridheader .e-detailrowcollapse.e-dragborder,
.e-grid.e-rtl .e-gridcontent tr.e-row:first-child .e-rowcell.e-dragborder,
.e-grid.e-rtl .e-gridcontent .e-rowcell.e-dragborder,
.e-grid.e-rtl .e-gridcontent .e-rowdragdrop.e-dragborder,
.e-grid.e-rtl .e-gridcontent .e-detailrowcollapse.e-dragborder,
.e-grid.e-rtl .e-gridheader tr.e-row:first-child .e-rowcell.e-dragborder,
.e-grid.e-rtl .e-gridheader .e-rowcell.e-dragborder,
.e-grid.e-rtl .e-gridheader .e-rowdragdrop.e-dragborder,
.e-grid.e-rtl .e-gridheader .e-detailrowcollapse.e-dragborder {
  box-shadow: 0 2px 0 0 ${bgColor};
  position: relative;
  z-index: 5;
}
.e-grid .e-ccdlg .e-cc-searchdiv.e-input-focus {
  border-color: ${bgColor};
}

 {
  text-transform: uppercase;
  font-family: Arial, Helvetica, sans-serif;
   color:${bgColor};
}
.e-dropitemscount {
  background-color: ${bgColor};
  text-transform: uppercase;
  font-family: Arial, Helvetica, sans-serif;
   color: #fff;
}
.e-excelfilter .e-filtered::before {
  text-transform: uppercase;
  font-family: Arial, Helvetica, sans-serif;
   color: ${bgColor};
}
.e-grid .e-groupdroparea.e-hover {
  background-color: ${bgColor};
}

.e-grid .e-groupdroparea.e-grouped {
  background-color: ${bgColor};
}
.e-grid .e-groupheadercell {
  background-color: #fff;
  border-color: #fff;
  border-radius: 0;
  text-transform: uppercase;
  font-family: Arial, Helvetica, sans-serif;
  color: ${bgColor};
}
.e-grid .e-groupdroparea .e-icons {
  text-transform: uppercase;
  /* font-family: Arial, Helvetica, sans-serif; */
  color: ${bgColor};
}
.e-grid .e-groupcaption, .e-grid .e-indentcell, .e-grid .e-recordplusexpand, .e-grid .e-recordpluscollapse {
  background-color: #fff;
  text-transform: uppercase;
  font-family: Arial, Helvetica, sans-serif;
  color: ${bgColor};
}
.e-bigger .e-responsive-dialog .e-dlg-header-content .e-dlg-header .e-res-custom-element .e-res-sort-clear-btn,
.e-bigger.e-responsive-dialog .e-dlg-header-content .e-dlg-header .e-res-custom-element .e-res-sort-clear-btn {
  text-transform: uppercase;
  font-family: Arial, Helvetica, sans-serif;
   color: ${bgColor};
  font-size: 19px;
  font-weight: 500;
  padding-top: 5px;
}
.e-bigger .e-responsive-dialog .e-dlg-header-content .e-btn:hover .e-btn-icon,
.e-bigger .e-responsive-dialog .e-dlg-header-content .e-btn:focus .e-btn-icon,
.e-bigger.e-responsive-dialog .e-dlg-header-content .e-btn:hover .e-btn-icon,
.e-bigger.e-responsive-dialog .e-dlg-header-content .e-btn:focus .e-btn-icon {
  text-transform: uppercase;
  font-family: Arial, Helvetica, sans-serif;
   color: ${bgColor};
}
.e-bigger .e-responsive-dialog .e-dlg-header-content .e-btn .e-btn-icon,
.e-bigger.e-responsive-dialog .e-dlg-header-content .e-btn .e-btn-icon {
  text-transform: uppercase;
  font-family: Arial, Helvetica, sans-serif;
   color: ${bgColor};
  font-size: 17px;
  margin-top: 0;
}
.e-grid .e-cloneproperties {
  background-color: #d6d7d8;
  border-color: #eaeaea;
  text-transform: uppercase;
  font-family: Arial, Helvetica, sans-serif;
   color: ${bgColor};
}
[className==sidebar-light-] .nav-treeview > .nav-item > .nav-link {
  color: ${txColor};
}
.nav-sidebar .nav-treeview>.nav-item>.nav-link>.nav-icon {
  width: 1.6rem;
  color: ${bgColor};
}
.fa-list-ul:before {
  content: "\f0ca";
  color: ${bgColor} !important;
}
.e-grid .e-rowcell:not(.e-editedbatchcell), .e-grid .e-detailrowcollapse:not(.e-editedbatchcell), .e-grid .e-detailrowexpand:not(.e-editedbatchcell), .e-grid .e-gridcontent .e-rowdragdrop:not(.e-editedbatchcell), .e-grid .e-gridheader .e-rowdragdrop:not(.e-editedbatchcell), .e-grid .e-emptyrow:not(.e-editedbatchcell) {
  text-transform: uppercase;
  font-family: Arial, Helvetica, sans-serif;
  color: ${txColor};
  font-weight: bold;
  /* font-size: 12px; */
}
h3{
  color:${txColor}
}
.e-btn.e-primary, .e-css.e-btn.e-primary {
  background-color: ${bgColor};
  border-color: ${bgColor};
  text-transform: uppercase;
  font-family: Arial, Helvetica, sans-serif;
  color: #fff;
}

.e-btn.e-primary:hover, .e-css.e-btn.e-primary:hover {
  background-color: ${txColor};
  border-color: #fff;
  box-shadow: none;
  text-transform: uppercase;
  font-family: Arial, Helvetica, sans-serif;
  color: #FFF;
}
.e-dlg-content {
  text-transform: uppercase;
  font-family: Arial, Helvetica, sans-serif;
  color:${txColor};
}
.heading > h3 {
  color:${bgColor}
}
.navbar-light .navbar-nav .nav-link {

  color:${txColor};
}
a {
  color: ${bgColor};
  text-decoration: none;
  background-color: transparent;
}
.small-box.bg-info  h3 {
  color:#fff;

}
.inner h3 {
  color:#fff;

}
.small-box .bg-warning .inner h3 {
  color:#000;

}
.e-ddl.e-input-group.e-control-wrapper .e-input {
  font-size: 20px;
  font-family: emoji;
  color: ${bgColor};
  background: white;
}
.makeStyles-avatar-4 {
  margin: 8px;
  
}

.makeStyles-submit-6 {
  margin: 24px 0px 16px;
  padding: 10px 50px;
  border-color: ${bgColor} !important;
  border-radius: 25px;
  background-color: ${bgColor} !important;
}

.common_colour {
  color: ${bgColor};
}
 table tr > th, td{
  color: ${bgColor};
}
.os-padding {
  background: ${mode};
}
nav.main-header.navbar.navbar-expand.navbar-white.navbar-light {
  background:  ${condition == true  ?`#fff`: `#454647` };
}
.heading-list {
  color:  ${condition == true  ?`#000`: `#fff` };
}
tr.e-row {
  background: ${mode};
}
th.e-headercell.e-fltr-icon.e-centeralign.e-mousepointer{
  background: ${mode};

}
th.e-headercell.e-fltr-icon.e-mousepointer{
  background: ${mode};

}
th.e-headercell.e-centeralign.e-defaultcursor{
  background: ${mode};

}
.heading-list{
  background-color: ${mode} !important;

}
.e-toolbar-item button {
  background: ${mode} !important;
  /* color: red !important; */
}
div#hscroll_1 {
  background: ${mode};
}
.e-gridpager.e-control.e-pager.e-lib {
  background: ${mode};
}
tr.e-summaryrow td {
  background: ${mode} !important;
}
// .content-wrapper div div {
//   background: ${mode} !important;
// }
aside.main-sidebar.sidebar-light-success.elevation-4 {
  background: ${mode} !important;
}
span.e-tbar-btn-text {
  color:  ${condition == true  ?`#000  !important`: `#fff  !important` };
}
[class*=sidebar-light-] .nav-treeview > .nav-item > .nav-link {
  color:  ${condition == true  ?`#000  !important`: `#fff  !important` };
}
  #get-data-btn{
    background-color: ${bgColor};
}
    #get-data-btn:hover{
        color: ${bgColor};

    }
        .MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-12.MuiGrid-grid-sm-8.MuiGrid-grid-md-5{
            background-color: ${bgColor};

        }
            .e-radio:checked + label::before{
            border-color:${bgColor};
            }
            .e-radio + label .e-label{
            color:${bgColor};
            }
            .e-radio:checked + label::after{
  color:${bgColor};
  background:${bgColor};
  }
  button.toggle-templete{
    background:${bgColor};
    
    }
    button.toggle-templete.active{
      color:${bgColor};

    }
 .template-save > button{
    background:${bgColor};
    
    }
    .template-save > button.active{
      color:${bgColor};

    }
    .toolbar{
      color:${bgColor};

    }
      #send-email-div{
        background:${bgColor};
        }
      #send-email-btn{
      color:${txColor};
      } 
      #report-input-btn{
        color:${bgColor};
        }
        input[type="radio"][name="reports"] {
          color:${bgColor};

  }
        `}</style>

  
   useEffect(() => {
    getByUserId()
    setTimeout(()=>{SetSetting(false)},900)
    setMode('#fff')
   }, []);


   const getByUserId = ()=>{
    if(securityService.getLoggedInUserId() != null || securityService.getLoggedInUserId() != undefined){

    userThemeService.getByID(securityService.getLoggedInUserId()).then((res)=>{
      console.log(res)
      setBgColor(res.data.recordset[0].ThemeBackGroundColor);
      settxColor(res.data.recordset[0].ThemeTextColor)  

    })
  }
      
   }     
   const updateTheme = (bg,tx)=>{
    
    const data = {
      "UserID": securityService.getLoggedInUserId(),
      "ThemeTextColor":tx,
      "ThemeBackGroundColor":bg
    }
    console.log(data)
    userThemeService.update(data).then((res)=>{
      console.log(res)
      SetSetting(false)
      

    })
      
   }     

   
  const ColorChange = (e)=>{
  let key= e.target.value
  switch (key) {
    case 'green':
      setBgColor('#00cc67');
      settxColor('#000')  
       updateTheme('#00cc67','#000')
       Cookies.set('login-bg-color', '#00cc67', { expires: 887 }); // Expires in 7 days
       Cookies.set('login-tx-color', '#000', { expires: 887 }); // Expires in 7 days
       Cookies.set('login-av-color', '#000', { expires: 887 }); // Expires in 7 days

      break;
    case 'blue':
      setBgColor('#3328a7');
      settxColor('#343a40')  
       updateTheme('#3328a7','#343a40')
       Cookies.set('login-bg-color', '#3328a7', { expires: 887 }); // Expires in 7 days
       Cookies.set('login-tx-color', '#343a40', { expires: 887 }); // Expires in 7 days
       Cookies.set('login-av-color', '#a3ace1', { expires: 887 }); // Expires in 7 days

       
      break;
    case 'yellow':
      setBgColor('#fdc011');
      settxColor('#000')  
       updateTheme('#fdc011','#000')
              Cookies.set('login-bg-color', '#fdc011', { expires: 887 }); // Expires in 7 days
              Cookies.set('login-tx-color', '#000', { expires: 887 }); // Expires in 7 days
              Cookies.set('login-av-color', '#fdc0111', { expires: 887 }); // Expires in 7 days

      break;
    case 'lightBlue':
      setBgColor('#008eff');
      settxColor('#333')  
       updateTheme('#008eff','#333')
              Cookies.set('login-bg-color', '#008eff', { expires: 887 }); // Expires in 7 days
              Cookies.set('login-tx-color', '#333', { expires: 887 }); // Expires in 7 days
              Cookies.set('login-av-color', '#1717173b', { expires: 887 }); // Expires in 7 days

      break;
    case 'orange':
      setBgColor('#ff8f01');
      settxColor('#333')  
       updateTheme('#ff8f01','#333')
              Cookies.set('login-bg-color', '#ff8f01', { expires: 887 }); // Expires in 7 days
              Cookies.set('login-tx-color', '#333', { expires: 887 }); // Expires in 7 days
              Cookies.set('login-av-color', '#9c7f5a', { expires: 887 }); // Expires in 7 days

      break;
    case 'pink':
      setBgColor('#e83e8c');
      settxColor('#000')  
       updateTheme('#e83e8c','#000')
              Cookies.set('login-bg-color', '#e83e8c', { expires: 887 }); // Expires in 7 days
              Cookies.set('login-tx-color', '#000', { expires: 887 }); // Expires in 7 days
              Cookies.set('login-av-color', '#876f7acf', { expires: 887 }); // Expires in 7 days

      break;
  
    default:
      break;
  }
    console.log(e.target.value)

  }


  return (
    <>
      
      <div className='page-wrapper' style={{ marginRight: setting ? '0px' : '-280px' }}>
        <input type="radio" id="green" name="colors" value="green"  onClick={ColorChange}/>
        <input type="radio" id="blue" name="colors" value="blue"  onClick={ColorChange}/>
        <input type="radio" id="yellow" name="colors" value="yellow"  onClick={ColorChange}/>
        <input type="radio" id="lightBlue" name="colors" value="lightBlue"  onClick={ColorChange}/>
        <input type="radio" id="orange" name="colors" value="orange"  onClick={ColorChange}/>
        <input type="radio" id="pink" name="colors" value="pink"  onClick={ColorChange}/>


 
           
      <div onClick={()=>{SetSetting(!setting)}} style={{color: bgColor}} >
        <i className=" icons fas fa-cog" >
        </i>
        </div>
          <div className="color-palette">
          <label className={condition ?  `svg`:''} onClick={()=>{
            console.log(condition)
            // setCondition(!condition)
            if(condition ==  true){
              // setMode('#000000d6')
              // setMode('#000000b3')
              setMode('#4c4c4c')
              setCondition(false)
            }else{
              setMode('#fff')
              
              setCondition(true)
            }
            
            }}><SvgColorTheme Condition={condition}></SvgColorTheme></label>
      
            <label for="green" style={{background:'#00cc67'}}></label>
            <label htmlFor="blue" style={{background:'#3028a6'}}></label>
            <label for="yellow" style={{background:'#fdc011'}}></label>
            <label for="lightBlue" style={{background:'#008eff'}}></label>
            <label for="orange" style={{background:'#ff8f01'}}></label>
            <label for="pink" style={{background:'#e83e8c'}}></label>
            </div>

      {textColor ?styled:null}
      
      </div>
      <div className='ui-switch'>
           
       
        </div>
      </>
     //   <div className='control-section'>
    //     
    //     <div id='default-control-1'  >
    //       <ColorPickerComponent 
    //       className='color-picker-text' 
    //       change={pickColor}
    //       value={textColor}
          
    //              >
    //                 </ColorPickerComponent>         
    //     <span className='TEXT-COLOR'>Text Color</span>
    //     </div>
    //     <div id='default-control-2'  >
    //       <ColorPickerComponent 
    //       className='color-picker-menu' 
    //       change={pickColor2}
    //       value={textColor2}
    //              ></ColorPickerComponent>         
    //     <span className='TEXT-COLOR'>Menu Color</span>
    //     </div>
    //     <div id='default-control-3'  >
    //       <ColorPickerComponent 
    //       className='color-picker-skin' 
    //       change={pickColor3}
    //       value={textColor3}
    //              ></ColorPickerComponent>         
    //     <span className='TEXT-COLOR'>Skin Color</span>
    //     </div>
    //   </div>
  

  )
}