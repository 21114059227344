import React, { useEffect,  useRef, useState, useMemo } from 'react';
import './irtiza-dynamic-input.scss';
import {
  GridComponent,
  Inject,
  Toolbar,
  ToolbarItems,
  Edit,
  EditSettingsModel,
  Page,
  Sort,
  PdfExport,
  PdfExportProperties,
  ExcelExport,
  Grid,
  Resize,
  FilterSettingsModel,
  Filter,
  Group,
  GroupSettingsModel,
  AggregateColumnDirective,
  ColumnDirective,
  ColumnsDirective,
  AggregateColumnsDirective,
  AggregateDirective,
  AggregatesDirective,
  Aggregate,
  Freeze,
  ColumnChooser,
  Reorder,
  DialogEditEventArgs,
  CommandColumn,
  CommandModel,
} from '@syncfusion/ej2-react-grids';
import { ClickEventArgs } from '@syncfusion/ej2-react-navigations';
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import HardDelete from '../hard-delete/hard-delete';
import AuditTableInput from '../audit-table/audit-table'
import SecureComponent from '../security/secure.component';
import SecurityService from '../security/security.service';
import IrtizDynamicInputAdd from './irtiza-dynamic-input-add';
// import { useDispatch, useSelector } from 'react-redux';
// import { gridColumnsList } from '../../store/slices/list-tempate';
import moment from 'moment';
import accessoryBOMService from './irtiza-dynamic-input.service';
import { AutoComplete, AutoCompleteComponent } from '@syncfusion/ej2-react-dropdowns';
import DropdownService from './dropdown-api.service';

import invoiceService from '../invoice/invoice.service';
import  imgSrc from './spinner.gif';




let irtizaDynamicGrid: Grid;

let selectedRows = []
let dataListData =[]

export default function AccessoryBOMList(
  {
  Id='Dynamic',
  ListTitle = '',
  ColumnsList = [],
  GridDataSource = [],
  DefaultSortedColumnName='',
  GridDialog = [],
  ListActionComplete,


  
  // OPTIONAL
  ListDelete=[],
  DeleteType='Hard',
  AllowTemplate=false,
  ToolbarOption= 'All',
  AllowGrouping=true,
  AllowExcelExport=true,
  AllowPdfExport=true,
  ShowColumnChooser=true,
  AllowPaging=true,
  AllowDataBound=false,
  ListSecurity='',
  AddSecurity='',
  EditSecurity='',
  DeleteSecurity='',
  DefaultSortedColumnSort='Descending',
  FilterColumns=[],
  GroupColumns=[],
  GridEditSettingHeaderTemplate='<p></p>',
  GridEditSettingMode='Dialog',
  GridSumColumns=[],
  AllowGridSum=false,
  footerSumCaption=[],
  // ListService,
  RegisterChildFunction ,
  PrintedByUserName='',

 }) {
  const formatOptions = { type: 'date', format: 'dd/MM/yy' }
  function addUnderscore(input) {
    return input.replace(/([a-z])([A-Z])/g, '$1_$2');
}
  // InvoiceEvaluationID	
  // InvoiceNo	
  // MainInvoiceID	
  // CurrencyName	
  // CurrencyID	
  // ExchangeRate	
  // HawalaCommissionPercent	
  // CurrencyNameHawala	
  // CurrencyIDHawala	
  // AccountTitleHawalaDr	
  // AccountIDHawalaDr	
  // AccountTitleHawalaCr	
  // AccountIDHawalaCr
//   ColumnsList.forEach(column => {
//     if (column.field.endsWith('Date')) {
//         column.other.push({ 'format': formatOptions });
//     }
// });
//
// if(ColumnsList.length > 0){
  
  
//   ColumnsList = [...ColumnsList];
  
//   let dateIndex = ColumnsList.findIndex(res => res.field.endsWith('Date'));
//   if (dateIndex !== -1) {
//     ColumnsList[dateIndex].other = [ { 'format': formatOptions }]
//     }
//     }
//   var ColumnProps =  ColumnsList

// let Columns = [...ColumnsList];


//       var ColumnProps =  ColumnsList
      // if (column.field.endsWith('Date')) {
      //     column.other.push({ 'format': formatOptions });
      // }
    

  
  // InputL ist Security Name
//   let InputListSecurity = props?.location?.pathname.split('/')[3] == 'Summary'  ? 'AccessoryBOMList' : 'AccessoryBOMDetailList';
// let defaultSortColumnName = props?.location?.pathname.split('/')[3] == 'Summary'  ? 'AccessoryBOMID' : 'AccessoryBOMDetailID'
// let defaultSortColumnType =  'Descending'
// let InputNameForPdfExcelExport = props?.location?.pathname.split('/')[3] == 'Summary'  ? 'Accessory BOM - LIST' : 'Accessory BOM DETAIL - LIST'
// let exportFileName = props?.location?.pathname.split('/')[3] == 'Summary'  ? 'accessory_bom' : 'accessory_bom_detail'
let InputListSecurity = ListSecurity == '' ? `${Id}List`:ListSecurity;
let defaultSortColumnName = DefaultSortedColumnName
let defaultSortColumnType =  DefaultSortedColumnSort
let InputNameForPdfExcelExport = ListTitle
let exportFileName = addUnderscore(Id)

 //  DYNAMIC CODE 
//  const dispatch = useDispatch()
//  const ColumnProps = useSelector(state =>{return state.listTemplate})

 var filterColumnName = []
 var recordColumnName = []
 var fiterCheckedValue = []
  const [dataSourceState, setDataSourceState] = useState([]);
  const [loadingdataSourceState, setLoadingDataSourceState] = useState(false);

  const [filterState, setFilterState] = useState([]);
  
  const [groupState, setGroupState] = useState([]);
  
  const [deletingProps, setDeletingProps] = useState();
  const [showDialogs, setShowDialogs] = useState(false);

  const [selectedRow, setSelectedRow] = useState([]);
  const handleProductCutSizeBlur = (inputID, inputObj, gridAddNewClicked , id) => {
    if (inputID === undefined || inputObj.element.value === null || inputObj.element.value === '' ) {
      if( document.getElementById(id)){
      document.getElementById(id).parentElement.style.border = '3px solid red';
      setTimeout(() => {
        document.getElementById(id).parentElement.style.border = '1px solid #c8c8c8';
      }, 1000)
    }
      if (gridAddNewClicked === false) {
        inputObj.element.focus();
      }
    }
  };
 
  const handleInputFocus = (inputRef ,isGrid) => {
    // Access the AutoCompleteComponent instance and open the dropdown
    if (isGrid == true ) {
    if (inputRef ) {
        inputRef.showPopup();
    }
  }
  else{
      if (inputRef ) {
          inputRef.current.showPopup();
      }

    }
};

const getDataSource = async (type,filter ,obj)=>{
  try {
    const response = await DropdownService.getAllForList(type);
    if (response.data && response.data.recordset) {
            if(filter != {}){

            }else{
            }
            obj.dataSource = response.data.recordset;
              
    }
      else {
      console.error("Invalid response structure:", response);
    }
   } catch (error) {
    }
}

// var saleProductNameDataSource = []
// let saleProduct = useRef(null)
// const [saleProductNameDataSource, setSaleProductNameDataSource] = useState([]);
// const getSaleProductNameDataSource =  async (id)=>{
//   try {
//     let response =  await invoiceService.getMultiDropDownSource(`Stock ,0, 01-JUN-24 ,  ${moment().format('DD-MMM-YY')},${id}`)
//       if(response.data  && response.data.recordset){
//         console.log(response.data.recordset)
//         console.log(saleProduct)
//         // setSaleProductNameDataSource(response.data.recordset);
//         // saleProductNameDataSource = response.data.recordset;
//         saleProduct.current.dataSource = response.data.recordset
//       }
    
//   } catch (error) {
//     console.log(error)
//   }  
// }


let autoCompleteInstances = {};
  const createAutoComplete = (params) => {
    let obj : AutoComplete;
      let Elem : HTMLElement;
    const create = () => {
       Elem = document.createElement(`input`);
      return Elem;
    }
    const destroy = () => {
      obj?.destroy();
    }
    const read = () => {
      return obj?.text;
    }
     // Store instances here
    
    const write = (args) => {
      let dynamicKey = `obj_${params.field.id}`; // Create a dynamic key
       autoCompleteInstances[dynamicKey]  = new AutoComplete({
        created:async ()=>{
          console.log(params)
          console.log(args)
          if(args.requestType == "beginEdit"){
              let idColumn = params.field.id;
              
              let valueColumn = params.field.value;

              autoCompleteInstances[dynamicKey].dropdownValues =  {[idColumn]: args.rowData[idColumn] ,  [valueColumn]: args.rowData[valueColumn]}

          }
            console.log(autoCompleteInstances[dynamicKey])
        if(params?.source?.type == 'Route'){
          await getDataSource(params.source.dataSource , params.filter, autoCompleteInstances[dynamicKey]  )
        }
        },
        focus:()=>handleInputFocus(args.element.ej2_instances[0],true),
        select: async (e) => {
          //     if(params.field.id == 'BrandID'){
          //  await getSaleProductNameDataSource(e?.itemData.id)

          //     }
          if(params.onSelect){ params.onSelect({
            ...e?.itemData,
            id:e?.itemData?.id ? e?.itemData?.id : undefined,
            value:e?.itemData?.value ? e?.itemData?.value : '' 
          
        }, params.name.value)
      }

          autoCompleteInstances[dynamicKey].dropdownValues =  {...e.itemData, [params.field.id]: e.itemData.id ,  [params.field.value]: e.itemData.value}
          args.rowData[params.field.id] = e.itemData.id;
          args.rowData[params.field.value] = e.itemData.value;

          if(params.select && params.select.source){
            if(params.select.source.type == 'Function'){
              let paramsArray = params.select.source.dataSource.params;
              const generateParams = paramsArray.map((res)=>{
                if(res.type == 'Column'){
                  return args.rowData[res.source]
                }else{
                  return res.source
                }
              })
              
              const response = await params.select.source.dataSource.function(generateParams.join(','))
              console.log(response)
                if(response.data && response.data.recordset){
                  autoCompleteInstances[`obj_${params.select.source.dataSource.dropDownKey}`].dataSource = response.data.recordset;
                }
            }else{

            }
          
          }
        },
        change: (e) => {
          const autoCompleteInstance = args.element.ej2_instances[0];
          
          const isValid = autoCompleteInstance.dataSource.some(s => s.value === e.value);
          if (!isValid) {
            args.rowData[params.field.id] = 0;
            args.rowData[params.field.value] = e.value;
          } else {
            const selectedItem = autoCompleteInstance.dataSource.find(s => s.value === e.value);
            autoCompleteInstances[dynamicKey].dropdownValues =  {...e.itemData , [params.field.id]: e.itemData.id ,  [params.field.value]: e.itemData.value}
            args.rowData[params.field.id] = selectedItem.id;
            args.rowData[params.field.value] = selectedItem.value;

          }
        },
        blur: () => {
         
          const id = `${Id}Grid${params.field.value}`;
          handleProductCutSizeBlur(args.rowData[params.field.id],  autoCompleteInstances[dynamicKey] , false, id);
        },
        value: args.rowData[params.field.value],
        fields: { value: "value" },
        floatLabelType: "Never",
        placeholder: `Select ${params.caption}`,
        highlight: true,
        cssClass: "GridDropdown",
        showClearButton: false,
        
      });
         autoCompleteInstances[dynamicKey]?.appendTo(Elem);
    }
    return { create, destroy, read, write };
  }

    const ddlFields: object = { value: 'value' };
  
  // const CreateAutoCompleteComponent =(params) =>{
  //   console.log(params);
  
  // // Assign a dynamic ref based on the name prop
  
  //   let dynamicKey = `obj_${params.field.id}`; // Create a dynamic key

  //   // const autoCompleteData = autoCompleteInstances[dynamicKey] || { dataSource: [], caption: '' };
  //   // autoCompleteInstances[dynamicKey] = {
  //   //   caption:params.caption,
  //   //   dataSource:[]
  //   // }
  //   const ddlFields: object = { value: 'value' };
  // //  console.log(autoCompleteInstances)
    
  //   return(
      // <>
      // <AutoCompleteComponent 
      // dataSource={params.dataSource}
      // fields={ddlFields}
      // floatLabelType='Auto' 
      // highlight={true}
      // />
      // </>
  //   )
  // }
    // const CreateMultiDropDown = useMemo(() => (props) => {
  //   console.log(props);
  //   console.log(autoCompleteInstances); // Assuming autoCompleteInstances is defined elsewhere
  //   return (
  //     <>
  //       <div>
  //         {/* Displaying a placeholder text based on your example */}
  //         irtiza 
  //       </div>
  //     </>
  //   );
  // }, [autoCompleteInstances]);

  var ColumnProps =   []
  useEffect(() => {
    // irtizaDynamicGrid?.dataBound(irtizaDynamicGrid?.autoFitColumns()) ;
    const transformedFilters = FilterColumns.map(col => ({
      field: col.field,
      operator: col.operator ? col.operator : 'equal',
      value: col.value.toString() // Ensure the value is a string
  }));
  setFilterState(transformedFilters);

//   ColumnProps = ColumnsList.map(column => {
//       if (column.field.endsWith('Date')) {
//           return {
//               ...column,
//               other: [{  'format': formatOptions , ...column.other[0] }]
//           };
//       }else if(column.field.startsWith('Is') && column.field != 'IsDeleted' && column.field != 'IsApproved') {
//         return {
//           ...column,
//           other: [{...column.other[0],  template:(props) => {
//             return (
//             <p style={{margin: '0px'}}>{props[column.field] == true ? 'Yes' : 'No'}</p>
//             )
//             }   }]
//       };
//       }else if(column.field == 'CompanyName') {
//         return {
//           ...column,
//           other: [{...column.other[0],  edit:createAutoComplete({caption:'Company'  , field:{id:'CompanyIDCustomer',value:'CompanyName'}}) }]
            
//       }
//       }else if(column.field == 'AccountTitleHawalaDr') {
//         return {
//           ...column,
//           other: [{...column.other[0],  edit:createAutoComplete({caption:'Company'  , field:{id:'AccountTitleHawalaID',value:'AccountTitleHawalaDr'}}) }]
            
//       }
//     }
//       return column;
//   });
 
    // dispatch(gridColumnsList(updatedColumns))
    setGroupState(GroupColumns)
    getListService();
    setTimeout(() => { 
      // Ref(getListService)
      // ListService(getListService)
      console.log(GridDataSource)
    }, 1000);
  // }, []);
 
  }, [GridDataSource[0].DataSource]);
  // }, [GridDataSource[0].DataSourceType]); 
// DYNAMIC TEMPLATE CODE
const [isToggled, setIsToggled] = useState(false);
const [ToggledValue, setToggledValue] = useState( 'Hide Template');
useEffect(() => {
  setTimeout(() => {
    setToggledValue(isToggled == true? 'Hide Template' : 'Show Template')
  }, 100);  
}, [isToggled]);

    // let dialogInstance: DialogComponent;
    // const toolbar: ToolbarItems[] = ['PDFExport'];
  const toolbarClick = (args: ClickEventArgs) => {
    let reportName = document.getElementById('reportName')?.value;
    let getOrientation = document.getElementsByClassName('orientation')
    let selectedOrientation = getOrientation[0]?.checked ==  true ? 'Portrait':'Landscape';
    let getHeaderCheck = document.getElementsByClassName('headerCheck')[0]?.checked;
    let headerCheck =  !getHeaderCheck
    if ( irtizaDynamicGrid && args.item.properties.id === `${Id}Grid_PDFExport` ) {
      if(headerCheck == true){
        let ress = filterColumnName.map((res, index) => {
          // Convert camelCase to Title Case and uppercase all letters
          let title = res.replace(/([a-z])([A-Z])/g, '$1 $2').toUpperCase();
          // Calculate position dynamically
          let position = { x: 10, y: 120 + (index * 40) };
          // Define style properties
          let style = { textBrushColor: "#000000", fontSize: 12, hAlign: 'Center' };
          // Join checked values with separator '|'
          let checkedValues = fiterCheckedValue[index].join(' | ');
          
          return {
              position: position,
              style: style,
              type: "Text",
              value: `${title}: ${checkedValues},`
          };
      });
      
  const exportProperties: PdfExportProperties = {
          header: { 
          contents: [ 
            // {
            //   position: { x: 10, y: 30 },
            //   size: { width: 120, height: 80 }, // Set the width and height of the image
            //   type: "Image",
            //   src:''
            // },
            { 
              position: { x:selectedOrientation == 'Landscape' ?420:260 , y: 50 }, 
              style: { textBrushColor: "#000000", fontSize: 20, hAlign:'Center' }, 
              type: "Text", 
              value: `DALAL INDUSTRIES`
              
            },
            { 
              position: { x:selectedOrientation == 'Landscape' ?410:270 , y: 80 }, 
              style: { textBrushColor: "#000000", fontSize: 16, hAlign:'Center' }, 
              type: "Text", 
              value: reportName.toUpperCase() ? reportName.toUpperCase() : InputNameForPdfExcelExport
              },  
            ...ress
          
          ]
           ,
           fromTop: 0, 
            height: 230 
        },
         footer: {
                    contents: [
                        {
                            format: 'Page {$current} of {$total}',
                            pageNumberType: 'Northwind Traders',
                            position: { x: selectedOrientation == 'Landscape' ?930:600, y: 25 },
                            style: {
                                fontSize: 16,
                                textBrushColor: '#000000'
                              },
                              type: 'PageNumber'
                            },
                            { 
                              position: { x:selectedOrientation == 'Landscape' ?20:10 , y: 25 }, 
                              style: { textBrushColor: "#000000", fontSize: 16, hAlign:'Center' }, 
                              type: "Text", 
                              value: `DATE: ${ moment().format('DD-MM-YYYY HH:mm:ss')}`
                            },
                            { 
                              position: { x:selectedOrientation == 'Landscape' ?420:320 , y: 25 }, 
                              style: { textBrushColor: "#000000", fontSize: 16, hAlign:'Center' }, 
                              type: "Text", 
                              value: `Printed By: ${PrintedByUserName}`
                              }
                          ],
                          fromBottom: 20,
                          height: 60
                },
        pageOrientation: selectedOrientation,
        fileName: `${moment().format('YYYYMMDD')}_${exportFileName}.pdf`
      };
      setTimeout(() => {
        
        return irtizaDynamicGrid.pdfExport(exportProperties); 
      }, 500);
}else{
  const withOutHeaderexportProperties: PdfExportProperties = {
    pageOrientation: selectedOrientation,
    fileName: `${moment().format('YYYYMMDD')}_${exportFileName}.pdf`
  };
  irtizaDynamicGrid.pdfExport(withOutHeaderexportProperties);
}
    
    }
    if (
      irtizaDynamicGrid &&
      args.item.properties.id === `${Id}Grid_excelexport`
    ) {
      if(headerCheck == true){
      let excelress =   filterColumnName.map((res,ind)=>{
        let title = res.replace(/([a-z])([A-Z])/g, '$1 $2').toUpperCase()
        // let len = fiterCheckedValue.length()
       return { cells: [{ colSpan: 6, value: `${ title} :  ${fiterCheckedValue[ind].join(',')}` , style: {  fontSize: 12,hAlign: 'Left', }} ]}
      
      })
      const exportProperties: ExcelExportProperties = {
        
        pageOrientation: 'Landscape',
        fileName: `${moment().format('YYYYMMDD')}_${exportFileName}.xlsx`,
        footer: {
          footerRows: 2,
          rows: [
              { cells: [{ colSpan: 4, value: `Printed By: ${PrintedByUserName}`, style: { hAlign: 'Center', bold: true } }] },
              
          ]
      },
      header: {
          headerRows: 4 + fiterCheckedValue.length,
          rows: [
              { cells: [{ colSpan: 2, value: "", style: {  fontSize: 20, hAlign: 'Center', bold: true, } }] },
              { cells: [{ colSpan: 4, value: "DALAL INDUSTRIES", style: {  fontSize: 25, hAlign: 'Center', bold: true, } }] },
              { cells: [{ colSpan: 4, value:reportName.toUpperCase() ? reportName.toUpperCase():'PRODUCT FINISH SIZE - LIST', style: { fontSize: 15, hAlign: 'Center', bold: true, } }] },
              { cells: [{ colSpan: 4, value: `DATE: ${ moment().format('DD-MM-YYYY HH:mm:ss')}` , style: {  fontSize: 12,hAlign: 'Right' } }] },
              ...excelress
          ]
      },

      };
      irtizaDynamicGrid.excelExport(exportProperties);
     }else{
      const exportProperties: PdfExportProperties = {
        pageOrientation: selectedOrientation,
        fileName: `${moment().format('YYYYMMDD')}_${exportFileName}.xlsx`
      };
      irtizaDynamicGrid.excelExport(exportProperties);
    }
    }
    if (
      irtizaDynamicGrid &&
      args.item.properties.id === `${Id}Grid_columnchooser`
    ) {
    }
    if (irtizaDynamicGrid && args.item.properties.id === 'Delete' ) {
      if (irtizaDynamicGrid.selectedRowIndex === -1) {
        // no rows selected
        // setDialogWarningVisibility(false);
        let obj={
          RowID:undefined,
          SelectedRow:false
          
        }
        setDeletingProps(obj)
        setShowDialogs(true)

      } else {
        if(ListDelete.length == 0){
          let GridObj = {...irtizaDynamicGrid.getSelectedRecords()[0]};
        for (let key in GridObj) {
          if (GridObj.hasOwnProperty(key)) {
            const dateKeys = ['ApprovedByDate', 'CreationDate', 'UpdationDate', 'DeletionDate'];
            const booleanKeys = ['IsDeleted', 'IsClosed', 'IsApproved'];
            if(key.endsWith('ID') || key.includes('ID') || booleanKeys.includes(key) || dateKeys.includes(key) ){
              delete GridObj[key]
            }else{
              if(key.includes('Date')){
                if(GridObj[key] instanceof Date){
                  GridObj[key] = moment(GridObj[key]).format('DD-MMM-YY')
                }
              }else if(key.includes('Rate')){
                GridObj[key] = parseFloat( GridObj[key]).toFixed(2)
              
              }else if(!isNaN( GridObj[key])){
                GridObj[key] = parseFloat( GridObj[key]).toFixed(1)
              }else{
                GridObj[key] = GridObj[key]
              }

            }
          }
          
        }
        // deleteDialog.current.hide();
        let obj={
          TableName:Id,
          ColumnName:DefaultSortedColumnName,
          // DeleteSecurity:ListDelete[0]?.DeleteSecurity ?  ListDelete[0].DeleteSecurity : undefined,
          RowID:irtizaDynamicGrid.getSelectedRecords()[0][DefaultSortedColumnName],
          SelectedRow:true,
          UserID:userId,
          // SelectedRowData:GridObj
            }
            if(DeleteType == 'Soft'){
              obj.DeleteSecurity = `${Id}Delete`
              }
          setDeletingProps(obj)
        setShowDialogs(true)
        }else{
        
        let GridObj = {...irtizaDynamicGrid.getSelectedRecords()[0]};
        for (let key in GridObj) {
          if (GridObj.hasOwnProperty(key)) {
            const dateKeys = ['ApprovedByDate', 'CreationDate', 'UpdationDate', 'DeletionDate'];
            const booleanKeys = ['IsDeleted', 'IsClosed', 'IsApproved'];
            if(key.endsWith('ID') || key.includes('ID') || booleanKeys.includes(key) || dateKeys.includes(key) ){
              delete GridObj[key]
            }else{
              if(key.includes('Date')){
                if(GridObj[key] instanceof Date){
                  GridObj[key] = moment(GridObj[key]).format('DD-MMM-YY')
                }
              }else if(key.includes('Rate')){
                GridObj[key] = parseFloat( GridObj[key]).toFixed(2)
              
              }else if(!isNaN( GridObj[key])){
                GridObj[key] = parseFloat( GridObj[key]).toFixed(1)
              }else{
                GridObj[key] = GridObj[key]
              }

            }
          }
          
        }
        // deleteDialog.current.hide();
        let obj={
          TableName:ListDelete[0].TableName,
          ColumnName:ListDelete[0].TableID,
          // DeleteSecurity:ListDelete[0]?.DeleteSecurity ?  ListDelete[0].DeleteSecurity : undefined,
          RowID:irtizaDynamicGrid.getSelectedRecords()[0][ListDelete[0].TableID],
          SelectedRow:true,
          UserID:userId,
          // SelectedRowData:GridObj
            }
            if(ListDelete[0]?.TableSecurity){
              obj.DeleteSecurity = ListDelete[0]?.TableSecurity
              }
          setDeletingProps(obj)
        setShowDialogs(true)
      }
    } 
    } 

  };
  const getListService = async (inside  = false) => {
      console.log(GridDataSource)
      console.log(GridDataSource[0]?.DataSourceSaveType)
      const maxRetries = 4;
      const delay = 6000; // 6 seconds
    
      if (GridDataSource[0].DataSourceType === 'Function' || inside == true) {
        try {
          setLoadingDataSourceState(true)

          const response = await GridDataSource[0]?.DataSource();
          setLoadingDataSourceState(false)

          console.log(response)
        const data = response.data.recordset.map((record) => {
          Object.keys(record).forEach((key) => {
            // Check if the key ends with 'date' (case-insensitive) and the value is not null or undefined
         
             if(key.toLowerCase().startsWith('isdeleted') || key.toLowerCase().startsWith('isapproved') ){
              // record[key] = record[key] == true ? 'Yes': 'No'
              record[key] = record[key] 
            }
            // Check if the key starts with 'is' (case-insensitive) and the value is true
            else if (key.toLowerCase().endsWith('date') && record[key]) {
              // Convert the date string to a Date object
              record[key] = new Date(record[key].split('T', 1)[0]);
            }
          });
          return record;
        });
          if(GridDataSource[0].CreateColumns && GridDataSource[0].CreateColumns.length > 0){
              let addColumn = data.map(item => {
                // Create a new object to avoid mutating the original item
                let newItem = { ...item };
              
                // Iterate over each column definition and add it to the new item
                GridDataSource[0].CreateColumns.forEach(column => {
                  newItem[column.columnName] = column.columnValue;
                });
              
                return newItem;
              });
              console.log(addColumn)
              console.log(addColumn)
              // setDataSourceState(addColumn);
              console.log(GridDataSource[0]?.DataSourceSaveType)
          if(GridDataSource[0]?.DataSourceSaveType == 'replace'){
            setDataSourceState(addColumn);
          }else if(GridDataSource[0]?.DataSourceSaveType == 'push'){
              setDataSourceState((prevArray) =>{
                            console.log(prevArray)
                            return [
                              ...prevArray,
                              ...addColumn
                              // ...addColumn[0]
                            ]
                          })
          }else{
            setDataSourceState(addColumn);

          }
        return addColumn

          }else{
            if(GridDataSource[0]?.DataSourceSaveType == 'replace'){
              setDataSourceState(data);
            }else if(GridDataSource[0]?.DataSourceSaveType == 'push'){
              console.log(data)
                setDataSourceState((prevArray) =>{
                              console.log(prevArray)
                              return [
                                ...prevArray,
                                ...data
                                // ...addColumn[0]
                              ]
                            })
            }else{
              setDataSourceState(data);
  
  
            }
            return data
          }
      } catch (e) {
        // if (retryCount < maxRetries) {
        //   console.warn(`Retry attempt ${retryCount + 1} after ${delay / 1000} seconds`);
        //   setTimeout(() => getListService(retryCount + 1), delay);
        // } else {
        //   console.error('Max retries reached. Failed to fetch data.');
        //   return false;
        // }     
        setLoadingDataSourceState(false)
      }
    } else if(GridDataSource[0].DataSourceType === 'Reset') {
      // accessoryBOMService.getAccessoryBOMForList()
      //   .then((response) => {
      //     setDataSourceState(response.data.recordset);
      //   })
      //   .catch((e) => {
      //   });
      setLoadingDataSourceState(false)
      setDataSourceState([])
      return true
    }else{
      return true
      setLoadingDataSourceState(false)
    }  };
  

  // useEffect(() => {
  // }, []);

  const getRoute = () =>
    window.location.pathname
      .substr(1, window.location.pathname.length)
      .split('/');

      let toolbarOptions = ToolbarOption;  // Assign directly from the input
      
// Check if toolbarOptions is a string and equals 'All'
if (typeof toolbarOptions === 'string' && toolbarOptions === 'All') {
  // toolbarOptions = ['Add', 'Edit', { text: "Delete", tooltipText: "Delete", id: "Delete", disabled: !SecurityService.canUserAccess('MadeupTransferTransactionsDelete') }, 'Update', 'Cancel', 'PDFExport', 'ExcelExport', 'Search', 'ColumnChooser'];
  toolbarOptions = ['Add', 'Edit', { text: "Delete", tooltipText: "Delete", id: "Delete", disabled: !true }, 'Update', 'Cancel', 'PDFExport', 'ExcelExport', 'Search', 'ColumnChooser'];
} else if (Array.isArray(toolbarOptions) && toolbarOptions.length > 0) {
  // Ensure toolbarOptions is an array and run the existing condition
  toolbarOptions = [...toolbarOptions];
  let deleteIndex = toolbarOptions.findIndex(res => res === 'Delete');
  if (deleteIndex !== -1) {
    // toolbarOptions[deleteIndex] = { text: "Delete", tooltipText: "Delete", id: "Delete", disabled: !SecurityService.canUserAccess('MadeupTransferTransactionsDelete') };
    toolbarOptions[deleteIndex] = { text: "Delete", tooltipText: "Delete", id: "Delete", disabled: !true };
  }
} else if (Array.isArray(toolbarOptions) && toolbarOptions.length === 0) {
  toolbarOptions = null;
}
     // Check if AllowPdfExport is false and remove PDFExport from toolbarOptions if present
if (!AllowPdfExport && Array.isArray(toolbarOptions) && toolbarOptions.length > 0) {
  toolbarOptions = toolbarOptions.filter(option => option !== 'PDFExport');
}

// Check if AllowExcelExport is false and remove ExcelExport from toolbarOptions if present
if (!AllowExcelExport && Array.isArray(toolbarOptions) && toolbarOptions.length > 0) {
  toolbarOptions = toolbarOptions.filter(option => option !== 'ExcelExport');
} 
if (!ShowColumnChooser && Array.isArray(toolbarOptions) && toolbarOptions.length > 0) {
  toolbarOptions = toolbarOptions.filter(option => option !== 'ColumnChooser');
} 
      

  const databound = (AllowDataBound) => {
    setTimeout(()=>{
      if (irtizaDynamicGrid && AllowDataBound == true ) {
       
      irtizaDynamicGrid.autoFitColumns();
    }
  },200)
  }
 
  // const dialogTemplate = (props) => {
  //   //  { isAdd, GridDialog, dataSourceState } 
  //   const { DialogType, Dialog } = GridDialog[0];
  //   if(DialogType === 'Self'){
  //   var DialogProps = Dialog.props
  //   var SelfDialog = Dialog.type}
  //   if (props.isAdd) {
  //     return DialogType === 'Master' ? (
  //       <IrtizDynamicInputAdd {...props} dataList={dataSourceState} />
  //     ) : DialogType === 'Self' ? (
  //       <SelfDialog {...DialogProps} {...props}  dataList={dataSourceState}/>

  //       // <InvoiceEvaluationAdd {...props} dataList={dataSourceState} />
  //   ): (
  //       <IrtizDynamicInputAdd {...props} dataList={dataSourceState} />
  //     );
  //   } else {
  //     return DialogType === 'Master' ? (
  //       <IrtizDynamicInputAdd {...props} dataList={dataSourceState} />
  //     ) : DialogType === 'Self' ? (
  //       <SelfDialog {...DialogProps} {...props}  dataList={dataSourceState}/>
  //     ) : (
  //       <IrtizDynamicInputAdd {...props} dataList={dataSourceState} />
  //     );
  //   }
  // };
  
  const dialogTemplate = (props) => {
    //  { isAdd, GridDialog, dataSourceState } 
   return(
    <div></div>
   )
  };
  

  const editSettings: EditSettingsModel = {
    
    // allowEditing: SecurityService.canUserAccess(ListSecurity == '' ? `${Id}List`:ListSecurity),
    // allowAdding: SecurityService.canUserAccess(ListSecurity == '' ? `${Id}List`:ListSecurity),
    // allowDeleting: SecurityService.canUserAccess(ListSecurity == '' ? `${Id}Delete`:ListSecurity),
    allowDeleting: true,

   allowEditing: true,
   allowAdding: true,
    // allowDeleting: true,

    mode: GridEditSettingMode,
    showDeleteConfirmDialog: true,
    headerTemplate:GridEditSettingHeaderTemplate
   };
   if(GridEditSettingMode == 'Dialog'){
    // editSettings.template= dialogTemplate;

   }
    
  const filterOptions: FilterSettingsModel = {
    type: 'Excel',
    // columns: [
    //   {
    //     field: 'IsDeleted',
    //     operator: 'notequal',
    //     value: 'True'
    //   }
    // ]
    columns: filterState

  };

  const groupOptions: GroupSettingsModel = {
    showDroopArea: true,
    columns: groupState
  };

  // const footerSum = (e: any) => {
    
  //   console.log(e)

  //   return <span> Sum: {e.Sum}</span>;

  // };
  const footerSum = (e: any) => {
    // Extract the second index key from the object
    const keys = Object.keys(e);
    // if (keys.length < 2) return null; // If there are fewer than two keys, return null or a fallback
    console.log(keys)
  
    const secondKey = keys[0];
    const sumValue = e[secondKey];
  
    // Check if this key exists in the footerSumCaption array
    const matchedCaption = footerSumCaption.find(item => item.ColumnName === secondKey);
  
    // If a match is found, replace the caption
    const caption = matchedCaption ? matchedCaption.Caption : 'Sum';
  
    // Log for debugging purposes
    console.log(`Key: ${secondKey}, Value: ${sumValue}, Caption: ${caption}`);
  
    return <span>{caption}: {sumValue}</span>;
  };
  const actionBagin = (args) =>{
    if (args.requestType === 'beginEdit' || args.requestType === 'add') {


    }
  }

  var visibleSortPassFields = useRef([defaultSortColumnName])
  var visibleSortPass = useRef([defaultSortColumnType])
  var ctrlPressed =  useRef(false)
  var groupingArray = []
  const actionComplete = (args: DialogEditEventArgs) => {
    if(args.requestType == "grouping"){
      groupingArray.push(args.columnName)
    }
    if(args.requestType == "ungrouping"){
      groupingArray.splice(groupingArray.indexOf(args.columnName),1)
    }
    ctrlPressed.current = false;
    let handleStore = (e) => {
      e.stopPropagation();
      return  ctrlPressed.current = e.ctrlKey; // Use e.ctrlKey, not e.ctrlkey
    };
    document.addEventListener('keydown', handleStore);
    setTimeout(() => {
      if(args.requestType ==  'sorting'){
        setTimeout(() => {
          if(ctrlPressed.current == true ){
            if(args.columnName == visibleSortPassFields.current[0] && visibleSortPassFields.current.length == 1){
            }else{
              if(visibleSortPassFields.current.includes(args.columnName)){
                visibleSortPass.current[visibleSortPassFields.current.indexOf(args.columnName)] = args.direction;
              }else{
                visibleSortPassFields.current.push(args.columnName)
                visibleSortPass.current.push(args.direction)
              }
            }
            setTimeout(() => {
              return ctrlPressed.current = false;
            }, 200);
          }else{
            visibleSortPassFields.current =[args.columnName]
            visibleSortPass.current = [args.direction]
          }
        }, 80);
        
        setTimeout(() => {
          
        }, 300);
          }
        }, 200);
          if(args.requestType == 'filtering'  && args.action == 'filter'){
            let filterArray = [...filterColumnName , args.currentFilteringColumn]
            filterColumnName = filterArray.filter((value, index, self) => {
        return self.indexOf(value) === index;
      });
    }
    
    if (args?.requestType === 'columnstate') {
      irtizaDynamicGrid?.autoFitColumns();
    }
    if(args.requestType == 'filtering'  && args.action == "clear-filter"){
      if(recordColumnName.includes(args.currentFilteringColumn)){

        recordColumnName.splice(recordColumnName.indexOf(args.currentFilteringColumn),1)
        
        fiterCheckedValue.splice(recordColumnName.indexOf(args.currentFilteringColumn),1)
        if(filterColumnName.includes(args.currentFilteringColumn) ){
          filterColumnName.splice(filterColumnName.indexOf(args.currentFilteringColumn),1)
        }
      }
   
    }
    if(args.requestType == 'filtering'  && args.action == 'filter'){
      if(!recordColumnName.includes(args.currentFilteringColumn)){
        const filteredData = irtizaDynamicGrid.getFilteredRecords();
        let checkedValue   = filteredData.map((val)=>{ return val[args.currentFilteringColumn]}).filter((value, index, self) => { return self.indexOf(value) === index })
        recordColumnName = [...recordColumnName , args.currentFilteringColumn]
        fiterCheckedValue = [...fiterCheckedValue , checkedValue]
        }else{
      
      fiterCheckedValue.splice(recordColumnName.indexOf(args.currentFilteringColumn) , 1)
        const filteredData = irtizaDynamicGrid.getFilteredRecords();
        let checkedValue   = filteredData.map((val)=>{ return val[args.currentFilteringColumn]}).filter((value, index, self) => { return self.indexOf(value) === index })
        fiterCheckedValue = [...fiterCheckedValue , checkedValue]
        
      } 
  }
  
  console.log(args)

      let dropDownArgs = Object.keys(autoCompleteInstances).map((res, ind) => {
        return autoCompleteInstances[res].dropdownValues;
    });
    
    let mergedObject = dropDownArgs.filter(item => item !== undefined)
        .reduce((acc, curr) => {
            return {...acc, ...curr};
        }, {});
    
     if (args.data) {
      Object.assign(args.data, mergedObject);
  }
  if (args.rowData) {
      Object.assign(args.rowData, mergedObject);
  }

  console.log(args)
  const selectRowByID=(id)=>{
    console.log(id)
    irtizaDynamicGrid.selectRow(id)
  }
  const startEdit =()=>{
    irtizaDynamicGrid.startEdit()
  }
      ListActionComplete(
        irtizaDynamicGrid,
        args,
        setDataSourceState,
        getListService ,
         selectedRows ,
         selectRowByID,
         startEdit
        )
            

    }

  // const commandClick = (args: CommandClickEventArgs) => {
  //   setShowDialog(false);
  //   setSelectedRow(args.rowData);
  //   setShowDialog(true);
  // };

  // const commands: CommandModel[] = [
  //   {
  //     buttonOption: {
  //       content: 'Delivery Schedule',
  //       cssClass: 'e-flat',
  //     },
  //   },
  // ];

  // const dialogClose = () => {
  //   this.buttonInstance.style.display = 'inline-block';
  //   setShowDialog(false);
  // };

  

  
  const sortingOptions: SortSettingsModel = {
    columns: [{ field:DefaultSortedColumnName, direction: DefaultSortedColumnSort }]
  }
  const userId = SecurityService.getLoggedInUserId();
      
 // DYNAMIC TEMPLATE CODE
 const GridSum = (GridSumColumns , ColumnsList)=>{
// Initialize gridSum as an empty array
let gridSum = [];

if(AllowGridSum  == true){

if (GridSumColumns.length > 0) {
  // Map over GridSumColumns to set format based on conditions
  gridSum = GridSumColumns.map((column) => {
    if (column.includes('Rate') || column.endsWith('Percent')) {
      return {
        field: column,
        format: 'n2', // Format for rate or percent
      };
    } else {
      return {
        field: column,
        format: 'n0', // Default format
      };
    }
  });
} else {
  // If GridSumColumns is empty, map over ColumnsList
  gridSum = ColumnsList.map((column) => {
    // Check if format is defined in other[0]
    if (column.other[0]?.format) {
      return {
        field: column.field,
        format: column.other[0].format,
      };
    }
  }).filter((result) => result !== undefined); // Filter out undefined results
}
}else{
  gridSum = [
    {
      field:'dontmakesum',
      format: 'n0',
    }
  ]
}
// Log the resulting gridSum array

return( 
  
  <AggregatesDirective>

  <AggregateDirective>
    <AggregateColumnsDirective>
      {
gridSum.map((res,key)=>{
return(<AggregateColumnDirective key={key} field={res.field} type='Sum' format={res.format} footerTemplate={footerSum} />)
})}
    </AggregateColumnsDirective>
  </AggregateDirective>

  <AggregateDirective>
    <AggregateColumnsDirective>
    {
gridSum.map((res,key)=>{
return(<AggregateColumnDirective key={key}  field={res.field} type='Sum' format={res.format} groupFooterTemplate={footerSum} />)
})}
      
     

    </AggregateColumnsDirective>
  </AggregateDirective>

</AggregatesDirective>
)
 }
 const ListGridComponent = useMemo(()=> {
  return ({ restrictPageSize = 12 }) => {
  ColumnProps = ColumnsList.map(column => {
    if (column.field.endsWith('Date')) {
        return {
            ...column,
            other: [{  'format': formatOptions , ...column.other[0] }]
        };
    }else if(column.field.startsWith('Is') && column.field != 'IsDeleted' && column.field != 'IsApproved') {
      return {
        ...column,
        other: [{...column.other[0],  template:(props) => {
          return (
          <p style={{margin: '0px'}}>{props[column.field] == true ? 'Yes' : 'No'}</p>
          )
          }   }]
    };
    }else if(column.other.length > 0  && column.other[0].editType == 'dropdownedit') {
      return {
        ...column,                                                  
        other: [{...column.other[0],  edit:createAutoComplete(column.other[0].edit) }]
          
    }
    }
    // else if(column.other.length > 0  && column.other[0].editType == 'multidropdownedit') {

    //   let dynamicKey = `obj_${column.other[0].edit.field.id}`;
    //   console.log(dynamicKey)
    //         autoCompleteInstances[dynamicKey] = {dataSource:[]}
    //   return {
    //     ...column,                                                  
    //     other: [{ editTemplate:()=><CreateAutoCompleteComponent  dataSource={autoCompleteInstances[column.other[0].edit.field.id].dataSource}/> }]

    //     // {...column.other[0].edit}
          
    // }
    // }
    // else if(column.other.length > 0  && column.other[0].editType == 'multidropdown') {
    //   return {
    //     ...column,                                                  
    //     other: [{ editTemplate:(props)=> <CreateMultiDropDown {...props} {...column.other[0].edit} /> }]
          
    // }
    // }
    return column;
});
  // const addedColumns = useMemo(() => {
  const addedColumns = () => {
    if(GridDataSource[0].CreateColumns && GridDataSource[0].CreateColumns.length > 0){
  
      return GridDataSource[0].CreateColumns.map((field, index) => {
      
          return (
            <ColumnDirective
              key={index} // You may use index as key here if there is no unique field key available
              // direction={visibleSort[index]}
              field={field.columnName}
              visible={false}
            />
          );
        })
      
    } else {
      return null
    }
  // }, [ GridDataSource[0].CreateColumns.length > 0]);
  };
  let addColumnss = addedColumns()
  // const columns = useMemo(() => {
  const columns = () => {
    if (ColumnProps.length > 0) {
      return ColumnProps.map((field, index) => {
        if (field.field == 'Commands') {
          return (
            <ColumnDirective
              key={index} // You may use index as key here if there is no unique field key available
              headerText={field.headerText}
              width={field.width}
              headerTextAlign={field.headerTextAlign}
              textAlign={field.textAlign}
              {...field.other[0]}
              
              // direction={visibleSort[index]}
            />
          );
        } 
        // else if(index == 3){
        //   return (
        //     <ColumnDirective
        //     key={'SaleProductID'}
        //     headerText={'Product Name'}
        //     id={'SaleProductName'}
        //     field={'SaleProductName'}
        //     width={400}
        //     headerTextAlign={'center'}
        //     textAlign={'left'}
        //     editTemplate={(props)=>{
        //       // console.log(saleProductNameDataSource)
        //       return(
        //         <>
        //         <AutoCompleteComponent 
        //         // dataSource={saleProductNameDataSource}
        //         fields={ddlFields}
        //         ref={saleProduct}
        //         floatLabelType='Auto' 
        //         highlight={true}
        //         />
        //         </>
        //       )
        //     }}
        //     // {...field.other[0]}
            
        //     // direction={visibleSort[index]}
        //     />
        //     );
        // }
        else {
        

            return (
              <ColumnDirective
              key={field.field}
              headerText={field.headerText}
              id={field.field}
              field={field.field}
              width={field.width}
              headerTextAlign={field.headerTextAlign}
              textAlign={field.textAlign}
              {...field.other[0]}
              
              // direction={visibleSort[index]}
              />
              );
            
        }
      });
    } else {
      return <ColumnDirective field='IsDeleted' visible={false} />; // You don't need to wrap it with () here
    }
  // }, [ColumnProps.length > 0]);
  }

  let CreateColumns =  columns()
  
return (
    <GridComponent
          ref={(grid) => (irtizaDynamicGrid = grid)}
          id={`${Id}Grid`}
          dataSource={dataSourceState}
          editSettings={editSettings}
          pageSettings={{ pageCount: 4, pageSizes: true, pageSize: restrictPageSize }}
          toolbar={toolbarOptions}
          toolbarClick={toolbarClick}
          allowExcelExport={AllowExcelExport}
          allowPdfExport={AllowPdfExport}
          showColumnChooser={ShowColumnChooser}
          allowPaging={AllowPaging}
          allowGrouping={AllowGrouping}

          filterSettings={filterOptions}
          groupSettings={groupOptions}
          actionComplete={actionComplete}
          actionBegin={actionBagin}
          allowTextWrap='true'
          
          allowSorting={true}
          allowFiltering={true}
          allowReordering={true}
          allowResizing={true}
          sortSettings={sortingOptions}
          dataBound={databound(AllowDataBound)}
          rowSelected={
            (args)=>{
              selectedRows = args.data
            setTimeout(() => {
              }, 200);
            }
          }
        >
            <ColumnsDirective>
          {CreateColumns}
          {addColumnss}
            </ColumnsDirective>
        {GridSum( GridSumColumns, ColumnsList )}

          <Inject
            services={[
              Edit,
              Toolbar,
              Page,
              Sort,
              PdfExport,
              ExcelExport,
              Filter,
              Group,
              Aggregate,
              Freeze,
              ColumnChooser,
              Reorder,
              Resize,
              CommandColumn,
            ]}
          />
        </GridComponent>

)
}
},[dataSourceState]);
// DYNAMIC TEMPLATE CODE
  const getColumnsDetail = ()=>{
    return irtizaDynamicGrid.getColumns()
  }

return (
    <div className='' >
      <div>
            <div>
              <h3>{
                }
              {/* // DYNAMIC TEMPLATE CODE */}
              {AllowTemplate == true ? <button 
              onClick={(e)=>{ e.preventDefault();setIsToggled(!isToggled)}}
              className={`toggle-templete ${isToggled ? 'active' : ''}`}
              >
       {ToggledValue}               
          </button >:null }
          {/* isToggled ,
  ColumnProps ,
  getListService ,
  getColumns , 
  InputListSecurity ,
  visibleSortPassFields ,
  visibleSortPass ,
  groupingArray ,
  defaultSortColumnName ,
  defaultSortColumnType */}
              </h3>
               
               
              <div>
              </div>
              {showDialogs ?
          <HardDelete
            {...deletingProps} 
            showResult={(e)=>{

                                setShowDialogs(false)
                                if(e == false){
                                  setShowDialogs(false)
                                setTimeout(() => {
                        
                  getListService(true);
              }, 200);
    }
              }} 
                ></HardDelete>
                  :null}
               {
            loadingdataSourceState == false ?  
              <ListGridComponent />
              :                 
              <div className='loader-div'> <img src={imgSrc} id='' /> LOADING...</div>
            } 
            </div>
          </div>
          </div>
  );
}





