import http from '../../services/http-common';

class ShopService {
    getShopForList() {
    return http.get(`/Shop/list`);
    }
insert(data) {
    return http.post(`/Shop/insert`,data);
}
update(data) {
    return http.put(`/Shop/update`,data);
}
}

export default new ShopService();