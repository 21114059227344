
import React , {useState , useEffect , useRef} from 'react';
import ProductTransferService from './product-transfer.service';
import { TextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { AutoCompleteComponent } from '@syncfusion/ej2-react-dropdowns';
import './product-transfer.scss';
const ProductTransfer = (props) => {
    let fromproductsRef = useRef(null)
    let toproductsRef = useRef(null)
    const [loadingProduct, setLoadingProduct] = useState(true);
    const [products, setProducts] = useState([]);
    const [formProductValue, setFromProductValue] = useState('');
    const [fromProductID, setFromProductID] = useState(undefined);
    const [toProductValue, setToProductValue] = useState('');
    const [toProductID, setToProductID] = useState(undefined);

    const ddlFields: object = { value: 'value' };

    useEffect(() => {
        getProducts();

    }, []);
    
    useEffect(() => {
        if(fromProductID == toProductID && toProductID != undefined &&  fromProductID != undefined ){
            document.getElementById('same-user-id').style.display = 'block'
            setTimeout(()=>{
                document.getElementById('same-user-id').style.display = 'none'
            },1500)
        }

    }, [fromProductID , toProductID]);
    const getProducts = async (type: string) => {
        setLoadingProduct(true);
        ProductTransferService.getForList()
          .then((response) => {
            console.log('Products get response', response.data.recordset);
            setProducts(response.data.recordset);
            setLoadingProduct(false);
          })
          .catch((e) => {
            console.log(e);
          });
      };

    const fromProductsChange = (e)=>{
        
        console.log(e)
        setFromProductValue(e?.itemData?.value ? e?.itemData?.value : '');
        setFromProductID(e?.itemData?.id ? e?.itemData?.id : undefined);
    if (e?.e?.srcElement?.id) {
      console.log('from product change: ', e?.itemData?.id);
      setFromProductID(e?.itemData?.id);
    }
    }
    const toProductsChange = (e)=>{
        
        console.log(e)
        setToProductValue(e?.itemData?.value ? e?.itemData?.value : '');
        setToProductID(e?.itemData?.id ? e?.itemData?.id : undefined);
    if (e?.e?.srcElement?.id) {
      console.log('from product change: ', e?.itemData?.id);
      setToProductID(e?.itemData?.id);
    }
    }

    const formSubmit = (e) =>{
        e.preventDefault()
        if(fromProductID == toProductID && toProductID != undefined &&  fromProductID != undefined ){
            document.getElementById('same-user-id').style.display = 'block'
            setTimeout(()=>{
                document.getElementById('same-user-id').style.display = 'none'
            },1500)
            setToProductID(undefined)
            setToProductValue('')
            toproductsRef.current.element.focus()
        }else{
            console.log(fromProductID)
            console.log(toProductID)
            let data = {
              Fromid:fromProductID,
              Toid:toProductID
            }
            ProductTransferService.productTransfer(data).then((res)=>{
              console.log(res)
              setToProductID(undefined)
            setToProductValue('')
              setFromProductID(undefined)
            setFromProductValue('')
            document.getElementById('user-replicate').style.display = 'block'
            setTimeout(()=>{
                document.getElementById('user-replicate').style.display = 'none'
            },1500)
            }).catch(()=>{

            })

        }
        console.log(e)
    }

    return (
        (props.isDialog) ? (
        
        <div>
            
        </div>
        ) : 
        (
            <div className="content-wrapper">
                  <div>
                    <h3> Product - Transfer</h3>
                    <form onSubmit={formSubmit}>

                    
                    <div className='user-replicate'>
                  <div className='first-dropdown'>

                    <mat-form-field>
                      {loadingProduct ? (
                        <div>... Loading  From Products </div>
                      ) : (
                        <div>
                          <AutoCompleteComponent
                            id='FromProduct'
                            dataSource={products}
                            placeholder='Select  From Product'
                            fields={ddlFields}
                            floatLabelType='Auto'
                            highlight={true}
                            select={fromProductsChange}
                            ref={fromproductsRef}
                            focus={()=>{
                              if (fromproductsRef ) {fromproductsRef.current.showPopup();}
                            }}
                            change={fromProductsChange}
                            value={formProductValue}
                              blur={()=>{
                                console.log('dfdfblur',fromProductID)
                                if (fromProductID  == undefined  || fromproductsRef.current.value == null || fromproductsRef.current.value == '' || fromProductID == undefined || fromProductID == null) {
                                 if(formProductValue != ''){
                                   let resstr = products.some(e=>e.value ==  formProductValue);
                                   console.log(resstr)
                                   if(resstr === false){
                                    //  setProductGroupErorr(`${designationValue} Value Not Find ` )
                                     document.getElementById("FromProduct").parentElement.parentElement.firstChild.style.border ='3px solid red'
                                     setTimeout(() => {
                                      //  setProductItemTypeError('')
                                       document.getElementById("FromProduct").parentElement.parentElement.firstChild.style.border ='1px solid #c8c8c8'

                                     }, 1000);
                                   }
                                 }
                                //  if (addNewClicked == false){
                                //   fromproductsRef.current.element.focus()

                                //  }
                               }
                             }}
                          />

                          <TextBoxComponent
                            className='e-input'
                            type='hidden'
                            name='FromProductID'
                            floatLabelType='Auto'
                            value={fromProductID}
                          />
                          </div>
                      )}
                    </mat-form-field>
                        </div>
                  <div className='second-dropdown'>

                    <mat-form-field>
                      {loadingProduct ? (
                        <div>... Loading  To Products </div>
                      ) : (
                        <div>
                          <AutoCompleteComponent
                            id='ToProduct'
                            dataSource={products}
                            placeholder='Select To Product'
                            fields={ddlFields}
                            floatLabelType='Auto'
                            highlight={true}
                            select={toProductsChange}
                            ref={toproductsRef}
                            focus={()=>{
                              if (toproductsRef ) {toproductsRef.current.showPopup();}
                            }}
                            change={toProductsChange}
                            value={toProductValue}
                              blur={()=>{
                                console.log('dfdfblur',toProductID)
                                if (toProductID  == undefined  || fromproductsRef.current.value == null || fromproductsRef.current.value == '' || toProductID == undefined || toProductID == null) {
                                 if(toProductValue != ''){
                                   let resstr = products.some(e=>e.value ==  toProductValue);
                                   console.log(resstr)
                                   if(resstr === false){
                                    //  setProductGroupErorr(`${designationValue} Value Not Find ` )
                                     document.getElementById("ToProduct").parentElement.parentElement.firstChild.style.border ='3px solid red'
                                     setTimeout(() => {
                                      //  setProductItemTypeError('')
                                       document.getElementById("ToProduct").parentElement.parentElement.firstChild.style.border ='1px solid #c8c8c8'

                                     }, 1000);
                                   }
                                 }
                                //  if (addNewClicked == false){
                                //   fromproductsRef.current.element.focus()

                                //  }
                               }
                             }}
                          />

                          <TextBoxComponent
                            className='e-input'
                            type='hidden'
                            name='ToProductID'
                            floatLabelType='Auto'
                            value={toProductID}
                          />
                          </div>
                      )}
                    </mat-form-field>
                        </div>
                        <div className='div-btn'>
                        <button 
                        type='submit'
                        id='get-data-btn'
                        className='user-replicate-btn' 
                        disabled={
                            toProductID == undefined || fromProductID == undefined ? true : false
                        }
                        > Product Transfer</button>
                        <p id='same-user-id'> Cannot Transfer Same Product!</p>
                        <p id='user-replicate'> Product Transfer SuccessFully!</p>
                        </div>
                      </div>
                      </form>
                 </div>
            </div>
        )
    );
}

export default ProductTransfer;
