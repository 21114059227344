import http from './http-common';

class ListTempleteService {
  getAllBySecurityItemID(id) {
    return http.get(`/listTemplete/${id}/list`);
  }
  addListTemplete(data) {
    return http.post(`/listTemplete/add`,data);
  }
  getYarnContractBrokers() {
    return http.get('/brokers/yarnContractBrokers');
  }
  get(id) {
    return http.get(`/brokers/${id}`);
  }
  update(data) {
    console.log('data: ', data);
    return http.put(`/brokers/update`, data);
  }
}

export default new ListTempleteService();
