import React, { useEffect, useState, useMemo } from 'react';
import moment from 'moment';
import DropdownService from '../../irtiza-dynamic-input/dropdown-api.service';
import invoiceService from '../../invoice/invoice.service';
import { Page, Text, View, Document, StyleSheet, PDFViewer } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  page: {
    // margin: 20,  // Increased margin for more space around the page
    padding: 25,
    fontSize: 10
  },
  // section: {
  //   margin: 10,
  //   padding: 10,
  //   flexGrow: 1
  // },
  table: {
    display: 'table',
    width: 'auto',
    borderStyle: 'none',
    borderWidth: 0, 
    borderRightWidth: 0,
    borderBottomWidth: 0,
  },
  tableRow: {
    flexDirection: 'row'
  },
  tableCol: {
    width: '10%',
    borderStyle: 'none',
    borderWidth: 0,
    borderLeftWidth: 0,
    borderTopWidth: 0
  },
  tableCell: {
    marginRight: 'auto',
    marginTop: 5,
    padding: 5,
    fontSize: 9
  },
  tableCellHeader: {
    margin: 'auto',
    marginTop: 5,
    fontWeight:'bold',
    padding: 5,
    fontSize: 9
  },
  title: {
    fontSize: 16,
    fontStyle:'italic',
    marginBottom: 10,
    textAlign: 'center',
    fontWeight:'bold'

  }
});

const Report = React.memo(({data ,fromDate, toDate }) => {
  
console.log(data)
  
  

  return(
  <Document title={'Inventory With Profit Loss'}>
    {/* <Page style={styles.page} orientation={'landscape'} size={'A4'}> */}
    <Page style={styles.page} orientation={'landscape'} size={'A4'}>
      <Text style={styles.title}>Inventory Status With Profit / Loss</Text>

      <View style={{ ...styles.tableRow, margin: '0px' }}>
    
            <View style={{ ...styles.tableCol, width: '10%', borderWidth: 0 }}>
              <Text style={{ ...styles.tableCell, fontWeight: 'bold' }}>From</Text>
            </View>
            <View style={{ ...styles.tableCol, width: '90%', borderWidth: 0 }}>
              <Text style={styles.tableCell}>{moment(fromDate).format('DD-MMM-YY')}</Text>
            </View>
            </View>
            
      <View style={{ ...styles.tableRow, margin: '0px' }}>
            
            <View style={{ ...styles.tableCol, width: '10%', borderWidth: 0 }}>
              <Text style={{ ...styles.tableCell, fontWeight: 'bold' }}>To</Text>
            </View>
            <View style={{ ...styles.tableCol, width: '90%', borderWidth: 0 }}>
              <Text style={styles.tableCell}>{moment(toDate).format('DD-MMM-YY')}</Text>
            </View>
            </View>


      <View style={styles.table}>
        <View style={{ ...styles.tableRow, borderBottom: 'solid', borderBottomWidth: 1,padding:4,fontSize:'11px' }} fixed>
        <View style={{ ...styles.tableCol, width: '20%' }}><Text style={{...styles.tableCellHeader }}>Item Name</Text></View>
        {/* <View style={{...styles.tableCol, width: '10%' }}><Text style={{...styles.tableCellHeader }}>Brand</Text></View> */}
        <View style={{...styles.tableCol, width: '12%' }}>
          <Text style={{...styles.tableCellHeader }}>Opening 
          </Text>
          <View style={{ ...styles.tableRow,fontSize:'11px' }} fixed>
          <View style={{...styles.tableCol, width: '33%' }}><Text style={{...styles.tableCellHeader }}>Qty.</Text></View>
          <View style={{...styles.tableCol, width: '33%' }}><Text style={{...styles.tableCellHeader }}>Rate</Text></View>
          <View style={{...styles.tableCol, width: '33%' }}><Text style={{...styles.tableCellHeader }}>Amt.</Text></View>
          </View>
          </View>
          <View style={{...styles.tableCol, width: '12%' }}>
          <Text style={{...styles.tableCellHeader }}>Purchase 
          </Text>
          <View style={{ ...styles.tableRow,fontSize:'11px' }} fixed>
          <View style={{...styles.tableCol, width: '33%' }}><Text style={{...styles.tableCellHeader }}>Qty.</Text></View>
          <View style={{...styles.tableCol, width: '33%' }}><Text style={{...styles.tableCellHeader }}>Rate</Text></View>
          <View style={{...styles.tableCol, width: '33%' }}><Text style={{...styles.tableCellHeader }}>Amt.</Text></View>
          </View>
          </View>
          <View style={{...styles.tableCol, width: '10%' }}>
          <Text style={{...styles.tableCellHeader }}>Trans 
          </Text>
          <View style={{ ...styles.tableRow,fontSize:'11px' }} fixed>
          <View style={{...styles.tableCol, width: '100%' }}><Text style={{...styles.tableCellHeader }}>Qty.</Text></View>
          </View>
          </View>
          <View style={{...styles.tableCol, width: '12%' }}>
          <Text style={{...styles.tableCellHeader }}>Available 
          </Text>
          <View style={{ ...styles.tableRow,fontSize:'11px' }} fixed>
          <View style={{...styles.tableCol, width: '33%' }}><Text style={{...styles.tableCellHeader }}>Qty.</Text></View>
          <View style={{...styles.tableCol, width: '33%' }}><Text style={{...styles.tableCellHeader }}>Rate</Text></View>
          <View style={{...styles.tableCol, width: '33%' }}><Text style={{...styles.tableCellHeader }}>Amt.</Text></View>
          </View>
          </View>
          <View style={{...styles.tableCol, width: '12%' }}>
          <Text style={{...styles.tableCellHeader }}>Closing Stock 
          </Text>
          <View style={{ ...styles.tableRow,fontSize:'11px' }} fixed>
          <View style={{...styles.tableCol, width: '33%' }}><Text style={{...styles.tableCellHeader }}>Qty.</Text></View>
          <View style={{...styles.tableCol, width: '33%' }}><Text style={{...styles.tableCellHeader }}>Rate</Text></View>
          <View style={{...styles.tableCol, width: '33%' }}><Text style={{...styles.tableCellHeader }}>Amt.</Text></View>
          </View>
          </View>
          <View style={{...styles.tableCol, width: '12%' }}>
          <Text style={{...styles.tableCellHeader }}>Sale
          </Text>
          <View style={{ ...styles.tableRow,fontSize:'11px' }} fixed>
          <View style={{...styles.tableCol, width: '33%' }}><Text style={{...styles.tableCellHeader }}>Qty.</Text></View>
          <View style={{...styles.tableCol, width: '33%' }}><Text style={{...styles.tableCellHeader }}>Rate</Text></View>
          <View style={{...styles.tableCol, width: '33%' }}><Text style={{...styles.tableCellHeader }}>Amt.</Text></View>
          </View>
          </View>
          <View style={{...styles.tableCol, width: '10%' }}>
          <Text style={{...styles.tableCellHeader }}>Profit / Loss
          </Text>
          <View style={{ ...styles.tableRow,fontSize:'11px' }} fixed>
          <View style={{...styles.tableCol, width: '50%' }}><Text style={{...styles.tableCellHeader }}>Rate</Text></View>
          <View style={{...styles.tableCol, width: '50%' }}><Text style={{...styles.tableCellHeader }}>Amt.</Text></View>
          </View>
          </View>
        {/* {type =='With' ? <View style={{...styles.tableCol, width: '10%' }}><Text style={{...styles.tableCellHeader }}>Avg Rate </Text></View> : null}
        {type =='With' ? <View style={{...styles.tableCol, width: '10%' }}><Text style={{...styles.tableCellHeader }}>Amount </Text></View> : null} */}
          </View> 
         {Object.keys(data.groupedData).map((group, groupKey) =>{ 
           console.log(data)
          console.log(data.groupedData[group]) 
          return ( 
          <View key={groupKey}> 
            <View style={{ width: '100%', padding: '5px', textAlign: 'center',backgroundColor:'gray',fontSize:'14px' , fontWeight:'bold' }}>
              <Text>{group}</Text>
            </View>
           {Object.keys(data.groupedData[group].brands).map((brandGroup, brandGroupKey) =>{
            return(<>
            <View key={brandGroupKey} style={{ width: '100%', padding: '5px', textAlign: 'center',fontSize:'12px' , fontWeight:'bold', borderTop:'dashed',borderTopWidth:1 }}>
              <Text>{brandGroup}</Text>
            </View>
             {data.groupedData[group].brands[brandGroup].items.map((product,key) => (
              <View style={styles.tableRow} key={key}>
                <View style={{ ...styles.tableCol, width: '20%' }}><Text style={styles.tableCell}>{product.ProductName}</Text></View>

                <View style={{ ...styles.tableCol, width: '12%' }}><View style={{ ...styles.tableRow,fontSize:'11px' }} >
                <View style={{ ...styles.tableCol, width: '33%' ,textAlign: 'right'}}><Text style={styles.tableCell}>{product.OpQuantity.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}</Text></View>
                <View style={{ ...styles.tableCol, width: '33%' ,textAlign: 'right'}}><Text style={styles.tableCell}>{product.OpRate.toLocaleString('en-US', { minimumFractionDigits:2, maximumFractionDigits: 2 })}</Text></View>
                <View style={{ ...styles.tableCol, width: '33%' ,textAlign: 'right'}}><Text style={styles.tableCell}>{product.OpAmount.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}</Text></View>
                </View></View>
                
                <View style={{ ...styles.tableCol, width: '12%' }}><View style={{ ...styles.tableRow,fontSize:'11px' }} >
                <View style={{ ...styles.tableCol, width: '33%' ,textAlign: 'right'}}><Text style={styles.tableCell}>{product.PurQuantity.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}</Text></View>
                <View style={{ ...styles.tableCol, width: '33%' ,textAlign: 'right'}}><Text style={styles.tableCell}>{product.PurRate.toLocaleString('en-US', { minimumFractionDigits:2, maximumFractionDigits: 2 })}</Text></View>
                <View style={{ ...styles.tableCol, width: '33%' ,textAlign: 'right'}}><Text style={styles.tableCell}>{product.PurAmount.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}</Text></View>
                </View></View>

                <View style={{ ...styles.tableCol, width: '10%' }}><View style={{ ...styles.tableRow,fontSize:'11px' }} >
                <View style={{ ...styles.tableCol, width: '100%' ,textAlign: 'right'}}><Text style={styles.tableCell}>{product.TransQuantity.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}</Text></View>
                </View></View>
                
                <View style={{ ...styles.tableCol, width: '12%' }}><View style={{ ...styles.tableRow,fontSize:'11px' }} >
                <View style={{ ...styles.tableCol, width: '33%' ,textAlign: 'right'}}><Text style={styles.tableCell}>{product.AvailableQuantity.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}</Text></View>
                <View style={{ ...styles.tableCol, width: '33%' ,textAlign: 'right'}}><Text style={styles.tableCell}>{product.AvailableRate.toLocaleString('en-US', { minimumFractionDigits:2, maximumFractionDigits: 2 })}</Text></View>
                <View style={{ ...styles.tableCol, width: '33%' ,textAlign: 'right'}}><Text style={styles.tableCell}>{product.AvailableAmount.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}</Text></View>
                </View></View>
               
                <View style={{ ...styles.tableCol, width: '12%' }}><View style={{ ...styles.tableRow,fontSize:'11px' }} >
                <View style={{ ...styles.tableCol, width: '33%' ,textAlign: 'right'}}><Text style={styles.tableCell}>{product.ClosingQuantity.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}</Text></View>
                <View style={{ ...styles.tableCol, width: '33%' ,textAlign: 'right'}}><Text style={styles.tableCell}>{product?.ClosingRate ? product.ClosingRate.toLocaleString('en-US', { minimumFractionDigits:2, maximumFractionDigits: 2 }) : 0.00}</Text></View>
                <View style={{ ...styles.tableCol, width: '33%' ,textAlign: 'right'}}><Text style={styles.tableCell}>{product.ClosingAmount.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}</Text></View>
                </View></View>

                <View style={{ ...styles.tableCol, width: '12%' }}><View style={{ ...styles.tableRow,fontSize:'11px' }} >
                <View style={{ ...styles.tableCol, width: '33%' ,textAlign: 'right'}}><Text style={styles.tableCell}>{product.SaleQuantity.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}</Text></View>
                <View style={{ ...styles.tableCol, width: '33%' ,textAlign: 'right'}}><Text style={styles.tableCell}>{product.SaleAvgRate.toLocaleString('en-US', { minimumFractionDigits:2, maximumFractionDigits: 2 })}</Text></View>
                <View style={{ ...styles.tableCol, width: '33%' ,textAlign: 'right'}}><Text style={styles.tableCell}>{product.SaleAmount.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}</Text></View>
                </View></View>
                
                <View style={{ ...styles.tableCol, width: '10%' }}><View style={{ ...styles.tableRow,fontSize:'11px' }} >
                <View style={{ ...styles.tableCol, width: '50%' ,textAlign: 'right'}}><Text style={styles.tableCell}>{product.SaleRateDiff.toLocaleString('en-US', { minimumFractionDigits:2, maximumFractionDigits: 2 })}</Text></View>
                <View style={{ ...styles.tableCol, width: '50%' ,textAlign: 'right'}}><Text style={styles.tableCell}>{product.ProfitLossAmount.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}</Text></View>
                </View></View>
                {/* <View style={{ ...styles.tableCol, width: '10%' ,textAlign: 'right'}}><Text style={styles.tableCell}>{product.TransferQty.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}</Text></View>
                <View style={{ ...styles.tableCol, width: '10%' ,textAlign: 'right'}}><Text style={styles.tableCell}>{product.BalQty.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}</Text></View> */}
                
                
                {/* <View style={{ ...styles.tableCol, textAlign: 'right' }}><Text style={styles.tableCell}>{product.TotalStockQty.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}</Text></View> */}
              </View>
            ))}
            <View style={{ ...styles.tableRow, borderTop: 'solid', borderTopWidth: 1 }}>
              <View style={{ ...styles.tableCol, width: '20%', padding: 0 }}><Text style={styles.tableCellHeader}>BRAND TOTAL</Text></View>
              
              <View style={{ ...styles.tableCol, width: '12%' }}><View style={{ ...styles.tableRow,fontSize:'11px' }} >
                <View style={{ ...styles.tableCol, width: '50%' ,textAlign: 'right'}}><Text style={styles.tableCell}>{data.groupedData[group].brands[brandGroup].totals.OpQuantity.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}</Text></View>
                <View style={{ ...styles.tableCol, width: '50%' ,textAlign: 'right'}}><Text style={styles.tableCell}></Text></View>
                <View style={{ ...styles.tableCol, width: '50%' ,textAlign: 'right'}}><Text style={styles.tableCell}>{data.groupedData[group].brands[brandGroup].totals.OpAmount.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}</Text></View>
                </View></View>
                
                <View style={{ ...styles.tableCol, width: '12%' }}><View style={{ ...styles.tableRow,fontSize:'11px' }} >
                <View style={{ ...styles.tableCol, width: '50%' ,textAlign: 'right'}}><Text style={styles.tableCell}>{data.groupedData[group].brands[brandGroup].totals.PurQuantity.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}</Text></View>
                <View style={{ ...styles.tableCol, width: '50%' ,textAlign: 'right'}}><Text style={styles.tableCell}></Text></View>
                <View style={{ ...styles.tableCol, width: '50%' ,textAlign: 'right'}}><Text style={styles.tableCell}>{data.groupedData[group].brands[brandGroup].totals.PurAmount.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}</Text></View>
                </View></View>

                <View style={{ ...styles.tableCol, width: '10%' }}><View style={{ ...styles.tableRow,fontSize:'11px' }} >
                <View style={{ ...styles.tableCol, width: '100%' ,textAlign: 'right'}}><Text style={styles.tableCell}>{data.groupedData[group].brands[brandGroup].totals.TransQuantity.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}</Text></View>
                </View></View>
                
                <View style={{ ...styles.tableCol, width: '12%' }}><View style={{ ...styles.tableRow,fontSize:'11px' }} >
                <View style={{ ...styles.tableCol, width: '33%' ,textAlign: 'right'}}><Text style={styles.tableCell}>{data.groupedData[group].brands[brandGroup].totals.AvailableQuantity.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}</Text></View>
                <View style={{ ...styles.tableCol, width: '33%' ,textAlign: 'right'}}><Text style={styles.tableCell}></Text></View>
                <View style={{ ...styles.tableCol, width: '33%' ,textAlign: 'right'}}><Text style={styles.tableCell}>{data.groupedData[group].brands[brandGroup].totals.AvailableAmount.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}</Text></View>
                </View></View>
               
                <View style={{ ...styles.tableCol, width: '12%' }}><View style={{ ...styles.tableRow,fontSize:'11px' }} >
                <View style={{ ...styles.tableCol, width: '33%' ,textAlign: 'right'}}><Text style={styles.tableCell}>{data.groupedData[group].brands[brandGroup].totals.ClosingQuantity.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}</Text></View>
                <View style={{ ...styles.tableCol, width: '33%' ,textAlign: 'right'}}><Text style={styles.tableCell}></Text></View>
                <View style={{ ...styles.tableCol, width: '33%' ,textAlign: 'right'}}><Text style={styles.tableCell}>{data.groupedData[group].brands[brandGroup].totals.ClosingAmount.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}</Text></View>
                </View></View>

                <View style={{ ...styles.tableCol, width: '12%' }}><View style={{ ...styles.tableRow,fontSize:'11px' }} >
                <View style={{ ...styles.tableCol, width: '33%' ,textAlign: 'right'}}><Text style={styles.tableCell}>{data.groupedData[group].brands[brandGroup].totals.SaleQuantity.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}</Text></View>
                <View style={{ ...styles.tableCol, width: '33%' ,textAlign: 'right'}}><Text style={styles.tableCell}></Text></View>
                <View style={{ ...styles.tableCol, width: '33%' ,textAlign: 'right'}}><Text style={styles.tableCell}>{data.groupedData[group].brands[brandGroup].totals.SaleAmount.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}</Text></View>
                </View></View>
                
                <View style={{ ...styles.tableCol, width: '10%' }}><View style={{ ...styles.tableRow,fontSize:'11px' }} >
                {/* <View style={{ ...styles.tableCol, width: '50%' ,textAlign: 'right'}}><Text style={styles.tableCell}>{data.groupedData[group].brands[brandGroup].totals.SaleRateDiff.toLocaleString('en-US', { minimumFractionDigits:2, maximumFractionDigits: 2 })}</Text></View> */}
                <View style={{ ...styles.tableCol, width: '100%' ,textAlign: 'right'}}><Text style={styles.tableCell}>{data.groupedData[group].brands[brandGroup].totals.ProfitLossAmount.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}</Text></View>
                </View></View>

              
              {/* <View style={{ ...styles.tableCol, textAlign: 'right' }}><Text style={styles.tableCell}>{group.totals.TotalStockQty.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}</Text></View> */}
          </View>
          </>
         )})}
            
          
            <View style={{ ...styles.tableRow, borderTop: 'solid', borderTopWidth: 1 }}>
              <View style={{ ...styles.tableCol, width: '20%', padding: 0 }}><Text style={styles.tableCellHeader}>SHOP TOTAL</Text></View>
                            
              <View style={{ ...styles.tableCol, width: '12%' }}><View style={{ ...styles.tableRow,fontSize:'11px' }} >
                <View style={{ ...styles.tableCol, width: '50%' ,textAlign: 'right'}}><Text style={styles.tableCell}>{data.groupedData[group].totals.OpQuantity.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}</Text></View>
                <View style={{ ...styles.tableCol, width: '50%' ,textAlign: 'right'}}><Text style={styles.tableCell}></Text></View>
                <View style={{ ...styles.tableCol, width: '50%' ,textAlign: 'right'}}><Text style={styles.tableCell}>{data.groupedData[group].totals.OpAmount.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}</Text></View>
                </View></View>
                
                <View style={{ ...styles.tableCol, width: '12%' }}><View style={{ ...styles.tableRow,fontSize:'11px' }} >
                <View style={{ ...styles.tableCol, width: '50%' ,textAlign: 'right'}}><Text style={styles.tableCell}>{data.groupedData[group].totals.PurQuantity.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}</Text></View>
                <View style={{ ...styles.tableCol, width: '50%' ,textAlign: 'right'}}><Text style={styles.tableCell}></Text></View>
                <View style={{ ...styles.tableCol, width: '50%' ,textAlign: 'right'}}><Text style={styles.tableCell}>{data.groupedData[group].totals.PurAmount.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}</Text></View>
                </View></View>

                <View style={{ ...styles.tableCol, width: '10%' }}><View style={{ ...styles.tableRow,fontSize:'11px' }} >
                <View style={{ ...styles.tableCol, width: '100%' ,textAlign: 'right'}}><Text style={styles.tableCell}>{data.groupedData[group].totals.TransQuantity.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}</Text></View>
                </View></View>
                
                <View style={{ ...styles.tableCol, width: '12%' }}><View style={{ ...styles.tableRow,fontSize:'11px' }} >
                <View style={{ ...styles.tableCol, width: '33%' ,textAlign: 'right'}}><Text style={styles.tableCell}>{data.groupedData[group].totals.AvailableQuantity.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}</Text></View>
                <View style={{ ...styles.tableCol, width: '33%' ,textAlign: 'right'}}><Text style={styles.tableCell}></Text></View>
                <View style={{ ...styles.tableCol, width: '33%' ,textAlign: 'right'}}><Text style={styles.tableCell}>{data.groupedData[group].totals.AvailableAmount.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}</Text></View>
                </View></View>
               
                <View style={{ ...styles.tableCol, width: '12%' }}><View style={{ ...styles.tableRow,fontSize:'11px' }} >
                <View style={{ ...styles.tableCol, width: '33%' ,textAlign: 'right'}}><Text style={styles.tableCell}>{data.groupedData[group].totals.ClosingQuantity.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}</Text></View>
                <View style={{ ...styles.tableCol, width: '33%' ,textAlign: 'right'}}><Text style={styles.tableCell}></Text></View>
                <View style={{ ...styles.tableCol, width: '33%' ,textAlign: 'right'}}><Text style={styles.tableCell}>{data.groupedData[group].totals.ClosingAmount.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}</Text></View>
                </View></View>

                <View style={{ ...styles.tableCol, width: '12%' }}><View style={{ ...styles.tableRow,fontSize:'11px' }} >
                <View style={{ ...styles.tableCol, width: '33%' ,textAlign: 'right'}}><Text style={styles.tableCell}>{data.groupedData[group].totals.SaleQuantity.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}</Text></View>
                <View style={{ ...styles.tableCol, width: '33%' ,textAlign: 'right'}}><Text style={styles.tableCell}></Text></View>
                <View style={{ ...styles.tableCol, width: '33%' ,textAlign: 'right'}}><Text style={styles.tableCell}>{data.groupedData[group].totals.SaleAmount.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}</Text></View>
                </View></View>
                
                <View style={{ ...styles.tableCol, width: '10%' }}><View style={{ ...styles.tableRow,fontSize:'11px' }} >
                {/* <View style={{ ...styles.tableCol, width: '50%' ,textAlign: 'right'}}><Text style={styles.tableCell}>{data.groupedData[group].totals.SaleRateDiff.toLocaleString('en-US', { minimumFractionDigits:2, maximumFractionDigits: 2 })}</Text></View> */}
                <View style={{ ...styles.tableCol, width: '100%' ,textAlign: 'right'}}><Text style={styles.tableCell}>{data.groupedData[group].totals.ProfitLossAmount.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}</Text></View>
                </View></View>
            </View>
          </View>
        )})} 

    <View style={{ ...styles.tableRow, borderBottom: 'solid', borderBottomWidth: 1 ,borderTop: 'solid', borderTopWidth: 1 }}> 
              <View style={{ ...styles.tableCol, width: '20%', padding: 0 }}><Text style={styles.tableCellHeader}>REPORT TOTAL</Text></View>
              
              <View style={{ ...styles.tableCol, width: '12%' }}><View style={{ ...styles.tableRow,fontSize:'11px' }} >
                <View style={{ ...styles.tableCol, width: '50%' ,textAlign: 'right'}}><Text style={styles.tableCell}>{data.finalTotals.OpQuantity.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}</Text></View>
                <View style={{ ...styles.tableCol, width: '50%' ,textAlign: 'right'}}><Text style={styles.tableCell}></Text></View>
                <View style={{ ...styles.tableCol, width: '50%' ,textAlign: 'right'}}><Text style={styles.tableCell}>{data.finalTotals.OpAmount.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}</Text></View>
                </View></View>
                
                <View style={{ ...styles.tableCol, width: '12%' }}><View style={{ ...styles.tableRow,fontSize:'11px' }} >
                <View style={{ ...styles.tableCol, width: '50%' ,textAlign: 'right'}}><Text style={styles.tableCell}>{data.finalTotals.PurQuantity.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}</Text></View>
                <View style={{ ...styles.tableCol, width: '50%' ,textAlign: 'right'}}><Text style={styles.tableCell}></Text></View>
                <View style={{ ...styles.tableCol, width: '50%' ,textAlign: 'right'}}><Text style={styles.tableCell}>{data.finalTotals.PurAmount.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}</Text></View>
                </View></View>

                <View style={{ ...styles.tableCol, width: '10%' }}><View style={{ ...styles.tableRow,fontSize:'11px' }} >
                <View style={{ ...styles.tableCol, width: '100%' ,textAlign: 'right'}}><Text style={styles.tableCell}>{data.finalTotals.TransQuantity.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}</Text></View>
                </View></View>
                
                <View style={{ ...styles.tableCol, width: '12%' }}><View style={{ ...styles.tableRow,fontSize:'11px' }} >
                <View style={{ ...styles.tableCol, width: '33%' ,textAlign: 'right'}}><Text style={styles.tableCell}>{data.finalTotals.AvailableQuantity.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}</Text></View>
                <View style={{ ...styles.tableCol, width: '33%' ,textAlign: 'right'}}><Text style={styles.tableCell}></Text></View>
                <View style={{ ...styles.tableCol, width: '33%' ,textAlign: 'right'}}><Text style={styles.tableCell}>{data.finalTotals.AvailableAmount.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}</Text></View>
                </View></View>
               
                <View style={{ ...styles.tableCol, width: '12%' }}><View style={{ ...styles.tableRow,fontSize:'11px' }} >
                <View style={{ ...styles.tableCol, width: '33%' ,textAlign: 'right'}}><Text style={styles.tableCell}>{data.finalTotals.ClosingQuantity.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}</Text></View>
                <View style={{ ...styles.tableCol, width: '33%' ,textAlign: 'right'}}><Text style={styles.tableCell}></Text></View>
                <View style={{ ...styles.tableCol, width: '33%' ,textAlign: 'right'}}><Text style={styles.tableCell}>{data.finalTotals.ClosingAmount.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}</Text></View>
                </View></View>

                <View style={{ ...styles.tableCol, width: '12%' }}><View style={{ ...styles.tableRow,fontSize:'11px' }} >
                <View style={{ ...styles.tableCol, width: '33%' ,textAlign: 'right'}}><Text style={styles.tableCell}>{data.finalTotals.SaleQuantity.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}</Text></View>
                <View style={{ ...styles.tableCol, width: '33%' ,textAlign: 'right'}}><Text style={styles.tableCell}></Text></View>
                <View style={{ ...styles.tableCol, width: '33%' ,textAlign: 'right'}}><Text style={styles.tableCell}>{data.finalTotals.SaleAmount.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}</Text></View>
                </View></View>
                
                <View style={{ ...styles.tableCol, width: '10%' }}><View style={{ ...styles.tableRow,fontSize:'11px' }} >
                {/* <View style={{ ...styles.tableCol, width: '50%' ,textAlign: 'right'}}><Text style={styles.tableCell}>{data.finalTotals.SaleRateDiff.toLocaleString('en-US', { minimumFractionDigits:2, maximumFractionDigits: 2 })}</Text></View> */}
                <View style={{ ...styles.tableCol, width: '100%' ,textAlign: 'right'}}><Text style={styles.tableCell}>{data.finalTotals.ProfitLossAmount.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}</Text></View>
                </View></View>
              {/* <View style={{ ...styles.tableCol, textAlign: 'right' }}><Text style={styles.tableCell}>{data.finalTotals.TotalStockQty}</Text></View> */}
            </View>
        </View>
    </Page>
  </Document>
)});

const StockReportWithValue = (props) => {
  // const [dataSource, setDataSource] = useState(undefined);
  
  // useEffect(() => {
  //   if (document.getElementById('pushmenu-bar')) {
  //     document.getElementById('pushmenu-bar').click();
  //   }
  //   reportDataSource();
  // }, []);

  
  
  // const groupProductsByBrand = (products) => {
  //   const grouped = products.reduce((acc, product) => {
  //     const { BrandID, TotalStockQty, ...shopBalances } = product;
  //     if (!acc[BrandID]) {
  //       acc[BrandID] = {
  //         BrandID,
  //         products: [],
  //         totals: {
  //           TotalStockQty: 0,
  //           ...Object.keys(shopBalances).reduce((totalsAcc, key) => {
  //             if (key.startsWith('Shop') && key.endsWith('BalQty')) {
  //               totalsAcc[key] = 0;
  //             }
  //             return totalsAcc;
  //           }, {})
  //         }
  //       };
  //     }

  //     acc[BrandID].products.push(product);
  //     acc[BrandID].totals.TotalStockQty += TotalStockQty;

  //     Object.keys(shopBalances).forEach(key => {
  //       if (key.startsWith('Shop') && key.endsWith('BalQty')) {
  //         acc[BrandID].totals[key] += shopBalances[key];
  //       }
  //     });

  //     return acc;
  //   }, {});

  //   return Object.values(grouped);
  // };

  // function groupAndCalculateTotals(data) {
  //   const groupedData = {};
  //   const finalTotals = {
  //     OpQty: 0,
  //     RecQty: 0,
  //     IssQty: 0,
  //     TransferQty: 0,
  //     BalQty: 0,
  //     // RecAmt: 0,
  //     // TotalQty: 0,
  //     // ShowRec: 0,
  //     ValRate: 0,
  //     ValAmount: 0
  //   };
  
  //   // Group by Shop and then by Brand
  //   data.forEach(item => {
  //     const { ShopName, BrandName } = item;
  
  //     if (!groupedData[ShopName]) {
  //       groupedData[ShopName] = { brands: {}, totals: { OpQty: 0, RecQty: 0, IssQty: 0,  TransferQty: 0, BalQty: 0,  ValRate: 0, ValAmount: 0 } };
  //     }
  
  //     if (!groupedData[ShopName].brands[BrandName]) {
  //       groupedData[ShopName].brands[BrandName] = { items: [], totals: {OpQty: 0, RecQty: 0, IssQty: 0,  TransferQty: 0, BalQty: 0,  ValRate: 0, ValAmount: 0 } };
  //     }
  
  //     groupedData[ShopName].brands[BrandName].items.push(item);
  
  //     // Calculate Brand-wise totals
  //     Object.keys(finalTotals).forEach(key => {
  //       groupedData[ShopName].brands[BrandName].totals[key] += item[key];
  //       groupedData[ShopName].totals[key] += item[key];
  //       finalTotals[key] += item[key];
  //     });
  //   });
  
  //   return { groupedData, finalTotals };
  // }
  
  // // Example usage:
  
  
  // const reportDataSource = async () => {
  //   const data = {
  //     ReportType: 'Stock',
  //     MainInvoiceID: props.match.params.MainInvoiceID,
  //     FromDate:  `${props.match.params.FromDate}`,
  //     ToDate:  `${props.match.params.ToDate}`,
  //     BrandID: props.match.params.BrandID,
  //     ProductID: props.match.params.ProductID,
  //     // ProductID: 326,
  //     ShopID: props.match.params.ShopID
  //   };
  //   const response = await invoiceService.getMultiDropDownSource(
  //     `${data.ReportType},${data.MainInvoiceID},${data.FromDate},${data.ToDate},${data.BrandID},${data.ProductID},${data.ShopID}`
  //   );
    
  // const result = groupAndCalculateTotals(response.data.recordset);
  
  // console.log(result);  // Grouped data by Shop and Brand
  // console.log(result.groupedData);  // Grouped data by Shop and Brand
  // console.log(result.finalTotals);  // Final overall totals
  
  //   setDataSource(result);

    
  // }
  return (
    // <div className='content-wrapper'>
    //   {dataSource === undefined ? (
    //     <h2>...Loading Report</h2>
    //   ) : (
    //     <PDFViewer width="100%" height="1000" fileName={props.match.params.Type == 'With' ? `Stock-Report-With-Value`:`Stock-Report-With-Out-Value`}>
    //       <Report data={dataSource} type={props.match.params.Type}/> 
    //     </PDFViewer>
    //   )}
    // </div>
    <>
          <Report data={props.DataSource} fromDate={props.FromDate} toDate={props.ToDate}/> 
     </>
  );
};

export default StockReportWithValue;
