import React ,{useEffect, useState} from 'react';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';

const Head = (props) => {
const reduxData = useSelector(state =>{return state.Head})
const [description, setDescription] = useState('Home');

  return (
    <Helmet>
      <title>{reduxData.Title} </title>
      <meta name="description" content={description} />
    </Helmet>
  );
};

export default Head;
