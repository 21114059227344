import React, { useEffect, useState } from 'react';
import Header from './app/layout/header';
import Menu from './app/layout/menu';
import Content from './app/layout/content';
import Footer from './app/layout/footer';
import './App.scss';
import './switcher.scss';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import ColorPicker from "./app/components/color-picker/color-picker";

// Security
import SignInSide from './app/components/security/login/signin';
import IdleTimerContainer from './app/components/idleTimerContainer';
import ProtectedRoute from '../src/app/components/security/protected.route';
import SecureComponent from '../src/app/components/security/secure.component'

import Head from './app/components/head/head';




import Reports from './app/components/reports/reports';
// import ExportSale from './app/components/reports/export-sale/export-sale';
import Voucher from './app/components/reports/voucher/voucher';
import InvoiceCosting from './app/components/reports/invoice-costing/invoice-costing';



function App() {
  console.log('App file Call')
  // const [ colorTheme, setColorTheme ] = useState('theme-white');

  // useEffect(() => {
  //   const currentThemeColor = localStorage.getItem('theme-color');
  //   if(currentThemeColor){
  //     setColorTheme(currentThemeColor);
  //   }
  // }, []);

  // const changeTheme =(theme) => {
  //   setColorTheme(theme);
  //   localStorage.setItem('theme-color', theme);
  // } 


  // irtiza test
  return (
    <div>
      {/* className={`App ${colorTheme}`} */}
      <div>
        <IdleTimerContainer
          extraTime={60 * 60 * 1000}
          standardTime={60 * 60 * 1000}
          // standardTime={30000}
        />
        
        <BrowserRouter>
          <Switch>
            <Route exact path='/' component={SignInSide} />
            <div className='content-box'>
              <ColorPicker />
              <Head />
              <Header />
              <Menu />
              <ProtectedRoute exact path='/home' component={Content} />

        
    {/* <ProtectedRoute exact  path='/Processing/IssueTransaction/Normal' component={SecureComponent} name='ProcessingFabricIssueNormalList'/>  */}
      
      <ProtectedRoute    exact  path='/CurrencyTransfer' component={SecureComponent} name='CurrencyTransferList'  />

      <ProtectedRoute    exact  path='/InvoiceEvaluation' component={SecureComponent}  name='InvoiceEvaluationList'/>

      <ProtectedRoute    exact  path='/Invoice/Sale/:listType' component={SecureComponent}  name='SaleInvoiceList'/>

      <ProtectedRoute    exact  path='/Invoice/Purchase/:listType' component={SecureComponent} name='PurchaseInvoiceList' />

      <ProtectedRoute    exact  path='/ShopTransfer/:listType' component={SecureComponent}  name='ShopTransferList' />

      <ProtectedRoute    exact  path='/Voucher/CR/:listType' component={SecureComponent} name='ReceiptVoucherList'  />
      
      <ProtectedRoute    exact  path='/Voucher/CP/:listType' component={SecureComponent} name='PaymentVoucherList'  />
      
      <ProtectedRoute    exact  path='/Voucher/J/:listType' component={SecureComponent} name='JournalVoucherList'  />
      
      <ProtectedRoute    exact  path='/setup/AccountTransfer' component={SecureComponent} name='AccountTransferList'  />

      <ProtectedRoute    exact  path='/setup/Brand' component={SecureComponent} name='BrandList'  />
      
      <ProtectedRoute    exact  path='/setup/BrandVendor' component={SecureComponent} name='BrandVendorList'  />
      
      <ProtectedRoute    exact  path='/setup/UnitOfMeasurement' component={SecureComponent} name='UnitOfMeasurementList'  />

      <ProtectedRoute    exact  path='/setup/Currency' component={SecureComponent} name='CurrencyList'  />

      <ProtectedRoute    exact  path='/setup/EvaluationRate' component={SecureComponent} name='EvaluationRateList'  />
      
      <ProtectedRoute    exact  path='/setup/Product' component={SecureComponent} name='ProductList'  />
      
      <ProtectedRoute    exact  path='/setup/ProductTransfer' component={SecureComponent} name='ProductTransferList'  />
      
      <ProtectedRoute    exact  path='/setup/Shop' component={SecureComponent} name='ShopList'  />
      
      <ProtectedRoute    exact  path='/setup/SystemLock' component={SecureComponent} name='SystemLockList'  />
      
      <ProtectedRoute    exact  path='/setup/Designation' component={SecureComponent} name='DesignationList'  />
      
      <ProtectedRoute    exact  path='/Users' component={SecureComponent} name='UsersList'  />
      
      <ProtectedRoute    exact  path='/ChartOfAccount' component={SecureComponent} name='ChartOfAccountList'  />
      
      <ProtectedRoute exact path="/Security/ChangePassword" component={SecureComponent} name={"ChangePasswordList"} />

      <ProtectedRoute exact path='/Security/ChangeAccess' component={SecureComponent} name={"ChangeAccessList"}/>
      
      <ProtectedRoute exact  path='/UserReplicate' component={SecureComponent} name='UserReplicateList' />
            
      
      {/* <ProtectedRoute    exact  path='/ExportSale' component={ExportSale}  /> */}
      <ProtectedRoute exact  path='/SendEmail' component={SecureComponent} name='SendEmailList' />

      {/* Report Section */}
      
      <ProtectedRoute    exact  path='/ExportToExcel' component={SecureComponent} name='ExportToExcelList'  />
      
      <ProtectedRoute    exact  path='/ChartOfAccount/Report' component={SecureComponent} name='ChartOfAccountReport'  />
      
      <ProtectedRoute    exact  path='/Test/Input' component={SecureComponent} name='TestInputList'  />


      <ProtectedRoute
  exact
  path='/ReportVoucherCP/:VoucherDate/:VoucherType/:MainVoucherID/:VoucherID/:AccountIDCashBank/:CurrencyID/:MainInvoiceID/:ShopID/:VoucherCategory/:AccountID/:VoucherDetailID'
  // component={Voucher}
  component={SecureComponent}
   name={"CashPaymentPreview"}
/>
      <ProtectedRoute
  exact
  path='/ReportVoucherCR/:VoucherDate/:VoucherType/:MainVoucherID/:VoucherID/:AccountIDCashBank/:CurrencyID/:MainInvoiceID/:ShopID/:VoucherCategory/:AccountID/:VoucherDetailID'
  // component={Voucher}
  component={SecureComponent}
   name={"CashReceiptPreview"}
/>
      <ProtectedRoute
  exact
  path='/ReportVoucherJ/:VoucherDate/:VoucherType/:MainVoucherID/:VoucherID/:AccountIDCashBank/:CurrencyID/:MainInvoiceID/:ShopID/:VoucherCategory/:AccountID/:VoucherDetailID'
  // component={Voucher}
  component={SecureComponent}
   name={"JournalVoucherPreview"}
/>
      <ProtectedRoute
  exact
  path='/ReportInventory/:MainInvoiceID/:FromDate/:ToDate/:BrandID/:ProductID/:ShopID'
  // component={Inventory}
 component={SecureComponent} name={"StockShopWise"}

/>
      <ProtectedRoute
  exact
  path='/StockReportWithValue/:MainInvoiceID/:FromDate/:ToDate/:BrandID/:ProductID/:ShopID/:Type'
  component={SecureComponent}
  name={'StockWithValue'}
/>
      <ProtectedRoute
  exact
  path='/StockReportWithOutValue/:MainInvoiceID/:FromDate/:ToDate/:BrandID/:ProductID/:ShopID/:Type'
  component={SecureComponent}
  name={'StockWithOutValue'}
/>
      <ProtectedRoute
  exact
  path='/ReportInvoiceCosting/:MainInvoiceID/:FromDate/:ToDate/:BrandID/:ProductID/:ShopID'
  component={SecureComponent}
  name={'InvoiceCosting'}
/>


<ProtectedRoute
exact 
path='/Reports'
component={Reports}
/>
      
      




















              
            </div>
          </Switch>
          <Footer />
        </BrowserRouter>
      </div>
    </div>

  );
}

export default App;
