import React, { useEffect, useState, useMemo } from 'react';
import moment from 'moment';
import DropdownService from '../../irtiza-dynamic-input/dropdown-api.service';
import invoiceService from '../../invoice/invoice.service';
import { Page, Text, View, Document, StyleSheet, PDFViewer } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  page: {
    padding: 20,
  },
  header: {
    textAlign: 'center',
    fontSize: 18,
    marginBottom: 20,
  },
  section: {
    marginBottom: 10,
  },
  table: {
    display: 'table',
    width: 'auto',
    borderStyle: 'solid',
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
    marginTop: '5px'
  },
  tableRow: {
    // margin: 'auto',
    flexDirection: 'row',
  },
  tableCol: {
    width: '25%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableCell: {
    margin: 5,
    fontSize: 10,
  },
  footer: {
    textAlign: 'center',
    fontSize: 12,
    marginTop: 50,
    display: 'flex',
    width: '100%',
    justifyContent: 'space-around',
    flexDirection: 'row',
  },
  boxes: {
    border: '1px solid #000',
    padding: '10px 20px',
    fontWeight: 'bold',
  },
  title: {
    fontSize: 16,
    fontStyle:'italic',
    marginBottom: 10,
    textAlign: 'center',
    fontWeight:'bold'

  }
});

const Report = React.memo(({data ,fromDate, toDate }) => {
  
console.log(data)
  
  

  return(

    
    <Document title={'Inventory With Profit Loss'}>
    {/* <Page style={styles.page} orientation={'landscape'} size={'A4'}> */}
    <Page style={styles.page} orientation={'potrait'} size={'A4'}>
    <Text style={styles.title}>HAP INTERNATIONAL LIMITED - MOZAMBIQUE</Text>
    <Text style={{...styles.title, marginBottom:'4px'}}>RECEIVABLE & PAYABLE POSITION</Text>

<View style={{ ...styles.tableRow }}>

      <View style={{ ...styles.tableCol, width: '10%' ,borderWidth: 0 }}>
        <Text style={{ ...styles.tableCell, fontWeight: 'bold' ,fontSize:12}}>From</Text>
      </View>
      <View style={{ ...styles.tableCol, width: '35%' ,borderWidth: 0 ,textAlign:'right'}}>
        <Text style={{...styles.tableCell ,  fontSize:12}}>{moment(fromDate).format('dddd, MMMM D, YYYY')}</Text>
      </View>
      <View style={{ ...styles.tableCol, width: '10%' ,borderWidth: 0 ,textAlign:'right'}}></View>
      <View style={{ ...styles.tableCol, width: '10%' ,borderWidth: 0 }}>
        <Text style={{ ...styles.tableCell , fontSize:12, fontWeight: 'bold' }}>To</Text>
      </View>
      <View style={{ ...styles.tableCol, width: '35%', borderWidth: 0 ,textAlign:'right'}}>
        <Text style={{...styles.tableCell , fontSize:12}}>{moment(toDate).format('dddd, MMMM D, YYYY')}</Text>
      </View>
      </View>
      
      <View style={styles.table}>
        <View style={{ ...styles.tableRow,fontSize:'10px' }} fixed>

        <View style={{ ...styles.tableCol ,width: '24%' }}><Text style={{...styles.tableCell , textAlign:'center',marginVertical:'auto'}}>Description</Text></View>
        <View style={{ ...styles.tableCol ,width: '10%' }}><Text style={{...styles.tableCell , textAlign:'center',marginVertical:'auto' }}>Currency</Text></View>
        <View style={{...styles.tableCol, width: '22%' }}>
          <Text style={{...styles.tableCell , textAlign:'center' }}>Opening Balance  
          </Text>
          <View style={{ ...styles.tableRow,fontSize:'10px' }} fixed>
          <View style={{...styles.tableCol, width: '50%' , borderBottomWidth:0, borderTopWidth:1}}><Text style={{...styles.tableCell , textAlign:'center' }}>Debit</Text></View>
          <View style={{...styles.tableCol, width: '50%' ,borderBottomWidth:0, borderRightWidth:0, borderTopWidth:1}}><Text style={{...styles.tableCell , textAlign:'center' }}>Credit</Text></View>
          </View>
          </View>
        <View style={{...styles.tableCol, width: '22%' }}>
          <Text style={{...styles.tableCell , textAlign:'center' }}>Transactions
          </Text>
          <View style={{ ...styles.tableRow,fontSize:'10px' }} fixed>
          <View style={{...styles.tableCol, width: '50%' ,borderBottomWidth:0, borderTopWidth:1 }}><Text style={{...styles.tableCell , textAlign:'center' }}>Debit</Text></View>
          <View style={{...styles.tableCol, width: '50%',borderBottomWidth:0, borderRightWidth:0, borderTopWidth:1 }}><Text style={{...styles.tableCell , textAlign:'center' }}>Credit</Text></View>
          </View>
          </View>
        <View style={{...styles.tableCol, width: '22%' }}>
          <Text style={{...styles.tableCell , textAlign:'center' }}>Ending Balance  
          </Text>
          <View style={{ ...styles.tableRow,fontSize:'10px' }} fixed>
          <View style={{...styles.tableCol, width: '50%',borderBottomWidth:0, borderTopWidth:1 }}><Text style={{...styles.tableCell , textAlign:'center' }}>Debit</Text></View>
          <View style={{...styles.tableCol, width: '50%' ,borderBottomWidth:0, borderRightWidth:0, borderTopWidth:1 }}><Text style={{...styles.tableCell , textAlign:'center' }}>Credit</Text></View>
          </View>
          </View>
        
          </View>
          </View>



          {Object.keys(data.groupedData).map((group, groupKey) =>{
            console.log(data.groupedData)
            console.log(group)
            return(<>
            <View style={{ width: '100%', padding: '5px', textAlign: 'left',fontSize:'15px' , fontWeight:'bold', }}>
              <Text>{group}</Text>
            </View>
             {data.groupedData[group].items.map((product,key) => (
              <View style={{...styles.tableRow ,borderBottom:'dashed',borderBottomWidth:1 }} key={key}>
                <View style={{ ...styles.tableCol,border:'none', width: '24%' }}><Text style={styles.tableCell}>{product.Level4AccountTitle}</Text></View>
                <View style={{ ...styles.tableCol,border:'none', width: '10%' }}><Text style={styles.tableCell}>{product.CurrencyName}</Text></View>

                <View style={{ ...styles.tableCol,border:'none', width: '22%' }}><View style={{ ...styles.tableRow,fontSize:'10px' }} >
                <View style={{ ...styles.tableCol,border:'none', width: '50%' ,textAlign: 'right'}}><Text style={styles.tableCell}>{product.OpeningAmountDebit.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}</Text></View>
                <View style={{ ...styles.tableCol,border:'none', width: '50%' ,textAlign: 'right'}}><Text style={styles.tableCell}>{product.OpeningAmountCredit.toLocaleString('en-US', { minimumFractionDigits:0, maximumFractionDigits: 0 })}</Text></View>
                </View></View>
             
                <View style={{ ...styles.tableCol,border:'none', width: '22%' }}><View style={{ ...styles.tableRow,fontSize:'10px' }} >
                <View style={{ ...styles.tableCol,border:'none', width: '50%' ,textAlign: 'right'}}><Text style={styles.tableCell}>{product.DurationAmountDebit.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}</Text></View>
                <View style={{ ...styles.tableCol,border:'none', width: '50%' ,textAlign: 'right'}}><Text style={styles.tableCell}>{product.DurationAmountCredit.toLocaleString('en-US', { minimumFractionDigits:0, maximumFractionDigits: 0 })}</Text></View>
                </View></View>

                <View style={{ ...styles.tableCol,border:'none', width: '22%' }}><View style={{ ...styles.tableRow,fontSize:'10px' }} >
                <View style={{ ...styles.tableCol,border:'none', width: '50%' ,textAlign: 'right'}}><Text style={styles.tableCell}>{product.ClosingAmountDebit.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}</Text></View>
                <View style={{ ...styles.tableCol,border:'none', width: '50%' ,textAlign: 'right'}}><Text style={styles.tableCell}>{product.ClosingAmountCredit.toLocaleString('en-US', { minimumFractionDigits:0, maximumFractionDigits: 0 })}</Text></View>
                </View></View>
                
                </View>
            ))}
            <View style={{ ...styles.tableRow,}}>
              <View style={{ ...styles.tableCol, border:'none', width: '34%', padding: 0  }}><Text style={{...styles.tableCell ,fontSize:'12px'}}> TOTAL</Text></View>
              
              <View style={{ ...styles.tableCol, border:'none', width: '22%' }}><View style={{ ...styles.tableRow,fontSize:'10px' }} >
                <View style={{ ...styles.tableCol, border:'none', width: '50%' ,textAlign: 'right'}}><Text style={{...styles.tableCell ,fontSize:'10px'}}>{data.groupedData[group].totals.OpeningAmountDebit.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}</Text></View>
                <View style={{ ...styles.tableCol, border:'none', width: '50%' ,textAlign: 'right'}}><Text style={{...styles.tableCell ,fontSize:'10px'}}>{data.groupedData[group].totals.OpeningAmountCredit.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}</Text></View>
                </View></View>
              
              <View style={{ ...styles.tableCol, border:'none', width: '22%' }}><View style={{ ...styles.tableRow,fontSize:'10px' }} >
                <View style={{ ...styles.tableCol, border:'none', width: '50%' ,textAlign: 'right'}}><Text style={{...styles.tableCell ,fontSize:'10px'}}>{data.groupedData[group].totals.DurationAmountDebit.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}</Text></View>
                <View style={{ ...styles.tableCol, border:'none', width: '50%' ,textAlign: 'right'}}><Text style={{...styles.tableCell ,fontSize:'10px'}}>{data.groupedData[group].totals.DurationAmountCredit.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}</Text></View>
                </View></View>
              
              <View style={{ ...styles.tableCol, border:'none', width: '22%' }}><View style={{ ...styles.tableRow,fontSize:'10px' }} >
                <View style={{ ...styles.tableCol, border:'none', width: '50%' ,textAlign: 'right'}}><Text style={{...styles.tableCell ,fontSize:'10px'}}>{data.groupedData[group].totals.ClosingAmountDebit.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}</Text></View>
                <View style={{ ...styles.tableCol, border:'none', width: '50%' ,textAlign: 'right'}}><Text style={{...styles.tableCell ,fontSize:'10px'}}>{data.groupedData[group].totals.ClosingAmountCredit.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}</Text></View>
                </View></View>
                
              
            </View>
          </>
         )})}





        

        </Page>
        </Document>
)});

const ReceivablePayable = (props) => {
  
  return (
    <>
          <Report data={props.DataSource} fromDate={props.FromDate} toDate={props.ToDate}/> 
     </>
  );
};

export default ReceivablePayable;
