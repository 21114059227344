
import React , {useState , useEffect , useRef} from 'react';
import AccountTransferService from './account-transfer.service';
import { TextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { AutoCompleteComponent } from '@syncfusion/ej2-react-dropdowns';
import './account-transfer.scss';
const AccountTransfer = (props) => {
    let fromaccountsRef = useRef(null)
    let toaccountsRef = useRef(null)
    const [loadingAccount, setLoadingAccount] = useState(true);
    const [accounts, setAccounts] = useState([]);
    const [formAccountValue, setFromAccountValue] = useState('');
    const [fromAccountID, setFromAccountID] = useState(undefined);
    const [toAccountValue, setToAccountValue] = useState('');
    const [toAccountID, setToAccountID] = useState(undefined);

    const ddlFields: object = { value: 'value' };

    useEffect(() => {
        getAccounts();

    }, []);
    
    useEffect(() => {
        if(fromAccountID == toAccountID && toAccountID != undefined &&  fromAccountID != undefined ){
            document.getElementById('same-user-id').style.display = 'block'
            setTimeout(()=>{
                document.getElementById('same-user-id').style.display = 'none'
            },1500)
        }

    }, [fromAccountID , toAccountID]);
    const getAccounts = async (type: string) => {
        setLoadingAccount(true);
        AccountTransferService.getForList()
          .then((response) => {
            console.log('Accounts get response', response.data.recordset);
            setAccounts(response.data.recordset);
            setLoadingAccount(false);
          })
          .catch((e) => {
            console.log(e);
          });
      };

    const fromAccountsChange = (e)=>{
        
        console.log(e)
        setFromAccountValue(e?.itemData?.value ? e?.itemData?.value : '');
        setFromAccountID(e?.itemData?.id ? e?.itemData?.id : undefined);
    if (e?.e?.srcElement?.id) {
      console.log('from account change: ', e?.itemData?.id);
      setFromAccountID(e?.itemData?.id);
    }
    }
    const toAccountsChange = (e)=>{
        
        console.log(e)
        setToAccountValue(e?.itemData?.value ? e?.itemData?.value : '');
        setToAccountID(e?.itemData?.id ? e?.itemData?.id : undefined);
    if (e?.e?.srcElement?.id) {
      console.log('from account change: ', e?.itemData?.id);
      setToAccountID(e?.itemData?.id);
    }
    }

    const formSubmit = (e) =>{
        e.preventDefault()
        if(fromAccountID == toAccountID && toAccountID != undefined &&  fromAccountID != undefined ){
            document.getElementById('same-user-id').style.display = 'block'
            setTimeout(()=>{
                document.getElementById('same-user-id').style.display = 'none'
            },1500)
            setToAccountID(undefined)
            setToAccountValue('')
            toaccountsRef.current.element.focus()
        }else{
            console.log(fromAccountID)
            console.log(toAccountID)
            let data = {
              Fromid:fromAccountID,
              Toid:toAccountID
            }
            AccountTransferService.accountTransfer(data).then((res)=>{
              console.log(res)
              setToAccountID(undefined)
            setToAccountValue('')
              setFromAccountID(undefined)
            setFromAccountValue('')
            document.getElementById('user-replicate').style.display = 'block'
            setTimeout(()=>{
                document.getElementById('user-replicate').style.display = 'none'
            },1500)
            }).catch(()=>{

            })

        }
        console.log(e)
    }

    return (
        (props.isDialog) ? (
        
        <div>
            
        </div>
        ) : 
        (
            <div className="content-wrapper">
                  <div>
                    <h3> Account - Transfer</h3>
                    <form onSubmit={formSubmit}>

                    
                    <div className='user-replicate'>
                  <div className='first-dropdown'>

                    <mat-form-field>
                      {loadingAccount ? (
                        <div>... Loading  From Accounts </div>
                      ) : (
                        <div>
                          <AutoCompleteComponent
                            id='FromAccount'
                            dataSource={accounts}
                            placeholder='Select  From Account'
                            fields={ddlFields}
                            floatLabelType='Auto'
                            highlight={true}
                            select={fromAccountsChange}
                            ref={fromaccountsRef}
                            focus={()=>{
                              if (fromaccountsRef ) {fromaccountsRef.current.showPopup();}
                            }}
                            change={fromAccountsChange}
                            value={formAccountValue}
                              blur={()=>{
                                console.log('dfdfblur',fromAccountID)
                                if (fromAccountID  == undefined  || fromaccountsRef.current.value == null || fromaccountsRef.current.value == '' || fromAccountID == undefined || fromAccountID == null) {
                                 if(formAccountValue != ''){
                                   let resstr = accounts.some(e=>e.value ==  formAccountValue);
                                   console.log(resstr)
                                   if(resstr === false){
                                    //  setAccountGroupErorr(`${designationValue} Value Not Find ` )
                                     document.getElementById("FromAccount").parentElement.parentElement.firstChild.style.border ='3px solid red'
                                     setTimeout(() => {
                                      //  setAccountItemTypeError('')
                                       document.getElementById("FromAccount").parentElement.parentElement.firstChild.style.border ='1px solid #c8c8c8'

                                     }, 1000);
                                   }
                                 }
                                //  if (addNewClicked == false){
                                //   fromaccountsRef.current.element.focus()

                                //  }
                               }
                             }}
                          />

                          <TextBoxComponent
                            className='e-input'
                            type='hidden'
                            name='FromAccountID'
                            floatLabelType='Auto'
                            value={fromAccountID}
                          />
                          </div>
                      )}
                    </mat-form-field>
                        </div>
                  <div className='second-dropdown'>

                    <mat-form-field>
                      {loadingAccount ? (
                        <div>... Loading  To Accounts </div>
                      ) : (
                        <div>
                          <AutoCompleteComponent
                            id='ToAccount'
                            dataSource={accounts}
                            placeholder='Select To Account'
                            fields={ddlFields}
                            floatLabelType='Auto'
                            highlight={true}
                            select={toAccountsChange}
                            ref={toaccountsRef}
                            focus={()=>{
                              if (toaccountsRef ) {toaccountsRef.current.showPopup();}
                            }}
                            change={toAccountsChange}
                            value={toAccountValue}
                              blur={()=>{
                                console.log('dfdfblur',toAccountID)
                                if (toAccountID  == undefined  || fromaccountsRef.current.value == null || fromaccountsRef.current.value == '' || toAccountID == undefined || toAccountID == null) {
                                 if(toAccountValue != ''){
                                   let resstr = accounts.some(e=>e.value ==  toAccountValue);
                                   console.log(resstr)
                                   if(resstr === false){
                                    //  setAccountGroupErorr(`${designationValue} Value Not Find ` )
                                     document.getElementById("ToAccount").parentElement.parentElement.firstChild.style.border ='3px solid red'
                                     setTimeout(() => {
                                      //  setAccountItemTypeError('')
                                       document.getElementById("ToAccount").parentElement.parentElement.firstChild.style.border ='1px solid #c8c8c8'

                                     }, 1000);
                                   }
                                 }
                                //  if (addNewClicked == false){
                                //   fromaccountsRef.current.element.focus()

                                //  }
                               }
                             }}
                          />

                          <TextBoxComponent
                            className='e-input'
                            type='hidden'
                            name='ToAccountID'
                            floatLabelType='Auto'
                            value={toAccountID}
                          />
                          </div>
                      )}
                    </mat-form-field>
                        </div>
                        <div className='div-btn'>
                        <button 
                        type='submit'
                        id='get-data-btn'
                        className='user-replicate-btn' 
                        disabled={
                            toAccountID == undefined || fromAccountID == undefined ? true : false
                        }
                        > Account Transfer</button>
                        <p id='same-user-id'> Cannot Transfer Same Account!</p>
                        <p id='user-replicate'> Account Transfer SuccessFully!</p>
                        </div>
                      </div>
                      </form>
                 </div>
            </div>
        )
    );
}

export default AccountTransfer;
