import React from 'react';
import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  table: {
    display: 'flex',
    flexDirection: 'column',
    width: '50%',
    border:'solid',
    borderWidth:1

  },
  tableRow: {
    // display: 'flex',
    // flexDirection: 'row',
    flexDirection: 'row',
    marginBottom: 2,
  },
  column70: {
    width: '70%',
    backgroundColor: '#f0f0f0',
    padding: 5,
  },
  header: {
    textAlign: 'center',
    fontSize: 18,
    marginBottom: 5,
    fontWeight:'bold'
  },
  column30: {
    width: '30%',
    backgroundColor: '#d0d0d0',
    padding: 5,
  },
  tableCell: {
    margin: 3,
    fontSize: 10,
  },
  tableCol: {
    width: '25%',
    // borderStyle: 'solid',
    // borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
});

const Report = ({data1 ,data2 ,fromDate}) => {
  console.log(data1)
  console.log(data2)
  
return(
  <Document title='Balance Sheet'>
    <Page size="A4" style={{ padding: 20 }}>
    <Text style={{...styles.header, fontSize:16}}>HAP INTERNATIONAL LIMITED - MOZAMBIQUE</Text>
          <Text style={{...styles.header, fontSize:17}}>BALANCE SHEET</Text>
          <Text style={{...styles.header, fontSize:12}}>{`upto ${fromDate}`}</Text>

      <View style={{ flexDirection: 'row' ,width:'auto',gap:5}}>
      <View style={styles.table}>
      {Object.keys(data1.groupedData).map((Title, TitleKey) => {
    // Calculate group total

                return (

                    <View key={TitleKey}>
                      <Text style={{fontSize:'13px' ,fontWeight:'bold',paddingBottom:5}}>{Title}</Text>
                        {data1.groupedData[Title].items.map((item, index) => {
                            let Sno = index + 1;

                            // Calculate totals for the section and group
                          

                            return (
                                <View style={styles.tableRow} key={index} >
                                    <View style={{ ...styles.tableCol, width: '70%'  }}>
                                        <Text style={styles.tableCell}>{item.Level4AccountTitle}</Text>
                                    </View>
                                        <View style={{ ...styles.tableCol, width: '30%', textAlign: 'right' }}>
                                            <Text style={styles.tableCell}>{item.Amount.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}</Text>
                                        </View>
                                    
                                </View>
                            );
                        })}

                        {/* Section footer */}
                        <View style={styles.tableRow}>
                            <View style={{ ...styles.tableCol, width: '70%' }}>
                                <Text style={{ ...styles.tableCell, fontWeight: 'bold' }}></Text>
                            </View>
                            <View style={{ ...styles.tableCol, width: '30%', textAlign: 'right', borderTopStyle:'solid',borderTopWidth:1 ,paddingBottom:10}}>
                                <Text style={{ ...styles.tableCell, fontWeight: 'bold',fontSize:12  ,fontStyle:'italic'}}>
                               {  data1.groupedData[Title].totals.Amount.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}
                               
                                </Text>
                            </View>
                        </View>
                    </View>
                );
            })}

          

  
      </View>

        <View style={styles.table}>
        {Object.keys(data2.groupedData).map((Title, TitleKey) => {
    // Calculate group total

                return (

                    <View key={TitleKey}>
                      <Text style={{fontSize:'13px' ,fontWeight:'bold',paddingBottom:5}}>{Title}</Text>
                      {data2.groupedData[Title].items.map((item, index) => {
                            let Sno = index + 1;

                            // Calculate totals for the section and group
                          

                            return (
                                <View style={styles.tableRow} key={index} >
                                    <View style={{ ...styles.tableCol, width: '70%'  }}>
                                        <Text style={styles.tableCell}>{item.Level4AccountTitle}</Text>
                                    </View>
                                        <View style={{ ...styles.tableCol, width: '30%', textAlign: 'right' }}>
                                            <Text style={styles.tableCell}>{item.Amount.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}</Text>
                                        </View>
                                    
                                </View>
                            );
                        })}

                        {/* Section footer */}
                        <View style={styles.tableRow}>
                            <View style={{ ...styles.tableCol, width: '70%' }}>
                                <Text style={{ ...styles.tableCell, fontWeight: 'bold' }}></Text>
                            </View>
                            <View style={{ ...styles.tableCol, width: '30%', textAlign: 'right', borderTopStyle:'solid',borderTopWidth:1 ,paddingBottom:10}}>
                                <Text style={{ ...styles.tableCell, fontWeight: 'bold',fontSize:12  ,fontStyle:'italic'}}>
                               {  data2.groupedData[Title].totals.Amount.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}
                               
                                </Text>
                            </View>
                        </View>
                    </View>
                );
            })}
           
        {/* Repeat the above row as needed */}
      </View>
      </View>
      <View style={styles.tableRow}>
                            
                            <View style={{ ...styles.tableCol ,border:'none', width: '50%', textAlign: 'right', borderTopStyle:'solid',borderTopWidth:1 ,paddingBottom:10}}>
                                <Text style={{ ...styles.tableCell, fontWeight: 'bold',fontSize:12  ,fontStyle:'italic'}}>
                               {  data1.finalTotals.Amount.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}
                               
                                </Text>
                            </View>
                            <View style={{ ...styles.tableCol ,border:'none', width: '50%', textAlign: 'right', borderTopStyle:'solid',borderTopWidth:1 ,paddingBottom:10}}>
                                <Text style={{ ...styles.tableCell, fontWeight: 'bold',fontSize:12  ,fontStyle:'italic'}}>
                               {  data2.finalTotals.Amount.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}
                               
                                </Text>
                            </View>
                        </View>
    </Page> 
  </Document>
)
};



// Main component to render the PDF in a viewer and export to Excel
const BalanceSheet = (props) => {
  return (
    <>
      
      <Report data1={props.DataSource1}  data2={props.DataSource1} fromDate={props.FromDate} />
    </>
  );
};

export default BalanceSheet;
