import React, { useState, useEffect } from 'react';
import moment from 'moment';
import shopVoucherService from '../../voucher/voucher.service';
import { Page, Text, View, Document, StyleSheet, PDFViewer } from '@react-pdf/renderer';


// Create styles
const styles = StyleSheet.create({
  page: {
    padding: 30,
  },
  header: {
    textAlign: 'center',
    fontSize: 18,
    marginBottom: 5,
    fontWeight:'bold'
  },
  section: {
    marginBottom: 10,
  },
  table: {
    display: 'table',
    width: 'auto',
    // borderStyle: 'solid',
    // borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
    marginTop: '5px'
  },
  tableRow: {
    margin: 'auto',
    flexDirection: 'row',
  },
  tableCol: {
    width: '25%',
    // borderStyle: 'solid',
    // borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableCell: {
    margin: 5,
    fontSize: 10,
  },
  footer: {
    textAlign: 'center',
    fontSize: 12,
    marginTop: 50,
    display: 'flex',
    width: '100%',
    justifyContent: 'space-around',
    flexDirection: 'row',
  },
  boxes: {
    border: '1px solid #000',
    padding: '10px 20px',
    fontWeight: 'bold',
  },
});

// Calculate totals
const calculateTotals = (data) => {
  let totalDebit = 0;
  let totalCredit = 0;

  data.forEach(item => {
    totalDebit += parseFloat(item.Debit);
    totalCredit += parseFloat(item.Credit);
  });

  return {
    totalDebit: totalDebit.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
    totalCredit: totalCredit.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
  };
};

// Create Document Component
const Report = ({ data ,fromDate }) => {

  console.log(data)
  
  let GrossProfit =() =>{

    let sumValues = data.groupedData[1].sections[1].totals.Credit +  data.groupedData[1].sections[1].totals.Dr;
    let returnVal = sumValues - data.groupedData[1].totals.Dr 
      return returnVal
  }

  let upperValue = GrossProfit();
  let createNetTotal = upperValue - data.groupedData[2].sections[2].totals.Dr;
console.log(createNetTotal)
  let NetTotal =  createNetTotal.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 });
  
  return (
    // <PDFViewer width="100%" height="1000" fileName='Voucher-Report'>
      <Document title='Profit Loss Statement'>
        <Page style={styles.page}>
          <Text style={{...styles.header, fontSize:16}}>HAP INTERNATIONAL LIMITED - MOZAMBIQUE</Text>
          <Text style={{...styles.header, fontSize:17}}>PROFIT & LOSS ACCOUNT</Text>
          <Text style={{...styles.header, fontSize:12}}>{`upto ${fromDate}`}</Text>

          
          <View style={styles.table}>
            <View  style={{...styles.tableRow, borderBottomStyle:'solid', borderBottomWidth:1 , margin:5}} fixed>
              <View style={{ ...styles.tableCol, width: '70%' }}>
                <Text style={styles.tableCell}></Text>
              </View>
              <View style={{ ...styles.tableCol, width: '30%' }}>
                <Text style={{...styles.tableCell,textAlign:'center'}}>US$</Text>
              </View>
              </View>
              {Object.keys(data.groupedData).map((Group, GroupKey) => {
    // Calculate group total
    
    return (
        <View key={GroupKey} style={Group == 1 ? {marginBottom:'10px'} :  {borderTop:'solid', borderTopWidth:1 , marginBottom:'10px'}}>
            {Object.keys(data.groupedData[Group].sections).map((section, sectionKey) => {
            

                return (
                    <View key={sectionKey}>
                        {data.groupedData[Group].sections[section].items.map((item, index) => {
                            let Sno = index + 1;

                            // Calculate totals for the section and group
                          

                            return (
                                <View style={styles.tableRow} key={index} >
                                    <View style={{ ...styles.tableCol, width: '70%'  }}>
                                        <Text style={styles.tableCell}>{item.Level4AccountTitle}</Text>
                                    </View>
                                    {item.Group == 1 && item.Section == 1 ? (
                                        <View style={{ ...styles.tableCol, width: '30%', textAlign: 'right' }}>
                                            <Text style={styles.tableCell}>{item.Credit.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}</Text>
                                        </View>
                                    ) : (
                                        <View style={{ ...styles.tableCol, width: '30%', textAlign: 'right' }}>
                                            <Text style={styles.tableCell}>{item.Dr.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}</Text>
                                        </View>
                                    )}
                                </View>
                            );
                        })}

                        {/* Section footer */}
                        <View style={styles.tableRow}>
                            <View style={{ ...styles.tableCol, width: '70%' }}>
                                <Text style={{ ...styles.tableCell, fontWeight: 'bold' }}></Text>
                            </View>
                            <View style={{ ...styles.tableCol, width: '30%', textAlign: 'right', borderTopStyle:'solid',borderTopWidth:1 ,paddingBottom:10}}>
                                <Text style={{ ...styles.tableCell, fontWeight: 'bold',fontSize:12  ,fontStyle:'italic'}}>
                                {Group == 1 && section == 1 ? 

                                 data.groupedData[Group].sections[section].totals.Credit.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                 :
                                 data.groupedData[Group].sections[section].totals.Dr.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                }
                                </Text>
                            </View>
                        </View>
                    </View>
                );
            })}

            {/* Group footer */}
            <View style={styles.tableRow}>
                <View style={{ ...styles.tableCol, width: '70%' }}>
                    <Text style={{ ...styles.tableCell, fontWeight: 'bold',fontSize:12 ,fontStyle:'italic'}}>{Group == 1 ?`Gross Profit` :`Total`}</Text>
                </View>
                <View style={{ ...styles.tableCol, width: '30%', textAlign: 'right' , border:'solid', borderWidth:1 }}>
                    <Text style={{ ...styles.tableCell, fontWeight: 'bold',fontSize:12 ,fontStyle:'italic'}}>
                    {
                      
                    Group == 1 ?
                    GrossProfit().toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 })
                    :
                    data.groupedData[Group].totals.Dr.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 })
                  }
                    </Text>
                </View>
            </View>
        </View>
    );
})}


            <View style={styles.tableRow}>
              <View style={{ ...styles.tableCol, width: '70%' }}>
                <Text style={{ ...styles.tableCell, fontWeight: 'bold', borderRightWidth: 'transparent',fontSize:12 ,fontStyle:'italic' }}>{NetTotal > 0 ? `Net Loss`: `Net Profit`}</Text>
              </View>
              <View style={{ ...styles.tableCol, width: '30%', textAlign: 'right' }}>
                <Text style={{ ...styles.tableCell, fontWeight: 'bold',fontSize:12 ,fontStyle:'italic' }}>{NetTotal}</Text>
              </View>
             
            </View>
          </View>

        </Page>
      </Document>
    // </PDFViewer>
  );
};

// Main component to render the PDF in a viewer and export to Excel
const ProfitLossStatement = (props) => {
  return (
    <>
      
      <Report data={props.DataSource} fromDate={props.FromDate} />
    </>
  );
};

export default ProfitLossStatement;
