// import React, { useRef, useState , useEffect,useMemo} from 'react';
// import moment from 'moment';
// import {
//   StructuredGenerate,
//   DynamicDropDownInput,
//   DynamicNumericInput,
//   DynamicDateInput,
//   DynamicTextInput,
//   DynamicCheckBoxInput,
//   DynamicHiddenInput,
//   DynamicButtonInput
// } from '../irtiza-dynamic-input/irtiza-dynamic-main-inputs';

// const Reports = (props) => {

// let { DynamicDropDownInput: DropdownComponent, DynamicNumericInput: NumberComponent, DynamicDateInput: DateComponent , DynamicTextInput: TextComponent ,DynamicCheckBoxInput:CheckBoxComponent , DynamicHiddenInput : HiddenComponent , DynamicButtonInput:ButtonComponent} = { DynamicDropDownInput, DynamicNumericInput, DynamicDateInput ,DynamicTextInput , DynamicCheckBoxInput,DynamicHiddenInput,DynamicButtonInput};

// const [inputProps, setInputProps] = useState({
  
//     FromDate:{name: 'FromDate',   value:  moment(new Date()).format('DD-MMM-YY') },

//     ToDate:{name: 'ToDate',   value:  moment(new Date()).format('DD-MMM-YY') },
    
//     AccountTitle:{
//         name:{id:'AccountID',value:'AccountTitle'},
//         caption: 'Select Account Title',
//         dataSource: {type:'Route',route: 'ChartOfAccountVoucher'},
//         value: {id:  undefined ,value: ''},
//         //enabled:true
//         //   addNew:{Route:'sldf',Security:'df'},
//         isControlBlur:false
//         },
    
//         ProductName:{
//         name:{id:'ProductID',value:'ProductName'},
//         caption: 'Select Item',
//         dataSource: {type:'Route',route: 'Product'},
//         value: {id:  undefined ,value: ''},
//         //enabled:true
//         //   addNew:{Route:'sldf',Security:'df'},
//         isControlBlur:false
//         },
//         BrandName:{
//             name: {id:'BrandID',value:'BrandName'},
//             caption: 'Select Brand Name',
//             value: {id:  undefined ,value: ''},
//             dataSource: {type:'Route',route:'Brand'},
//             isControlBlur:false
//             },
//             Invoice:{
//             name: {id:'InvoiceID',value:'Invoice'},
//             caption: 'Select Invoice',
//             value: {id:  undefined ,value: ''},
//             dataSource: {type:'Route',route:'InvoiceRI'},
//             isControlBlur:false
//             },
//     ShopName:{
//       name:{id:'ShopID',value:'ShopName'},
//       caption: 'Select  Shop',
//       value: {id:undefined ,value: ''},
//       dataSource: {type:'Route',route:'Shop'},
//     //   enabled:false
//     isControlBlur:false
//         },
   
//       ToShopName:{
//       name:{id:'ToShopID',value:'ToShopName'},
//       caption: 'Select To Shop',
//       value: {id:  undefined ,value: ''},
//       dataSource: {type:'Route',route:'Shop'},
//       isControlBlur:false
//     },

// })


// const handleInputCreated = async (e, name) => {
//     if(name == 'FromDate'){
//         setTimeout(()=>document.getElementById('FromDate').focus(),1000)
//     }
//       console.log(`Input ${name} changed to ${e}`);
//     };

//   const handleInputFocus = async (e, name) => {
    
//       console.log(e);
//       console.log(`Input ${name} focus to ${e}`);
//     };

//   const handleInputChange = async (value, name) => {
    
//       console.log(value);
//       console.log(`Input ${name} focus to ${value}`);
//     };

//   const handleInputBlur = async  (value, name) => {
//       console.log(value);
//       console.log(`Input ${name} focus to ${value}`);
//     };
// let inputsList = [
//     {Input: DateComponent ,Props: {...inputProps.FromDate,onCreated:handleInputCreated ,setInputProps },ColumnSize:2},
    
//     {Input: DateComponent ,Props: {...inputProps.ToDate, setInputProps },ColumnSize:2},

//     { Input:  HiddenComponent,Props: {name:'spaceCreate',setInputProps },ColumnSize:8},

//     {Input: DropdownComponent,Props: {...inputProps.AccountTitle, setInputProps },},

//     {Input: DropdownComponent,Props: {...inputProps.ProductName, setInputProps },},
    
//     {Input: DropdownComponent,Props: {...inputProps.BrandName, setInputProps },},
    
//     {Input: DropdownComponent,Props: {...inputProps.Invoice, setInputProps },},
    
//     {Input: DropdownComponent,Props: {...inputProps.ShopName, setInputProps },},

//     {Input: DropdownComponent,Props: {...inputProps.ToShopName, setInputProps },},
    
//   ];


//   const [selectedReport, setSelectedReport] = useState('');

//   const handleRadioChange = (event) => {
//     console.log(event.target.value)
//       setSelectedReport(event.target.value);
//   };


//   const containerStyle = {
//     display: 'flex',
//     padding: '20px'
// };

// const columnStyle = {
//     marginRight: '20px'
// };

// const labelStyle = {
//     marginLeft: '8px',
//     textTransform:'upperCase',
//     cursor:'pointer'
// };
// const reportNames = [
//     "Costing Invoice", "Stock Shop Wise","Stock Report With Value","Stock Report With Out Value"
// ];






// const previewReport = (event) => {
//     console.log(selectedReport)
//     console.log(inputProps)
//     let url=''
//     let newTab=``
//     switch (selectedReport) {
//         case 'Costing Invoice':
//             var invoice =  inputProps.Invoice.value?.id || 0
//             var brandName =  inputProps.BrandName.value?.id || 0
//             var productName =  inputProps.ProductName.value?.id || 0
//             var shopName =  inputProps.ShopName.value?.id || 0
//             url   = `/ReportInvoiceCosting/${invoice}/${inputProps.FromDate.value}/${inputProps.ToDate.value}/${brandName}/${productName}/${shopName}`;
      
//         // Open the URL in a new tab and focus on it
//          newTab = window.open(url, '_blank');
//         newTab.focus()
//             break;
//         case 'Stock Shop Wise':
//             var invoice =  inputProps.Invoice.value?.id || 0
//             var brandName =  inputProps.BrandName.value?.id || 0
//             var productName =  inputProps.ProductName.value?.id || 0
//             var shopName =  inputProps.ShopName.value?.id || 0
//              url = `/ReportInventory/${invoice}/${inputProps.FromDate.value}/${inputProps.ToDate.value}/${brandName}/${productName}/${shopName}`;
      
//         // Open the URL in a new tab and focus on it
//          newTab = window.open(url, '_blank');
//         newTab.focus()
//             break;
//         case 'Stock Report With Value':
//             var invoice =  inputProps.Invoice.value?.id || 0
//             var brandName =  inputProps.BrandName.value?.id || 0
//             var productName =  inputProps.ProductName.value?.id || 0
//             var shopName =  inputProps.ShopName.value?.id || 0
//              url = `/StockReportWithValue/${invoice}/${inputProps.FromDate.value}/${inputProps.ToDate.value}/${brandName}/${productName}/${shopName}/With`;
      
//         // Open the URL in a new tab and focus on it
//          newTab = window.open(url, '_blank');
//         newTab.focus()
//             break;
//         case 'Stock Report With Out Value':
//             var invoice =  inputProps.Invoice.value?.id || 0
//             var brandName =  inputProps.BrandName.value?.id || 0
//             var productName =  inputProps.ProductName.value?.id || 0
//             var shopName =  inputProps.ShopName.value?.id || 0
//              url = `/StockReport/${invoice}/${inputProps.FromDate.value}/${inputProps.ToDate.value}/${brandName}/${productName}/${shopName}/WithOut`;
      
//         // Open the URL in a new tab and focus on it
//          newTab = window.open(url, '_blank');
//         newTab.focus()
//             break;
    
//         default:
//             break;
//     }
//     };
// return (
//     <>
//       <div onKeyDown={(e)=>e.stopPropagation()} className='content-wrapper'>
//       {/* <FirstGridComponent/> */}
//       <StructuredGenerate   mainProps={props} title={`Reports`} Inputs={inputsList}/>

//       <div style={containerStyle}>
//                 <div style={columnStyle}>
//                     {reportNames.slice(0, 5).map((report, index) => (
//                         <div key={index}>
//                             <input type="radio" id={`report${index + 1}`} name="report" value={report} onChange={handleRadioChange} />
//                             <label htmlFor={`report${index + 1}`} style={labelStyle}>{report}</label>
//                         </div>
//                     ))}
//                 </div>
//                 <div style={columnStyle}>
//                     {reportNames.slice(5).map((report, index) => (
//                         <div key={index + 5}>
//                             <input type="radio" id={`report${index + 6}`} name="report" value={report}  onChange={handleRadioChange} />
//                             <label htmlFor={`report${index + 6}`} style={labelStyle}>{report}</label>
//                         </div>
//                     ))}
//                 </div>
//             </div>
//             <button className='e-input e-control e-btn e-lib' style={{color:'black'}}onClick={previewReport}>
//                 Preview Report
//             </button>
//       </div>
//     </>
// )
// }
// export default Reports;


import React, { useRef, useState , useEffect,useMemo, Component} from 'react';
import { renderToStream ,pdf} from '@react-pdf/renderer';
import { saveAs } from 'file-saver'
import moment from 'moment';
import {
  StructuredGenerate,
  DynamicDropDownInput,
  DynamicNumericInput,
  DynamicDateInput,
  DynamicTextInput,
  DynamicCheckBoxInput,
  DynamicHiddenInput,
  DynamicButtonInput
} from '../irtiza-dynamic-input/irtiza-dynamic-main-inputs';
import InvoiceCosting from './invoice-costing/invoice-costing';
import invoiceService from '../invoice/invoice.service';
import Inventory from './inventory/inventory';
import InventoryWithProfitLoss from './Inventory-with-profit-loss/Inventory-with-profit-loss';
import StockReportWithValue from './stock-report-with-value/stock-report-with-value';
import DropdownService from './../irtiza-dynamic-input/dropdown-api.service'
import SecurityService from '../security/security.service';
import ReportService from './reports.service';
import CashBookUsdVoucher from './cash-book-usd-voucher/cash-book-usd-voucher';
import ChartOfAccountReport from './chart-of-account/chart-of-account';
import ReceivablePayableReport from './receivable-payable/receivable-payable';
import TBUSDReport from './tb-usd/tb-usd';
import SaleReport from './sale-report/sale-report';
import reportsService from './reports.service';
import ProfitLossStatementReport from './profit-loss-statement/profit-loss-statement';
import BalanceSheetReport from './balance-sheet/balance-sheet';


const Reports = (props) => {

let { DynamicDropDownInput: DropdownComponent, DynamicNumericInput: NumberComponent, DynamicDateInput: DateComponent , DynamicTextInput: TextComponent ,DynamicCheckBoxInput:CheckBoxComponent , DynamicHiddenInput : HiddenComponent , DynamicButtonInput:ButtonComponent} = { DynamicDropDownInput, DynamicNumericInput, DynamicDateInput ,DynamicTextInput , DynamicCheckBoxInput,DynamicHiddenInput,DynamicButtonInput};

const [inputProps, setInputProps] = useState({
  
    FromDate:{name: 'FromDate',   value:  moment(new Date()).format('DD-MMM-YY') },

    ToDate:{name: 'ToDate',   value:  moment(new Date()).format('DD-MMM-YY') },
    
    AccountTitle:{
        name:{id:'AccountID',value:'AccountTitle'},
        caption: 'Select Account Title',
        dataSource: {type:'Route',route: 'ChartOfAccountVoucher'},
        value: {id:  undefined ,value: ''},
        //enabled:true
        //   addNew:{Route:'sldf',Security:'df'},
        isControlBlur:false
        },
    
        ProductName:{
        name:{id:'ProductID',value:'ProductName'},
        caption: 'Select Item',
        dataSource: {type:'Route',route: 'Product'},
        value: {id:  undefined ,value: ''},
        //enabled:true
        //   addNew:{Route:'sldf',Security:'df'},
        isControlBlur:false
        },
        BrandName:{
            name: {id:'BrandID',value:'BrandName'},
            caption: 'Select Brand Name',
            value: {id:  undefined ,value: ''},
            dataSource: {type:'Route',route:'Brand'},
            isControlBlur:false
            },
            Invoice:{
            name: {id:'InvoiceID',value:'Invoice'},
            caption: 'Select Invoice',
            value: {id:  undefined ,value: ''},
            dataSource: {type:'Route',route:'InvoiceRI'},
            isControlBlur:false
            },
    ShopName:{
      name:{id:'ShopID',value:'ShopName'},
      caption: 'Select  Shop',
      value: {id:SecurityService.getLoggedInUserId() == 0 ? undefined : SecurityService.getLoggedInShopID() ,value: ''},
      dataSource: {type:'Route',route:'Shop'},
      enabled:SecurityService.getLoggedInUserId() == 0 ? true : false,
      isAdd:true,
    isControlBlur:false
        },
   
      ToShopName:{
      name:{id:'ToShopID',value:'ToShopName'},
      caption: 'Select To Shop',
      value: {id:  undefined ,value: ''},
      dataSource: {type:'Route',route:'Shop'},
      isControlBlur:false
    },

})


const handleInputCreated = async (e, name) => {
    if(name == 'FromDate'){
        setTimeout(()=>document.getElementById('FromDate').focus(),1000)
    }
      console.log(`Input ${name} changed to ${e}`);
    };

  const handleInputFocus = async (e, name) => {
    
      console.log(e);
      console.log(`Input ${name} focus to ${e}`);
    };

  const handleInputChange = async (value, name) => {
    
      console.log(value);
      console.log(`Input ${name} focus to ${value}`);
    };

  const handleInputBlur = async  (value, name) => {
      console.log(value);
      console.log(`Input ${name} focus to ${value}`);
    };
let inputsList = [
    {Input: DateComponent ,Props: {...inputProps.FromDate,onCreated:handleInputCreated ,setInputProps },ColumnSize:2},
    
    {Input: DateComponent ,Props: {...inputProps.ToDate, setInputProps },ColumnSize:2},

    { Input:  HiddenComponent,Props: {name:'spaceCreate',setInputProps },ColumnSize:8},

    {Input: DropdownComponent,Props: {...inputProps.AccountTitle, setInputProps },},

    {Input: DropdownComponent,Props: {...inputProps.ProductName, setInputProps },},
    
    {Input: DropdownComponent,Props: {...inputProps.BrandName, setInputProps },},
    
    {Input: DropdownComponent,Props: {...inputProps.Invoice, setInputProps },},
    
    {Input: DropdownComponent,Props: {...inputProps.ShopName, setInputProps },},

    {Input: DropdownComponent,Props: {...inputProps.ToShopName, setInputProps },},
    
  ];

  const [invoiceCostingData, setInvoiceCostingData] = useState(null);
  const [invoiceValueTotalData, setInvoiceValueTotalData] = useState(null);
  const [ detailData, setDetailData] = useState(null);

  const reportNames = [
    SecurityService.canUserAccess('ReportInvoiceCostingList') ? "InvoiceCosting" : undefined, 
    SecurityService.canUserAccess('ReportStockShopWiseList') ? "StockShopWise" : undefined, 
    SecurityService.canUserAccess('ReportStockWithValueList') ? "StockReportWithValue" : undefined, 
    SecurityService.canUserAccess('ReportStockWithoutValueList') ? "StockReport" : undefined, 
    SecurityService.canUserAccess('ReportInventoryWithPLList') ? "InventoryWithProfitLoss" : undefined, 
    SecurityService.canUserAccess('ReportCashBookUS$List') ? "CashBookUsd" : undefined, 
    SecurityService.canUserAccess('ReportCashBookLocalCurrencyList') ? "CashBookLocalCurrency" : undefined, 
    SecurityService.canUserAccess('ReportLedgerList') ? "Ledger" : undefined, 
    SecurityService.canUserAccess('ReportChartOfAccountList') ? "ChartOfAccount" : undefined, 
    SecurityService.canUserAccess('ReportReceivablePayableList') ? "ReceivablePayable" : undefined, 
    SecurityService.canUserAccess('ReportTrailBalanceUSDList') ? "TrailBALANCE" : undefined, 
    SecurityService.canUserAccess('ReportSaleReportList') ? "SaleReport" : "SaleReport", 
    SecurityService.canUserAccess('ReportProfitLossStatementList') ? "ProfitLossStatement" : "ProfitLossStatement", 
    SecurityService.canUserAccess('ReportBalanceSheetList') ? "BalanceSheet" : "BalanceSheet", 
  ];


  const [selectedReport, setSelectedReport] = useState('');
  const [generateReport, setGenerateReport] = useState(false);
  const [pdfBlob, setPdfBlob] = useState({});
  // const [loadingReport, setLoadingReport] = useState(false);

  useEffect(() => {
    setGenerateReport(false)
    // if(selectedReport != ''){
    // setSelectedReport('')
    // setPdfBlob({})
    // for (let index = 0; index < reportNames.length; index++) {
    //   console.log(`report${index}`)
    //   // document.getElementById(`report${index + 1}`).checked =false
    //   if(document.getElementById(`report${index + 1}`)){
    //     document.getElementById(`report${index + 1}`).checked =false
    //     }
    //   }
    // }

  },[inputProps])
  // useEffect(() => {
  //   setTimeout(()=>setGenerateReport(true),1000)
  // },[])
  // useEffect(() => {
    // setTimeout(()=>{
    //     if (document.getElementById('pushmenu-bar')) {
    //         document.getElementById('pushmenu-bar').click();
    //       }

    // },1000)
    
  //   if(generateReport == true && selectedReport != ''){
     
  //   }
  // }, [selectedReport]);
  const fetchData = async () => {
    const params = [
      { ReportType: 'InvoiceCosting', MainInvoiceID:inputProps.Invoice.value.id || 0, FromDate: `${inputProps.FromDate.value}`, ToDate: `${inputProps.ToDate.value}`, BrandID: inputProps.BrandName.value.id || 0, ProductID: inputProps.ProductName.value.id || 0 , ShopID: inputProps.ShopName.value.id || 0 },
      { ReportType: 'InvoiceValue_Total', MainInvoiceID:inputProps.Invoice.value.id || 0, FromDate: `${inputProps.FromDate.value}`, ToDate: `${inputProps.ToDate.value}`, BrandID: inputProps.BrandName.value.id || 0, ProductID: inputProps.ProductName.value.id || 0 , ShopID: inputProps.ShopName.value.id || 0 },
      { ReportType: 'Detail', MainInvoiceID:inputProps.Invoice.value.id || 0, FromDate: `${inputProps.FromDate.value}`, ToDate: `${inputProps.ToDate.value}`, BrandID: inputProps.BrandName.value.id || 0, ProductID: inputProps.ProductName.value.id || 0 , ShopID: inputProps.ShopName.value.id || 0}
      // 0/03-Aug-24/03-Aug-24/25/1/1
      // { ReportType: 'InvoiceCosting', MainInvoiceID: 0, FromDate: `03-Aug-24`, ToDate: `03-Aug-24`, BrandID: 52, ProductID: 1, ShopID: 1},
      // { ReportType: 'InvoiceValue_Total', MainInvoiceID: 0, FromDate: `03-Aug-24`, ToDate: `03-Aug-24`, BrandID: 52, ProductID: 1, ShopID: 1},
      // { ReportType: 'Detail', MainInvoiceID: 0, FromDate: `03-Aug-24`, ToDate: `03-Aug-24`, BrandID: 52, ProductID: 1, ShopID: 1 }
    ];

    try {
      setGenerateReport(true)
      document.getElementById('InvoiceCosting_label').style.color = 'black'

      const [costingResponse, valueTotalResponse, detailResponse] = await Promise.all(params.map(param => 
        invoiceService.getMultiDropDownSource(
          `${param.ReportType},${param.MainInvoiceID},${param.FromDate},${param.ToDate},${param.BrandID},${param.ProductID},${param.ShopID}`
        )
      ));
      console.log(costingResponse.data.recordset)
      console.log(valueTotalResponse.data.recordset)
      console.log(detailResponse.data.recordset)
      const noRecordsFound = [costingResponse, valueTotalResponse, detailResponse].some(response => 
        !response || response.data.recordset.length === 0
      );
    
      if (noRecordsFound) {
        alert('No records found');
        setGenerateReport(false)

      } else {
    
      // setInvoiceCostingData(costingResponse.data.recordset);
      // setInvoiceValueTotalData(valueTotalResponse.data.recordset);
      // setDetailData(detailResponse.data.recordset);
      const asPdf = pdf([]); // Creating a new PDF instance
      let component =<InvoiceCosting
        detailData={detailResponse.data.recordset}
        invoiceValueTotalData={valueTotalResponse.data.recordset}
        invoiceCostingData={costingResponse.data.recordset} />;
      asPdf.updateContainer(component); // Update the container with the document
  
    const blob = await asPdf.toBlob(); // Generate the PDF as a Blob
    saveAs(blob, `InvoiceCosting.pdf`);
    // setPdfBlob((val)=>{
    //   console.log(val)
    //   return({...val , InvoiceCosting:blob})
    // }
    //   ) 
    setGenerateReport(false)

      document.getElementById('InvoiceCosting_label').style.color = 'black'
}
    } catch (error) {
      setGenerateReport(false)

        alert('An error occurred while fetching the data');
      }
  };

// Stock Shop Wise Report ****************************
// Stock Shop Wise Report ****************************
// Stock Shop Wise Report ****************************
// Stock Shop Wise Report ****************************
// Stock Shop Wise Report ****************************
// Stock Shop Wise Report ****************************
// Stock Shop Wise Report ****************************

// const [dynamicColumns, setDynamicColumns] = useState([]);
// const [dataSource, setDataSource] = useState(undefined);
// const [reportTotal, setReportTotal] = useState({});

const filterDataByID = (data, id) => data.filter(res => res.id === id)[0]?.value;

const calculateTotals = (data) => {
  const totals = {
    Shop1BalQty: 0,
    Shop2BalQty: 0,
    Shop3BalQty: 0,
    Shop4BalQty: 0,
    Shop5BalQty: 0,
    Shop6BalQty: 0,
    Shop7BalQty: 0,
    Shop8BalQty: 0,
    Shop9BalQty: 0,
    Shop10BalQty: 0,
    Shop15BalQty: 0,
    TotalStockQty: 0
  };

  data.forEach(item => {
    totals.Shop1BalQty += item.Shop1BalQty;
    totals.Shop2BalQty += item.Shop2BalQty;
    totals.Shop3BalQty += item.Shop3BalQty;
    totals.Shop4BalQty += item.Shop4BalQty;
    totals.Shop5BalQty += item.Shop5BalQty;
    totals.Shop6BalQty += item.Shop6BalQty;
    totals.Shop7BalQty += item.Shop7BalQty;
    totals.Shop8BalQty += item.Shop8BalQty;
    totals.Shop9BalQty += item.Shop9BalQty;
    totals.Shop10BalQty += item.Shop10BalQty;
    totals.Shop15BalQty += item.Shop15BalQty;
    totals.TotalStockQty += item.TotalStockQty;
  });

  for (let key in totals) {
    totals[key] = totals[key].toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 });
  }

  return totals;
};

const groupProductsByBrand = (products) => {
  const grouped = products.reduce((acc, product) => {
    const { BrandID, TotalStockQty, ...shopBalances } = product;
    if (!acc[BrandID]) {
      acc[BrandID] = {
        BrandID,
        products: [],
        totals: {
          TotalStockQty: 0,
          ...Object.keys(shopBalances).reduce((totalsAcc, key) => {
            if (key.startsWith('Shop') && key.endsWith('BalQty')) {
              totalsAcc[key] = 0;
            }
            return totalsAcc;
          }, {})
        }
      };
    }

    acc[BrandID].products.push(product);
    acc[BrandID].totals.TotalStockQty += TotalStockQty;

    Object.keys(shopBalances).forEach(key => {
      if (key.startsWith('Shop') && key.endsWith('BalQty')) {
        acc[BrandID].totals[key] += shopBalances[key];
      }
    });

    return acc;
  }, {});

  return Object.values(grouped);
};

const StockShopWiseDataSource = async () => {
  try {
     setGenerateReport(true)
      document.getElementById('StockShopWise_label').style.color = 'black'
      let dynamicColumns = []
    const response = await DropdownService.getAllForList('Shop');
    if (response.data && response.data.recordset) {
      // setDynamicColumns(
     dynamicColumns =  [
          `${filterDataByID(response.data.recordset, 1)} `,
          `${filterDataByID(response.data.recordset, 2)} `,
          `${filterDataByID(response.data.recordset, 3)} `,
          `${filterDataByID(response.data.recordset, 4)} `,
          `${filterDataByID(response.data.recordset, 5)} `,
          `${filterDataByID(response.data.recordset, 6)} `,
          `${filterDataByID(response.data.recordset, 7)} `,
          `${filterDataByID(response.data.recordset, 8)} `,
          `${filterDataByID(response.data.recordset, 9)} `,
          `${filterDataByID(response.data.recordset, 10)} `,
          `${filterDataByID(response.data.recordset, 15)} `
        ]
      // );
    } else {
      console.error("Invalid response structure:", response);
      return;
    }

    const reportData = {
      ReportType: 'StockShopWise',
      MainInvoiceID: 0,
      // FromDate: `${props.match.params.FromDate}`,
      // ToDate: `${props.match.params.ToDate}`,
      // BrandID: props.match.params.BrandID,
      // ProductID: props.match.params.ProductID,
      // ShopID: props.match.params.ShopID
      FromDate: `${inputProps.FromDate.value}`,
      ToDate: `${inputProps.ToDate.value}`,
      BrandID: inputProps.BrandName.value.id || 0 ,
      ProductID: inputProps.ProductName.value.id || 0 ,
      ShopID: inputProps.ShopName.value.id || 0 };

   invoiceService.getMultiDropDownSource(
      `${reportData.ReportType},${reportData.MainInvoiceID},${reportData.FromDate},${reportData.ToDate},${reportData.BrandID},${reportData.ProductID},${reportData.ShopID}`
    ).then( async (reportResponse)=>{
if(!reportResponse || reportResponse.data.recordset.length == 0 || reportResponse.data.length == 0){

  alert('No records found');
  setGenerateReport(false)

}
else{
    

    const dataSource = groupProductsByBrand(reportResponse.data.recordset);
    // setDataSource(dataSource);

    const totals = calculateTotals(reportResponse.data.recordset);
    // setReportTotal(totals);
    console.log(dynamicColumns)
    const asPdf = pdf([]); // Creating a new PDF instance
    let component =<Inventory
    reportTotal={totals}
    dynamicColumns={dynamicColumns}
    dataSource={dataSource} />;
    asPdf.updateContainer(component); // Update the container with the document

  const blob = await asPdf.toBlob(); // Generate the PDF as a Blob
  saveAs(blob, `StockShopWise.pdf`);
  // setPdfBlob((val)=>{
  //   console.log(val)
  //   return({...val , StockShopWise:blob})
  // }
  //   )
  setGenerateReport(false)

    document.getElementById('StockShopWise_label').style.color = 'black'
}
  }).catch(()=>{
    alert('An error occurred while fetching the data');
    setGenerateReport(false)


    
  })
  } catch (error) {
    alert('An error occurred while fetching the data');
    setGenerateReport(false)

    console.error("Error fetching StockShopWise data:", error);
  }
};

  

// Stock Report With Value Report OR Stock Report With Out Value Report ****************************
// Stock Report With Value Report OR Stock Report With Out Value Report ****************************
// Stock Report With Value Report OR Stock Report With Out Value Report ****************************
// Stock Report With Value Report OR Stock Report With Out Value Report ****************************
// Stock Report With Value Report OR Stock Report With Out Value Report ****************************
// Stock Report With Value Report OR Stock Report With Out Value Report ****************************
// Stock Report With Value Report OR Stock Report With Out Value Report ****************************

function groupAndCalculateTotalsStockReportWithValue(data) {
  const groupedData = {};
  const finalTotals = {
    OpQty: 0,
    RecQty: 0,
    IssQty: 0,
    TransferQty: 0,
    BalQty: 0,
    // RecAmt: 0,
    // TotalQty: 0,
    // ShowRec: 0,
    ValRate: 0,
    ValAmount: 0
  };

  // Group by Shop and then by Brand
  data.forEach(item => {
    const { ShopName, BrandName } = item;

    if (!groupedData[ShopName]) {
      groupedData[ShopName] = { brands: {}, totals: { OpQty: 0, RecQty: 0, IssQty: 0,  TransferQty: 0, BalQty: 0,  ValRate: 0, ValAmount: 0 } };
    }

    if (!groupedData[ShopName].brands[BrandName]) {
      groupedData[ShopName].brands[BrandName] = { items: [], totals: {OpQty: 0, RecQty: 0, IssQty: 0,  TransferQty: 0, BalQty: 0,  ValRate: 0, ValAmount: 0 } };
    }

    groupedData[ShopName].brands[BrandName].items.push(item);

    // Calculate Brand-wise totals
    Object.keys(finalTotals).forEach(key => {
      groupedData[ShopName].brands[BrandName].totals[key] += item[key];
      groupedData[ShopName].totals[key] += item[key];
      finalTotals[key] += item[key];
    });
  });

  return { groupedData, finalTotals };
}

// Example usage:


const StockReportWithValuetDataSource = async () => {
  try {
    
 
   setGenerateReport(true)
      document.getElementById('StockReportWithValue_label').style.color = 'black'
      document.getElementById('StockReport_label').style.color = 'black'
  const data = {
    ReportType: 'Stock',
    // MainInvoiceID: props.match.params.MainInvoiceID,
    // FromDate:  `${props.match.params.FromDate}`,
    // ToDate:  `${props.match.params.ToDate}`,
    // BrandID: props.match.params.BrandID,
    // ProductID: props.match.params.ProductID,
    // // ProductID: 326,
    // ShopID: props.match.params.ShopID
    MainInvoiceID: inputProps.Invoice.value.id || 0,
    FromDate:  `${inputProps.FromDate.value}`,
    ToDate:  `${inputProps.ToDate.value}`,
    BrandID: inputProps.BrandName.value.id || 0,
    ProductID: inputProps.ProductName.value.id || 0,
    // ProductID: 326,
    ShopID: inputProps.ShopName.value.id || 0
  };
 invoiceService.getMultiDropDownSource(
    `${data.ReportType},${data.MainInvoiceID},${data.FromDate},${data.ToDate},${data.BrandID},${data.ProductID},${data.ShopID}`
  ).then( async (response)=>{

    if(!response || response.data.recordset.length == 0 || response.data.length == 0){
      alert('No records found');
      setGenerateReport(false)
    }
    else{
const result = groupAndCalculateTotalsStockReportWithValue(response.data.recordset);

console.log(selectedReport);  // Grouped data by Shop and Brand
console.log(result.groupedData);  // Grouped data by Shop and Brand
console.log(result.finalTotals);  // Final overall totals
const asPdf = pdf([]); // Creating a new PDF instance
    let component =<StockReportWithValue
    Type={selectedReport == 'StockReportWithValue'? `With` : `WithOut` }
    DataSource={result} />;
    asPdf.updateContainer(component); // Update the container with the document

  const blob = await asPdf.toBlob(); // Generate the PDF as a Blob
  saveAs(blob, `${selectedReport}.pdf`);
  // setPdfBlob((val)=>{
  //   console.log(val)
  //   return({...val , StockShopWise:blob})
  // }
  //   )
  setGenerateReport(false)

    document.getElementById('StockReportWithValue_label').style.color = 'black'
          document.getElementById('StockReport_label').style.color = 'black'
  // setDataSource(result);
 }
  }).catch(()=>{
    // console.log(response.data.recordset)
    // alert('An error occurred while fetching the data');
    setGenerateReport(false)


    
  })
  } catch (error) {
    alert('An error occurred while fetching the data');
    setGenerateReport(false)

    // console.error("Error fetching StockShopWise data:", error);
  }
}



// Inventory With Profit Loss Report ****************************
// Inventory With Profit Loss Report ****************************
// Inventory With Profit Loss Report ****************************
// Inventory With Profit Loss Report ****************************
// Inventory With Profit Loss Report ****************************
// Inventory With Profit Loss Report ****************************
// Inventory With Profit Loss Report ****************************

// const [dynamicColumns, setDynamicColumns] = useState([]);
// const [dataSource, setDataSource] = useState(undefined);
// const [reportTotal, setReportTotal] = useState({});
function groupAndCalculateTotalsInventoryWithProfitLoss(data) {
  const groupedData = {};
  const finalTotals = {
        OpQuantity:0,
        OpRate:0,
        OpAmount:0,

        PurQuantity:	0,
        PurAmount:0,
        PurRate:0,

        TransQuantity:0,

        AvailableQuantity:0,
        AvailableRate:	0,
        AvailableAmount	:0,

        ClosingQuantity:	0,
        ClosingRate:0,
        ClosingAmount	:0,

        SaleQuantity:0,
        SaleAmount:0,
        SaleAvgRate:	0,

        SaleRateDiff:	0,
        ProfitLossAmount:0

  };

  // Group by Shop and then by Brand
  data.forEach(item => {
    const { ShopName, BrandName } = item;

    if (!groupedData[ShopName]) {
      groupedData[ShopName] = { brands: {}, totals: { 
        OpQuantity:0,
        OpRate:0,
        OpAmount:0,

        PurQuantity:	0,
        PurAmount:0,
        PurRate:0,

        TransQuantity:0,

        AvailableQuantity:0,
        AvailableRate:	0,
        AvailableAmount	:0,

        ClosingQuantity:	0,
        ClosingRate:0,
        ClosingAmount	:0,

        SaleQuantity:0,
        SaleAmount:0,
        SaleAvgRate:	0,

        SaleRateDiff:	0,
        ProfitLossAmount:0

      } };
    }

    if (!groupedData[ShopName].brands[BrandName]) {
      groupedData[ShopName].brands[BrandName] = { items: [], totals: {
        OpQuantity:0,
        OpRate:0,
        OpAmount:0,

        PurQuantity:	0,
        PurAmount:0,
        PurRate:0,

        TransQuantity:0,

        AvailableQuantity:0,
        AvailableRate:	0,
        AvailableAmount	:0,

        ClosingQuantity:	0,
        ClosingRate:0,
        ClosingAmount	:0,

        SaleQuantity:0,
        SaleAmount:0,
        SaleAvgRate:	0,

        SaleRateDiff:	0,
        ProfitLossAmount:0

      } };
    }

    groupedData[ShopName].brands[BrandName].items.push(item);

    // Calculate Brand-wise totals
    Object.keys(finalTotals).forEach(key => {
      groupedData[ShopName].brands[BrandName].totals[key] += item[key];
      groupedData[ShopName].totals[key] += item[key];
      finalTotals[key] += item[key];
    });
  });

  return { groupedData, finalTotals };
}

// Example usage:


const InventoryWithProfitLossDataSource = async () => {
  try{
   setGenerateReport(true)
      document.getElementById('InventoryWithProfitLoss_label').style.color = 'black'
    const data = {
    ReportType: 'INVENTORY_WITH_PL',
    // MainInvoiceID: props.match.params.MainInvoiceID,
    // FromDate:  `${props.match.params.FromDate}`,
    // ToDate:  `${props.match.params.ToDate}`,
    // BrandID: props.match.params.BrandID,
    // ProductID: props.match.params.ProductID,
    // // ProductID: 326,
    // ShopID: props.match.params.ShopID
    MainInvoiceID: inputProps.Invoice.value.id || 0,
    FromDate:  `${inputProps.FromDate.value}`,
    ToDate:  `${inputProps.ToDate.value}`,
    BrandID: inputProps.BrandName.value.id || 0,
    ProductID: inputProps.ProductName.value.id || 0,
    // ProductID: 326,
    ShopID: inputProps.ShopName.value.id || 0
  };
  invoiceService.getMultiDropDownSource(
    `${data.ReportType},${data.MainInvoiceID},${data.FromDate},${data.ToDate},${data.BrandID},${data.ProductID},${data.ShopID}`
  ).then(   async (response)=>{
    if(!response || response.data.recordset.length == 0 || response.data.length == 0){

      alert('No records found');
      setGenerateReport(false)
    
    }
    else{

const result = groupAndCalculateTotalsInventoryWithProfitLoss(response.data.recordset);

console.log(selectedReport);  // Grouped data by Shop and Brand
console.log(result.groupedData);  // Grouped data by Shop and Brand
console.log(result.finalTotals);  // Final overall totals
const asPdf = pdf([]); // Creating a new PDF instance
    let component =<InventoryWithProfitLoss
    // Type={selectedReport == 'InventoryWithProfitLoss'? `With` : `WithOut` }
    FromDate={`${inputProps.FromDate.value}`}
    ToDate={`${inputProps.ToDate.value}`}
    DataSource={result} />;
    asPdf.updateContainer(component); // Update the container with the document

  const blob = await asPdf.toBlob(); // Generate the PDF as a Blob
  saveAs(blob, `${selectedReport}.pdf`);
  // setPdfBlob((val)=>{
  //   console.log(val)
  //   return({...val , StockShopWise:blob})
  // }
  //   )
  setGenerateReport(false)

    document.getElementById('InventoryWithProfitLoss_label').style.color = 'black'
          
  // setDataSource(result);
}
}).catch(()=>{
  // alert('An error occurred while fetching the data');
  setGenerateReport(false)


  
})
} catch (error) {
  alert('An error occurred while fetching the data');
  setGenerateReport(false)

  console.error("Error fetching StockShopWise data:", error);
}
  
}

// Cash Book Usd Report ****************************
// Cash Book Usd Report ****************************
// Cash Book Usd Report ****************************
// Cash Book Usd Report ****************************
// Cash Book Usd Report ****************************
// Cash Book Usd Report ****************************
// Cash Book Usd Report ****************************

// const [dynamicColumns, setDynamicColumns] = useState([]);
// const [dataSource, setDataSource] = useState(undefined);
// const [reportTotal, setReportTotal] = useState({});
const generateCashBookUsdVoucher = (data) => {
  let balance = 0;
  let totalBal = 0;
  let totalDr = 0;
  let totalCr = 0;

  const updatedEntries = data.map((entry, index) => {
      if (index === 0) {
          balance = entry.OBal;
      } else {
          balance = balance + entry.Dr - entry.Cr;
      }

      totalBal += balance;
      totalDr += entry.Dr;
      totalCr += entry.Cr;

      return { ...entry, Bal: balance };
  });

  return { updatedEntries, totalBal, totalDr, totalCr };
};
// Example usage:


const CashBookUsdVoucherDataSource = async () => {
  try{
   setGenerateReport(true)
      document.getElementById('CashBookUsd_label').style.color = 'black'
    const data = {
    ReportType:'CASHBOOKUSD',
    AccountID: inputProps.AccountTitle.value.id || 0,
    ShopID: inputProps.ShopName.value.id || 0,
    FromDate:  `${inputProps.FromDate.value}`,
    ToDate:  `${inputProps.ToDate.value}`,
    // ProductID: 326,
  };
  ReportService.getCashBookUsdVoucher(data).then(async (response)=>{
    if(!response || response.data.recordset.length == 0 || response.data.length == 0){

      alert('No records found');
      setGenerateReport(false)
    
    }
    else{
const result = generateCashBookUsdVoucher(response.data.recordset);

console.log(selectedReport);  // Grouped data by Shop and Brand
console.log(result);  // Grouped data by Shop and Brand
console.log(result);  // Final overall totals
const asPdf = pdf([]); // Creating a new PDF instance
    let component =<CashBookUsdVoucher
    // Type={selectedReport == 'InventoryWithProfitLoss'? `With` : `WithOut` }
    FromDate={`${inputProps.FromDate.value}`}
    ToDate={`${inputProps.ToDate.value}`}
    AccountTitle={inputProps.AccountTitle.value.value}
    DataSource={result} 
    />;
    asPdf.updateContainer(component); // Update the container with the document

  const blob = await asPdf.toBlob(); // Generate the PDF as a Blob
  saveAs(blob, `${selectedReport}.pdf`);
  // setPdfBlob((val)=>{
  //   console.log(val)p
  //   return({...val , StockShopWise:blob})
  // }
  //   )
  setGenerateReport(false)

    document.getElementById('CashBookUsd_label').style.color = 'black'
          
  // setDataSource(result);

}
}).catch(()=>{
  // alert('An error occurred while fetching the data');
  setGenerateReport(false)


  
})
} catch (error) {
  alert('An error occurred while fetching the data');
  setGenerateReport(false)

  console.error("Error fetching StockShopWise data:", error);
}
}

// Ledger Report ****************************
// Ledger Report ****************************
// Ledger Report ****************************
// Ledger Report ****************************
// Ledger Report ****************************
// Ledger Report ****************************
// Ledger Report ****************************

// const [dynamicColumns, setDynamicColumns] = useState([]);
// const [dataSource, setDataSource] = useState(undefined);
// const [reportTotal, setReportTotal] = useState({});
const ledgerVoucher = (data) => {
  let balance = 0;
  let totalBal = 0;
  let totalDr = 0;
  let totalCr = 0;

  const updatedEntries = data.map((entry, index) => {
      if (index === 0) {
          balance = entry.OBal;
      } else {
          balance = balance + entry.Dr - entry.Cr;
      }

      totalBal += balance;
      totalDr += entry.Dr;
      totalCr += entry.Cr;

      return { ...entry, Bal: balance };
  });

  return { updatedEntries, totalBal, totalDr, totalCr };
};
// Example usage:


const LedgerDataSource = async () => {
  try{
   setGenerateReport(true)
      document.getElementById('Ledger_label').style.color = 'black'
    const data = {
    ReportType:'LEDGER',
    AccountID: inputProps.AccountTitle.value.id || 0,
    ShopID: inputProps.ShopName.value.id || 0,
    FromDate:  `${inputProps.FromDate.value}`,
    ToDate:  `${inputProps.ToDate.value}`,
    // ProductID: 326,
  };
  ReportService.getCashBookUsdVoucher(data).then(async (response)=>{
    if(!response || response.data.recordset.length == 0 || response.data.length == 0){

      alert('No records found');
      setGenerateReport(false)
    
    }
    else{
const result = ledgerVoucher(response.data.recordset);

console.log(selectedReport);  // Grouped data by Shop and Brand
console.log(result);  // Grouped data by Shop and Brand
console.log(result);  // Final overall totals
const asPdf = pdf([]); // Creating a new PDF instance
    let component =<CashBookUsdVoucher
    // Type={selectedReport == 'InventoryWithProfitLoss'? `With` : `WithOut` }
    FromDate={`${inputProps.FromDate.value}`}
    ToDate={`${inputProps.ToDate.value}`}
    AccountTitle={inputProps.AccountTitle.value.value}

    DataSource={result} 
    />;
    asPdf.updateContainer(component); // Update the container with the document

  const blob = await asPdf.toBlob(); // Generate the PDF as a Blob
  saveAs(blob, `${selectedReport}.pdf`);
  // setPdfBlob((val)=>{
  //   console.log(val)
  //   return({...val , StockShopWise:blob})
  // }
  //   )
  setGenerateReport(false)

    document.getElementById('Ledger_label').style.color = 'black'
          
  // setDataSource(result);
  }
  }).catch(()=>{
    // alert('An error occurred while fetching the data');
    setGenerateReport(false)


    
  })
  } catch (error) {
    alert('An error occurred while fetching the data');
    setGenerateReport(false)

    console.error("Error fetching StockShopWise data:", error);
  }

  
}


// CHART OF ACCOUNT Report ****************************
// CHART OF ACCOUNT Report ****************************
// CHART OF ACCOUNT Report ****************************
// CHART OF ACCOUNT Report ****************************
// CHART OF ACCOUNT Report ****************************
// CHART OF ACCOUNT Report ****************************
// CHART OF ACCOUNT Report ****************************

// const [dynamicColumns, setDynamicColumns] = useState([]);
// const [dataSource, setDataSource] = useState(undefined);
// const [reportTotal, setReportTotal] = useState({});


// Example usage:
// const groupByAccountCodeLength = (accounts) => {
//   return accounts.reduce((acc, account) => {
//       const length = account.AccountCode.toString().length;

//       let level;
//       switch (length) {
//           case 1:
//               level = 'LEVEL 1';
//               break;
//           case 3:
//               level = 'LEVEL 2';
//               break;
//           case 5:
//               level = 'LEVEL 3';
//               break;
//           case 8:
//               level = 'LEVEL 4';
//               break;
//           case 12:
//               level = 'LEVEL 5';
//               break;
//           default:
//               level = 'UNKNOWN LEVEL';
//               break;
//       }

//       if (!acc[level]) acc[level] = [];
//       acc[level].push(account);

//       return acc;
//   }, {});
// };
function createTreeData(accountCodes) {
  console.log(accountCodes)
  const treeData = [];

  function findChildren(parentCode, parentLevel) {
    const children = [];

    accountCodes.forEach((account) => {
      if (
        account.AccountCode.startsWith(parentCode) &&
        account.AccountCode.length === parentLevel + 2
      ) {
        const child = {
          AccountCode: account.AccountCode,
          AccountTitleShort: account.AccountTitleShort,
          ShowOpeningBalance: account.ShowOpeningBalance
          
        };

        const childChildren = findChildren(account.AccountCode, account.AccountCode.length);
        if (childChildren.length > 0) {
          child.children = childChildren;
        }

        children.push(child);
      }
      if (
        account.AccountCode.startsWith(parentCode) &&
        account.AccountCode.length == 8 &&
        account.AccountCode.length === parentLevel + 3
      ) {
        const child = {
          AccountCode: account.AccountCode,
          AccountTitleShort: account.AccountTitleShort,
          ShowOpeningBalance: account.ShowOpeningBalance
        };

        const childChildren = findChildren(account.AccountCode, account.AccountCode.length);
        if (childChildren.length > 0) {
          child.children = childChildren;
        }

        children.push(child);
      }
      if (
        account.AccountCode.startsWith(parentCode) &&
        account.AccountCode.length == 12 &&
        account.AccountCode.length === parentLevel + 4
      ) {
        const child = {
          AccountCode: account.AccountCode,
          AccountTitleShort: account.AccountTitleShort,
          ShowOpeningBalance: account.ShowOpeningBalance
          
        };

        const childChildren = findChildren(account.AccountCode, account.AccountCode.length);
        if (childChildren.length > 0) {
          child.children = childChildren;
        }

        children.push(child);
      }
    });

    return children;
  }

  accountCodes.forEach((account) => {
    if (account.AccountCode.length < 2) {
      const parent = {
        AccountCode: account.AccountCode,
        AccountTitleShort: account.AccountTitleShort,
        ShowOpeningBalance: account.ShowOpeningBalance
      };
      console.log(account.AccountCode, account.AccountCode.length)
      const parentChildren = findChildren(account.AccountCode, account.AccountCode.length);
      if (parentChildren.length > 0) {
        parent.children = parentChildren;
      }

      treeData.push(parent);
    }
  });
console.log(treeData)
  return treeData;
}
const ChartOfAccountDataSource = async () => {
  try{
   setGenerateReport(true)
      document.getElementById('ChartOfAccount_label').style.color = 'black'
  
   ReportService.getChartOfAccount().then(async (response)=>{
    if(!response || response.data.recordset.length == 0 || response.data.length == 0){

      alert('No records found');
      setGenerateReport(false)
    
    }
    else{
  console.log(response.data.recordset)
  const result = createTreeData(response.data.recordset);

console.log(selectedReport);  // Grouped data by Shop and Brand
console.log(result);  // Grouped data by Shop and Brand
// console.log(
//  <pre>{JSON.stringify(result, null, 2)}</pre> )
// Final overall totals
const asPdf = pdf([]); // Creating a new PDF instance
    let component =<ChartOfAccountReport
    // Type={selectedReport == 'InventoryWithProfitLoss'? `With` : `WithOut` }
    DataSource={result} 
    ApiDataSource={response.data.recordset} 
    />;
    asPdf.updateContainer(component); // Update the container with the document

  const blob = await asPdf.toBlob(); // Generate the PDF as a Blob
  saveAs(blob, `${selectedReport}.pdf`);
  // setPdfBlob((val)=>{
  //   console.log(val)
  //   return({...val , StockShopWise:blob})
  // }
    // )
  setGenerateReport(false)

    document.getElementById('ChartOfAccount_label').style.color = 'black'
          
  // setDataSource(result);
  }
}).catch(()=>{
  // alert('An error occurred while fetching the data');
  setGenerateReport(false)


  
})
} catch (error) {
  alert('An error occurred while fetching the data');
  setGenerateReport(false)

  console.error("Error fetching StockShopWise data:", error);
}
  
}

// Cash Book Report ****************************
// Cash Book Report ****************************
// Cash Book Report ****************************
// Cash Book Report ****************************
// Cash Book Report ****************************
// Cash Book Report ****************************
// Cash Book Report ****************************

// const [dynamicColumns, setDynamicColumns] = useState([]);
// const [dataSource, setDataSource] = useState(undefined);
// const [reportTotal, setReportTotal] = useState({});
const cashBookVoucher = (data) => {
  let balance = 0;
  let totalBal = 0;
  let totalDr = 0;
  let totalCr = 0;

  const updatedEntries = data.map((entry, index) => {
      if (index === 0) {
          balance = entry.OBal;
      } else {
          balance = balance + entry.Dr - entry.Cr;
      }

      totalBal += balance;
      totalDr += entry.Dr;
      totalCr += entry.Cr;

      return { ...entry, Bal: balance };
  });

  return { updatedEntries, totalBal, totalDr, totalCr };
};
// Example usage:


const CashBookDataSource = async () => {
  try{
   setGenerateReport(true)
      document.getElementById('CashBookLocalCurrency_label').style.color = 'black'
    const data = {
    ReportType:'CASHBOOK_LOCALCURRENCY',
    AccountID: inputProps.AccountTitle.value.id || 0,
    ShopID: inputProps.ShopName.value.id || 0,
    FromDate:  `${inputProps.FromDate.value}`,
    ToDate:  `${inputProps.ToDate.value}`,
    // ProductID: 326,
  };
  ReportService.getCashBookUsdVoucher(data).then(async (response)  => {

  
    if(!response || response.data.recordset.length == 0 || response.data.length == 0){

      alert('No records found');
      setGenerateReport(false)
    
    }
    else{
const result = cashBookVoucher(response.data.recordset);

console.log(selectedReport);  // Grouped data by Shop and Brand
console.log(result);  // Grouped data by Shop and Brand
console.log(result);  // Final overall totals
const asPdf = pdf([]); // Creating a new PDF instance
    let component =<CashBookUsdVoucher
    // Type={selectedReport == 'InventoryWithProfitLoss'? `With` : `WithOut` }
    FromDate={`${inputProps.FromDate.value}`}
    ToDate={`${inputProps.ToDate.value}`}
    AccountTitle={inputProps.AccountTitle.value.value}

    DataSource={result} 
    />;
    asPdf.updateContainer(component); // Update the container with the document

  const blob = await asPdf.toBlob(); // Generate the PDF as a Blob
  saveAs(blob, `${selectedReport}.pdf`);
  // setPdfBlob((val)=>{
  //   console.log(val)
  //   return({...val , StockShopWise:blob})
  // }
  //   )
  setGenerateReport(false)

    document.getElementById('CashBookLocalCurrency_label').style.color = 'black'
          
  // setDataSource(result);
}
}).catch(()=>{
  // alert('An error occurred while fetching the data');
  setGenerateReport(false)


  
})
} catch (error) {
  alert('An error occurred while fetching the data');
  setGenerateReport(false)

  console.error("Error fetching StockShopWise data:", error);
}
  
}


// Receivable Payable Report ****************************
// Receivable Payable Report ****************************
// Receivable Payable Report ****************************
// Receivable Payable Report ****************************
// Receivable Payable Report ****************************
// Receivable Payable Report ****************************
// Receivable Payable Report ****************************

// const [dynamicColumns, setDynamicColumns] = useState([]);
// const [dataSource, setDataSource] = useState(undefined);
// const [reportTotal, setReportTotal] = useState({});
// function ReceivablePayable(data, groupByColumn, sumColumns) {
//   // Initialize an object to hold the grouped data
//   const groupedData = {};

//   // Iterate over each item in the data array
//   data.forEach(item => {
//       const groupValue = item[groupByColumn];

//       // If the group doesn't exist, initialize it
//       if (!groupedData[groupValue]) {
//           groupedData[groupValue] = { [groupByColumn]: groupValue };

//           // Initialize the sum columns with 0
//           sumColumns.forEach(col => {
//               groupedData[groupValue][col] = 0;
//           });
//       }

//       // Sum the values for the specified columns
//       sumColumns.forEach(col => {
//           groupedData[groupValue][col] += item[col];
//       });
//   });

//   // Convert the grouped object back to an array
//   const result = Object.values(groupedData);

//   // Calculate the total sum for each sum column
//   // const totalSums = {};
//   // sumColumns.forEach(col => {
//   //     totalSums[col] = result.reduce((acc, group) => acc + group[col], 0);
//   // });

//   // Add the total sums to the result
//   // result.push({ ReportTotal: ', ...totalSums });
  

//   return result;
// }


function ReceivablePayable(props) {
  const groupedData = {};
  const finalTotals = {
  OpeningAmountDebit:0, 
  OpeningAmountCredit:0, 
  DurationAmountDebit:0, 
  DurationAmountCredit:0,
  ClosingAmountDebit:0,
  ClosingAmountCredit:0
  };

  // Group by Shop and then by Brand
  console.log(props)
  props.forEach(item => {
    const { Level3AccountTitle } = item;

    if (!groupedData[Level3AccountTitle]) {
      groupedData[Level3AccountTitle] = { items: [], totals: { 
  OpeningAmountDebit:0, 
  OpeningAmountCredit:0, 
  DurationAmountDebit:0, 
  DurationAmountCredit:0,
  ClosingAmountDebit:0,
  ClosingAmountCredit:0
      } };
    }

    groupedData[Level3AccountTitle].items.push(item);


    Object.keys(finalTotals).forEach(key => {
      groupedData[Level3AccountTitle].totals[key] += item[key];
      finalTotals[key] += item[key];
    });
  });

  return { groupedData, finalTotals };
}

// Example usage:


// Example usage:


const ReceivablePayableDataSource = async () => {
  try{
   setGenerateReport(true)
      document.getElementById('ReceivablePayable_label').style.color = 'black'
    const data = {
    ReportType:'ReceivablePayable',
    AccountID: inputProps.AccountTitle.value.id || 0,
    ShopID: inputProps.ShopName.value.id || 0,
    FromDate:  `${inputProps.FromDate.value}`,
    ToDate:  `${inputProps.ToDate.value}`,
    // ProductID: 326,
  };
   ReportService.getCashBookUsdVoucher(data).then(async (response)=>{
    if(!response || response.data.recordset.length == 0 || response.data.length == 0){

      alert('No records found');
      setGenerateReport(false)
    
    }
    else{
   
    console.log(response)
const result =  ReceivablePayable(response.data.recordset);
console.log(result);

// const result = cashBookVoucher(response.data.recordset);

console.log(selectedReport);  // Grouped data by Shop and Brand
console.log(result);  // Grouped data by Shop and Brand
console.log(result);  // Final overall totals
const asPdf = pdf([]); // Creating a new PDF instance
    let component =<ReceivablePayableReport
    // Type={selectedReport == 'InventoryWithProfitLoss'? `With` : `WithOut` }
    FromDate={`${inputProps.FromDate.value}`}
    ToDate={`${inputProps.ToDate.value}`}
    DataSource={result} 
    />;
    asPdf.updateContainer(component); // Update the container with the document

  const blob = await asPdf.toBlob(); // Generate the PDF as a Blob
  saveAs(blob, `${selectedReport}.pdf`);
  // setPdfBlob((val)=>{
  //   console.log(val)
  //   return({...val , StockShopWise:blob})
  // }
  //   )
  setGenerateReport(false)

    document.getElementById('ReceivablePayable_label').style.color = 'black'
          
  // setDataSource(result);

}
}).catch(()=>{
  // alert('An error occurred while fetching the data');
  setGenerateReport(false)


  
})
} catch (error) {
  alert('An error occurred while fetching the data');
  setGenerateReport(false)

  console.error("Error fetching StockShopWise data:", error);
}
}

// TD USD Report ****************************
// TD USD Report ****************************
// TD USD Report ****************************
// TD USD Report ****************************
// TD USD Report ****************************
// TD USD Report ****************************
// TD USD Report ****************************
function TBUSD(data) {
  const groupedData = {};
  const finalTotals = {
    OpeningBalanceFC: 0,
    DurationDebitFC: 0,
    DurationCreditFC: 0,
    ClosingBalanceFC: 0,
  };

  data.forEach(item => {
    const {
      Level1AccountTitle,
      Level2AccountTitle,
      Level3AccountTitle,
      OpeningBalanceFC,
      DurationDebitFC,
      DurationCreditFC,
      ClosingBalanceFC
    } = item;

    // Round to 0 decimal places before adding
    const roundedOpeningBalanceFC = Math.round(OpeningBalanceFC);
    const roundedDurationDebitFC = Math.round(DurationDebitFC);
    const roundedDurationCreditFC = Math.round(DurationCreditFC);
    const roundedClosingBalanceFC = Math.round(ClosingBalanceFC);

    // Group by Level1AccountTitle
    if (!groupedData[Level1AccountTitle]) {
      groupedData[Level1AccountTitle] = { 
        levels: {}, 
        totals: { 
          OpeningBalanceFC: 0, 
          DurationDebitFC: 0, 
          DurationCreditFC: 0, 
          ClosingBalanceFC: 0 
        } 
      };
    }

    // Group by Level2AccountTitle within Level1AccountTitle
    if (!groupedData[Level1AccountTitle].levels[Level2AccountTitle]) {
      groupedData[Level1AccountTitle].levels[Level2AccountTitle] = { 
        levels: {}, 
        totals: { 
          OpeningBalanceFC: 0, 
          DurationDebitFC: 0, 
          DurationCreditFC: 0, 
          ClosingBalanceFC: 0 
        } 
      };
    }

    // Group by Level3AccountTitle within Level2AccountTitle
    if (!groupedData[Level1AccountTitle].levels[Level2AccountTitle].levels[Level3AccountTitle]) {
      groupedData[Level1AccountTitle].levels[Level2AccountTitle].levels[Level3AccountTitle] = { 
        items: [], 
        totals: { 
          OpeningBalanceFC: 0, 
          DurationDebitFC: 0, 
          DurationCreditFC: 0, 
          ClosingBalanceFC: 0 
        } 
      };
    }

    // Push item into Level3 group
    groupedData[Level1AccountTitle].levels[Level2AccountTitle].levels[Level3AccountTitle].items.push(item);

    // Update Level3 totals
    groupedData[Level1AccountTitle].levels[Level2AccountTitle].levels[Level3AccountTitle].totals.OpeningBalanceFC += roundedOpeningBalanceFC;
    groupedData[Level1AccountTitle].levels[Level2AccountTitle].levels[Level3AccountTitle].totals.DurationDebitFC += roundedDurationDebitFC;
    groupedData[Level1AccountTitle].levels[Level2AccountTitle].levels[Level3AccountTitle].totals.DurationCreditFC += roundedDurationCreditFC;
    groupedData[Level1AccountTitle].levels[Level2AccountTitle].levels[Level3AccountTitle].totals.ClosingBalanceFC += roundedClosingBalanceFC;

    // Update Level2 totals
    groupedData[Level1AccountTitle].levels[Level2AccountTitle].totals.OpeningBalanceFC += roundedOpeningBalanceFC;
    groupedData[Level1AccountTitle].levels[Level2AccountTitle].totals.DurationDebitFC += roundedDurationDebitFC;
    groupedData[Level1AccountTitle].levels[Level2AccountTitle].totals.DurationCreditFC += roundedDurationCreditFC;
    groupedData[Level1AccountTitle].levels[Level2AccountTitle].totals.ClosingBalanceFC += roundedClosingBalanceFC;

    // Update Level1 totals
    groupedData[Level1AccountTitle].totals.OpeningBalanceFC += roundedOpeningBalanceFC;
    groupedData[Level1AccountTitle].totals.DurationDebitFC += roundedDurationDebitFC;
    groupedData[Level1AccountTitle].totals.DurationCreditFC += roundedDurationCreditFC;
    groupedData[Level1AccountTitle].totals.ClosingBalanceFC += roundedClosingBalanceFC;

    // Update final totals
    finalTotals.OpeningBalanceFC += roundedOpeningBalanceFC;
    finalTotals.DurationDebitFC += roundedDurationDebitFC;
    finalTotals.DurationCreditFC += roundedDurationCreditFC;
    finalTotals.ClosingBalanceFC += roundedClosingBalanceFC;
  });

  return { groupedData, finalTotals };
}

// Example usage:

// Example usage:


const TBUSDDataSource = async () => {
  try{
   setGenerateReport(true)
      document.getElementById('TRAILBALANCE_label').style.color = 'black'
    const data = {
    ReportType:'TBUSD',
    AccountID: inputProps.AccountTitle.value.id || 0,
    ShopID: inputProps.ShopName.value.id || 0,
    FromDate:  `${inputProps.FromDate.value}`,
    ToDate:  `${inputProps.ToDate.value}`,
    // ProductID: 326,
  };
   ReportService.getCashBookUsdVoucher(data).then(async (response)=>{

    if(!response || response.data.recordset.length == 0 || response.data.length == 0){

      alert('No records found');
      setGenerateReport(false)
    
    }
    else{
    console.log(response)
const result =  TBUSD(response.data.recordset);
console.log(result);

// const result = cashBookVoucher(response.data.recordset);

console.log(selectedReport);  // Grouped data by Shop and Brand
console.log(result);  // Grouped data by Shop and Brand
console.log(result);  // Final overall totals
const asPdf = pdf([]); // Creating a new PDF instance
    let component =<TBUSDReport
    // Type={selectedReport == 'InventoryWithProfitLoss'? `With` : `WithOut` }
    FromDate={`${inputProps.FromDate.value}`}
    ToDate={`${inputProps.ToDate.value}`}
    DataSource={result} 
    />;
    asPdf.updateContainer(component); // Update the container with the document

  const blob = await asPdf.toBlob(); // Generate the PDF as a Blob
  saveAs(blob, `${selectedReport}.pdf`);
  // setPdfBlob((val)=>{
  //   console.log(val)
  //   return({...val , StockShopWise:blob})
  // }
  //   )
  setGenerateReport(false)

    document.getElementById('TRAILBALANCE_label').style.color = 'black'
          
  // setDataSource(result);

}
}).catch(()=>{
  // alert('An error occurred while fetching the data');
  setGenerateReport(false)


  
})
} catch (error) {
  alert('An error occurred while fetching the data');
  setGenerateReport(false)

  console.error("Error fetching StockShopWise data:", error);
}
}




// SALE REPORT REPORT
// SALE REPORT REPORT
// SALE REPORT REPORT
// SALE REPORT REPORT
// SALE REPORT REPORT
// SALE REPORT REPORT
// SALE REPORT REPORT
// SALE REPORT REPORT
// SALE REPORT REPORT


function saleReport(data) {
  const groupedData = {};
  const finalTotals = {
    InvoiceQuantity	:0,
    InvoiceRate	:0,
    InvoiceAmount	:0,
    InvoiceRateUSD	:0,
    InvoiceAmountUSD	:0,
    InvoiceRateLocalCurrency	:0,
    InvoiceAmountLocalCurrency:0
    };
    const sortedData = data.sort((a, b) => {
      if (a.ShopName < b.ShopName) return -1;
      if (a.ShopName > b.ShopName) return 1;
      return 0;
    });
  
  // Group by Shop and then by Brand
  sortedData.forEach(item => {
    const { ShopName } = item;

    if (!groupedData[ShopName]) {
      groupedData[ShopName] = { items: [], totals: { InvoiceQuantity	:0,
        InvoiceRate	:0,
        InvoiceAmount	:0,
        InvoiceRateUSD	:0,
        InvoiceAmountUSD	:0,
        InvoiceRateLocalCurrency	:0,
        InvoiceAmountLocalCurrency:0 } };
    }



    groupedData[ShopName].items.push(item);

    // Calculate Brand-wise totals
    Object.keys(finalTotals).forEach(key => {
      groupedData[ShopName].totals[key] += item[key];
      finalTotals[key] += item[key];
    });
  });

  return { groupedData, finalTotals };
}

// Example usage:


const SaleReportDataSource = async () => {
  try {
    setGenerateReport(true)
      document.getElementById('SaleReport_label').style.color = 'black'
    const data = {
    FromDate:  `${inputProps.FromDate.value}`,
    ToDate:  `${inputProps.ToDate.value}`,
    MainInvoiceID: inputProps.Invoice.value.id || 0,
    InvoiceID:0,
    InvoiceType:'II',
    AccountID: inputProps.AccountTitle.value.id || 0,
    CurrencyID:0,
    BrandID: inputProps.BrandName.value.id || 0 ,
    ProductID: inputProps.ProductName.value.id || 0 ,
    ShopID: inputProps.ShopName.value.id || 0 ,
    InvoiceDetailID: 0
    
  };
 reportsService.getSaleReport(data).then( async (response)=>{
    console.log(response)
    if(!response || response.data.recordset.length == 0 || response.data.length == 0){
      alert('No records found');
      setGenerateReport(false)
    }
    else{
const result = saleReport(response.data.recordset);

console.log(selectedReport);  // Grouped data by Shop and Brand
console.log(result.groupedData);  // Grouped data by Shop and Brand
console.log(result.finalTotals);  // Final overall totals
const asPdf = pdf([]); // Creating a new PDF instance
    let component =<SaleReport
    FromDate={`${inputProps.FromDate.value}`}
    ToDate={`${inputProps.ToDate.value}`}
    DataSource={result} />;
    asPdf.updateContainer(component); // Update the container with the document

  const blob = await asPdf.toBlob(); // Generate the PDF as a Blob
  saveAs(blob, `${selectedReport}.pdf`);
  setGenerateReport(false)
  document.getElementById('SaleReport_label').style.color = 'black'
 }
    // setGenerateReport(false)
})
  } catch (error) {
    alert('An error occurred while fetching the data');
    setGenerateReport(false)
  }
}




// Profit Loss Statement REPORT REPORT
// Profit Loss Statement REPORT REPORT
// Profit Loss Statement REPORT REPORT
// Profit Loss Statement REPORT REPORT
// Profit Loss Statement REPORT REPORT
// Profit Loss Statement REPORT REPORT
// Profit Loss Statement REPORT REPORT
// Profit Loss Statement REPORT REPORT
// Profit Loss Statement REPORT REPORT

function ProfitLossStatement(data) {
  const groupedData = {};
  const finalTotals = {
    Credit:0,
    Dr: 0,
  };

  // Sort data by Group and Section
  const sortedData = data.sort((a, b) => {
    if (a.Group < b.Group) return -1;
    if (a.Group > b.Group) return 1;
    if (a.Section < b.Section) return -1;
    if (a.Section > b.Section) return 1;
    return 0;
  });
 
  // Group by Group and then by Section
  sortedData.forEach(item => {
    const {
      Group,
      Section,
      Credit,
      Dr,
      } = item;
  
    const roundedCredit = Math.round(Credit);
    const roundedDr = Math.round(Dr);
    
    if (!groupedData[Group]) {
      groupedData[Group] = { sections: {},totals: { Credit: 0, Dr: 0 }};
    }
 
    if (!groupedData[Group].sections[Section]) {
      groupedData[Group].sections[Section] = { items: [], totals: { Credit: 0, Dr: 0 }};
    }

    groupedData[Group].sections[Section].items.push(item);
    
    // if (groupedData[1]) {
    //   groupedData[1].GrossProfit = groupedData[1].Section1Sum - groupedData[1].OtherSectionsSum;
    //   finalTotals.GrossProfit = groupedData[1].GrossProfit;
    // }
    // Object.keys(finalTotals).forEach(key => {
      groupedData[Group].sections[Section].totals.Credit  +=roundedCredit;
      groupedData[Group].sections[Section].totals.Dr  +=roundedDr;
      
      groupedData[Group].totals.Credit +=roundedCredit;
      groupedData[Group].totals.Dr +=roundedDr;
      
      finalTotals.Credit +=roundedCredit;
      finalTotals.Dr +=roundedDr;
    // });

  });

  // Calculate Gross Profit for Group 1
 

  return { groupedData, finalTotals };
}
// Example usage:


const ProfitLossStatementDataSource = async () => {
  try {
    setGenerateReport(true)
      document.getElementById('ProfitLossStatement_label').style.color = 'black'
      const data = {
        ReportType:'PROFITLOSS',
        AccountID: inputProps.AccountTitle.value.id || 0,
        ShopID: inputProps.ShopName.value.id || 0,
        FromDate:  `${inputProps.FromDate.value}`,
        ToDate:  `${inputProps.ToDate.value}`,
        // ProductID: 326,
      };
      ReportService.getCashBookUsdVoucher(data).then(async (response)=>{
        
        if(!response || response.data.recordset.length == 0 || response.data.length == 0){
    
          alert('No records found');
          setGenerateReport(false)
        
        }
    else{
      let dataObject = response.data.recordset.filter((e)=> e.Group == 1 || e.Group ==2)
      console.log(dataObject)
const result = ProfitLossStatement(dataObject);

console.log(result);  // Grouped data by Shop and Brand
console.log(result.groupedData);  // Grouped data by Shop and Brand
console.log(result.finalTotals);  // Final overall totals
const asPdf = pdf([]); // Creating a new PDF instance
    let component =<ProfitLossStatementReport
    FromDate={`${inputProps.FromDate.value}`}
    ToDate={`${inputProps.ToDate.value}`}
    DataSource={result} />;
     asPdf.updateContainer(component); // Update the container with the document

  const blob = await asPdf.toBlob(); // Generate the PDF as a Blob
  saveAs(blob, `${selectedReport}.pdf`);
  setGenerateReport(false)
  document.getElementById('ProfitLossStatement_label').style.color = 'black'
 }
    // setGenerateReport(false)
})
  } catch (error) {
    alert('An error occurred while fetching the data');
    setGenerateReport(false)
  }
}





// Balance Sheet REPORT REPORT
// Balance Sheet REPORT REPORT
// Balance Sheet REPORT REPORT
// Balance Sheet REPORT REPORT
// Balance Sheet REPORT REPORT
// Balance Sheet REPORT REPORT
// Balance Sheet REPORT REPORT
// Balance Sheet REPORT REPORT
// Balance Sheet REPORT REPORT

function BalanceSheet(data) {
  const groupedData = {};
  const finalTotals = {
    Amount:0
  };

  // Sort data by Group and Section
  const sortedData = data.sort((a, b) => {
    if (a.Level3AccountCode < b.Level3AccountCode) return -1;
    if (a.Level3AccountCode > b.Level3AccountCode) return 1;
    if (a.Level4AccountTitle < b.Level4AccountTitle) return -1;
    if (a.Level4AccountTitle > b.Level4AccountTitle) return 1;
    return 0;
  });
 
  // Group by Group and then by Section
  sortedData.forEach(item => {
    const {
      Level3AccountTitle,
      Level4AccountTitle,
      Amount,
      } = item;
  
    const roundedAmount = Math.round(Amount);
    
    
    if (!groupedData[Level3AccountTitle]) {
      groupedData[Level3AccountTitle] = { items: [],totals: { Amount: 0 }};
    }
 
    

    groupedData[Level3AccountTitle].items.push(item);
    
    // if (groupedData[1]) {
    //   groupedData[1].GrossProfit = groupedData[1].Section1Sum - groupedData[1].OtherSectionsSum;
    //   finalTotals.GrossProfit = groupedData[1].GrossProfit;
    // }
    // Object.keys(finalTotals).forEach(key => {
      
      groupedData[Level3AccountTitle].totals.Amount +=roundedAmount;
      
      
      finalTotals.Amount +=roundedAmount;
    // });

  });

  // Calculate Gross Profit for Group 1
 

  return { groupedData, finalTotals };
}
// Example usage:


const BalanceSheetDataSource = async () => {
  try {
    setGenerateReport(true)
      document.getElementById('BalanceSheet_label').style.color = 'black'
      const data = {
        ReportType:'BALANCESHEET',
        AccountID: inputProps.AccountTitle.value.id || 0,
        ShopID: inputProps.ShopName.value.id || 0,
        FromDate:  `${inputProps.FromDate.value}`,
        ToDate:  `${inputProps.ToDate.value}`,
        // ProductID: 326,
      };
      ReportService.getCashBookUsdVoucher(data).then(async (response)=>{
        console.log(response)
        if(!response || response.data.recordsets.length == 0 || response.data.length == 0){
    
          alert('No records found');
          setGenerateReport(false)
        
        }
    else{
      // let dataObject = response.data.recordset.filter((e)=> e.Group == 1 || e.Group ==2)
      // console.log(dataObject)
      // BalanceSheet(dataObject);
      const result1 = BalanceSheet(response.data.recordsets[0]);
console.log(result1);  // Grouped data by Shop and Brand
console.log(result1.groupedData);  // Grouped data by Shop and Brand
console.log(result1.finalTotals);  // Final overall totals
      const result2 = BalanceSheet(response.data.recordsets[1]);
console.log(result2);  // Grouped data by Shop and Brand
console.log(result2.groupedData);  // Grouped data by Shop and Brand
console.log(result2.finalTotals);  // Final overall totals
const asPdf = pdf([]); // Creating a new PDF instance
    let component =<BalanceSheetReport
    FromDate={`${inputProps.FromDate.value}`}
    ToDate={`${inputProps.ToDate.value}`}
    DataSource1={result1}
    DataSource2={result2}
     />;
     asPdf.updateContainer(component); // Update the container with the document

  const blob = await asPdf.toBlob(); // Generate the PDF as a Blob
  saveAs(blob, `${selectedReport}.pdf`);
  setGenerateReport(false)
  document.getElementById('BalanceSheet_label').style.color = 'black'
 }
    // setGenerateReport(false)
})
  } catch (error) {
    alert('An error occurred while fetching the data');
    setGenerateReport(false)
  }
}














  const handleRadioChange = (event) => {
    console.log(event.target.value)
    setGenerateReport(false)
      setSelectedReport(event.target.value);
  };


 

  const containerStyle = {
    display: 'flex',
    padding: '20px'
};

const columnStyle = {
    marginRight: '20px'
};

const labelStyle = {
    marginLeft: '8px',
    textTransform:'upperCase',
    cursor:'pointer'
};


 const handleDownload = async () => {
  console.log(pdfBlob)
    
      if(selectedReport == 'InvoiceCosting'){
        await fetchData();
        
    
    
    }else if(selectedReport == 'StockShopWise'){
      StockShopWiseDataSource();
    }else if(selectedReport == 'StockReportWithValue' || selectedReport == 'StockReport'){
      StockReportWithValuetDataSource()
    
    }
    else if(selectedReport == 'InventoryWithProfitLoss'){
      InventoryWithProfitLossDataSource();
    }
    else if(selectedReport == 'CashBookUsd'){
      CashBookUsdVoucherDataSource();
    }
    else if(selectedReport == 'CashBookLocalCurrency'){
      CashBookDataSource();
    }
    else if(selectedReport == 'Ledger'){
      LedgerDataSource();
    }
    else if(selectedReport == 'ChartOfAccount'){
      ChartOfAccountDataSource();
    }
    else if(selectedReport == 'ReceivablePayable'){
      ReceivablePayableDataSource();
    }
    else if(selectedReport == 'TRAILBALANCE'){
      TBUSDDataSource();
    }
    else if(selectedReport == 'SaleReport'){
      SaleReportDataSource();
    }
    else if(selectedReport == 'ProfitLossStatement'){
      ProfitLossStatementDataSource();
    }
    else if(selectedReport == 'BalanceSheet'){
      BalanceSheetDataSource();
    }
     else {
      alert('please Select Report');
    }
  };



// const previewReport = (event) => {
//     console.log(selectedReport)
//     console.log(inputProps)
//     let url=''
//     let newTab=``
//     switch (selectedReport) {
//         case 'Costing Invoice':
//             var invoice =  inputProps.Invoice.value?.id || 0
//             var brandName =  inputProps.BrandName.value?.id || 0
//             var productName =  inputProps.ProductName.value?.id || 0
//             var shopName =  inputProps.ShopName.value?.id || 0
//             url   = `/ReportInvoiceCosting/${invoice}/${inputProps.FromDate.value}/${inputProps.ToDate.value}/${brandName}/${productName}/${shopName}`;
      
//         // Open the URL in a new tab and focus on it
//          newTab = window.open(url, '_blank');
//         newTab.focus()
//             break;
//         case 'Stock Shop Wise':
//             var invoice =  inputProps.Invoice.value?.id || 0
//             var brandName =  inputProps.BrandName.value?.id || 0
//             var productName =  inputProps.ProductName.value?.id || 0
//             var shopName =  inputProps.ShopName.value?.id || 0
//              url = `/ReportInventory/${invoice}/${inputProps.FromDate.value}/${inputProps.ToDate.value}/${brandName}/${productName}/${shopName}`;
      
//         // Open the URL in a new tab and focus on it
//          newTab = window.open(url, '_blank');
//         newTab.focus()
//             break;
//         case 'Stock Report With Value':
//             var invoice =  inputProps.Invoice.value?.id || 0
//             var brandName =  inputProps.BrandName.value?.id || 0
//             var productName =  inputProps.ProductName.value?.id || 0
//             var shopName =  inputProps.ShopName.value?.id || 0
//              url = `/StockReportWithValue/${invoice}/${inputProps.FromDate.value}/${inputProps.ToDate.value}/${brandName}/${productName}/${shopName}/With`;
      
//         // Open the URL in a new tab and focus on it
//          newTab = window.open(url, '_blank');
//         newTab.focus()
//             break;
//         case 'Stock Report With Out Value':
//             var invoice =  inputProps.Invoice.value?.id || 0
//             var brandName =  inputProps.BrandName.value?.id || 0
//             var productName =  inputProps.ProductName.value?.id || 0
//             var shopName =  inputProps.ShopName.value?.id || 0
//              url = `/StockReport/${invoice}/${inputProps.FromDate.value}/${inputProps.ToDate.value}/${brandName}/${productName}/${shopName}/WithOut`;
      
//         // Open the URL in a new tab and focus on it
//          newTab = window.open(url, '_blank');
//         newTab.focus()
//             break;
    
//         default:
//             break;
//     }
//     };
const columnCount = 3; // Number of columns
const rowsPerColumn = 5; // Maximum rows per column

const columns = Array.from({ length: columnCount }, (_, colIndex) =>
    reportNames
        .filter((res) => res != undefined)
        .slice(colIndex * rowsPerColumn, (colIndex + 1) * rowsPerColumn)
);

return (
    <>
      <div onKeyDown={(e)=>e.stopPropagation()} className='content-wrapper'>
      {/* <FirstGridComponent/> */}
      <StructuredGenerate   mainProps={props} title={`Reports`} Inputs={inputsList}/>


<div style={containerStyle}>
    {columns.map((columnReports, colIndex) => (
        <div key={colIndex} style={columnStyle}>
            {columnReports.map((report, index) => (
                <div key={index + colIndex * rowsPerColumn}>
                    <input
                        type="radio"
                        id={`report${index + 1 + colIndex * rowsPerColumn}`}
                        name="report"
                        value={report}
                        onChange={handleRadioChange}
                    />
                    <label
                        id={`${report}_label`}
                        htmlFor={`report${index + 1 + colIndex * rowsPerColumn}`}
                        style={labelStyle}
                    >
                        {report.replace(/([a-z])([A-Z])/g, '$1 $2')}
                    </label>
                </div>
            ))}
        </div>
    ))}
</div>

            {generateReport == true ? <p>Loading Report...</p>:
            <button className='e-input e-control e-btn e-lib'  id='report-input-btn' onClick={handleDownload}>
                Pdf Report Export
            </button>}
      </div>
    </>
)
}
export default Reports;
