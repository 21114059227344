import React, { useRef, useState , useEffect,useMemo} from 'react';
import moment from 'moment';
import {
  StructuredGenerate,
  DynamicDropDownInput,
  DynamicNumericInput,
  DynamicDateInput,
  DynamicTextInput,
  DynamicCheckBoxInput,
  DynamicHiddenInput,
  DynamicButtonInput
} from '../irtiza-dynamic-input/irtiza-dynamic-main-inputs';


const ShopAdd = (props) => {
  let { DynamicDropDownInput: DropdownComponent, DynamicNumericInput: NumberComponent, DynamicDateInput: DateComponent , DynamicTextInput: TextComponent ,DynamicCheckBoxInput:CheckBoxComponent , DynamicHiddenInput : HiddenComponent , DynamicButtonInput:ButtonComponent} = { DynamicDropDownInput, DynamicNumericInput, DynamicDateInput ,DynamicTextInput , DynamicCheckBoxInput,DynamicHiddenInput,DynamicButtonInput};
  const [inputProps, setInputProps] = useState({
    ShopID:{name: 'ShopID',   value: props.ShopID || '' ,enabled:false},
    ShopName:{name: 'ShopName',   value: props.ShopName || '' },
    LocalCurrencyOpening:{name: 'LocalCurrencyOpening',   value: props.LocalCurrencyOpening || 0 },
    SerialNo:{name: 'SerialNo',   value: props.SerialNo || 0 },
    });
// EXTRA CODE START
// EXTRA CODE END
const handleInputCreated = async (e, name) => {
    console.log(`Input ${name} changed to ${e}`);
  };
const handleInputFocus = async (e, name) => {
    console.log(`Input ${name} focus to ${e}`);
  };
const handleInputChange = async (value, name) => {
   console.log(`Input ${name} focus to ${value}`);
  };
const handleInputBlur = async  (value, name) => {
    console.log(`Input ${name} focus to ${value}`);
  };
 
  let inputsList = [
    {Input: HiddenComponent ,Props: {...inputProps.ShopID, setInputProps },ColumnSize:0},
    {Input: TextComponent,Props: {...inputProps.ShopName,setInputProps },ColumnSize:12},
    {Input: NumberComponent,Props: {...inputProps.LocalCurrencyOpening, setInputProps },ColumnSize:6},
    {Input: NumberComponent,Props: {...inputProps.SerialNo, setInputProps },ColumnSize:6},
    ];
  return (
    <div onKeyDown={(e)=>e.stopPropagation()}>
      <StructuredGenerate   mainProps={props} title={`Shop ${props.isAdd == true ? `Add` : `Edit`}`} Inputs={inputsList}/>
      {/* <pre>{JSON.stringify(inputProps, null, 2)}</pre> This is to display current state for debugging */}
      

      </div>
  );
};  
export default ShopAdd;