import ThreeInputService from '../country-productSize-productCategory/country-productSize-productCategory.service';
import SecurityService from '../security/security.service';


class AuditTable {
   
    auditDelete = async (table ,id,values)=>{

       console.log(table, id,values)
       const userlogs= await SecurityService.userLog()
       let data = {
         TableName:'UserLog',
         Columns:{
            UserID:	SecurityService.getLoggedInUserId(),
            MacAddress:userlogs[0].MacAddress,
            ComputerName:userlogs[0].DeviceName,
            InputName:table,
            ActionType:'Delete',
            ActionDate:new Date().toISOString().slice(0, 19).replace('T', ' '),
            EntryID:id,
            OldValue:JSON.stringify(values),
            NewValue:'{}'
         },
         appendCreationDate:false
       }
       console.log(navigator.platform)
       console.log(navigator)
       ThreeInputService.addMultipleInputs(data).then((response) =>{
      console.log(response)
       })
    }
     filterValues =   async (column,props,editprops)=>{

      let oldValue = {}
      let newValue = {}
       column.map((res)=>{
         if(props[res] != editprops[res]){
         newValue[res] = editprops[res]
         oldValue[res] = props[res]
        }
      })
     const finalres = [JSON.stringify(oldValue),JSON.stringify(newValue)]
      return finalres
    }

//  systemInfoUserLog = async ()=>{
//    var result =[]
//    SecurityService.userLog().then((res)=>{
//       console.log(res)
//        let me = {
//          MacAddress:res.data[0].MacAddress,
//          DeviceName:res.data[0].DeviceName

//       }
//       console.log(me)
//       return result =me
//    })
//    console.log(result)
//    return [result];
// }

    auditEdit =  async (column,props,editprops,inputName,entryID)=>{
       
      const userlogs= await SecurityService.userLog()
       const res = await this.filterValues(column,props,editprops);
       console.log(res)
      //  appendCreationDate
       console.log(userlogs)
      let data = {
         TableName:'UserLog',
         Columns:{
            UserID:	SecurityService.getLoggedInUserId(),
            MacAddress:userlogs[0].MacAddress,
            ComputerName:userlogs[0].DeviceName,
            InputName:inputName,
            ActionType:'Edit',
            ActionDate:new Date().toISOString().slice(0, 19).replace('T', ' '),
            EntryID:entryID,
            OldValue:res[0],
            NewValue:res[1]
         },
         appendCreationDate:false
       }
       console.log(navigator.platform)
       console.log(navigator)
       ThreeInputService.addMultipleInputs(data).then((response) =>{
      console.log(response)
       })

    
    }
    
   getEnterID = async (tableName,tableID)=>{
      let getData = {
         TableName:tableName,
         Columns:[`max(${tableID}) as EntryID`]
      }
      var entry
    await  ThreeInputService.getThreeInputsForList(getData).then(res=>{
         console.log(res)
         return entry= {
               EntryID: res.data.recordset[0].EntryID
            
         }
      })
      return entry
   }
   auditAdd = async (inputName,tableName,tableID,values)=>{
      console.log(inputName,tableName,tableID)
      const userlogs= await SecurityService.userLog()
      const {EntryID} = await this.getEnterID(tableName,tableID);
      console.log(EntryID)
 
      let data = {
         TableName:'UserLog',
         Columns:{
            UserID:	SecurityService.getLoggedInUserId(),
            MacAddress:userlogs[0].MacAddress,
            ComputerName:userlogs[0].DeviceName,
            InputName:inputName,
            ActionType:'Add',
            ActionDate:new Date().toISOString().slice(0, 19).replace('T', ' '),
            EntryID,
            OldValue:'{}',
            NewValue:JSON.stringify({...values})
         },
         appendCreationDate:false
       }
       console.log(navigator.platform)
       console.log(navigator)
       ThreeInputService.addMultipleInputs(data).then((response) =>{
      console.log(response)
       })
    }

}

export default new AuditTable()