import http from '../../services/http-common';

class InvoiceEvaluationService {
  constructor() {}
  getInvoiceEvaluation() {
    return http.get(`/InvoiceEvaluation/list`);
  }
  
  getAccessoyrBOMAddForList(data) {
    return http.post(`/AccessoryBOM/add/grid/list`,data);
  }
  
  getAccessoryBOMExportContract(id) {
    return http.get(`/AccessoryBOM/getAccessoryBOMExportContract/${id}`);
  }
  
  addAccessoryBOM(data) {
    return http.post(`/AccessoryBOM/add`, data);
  }
  addAccessoryBOMDetail(data) {
    return http.post(`/AccessoryBOM/detail/add`, data);
  }
  updateAccessoryBOM(data) {
    return http.put(`/AccessoryBOM/update`, data);
  }
  updateAccessoryBOMDetail(data) {
    return http.put(`/AccessoryBOM/detail/update`, data);
  }
  getCostingAmount(id) {
    return http.get(`/AccessoryBOM/getCostingAmount/${id}`);
  }
  getTargetAmount(id) {
    return http.get(`/AccessoryBOM/getTargetAmount/${id}`);
  }
  
}

export default new InvoiceEvaluationService();
