import React from 'react';
import {Route, Redirect} from 'react-router-dom';
import securityService from '../security/security.service';

export default function ProtectedRoute({component: Component, ...rest})
{        
    return (
        <Route {...rest} render= {
            (props) => {
                if(securityService.isAuthenticated()){
                    return <Component {...rest} {...props}/>                    
                }
                else{
                    return <Redirect to= {
                        {
                            pathname: '/',
                            state: {
                                from: props.location
                            }
                        }
                    }/>
                }            
            }
        }/>
    );
}