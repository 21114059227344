import http from '../../services/http-common';

class SecurityService {
  constructor() {
    this.authenticated = false;
  }

  isAuthenticated() {
    return localStorage.getItem('login') !== null;
  }

  // I want to set this.authenticated to true on successful login
  authenticateUser(data) {
    return http.post('/security/authenticateUser', data);
  }

  changePassword(data) {
    return http.post('/security/changePassword', data);
  }

  verifyEmail(data) {
    return http.post('/security/verifyEmail', data);
  }

  getLoggedInUser() {
    const loggedInUser = JSON.parse(localStorage.getItem('login'));
    console.log('login user detail' ,  loggedInUser)
    if(loggedInUser?.username){
      return loggedInUser.username;
    }
    return 'Unknown';
  }
  getLoggedInShopID() {
    const loggedInUser = JSON.parse(localStorage.getItem('login'));
    console.log('login user detail' ,  loggedInUser.ShopID)
    if(loggedInUser?.ShopID){
      return loggedInUser.ShopID;
    }
    return null
  }

  getLoggedInUserId() {
    const loggedInUser = JSON.parse(localStorage.getItem('login'));
    console.log(loggedInUser)
    return loggedInUser?.userId;
  }

  canUserAccess(itemName) {
    if (itemName) {
      const accessList = JSON.parse(localStorage.getItem('userAccessList'));
      console.log('loggedInUserAccessList: ', accessList);
      console.log('ItemName: ', itemName);
      const itemFound = accessList?.filter(item => item.ItemName === itemName && item.CanAccess);
      console.log('itemFound: ', itemFound);
      return itemFound?.length > 0;
    }
    else {
      return false;
    }
  }
  userLog = async () => {
    var dat
 await  http.get('security/os').then((res)=>{
      console.log(res)
 dat = res.data
    })
    return dat
  }

  getUserAccessList(userId) {
    return http.get(`/security/accessList/${userId}`);
  }
  getUserAccessListSingleLine(userId) {
    return http.get(`/security/accessList/singleLine/${userId}`);
  }

  getSecurityItems() {
    return http.get(`/security/items`);
  }

  getSecurityItemTypes() {
    return http.get(`/security/itemTypes`);
  }

  addSecurityItem(data) {
    return http.post(`/security/items/add`, data);
  }

  changeAccess(data) {
    console.log(data);
    return http.post(`/security/changeAccess`, data);
  }

  changeAccessMenu(data , id) {
    console.log(data);
    return http.post(`/security/changeAccess/menu/${id}`, data);
  }
  changeAccessList(data , id) {
    console.log(data);
    return http.post(`/security/changeAccess/list/${id}`, data);
  }
  changeAccessEdit(data , id) {
    console.log(data);
    return http.post(`/security/changeAccess/edit/${id}`, data);
  }
  changeAccessAdd(data , id) {
    console.log(data);
    return http.post(`/security/changeAccess/add/${id}`, data);
  }
  changeAccessDelete(data , id) {
    console.log(data);
    return http.post(`/security/changeAccess/delete/${id}`, data);
  }

 CompanyIP (){
       return http.get(`/security/company/ip`)
}
emailSend(data) {
  return http.post('/mail/pass', data);
}
getIP() {
  return http.get('https://api64.ipify.org?format=json');
}

 OpeningDate = async () => {
  try {
    const response = await http.get('security/company/openingDate');
    console.log(response.data.recordset[0].OpeningDate);
    return response.data.recordset[0].OpeningDate;
  } catch (error) {
    console.error('Error fetching opening date:', error);
    throw error; // Rethrow the error to be handled by the caller
  }
};


}
export default new SecurityService();
