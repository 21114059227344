import React, { useState, useEffect, useRef } from 'react';
import { GridComponent, ColumnsDirective, ColumnDirective, Page, Inject } from '@syncfusion/ej2-react-grids';
import InvoiceService from '../invoice/invoice.service';

const STEPS = 101; // Number of records to load in each step
let CHUNK_SIZE = 0; // Number of records to load in each step
const INTERVAL = 2000; // Interval time in milliseconds (2 seconds)

const InputTest = () => {
  const [processDesc, setProcessDesc] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const allRecordsRef = useRef([]); // Use a ref to store allRecords
  const intervalIdRef = useRef(null); // Use a ref to store the interval ID

  useEffect(() => {
    getProcessDesc();
    return () => clearInterval(intervalIdRef.current); // Clear interval on unmount
  }, []);
  const findValidStepValue = (totalRecords, maxSteps) => {
    for (let steps = 4; steps <= maxSteps; steps++) {
      const chunkSize = Math.floor(totalRecords / steps);
      if (totalRecords % steps === 0) {
        return { steps, chunkSize };
      }
    }
    return { steps: 1, chunkSize: totalRecords }; // Fallback if no valid step found
  };
  const getProcessDesc = async () => {
    try {
      const response = await InvoiceService.getInvoiceForList(0, 'II', 'Sale');
      console.log('Fetched all data:', response);

      // Store all records in the ref and load the first chunk
      allRecordsRef.current = response.data.recordset;
      // const { steps, chunkSize } = findValidStepValue(response.data.recordset.length, 20); // Max 20 steps
      const { steps, chunkSize } = findValidStepValue(response.data.recordset.length, STEPS); // Max 20 steps
      console.log(steps)
      console.log(chunkSize)

      // setChunkSize(calculatedChunkSize)
      CHUNK_SIZE = chunkSize
      setProcessDesc(response.data.recordset.slice(0, CHUNK_SIZE));
      setCurrentIndex(CHUNK_SIZE);

      // Start loading the remaining records in intervals
      intervalIdRef.current = setInterval(() => {
        loadMoreRecords();
      }, response.data.recordset.length > 50000 ? 1000 : INTERVAL);
    } catch (error) {
      console.log(error);
    }
  };

  const loadMoreRecords = () => {
    const allRecords = allRecordsRef.current; // Access allRecords from the ref
    console.log('All Records:', allRecords);
    const endIndex = Math.min(currentIndex + CHUNK_SIZE, allRecords.length);

    setProcessDesc((prevRecords) => [
      ...prevRecords,
      // ...allRecords.slice(currentIndex, currentIndex + CHUNK_SIZE),
      ...allRecords.slice(currentIndex, endIndex),
    ]);
    setCurrentIndex((prevIndex) => {
      const newIndex = prevIndex + CHUNK_SIZE;
      
      // Clear the interval once all records are loaded
      if (newIndex >= allRecords.length) {
        clearInterval(intervalIdRef.current);
      }
      
      return newIndex;
    });
  };

  return (
    <div className='content-wrapper'>
      <GridComponent
        dataSource={processDesc}
        allowPaging={true}
        pageSettings={{ pageCount: 4, pageSizes: true, pageSize: 12 }}
      >
        <ColumnsDirective>
          <ColumnDirective field='BrandID' headerText='Order ID' width='100' textAlign='Right' />
          <ColumnDirective field='BrandName' headerText='Customer Name' width='120' />
        </ColumnsDirective>
        <Inject services={[Page]} />
      </GridComponent>
    </div>
  );
};

export default InputTest;



// import React, { useEffect, useState } from 'react';

// export default function Input() {
//   const [state, setState] = useState(false);  // Corrected the variable naming

//   useEffect(() => {
//     // You can add your effect logic here
//     setTimeout(() => {
//       console.log('irtdjskldfj')
      
//     }, 5000);
//   }, [state == true]);

//   const handleClick = () => {
//     setState(true);
//   };

//   return (
//     <div className='content-wrapper'>
//       <button onClick={handleClick}></button>
//       {/* You can add your input or other elements here */}
//     </div>
//   );
// }
