import http from '../../services/http-common';

class VoucherService {
    
    getVoucherForList(shopID ,type, listType) {
        return http.get(`/Voucher/list/${shopID}/${type}/${listType}`);
    }
    getVoucherIDValue(shopID, type) {
        return http.get(`/Voucher/VoucherValue/${shopID}/${type}`);
    }
    getMultiDropDownSource() {
    return http.get(`/Voucher/mainTransferID/multidropdown`);
    }  
    getGridDataSource(data) {
        return http.post(`/Voucher/detail/list` , data);
    }



    addVoucher(data) {
        return http.post(`/Voucher/add`, data);
    }
    addGridVoucher(data) {
        return http.post(`/Voucher/grid/add`, data);
    }
    editVoucher(data) {
        return http.put(`/Voucher/edit`, data);
    }
    editGridVoucher(data) {
        return http.put(`/Voucher/grid/edit`, data);
    }
   
getUnitOfMeasurement(id) {
    return http.get(`/Invoice/unitOfMeasurement/${id}`);
}  
       
       
       
       
       
       
       
       
       
       
       
       
       
       
       
       
       
       
       
       
        insert(data) {
            return http.post(`/Voucher/add`,data);
        }
        update(data) {
            return http.put(`/Voucher/edit`,data);
        }





getVoucherIDforFirst(id) {
    return http.get(`/Voucher/first/voucherID/${id}`);
}
getVoucherIDOtherEntry(id) {
    return http.get(`/Voucher/otherEntry/voucherID/${id}`);
}
}

export default new VoucherService();