import React,{useEffect} from 'react';
import { Link } from 'react-router-dom';
import ProtectedMenu from '../components/security/protected.menu';
import ProtectedSubmenu from '../components/security/protected.submenu';

export default function Menu() {

  useEffect(()=>{
    
    const navItem = document.querySelectorAll('ul .nav-item');
    
    navItem.forEach((va)=>{
      console.log(va.firstChild)
      // classList.contains("myClass")
      if(va.firstChild.classList.contains("nav-treeview")){
        console.log(va)
        va.remove()
      }
    })
   
  },[])
  return (
    <div>
      <aside className='main-sidebar sidebar-light-success elevation-4'>
        <p></p>
        <div className='sidebar'>
          <div className='form-inline'>
            <div className='input-group' data-widget='sidebar-search'>
              <input
                className='form-control form-control-sidebar'
                type='search'
                placeholder='Search'
                aria-label='Search'
              />
              <div className='input-group-append'>
                <button className='btn btn-sidebar'>
                  <i className='fas fa-search fa-fw' />
                </button>
              </div>
            </div>
          </div>
          {/* Sidebar Menu */}
          <nav className='mt-2'>
            <ul
              className='nav nav-pills nav-sidebar flex-column'
              data-widget='treeview'
              role='menu'
              data-accordion='false'>
              {/* Add icons to the links using the .nav-icon class
           with font-awesome or any other icon font library */}
              {/* <li className='nav-item menu-open'>
                  <a href='#' className='nav-link active'>
                    <i className='nav-icon fas fa-tachometer-alt' />
                    <p>
                      Dashboard
                      <i className='right fas fa-angle-left' />
                    </p>
                  </a>
                  <ul className='nav nav-treeview'>
                    <li className='nav-item'>
                      <Link to='./index.html' className='nav-link active'>
                        <i className='fas fa-external-link-alt nav-icon' />
                        <p>Dashboard v1</p>
                      </Link>
                    </li>
                    <li className='nav-item'>
                      <Link to='./index2.html' className='nav-link'>
                        <i className='fas fa-external-link-alt nav-icon' />
                        <p>Dashboard v2</p>
                      </Link>
                    </li>
                    <li className='nav-item'>
                      <Link to='./index3.html' className='nav-link'>
                        <i className='fas fa-external-link-alt nav-icon' />
                        <p>Dashboard v3</p>
                      </Link>
                    </li>
                  </ul>
                </li> */}

              <li className='nav-item'>
                <ProtectedSubmenu
                    displayValue='Setup'
                    names = { [`Brand`,
                      `BrandVendor`,
                      `Currency`,
                      `Designation`,
                      `EvaluationRate`,
                      `Product`,
                      `Shop`,
                      `SystemLock`,
                      `UnitOfMeasurement`,
                      `AccountTransfer`,
                      `ProductTransfer`
                  ]}
                    type='mainmenu'
                    icon='fa-bars'
                    
                    />
                <ul className='nav nav-treeview'>
                <ProtectedMenu
                         to='/Setup/Brand'
                         name='Brand'
                         displayName='Brand'
                      />
                <ProtectedMenu
                         to='/Setup/AccountTransfer'
                         name='AccountTransfer'
                         displayName='Account Transfer'
                      />
                <ProtectedMenu
                         to='/Setup/BrandVendor'
                         name='BrandVendor'
                         displayName='Brand Vendor'
                      />
                         <ProtectedMenu
                                to='/Setup/Currency'
                                name='Currency'
                                displayName='Currency'
                             />
                         <ProtectedMenu
                                  to='/Setup/Designation'
                                  name='Designation'
                                  displayName='Designation'
                               />
                         <ProtectedMenu
                                to='/Setup/EvaluationRate'
                                name='EvaluationRate'
                                displayName='Evaluation Rate'
                             />
                                  <ProtectedMenu
                                         to='/Setup/Product'
                                         name='Product'
                                         displayName='Product'
                                      />
                                  <ProtectedMenu
                                         to='/Setup/ProductTransfer'
                                         name='ProductTransfer'
                                         displayName='Product Transfer'
                                      />
                         <ProtectedMenu
                                  to='/Setup/Shop'
                                  name='Shop'
                                  displayName='Shop'
                               />
                         <ProtectedMenu
                                  to='/Setup/SystemLock'
                                  name='SystemLock'
                                  displayName='System Lock'
                               />
                <ProtectedMenu
                         to='/Setup/UnitOfMeasurement'
                         name='UnitOfMeasurement'
                         displayName='Unit Of Measurement'
                      />
                       
                  
                
                
                </ul>
              </li>

              
        
      <li className='nav-item'>
              <ProtectedSubmenu
                    displayValue='Inventory '
                    names = { [ `CurrencyTransfer`,`InvoiceEvaluation` , `PurchaseInvoice` , `SaleInvoice`,`ShopTransfer`]}
                    type='mainmenu'
                    icon=' fa-list-ul'
                    
                    />
                <ul className='nav nav-treeview'>
                <ProtectedMenu
                        to='/CurrencyTransfer'
                        name='CurrencyTransfer'
                        displayName='Currency Transfer'
                      />
                <ProtectedMenu
                        to='/InvoiceEvaluation'
                        name='InvoiceEvaluation'
                        displayName='Invoice Evaluation'
                      />

                
               

        <li className='nav-item'>
                  <ProtectedSubmenu
                    displayValue='Purchase Invoice'
                    names = { [`PurchaseInvoice`]}
                    type='submenu'
                    icon='nav-icon'
                    
                    />
                <ul className='nav nav-treeview'>
                <ProtectedMenu
                        to='/Invoice/Purchase/Detail'
                        name='PurchaseInvoice'
                        displayName='Detail'
                      />
                <ProtectedMenu
                         to='/Invoice/Purchase/Summary'
                         name='PurchaseInvoice'
                         displayName='Summary'
                      />
               
                  </ul>
                  </li>
        <li className='nav-item'>
                  <ProtectedSubmenu
                    displayValue='Sale Invoice'
                    names = { [`SaleInvoice`]}
                    type='submenu'
                    icon='nav-icon'
                    
                    />
                <ul className='nav nav-treeview'>

                <ProtectedMenu
                        to='/Invoice/Sale/Detail'
                        name='SaleInvoice'
                        displayName='Detail'
                      />
                <ProtectedMenu
                         to='/Invoice/Sale/Summary'
                         name='SaleInvoice'
                         displayName='Summary'
                      />
               
                  </ul>
                  </li>

        <li className='nav-item'>
                  <ProtectedSubmenu
                    displayValue='Shop Transfer'
                    names = { [`ShopTransfer`]}
                    type='submenu'
                    icon='nav-icon'
                    
                    />
                <ul className='nav nav-treeview'>

                <ProtectedMenu
                        to='/ShopTransfer/Detail'
                        name='ShopTransfer'
                        displayName='Detail'
                      />
                <ProtectedMenu
                         to='/ShopTransfer/Summary'
                         name='ShopTransfer'
                         displayName='Summary'
                      />
               
                  </ul>
                  </li>
                
                

               
                </ul>
              </li>

              <li className='nav-item'>
                 <ProtectedSubmenu
                    displayValue='Finance '
                    names = { [ `JournalVoucher`,`PaymentVoucher`,`ReceiptVoucher`]}
                    type='mainmenu'
                    icon='fa-sitemap'
                    
                    />
                <ul className='nav nav-treeview'>
                  
                   <ProtectedMenu
                    to='/ChartOfAccount'
                    name='ChartOfAccount'
                    displayName='Chart Of Account'
                  />
                   <li className='nav-item'>
                  <ProtectedSubmenu
                    displayValue='Journal Voucher'
                    names = { [`JournalVoucher`]}
                    type='submenu'
                    icon='nav-icon'
                    
                    />
                <ul className='nav nav-treeview'>

                  <ProtectedMenu
                    to='/Voucher/J/Detail'
                    name='JournalVoucher'
                    displayName='Detail'
                  />
                  <ProtectedMenu
                    to='/Voucher/J/Summary'
                    name='JournalVoucher'
                    displayName='Summary'
                  />
                  </ul>
                  </li>
                   <li className='nav-item'>
                  <ProtectedSubmenu
                    displayValue='Payment Voucher'
                    names = { [`PaymentVoucher`]}
                    type='submenu'
                    icon='nav-icon'
                    
                    />
                <ul className='nav nav-treeview'>

                <ProtectedMenu
                    to='/Voucher/CP/Detail'
                    name='PaymentVoucher'
                    displayName=' Detail'
                  />
                  <ProtectedMenu
                    to='/Voucher/CP/Summary'
                    name='PaymentVoucher'
                    displayName=' Summary'
                  />
                  </ul>
                  </li>
                   <li className='nav-item'>
                  <ProtectedSubmenu
                    displayValue='Receipt Voucher'
                    names = { [`ReceiptVoucher`]}
                    type='submenu'
                    icon='nav-icon'
                    
                    />
                <ul className='nav nav-treeview'>

                <ProtectedMenu
                    to='/Voucher/CR/Detail'
                    name='ReceiptVoucher'
                    displayName='Detail'
                  />
                  <ProtectedMenu
                    to='/Voucher/CR/Summary'
                    name='ReceiptVoucher'
                    displayName='Summary'
                  />
                  </ul>
                  </li>
                  
                  
                  
                  
                </ul>
              </li>
              <li className='nav-item'>
                 <ProtectedSubmenu
                    displayValue='Security '
                    names = { [ `Users`,`ChangeAccessList`,`UserReplicate`,`ChangePassword`]}
                    type='mainmenu'
                    icon='fa-sitemap'
                    
                    />
                <ul className='nav nav-treeview'>
                <ProtectedMenu
                         to='/Users'
                         name='Users'
                         displayName='Users'
                      />
                <ProtectedMenu
                        to='/UserReplicate'
                        name='UserReplicate'
                        displayName='User Replicate'
                      />
                <ProtectedMenu
                         to='/Security/ChangePassword'
                         name='ChangePassword'
                         displayName='Change Password'
                      />
                  <ProtectedMenu
                        to='/Security/ChangeAccess'
                        name='ChangeAccessList'
                        displayName='User Rights'
                      />

                </ul>
              </li>
              <li className='nav-item'>
                 <ProtectedSubmenu
                    displayValue='Reports '
                    names = { [
                      `ReportInvoiceCostingList`,
                            `ReportStockShopWiseList`,
                            `ReportStockWithValueList`,
                            `ReportStockWithoutValueList`,
                            `ReportInventoryWithPLList`,
                            `ReportCashBookUS$List`,
                            `ReportLedgerList`]}
                    type='mainmenu'
                    icon='fa-sitemap'
                    
                    />
                <ul className='nav nav-treeview'>
                {/* <ProtectedMenu
                         to='/Reports'
                         name='ChangePassword'
                         displayName='Reports'
                      /> */}
                       <li className='nav-item'>
                      <Link to='/Reports' className='nav-link active'>
                        <i className='fas fa-external-link-alt nav-icon' />
                        <p>Reports</p>
                      </Link>
                    </li>
                       <li className='nav-item'>
                      <Link to='/SendEmail' className='nav-link active'>
                        <i className='fas fa-external-link-alt nav-icon' />
                        <p>Email</p>
                      </Link>
                    </li>
                </ul>
              </li>

              {/* <ProtectedMenu
                    to='/Reports'
                    name='Reports'
                    displayName='Journal Voucher Summary'
                  /> */}
                  

            </ul>

          </nav>
          {/* /.sidebar-menu */}
        </div>
        {/* /.sidebar */}
      </aside>
    </div>
  );
}
