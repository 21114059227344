import { React } from 'react';
import './access-denied.scss';

export default function AccessDenied() {    
    return (
        <div className='access-denied'>           
            <div className="fa fa-ban ban-icon"></div>
            <h3>Access to this page is restricted</h3>
            <div className='message'>
                <p>
                    Sorry, you are not allowed to access this resource. Please contact your admin.
                </p>
            </div>     
        </div>
    );    
}
