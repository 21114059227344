import http from '../../services/http-common';

class ReportService {
    
    
  getCashBookUsdVoucher(data) {
        return http.post(`/CashBookUsdVoucher/getSpFinance` , data);
    }
    getChartOfAccount() {
        return http.get(`/ReportChartOfAccount` );
    }
    getSaleReport(data) {
        return http.post(`/SaleReport` ,data );
    }
}

export default new ReportService();