import axios from "axios";
export default axios.create({

    baseURL: "https://hapafrica.org/api/v1",
    // baseURL: "http://localhost:5000/api/v1",
    headers: {
        "Content-type": "application/json",
        "Access-Control-Allow-Origin": "*"
    }
})


// import axios from "axios";
// export default axios.create({
//     baseURL: "http://localhost:5000/api/v1",
//     headers: {
//         "Content-type": "application/json",
//         "Access-Control-Allow-Origin": "*"
//     }
// })