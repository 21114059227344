import React, { useRef, useState, useEffect } from 'react';
import IdleTimer from 'react-idle-timer';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';

let countdownInterval;
let sessionTimeout;

const IdleTimerContainer = ({ extraTime, standardTime}) => {
    const idleTimerRef = useRef(null);
    const [signedIn, setSignedIn] = useState(true);
    const linkElem = useRef(null);
    const [timeoutTime, setTimeoutTime] = useState(standardTime ? standardTime: 5*1000);
    const [isExtended, setIsExtended] = useState(false);
    const extendedTime = extraTime ? extraTime: 5 * 1000;
    const [open, setOpen] = useState(false);
    const [timeoutCountdown, setTimeoutCountdown] = useState(0);

    useEffect(() => {
        if(localStorage.getItem('login')){
            setSignedIn(true);
            const loginDetails = JSON.parse(localStorage.getItem('login'));
            if(loginDetails?.remembered){
                console.log('extending time');
                setIsExtended(true);
                idleTimerRef.current.reset();
                console.log(idleTimerRef.current.getRemainingTime());
            }
        } else {
            setSignedIn(false);
        }
    }, [idleTimerRef, localStorage]);

    const logout = () => {
        //console.log('idle');
        localStorage.removeItem('login');
        setSignedIn(false);
        linkElem.current.click();
    }

    const triggerTimeoutDialog = (countdownTime = 10) => {
        sessionTimeout =  setTimeout(() => {
            let time = countdownTime;
            handleClickOpen();
            setTimeoutCountdown(time);
            countdownInterval = setInterval(() => {
                if(time > 0){
                    setTimeoutCountdown(--time);
                } else {
                    logout();
                }
            }, 1000);
        }, 1000)
    }
    const onIdle = () => {
        // console.log('idle');
        if (isExtended){
            setIsExtended(false);
            setTimeout(triggerTimeoutDialog, extendedTime);
        } else {
            triggerTimeoutDialog();
        }
    };

    const handleClickOpen = () => {
        setOpen(true);
    };
    
    const handleClose = (extend) => {
        setOpen(false);
        if (extend){
            clearInterval(countdownInterval);
            clearTimeout(sessionTimeout);
            idleTimerRef.current.start();
        } else {
            logout();
        }
    };

    const checkLoggedIn = () => {
        if(!localStorage.getItem('login')){
            console.log('Action');
            setSignedIn(false);
        }
    }

    return (
        <div>
            {signedIn && 
                <IdleTimer 
                    ref={idleTimerRef} 
                    stopOnIdle 
                    timeout={timeoutTime} 
                    onIdle={onIdle} 
                    debounce={250}
                    onAction={checkLoggedIn}
                >
                </IdleTimer>
            }
            <a ref={linkElem} href="/" />
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                {"Session Timeout"}
                </DialogTitle>
                <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Your current session is about to expire in {timeoutCountdown} seconds.
                    Would you like to extend the session?
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                <Button onClick={() => handleClose(false)}>No</Button>
                <Button onClick={() => handleClose(true)} autoFocus>
                    Yes
                </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default IdleTimerContainer

