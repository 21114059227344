import http from '../../services/http-common';

class InvoiceEvaluationService {
    getInvoiceEvaluation() {
        return http.get(`/InvoiceEvaluation/list`);
    }
    getDefaultAccountTitleHawalaDr() {
    return http.get(`/InvoiceEvaluation/default/accountTitleHawalaDr`);
}
getDefaultAccountTitleHawalaCr() {
    return http.get(`/InvoiceEvaluation/default/accountTitleHawalaCr`);
}
insert(data) {
    return http.post(`/InvoiceEvaluation/add`,data);
}
update(data) {
    return http.put(`/InvoiceEvaluation/edit`,data);
}
}

export default new InvoiceEvaluationService();