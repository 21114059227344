import Header from '../layout/header';
import http from './http-common';

class HardDeleteService {
  hardDelete(data) {
    return http.delete('/hardDelete/remove',{  headers: 
      {
       'Content-Type': 'application/json',
      },
      data:data
}
    );
  }
  
}

export default new HardDeleteService();
