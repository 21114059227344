import React, { useState, useEffect } from 'react';
import moment from 'moment';
// import ReportService from '../reports.service'
import { Page, Text, View, Document, StyleSheet, PDFViewer } from '@react-pdf/renderer';


// Sample data (you can replace this with your actual data)


// Create styles
const styles = StyleSheet.create({
  page: {
    padding: 30,
  },
  section: {
    margin: 10,
    padding: 10,
    fontSize: 12,
  },
  tableRow: {
    margin: 'auto',
    flexDirection: 'row',
  },
  tableCol: {
    width: '25%',
    // borderStyle: 'solid',
    // borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableCell: {
    margin: 5,
    fontSize: 11,
  },
  title: {
    fontSize: 16,
    marginBottom: 10,
    fontWeight: 'bold',
  },
  item: {
    marginLeft: 10,
    fontSize: 12,
  },
  nestedItem: {
    marginLeft: 20,
  },
  header: {
        textAlign: 'center',
        fontSize: 18,
        marginBottom: 20,
        fontStyle:'italic' 
        
      }
});
const calculateTotals = (data) => {
  let totalOpBal = 0;
  

  data.forEach(item => {
    totalOpBal += parseFloat(item.ShowOpeningBalance);
    // totalCredit += parseFloat(item.Credit);
  });

  return {
    totalOpBal: totalOpBal.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 }),
    // totalCredit: totalCredit.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
  };
};

// Recursive component to render nested items
const RenderAccount = ({ key , account}) => {
    console.log(account.AccountCode.length)
    console.log(key)
    return(
    <View style={styles.table}>
    <View style={styles.tableRow}  key={key}>
                  
                  <View style={{ ...styles.tableCol, width: '30%' }}>
                    <Text style={{
                      ...styles.tableCell ,
                      marginLeft:account.AccountCode.length == 3 ? '10px' : account.AccountCode.length == 5 ? '18px' : account.AccountCode.length == 8 ?  '28px' :  account.AccountCode.length == 8 ?  '38px' : '5px',
                      fontSize:account.AccountCode.length == 1 ? '18px' : account.AccountCode.length == 3 ? '15px' : account.AccountCode.length == 5?  '13px' :  account.AccountCode.length == 8 ?  '11px' : '10px'
                      }}>{account.AccountCode}</Text>
                  </View>
                  <View style={{ ...styles.tableCol, width: '45%' }}>
                    <Text style={{
                      ...styles.tableCell ,
                      marginLeft:account.AccountCode.length == 3 ? '10px' : account.AccountCode.length == 5 ? '18px' : account.AccountCode.length == 8 ?  '28px' :  account.AccountCode.length == 8 ?  '38px' : '5px',
                      fontSize:account.AccountCode.length == 1 ? '18px' : account.AccountCode.length == 3 ? '15px' : account.AccountCode.length == 5?  '13px' :  account.AccountCode.length == 8 ?  '11px' : '10px'

                      }}>{account.AccountTitleShort}</Text>
                  </View>
                  <View style={{ ...styles.tableCol, width: '25%', textAlign: 'right' }}>
                    <Text style={styles.tableCell}>{
                      account.AccountCode.length == 1 || account.AccountCode.length == 3 || account.AccountCode.length == 5 ? null :
                      account.ShowOpeningBalance == 0 ? null :account.ShowOpeningBalance.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 })
                    }</Text>
                  </View>
                  
    </View>
    {/* <Text>
      {account.AccountCode} - {account.AccountTitleShort} : {account.ShowOpeningBalance}
    </Text> */}
    {account.children && account.children.map((child, index) => (
      <RenderAccount key={index} account={child} />
    ))}
  </View>
)};

// Main PDF Document

const Report = ({ data ,ApiData }) => {
  console.log(data);
  const { totalOpBal } = calculateTotals(ApiData);

  return (
    // <PDFViewer width="100%" height="1000">
      <Document>
        <Page size="A4" style={styles.page}>
          {/* Page Heading */}
          <Text style={styles.header}>CHART OF ACCOUNTS </Text>
          
          {/* Table Header */}
          <View style={{    flexDirection: 'row', borderBottom:'solid' ,borderBottomWidth:1 ,  borderTop:'solid' ,borderTopWidth:1,marginBottom:'5px'}} fixed>
            <View style={{ ...styles.tableCol, width: '30%' }}>
              <Text style={styles.tableCell}></Text>
            </View>
            <View style={{ ...styles.tableCol, width: '45%' }}>
              <Text style={{...styles.tableCell ,fontSize:13}}>Account</Text>
            </View>
            <View style={{ ...styles.tableCol, width: '25%' }}>
              <Text style={styles.tableCell}>OpBal</Text>
            </View>
          </View>

          {/* Table Content */}
          {data.map((account, index) => (
            <RenderAccount key={index} account={account} />
          ))}
          <View style={{    flexDirection: 'row',  borderTop:'solid' ,borderTopWidth:1} } >
            <View style={{ ...styles.tableCol, width: '75%' }}>
              <Text style={styles.tableCell}>Grand Total</Text>
            </View>
            
            <View style={{ ...styles.tableCol, width: '25%' }}>
              <Text style={{...styles.tableCell , textAlign:'right'}}>{totalOpBal}</Text>
            </View>
          </View>
        </Page>
      </Document>
    // </PDFViewer>
  );
};


const ChartOfAccountReport = (props) => {
    
    // const [result, setResult] = useState([]);
    // const [apiResult, setApiResult] = useState([]);
      
    //   useEffect( async () => {
    //     const response = await ReportService.getChartOfAccount();
    //     console.log(response.data.recordset)
    //     const  results = createTreeData(response.data.recordset);

    //      setResult(results)
    //      setApiResult(response.data.recordset)

    //     return () => {
            
    //     };
    //   }, []);
  return (
    <>
      {/* {result.length > 0 ?( <> */}
      <Report data={props.DataSource} ApiData={props.ApiDataSource} />
{/* <pre className='content-wrapper'>{JSON.stringify(result, null, 2)}</pre></>) */}
      {/* :null
} */}
    </>
  );
};

export default ChartOfAccountReport;

