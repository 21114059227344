import { React, useState } from "react";
import {
  ComboBoxComponent,
  FilteringEventArgs,
} from "@syncfusion/ej2-react-dropdowns";
//import { SampleBase } from './sample-base';
import { Query, Predicate } from "@syncfusion/ej2-data";
import { TextBoxComponent } from "@syncfusion/ej2-react-inputs";
import { Col, Row } from "react-bootstrap";
import "./MultiDropDown.scss";
const MultiDropDown = ({
  data,
  fields,
  placeholder,
  dropDownID,
  getDetails,
  idFieldName,
  excludingFields,
  values,
  enabled,
  columnWidths = {},
  columnHeadings = {},
  columnFontSizes = {}
}) => {
  const [contractID, setContractID] = useState();



  const onFiltering = (e) => {
    if (e.text != "" && data.length > 0) {
      var predicate = null;
      Object.keys(data[0]).map((value, key) => {
        console.log("dropdown filtering value", value);
        if (value.includes(idFieldName)) {
        } else if (predicate === null) {
          predicate = new Predicate(value, "contains", e.text, true);
        } else {
          predicate = predicate.or(value, "contains", e.text, true);
        }
      });
      console.log("predicate: ", predicate);
   
      let query = new Query();
      //frame the query based on search string with filter type.
      query = e.text !== "" && e.value !== "" ? query.where(predicate) : query;
      //pass the filter data source, filter query to updateData method.
      e.updateData(data, query);
    }
  };

  const headerTemplate = () => (
    <>
      <table>
        <tbody>
          <tr>
            {Object.entries(data[0]).map((value, key) => {
              // if field is a contract id return nothing
              if (value[0].includes("ContractID")) {
                return <></>;
              }
              return <th>{value[0]}</th>;
            })}
            {/* <th>Object Id</th>
        <th>YARN x</th>
        <th>YA as</th> */}
          </tr>
        </tbody>
      </table>
    </>
  );

  const widthStyle = (width) => ({
    width: `${width}px`,
  });

  const fontSizeStyle = (fontSize) => ({
    fontSize: `${fontSize}px`,
  });

  const getColumnStyle = (field) => {
    let style = {};
    if (columnWidths[field]) {
      style = {
        ...widthStyle(columnWidths[field])
      };
    }
    if (columnFontSizes[field]) {
      style = {
        ...style,
        ...fontSizeStyle(columnFontSizes[field])
      };
    }
    return style;
  }

  function getColumnHeading(dataField) {
    return columnHeadings[dataField] ? columnHeadings[dataField] : dataField;
  }

  function isEmpty(object) {
    return Object.keys(object).length === 0;
  }

  const itemTemplate = (data) => {
    let isFirst = true;
    return (
      <div>
        <table /*style={{tableLayout:'auto'}}*/>
          <tbody>
            <tr>
              {Object.entries(data).map((value, key) => {
                // if field is a contract id return nothing
                // TODO: change to generalise the fields to exclude
                //if (value[0].includes('ContractID')) {
                if (excludingFields.includes(value[0])) {
                  return;
                }
  
                // styles include column font size and width
                const columnStyle = getColumnStyle(value[0]);
                if (value[1] === -1) {
                  return isEmpty(columnStyle) ? (
                    <th key={key}>{getColumnHeading(value[0])}</th>
                  ) : (
                    <th key={key} style={columnStyle}>
                      {getColumnHeading(value[0])}
                    </th>
                  );
                } else {
                  const formattedValue =
                    typeof value[1] === 'number'
                      ? value[1].toLocaleString()
                      : value[1];
  
                  return isEmpty(columnStyle) ? (
                    <td key={key}>{formattedValue}</td>
                  ) : (
                    <td key={key} style={columnStyle}>
                      {formattedValue}
                    </td>
                  );
                }
              })}
            </tr>
          </tbody>
        </table>
      </div>
    );
  };
  

  const onChangeID = (e) => {
    console.log("e", e);
    if (
      e?.itemData &&
      Object.entries(e.itemData).find((a) => a[0].includes(idFieldName))
    ) {
      const currContractId = Object.entries(e.itemData).find((a) =>
        a[0].includes(idFieldName)
      )[1];
      if (currContractId > 0) {
        console.log("yarn Contract change: ", currContractId);
        // const inputId = e?.e?.srcElement.id;
        // console.log('yarn Contract change: ', inputId);
        // if(inputId === 'YarnContract'){
        setContractID(currContractId);
        // }
        getDetails(currContractId);
      } else {
        e.cancel = true;
      }
    }
  };

  const onOpen = (e) => {
    e?.popup?.element
      ?.querySelectorAll(".e-list-item")[0]
      ?.classList.add("e-disabled");
    e?.popup?.element
      ?.querySelectorAll(".e-list-item")[0]
      .classList.add("e-overlay");
  };

  return (
    <>
      <ComboBoxComponent
        delayUpdate="true"
        id={dropDownID}
        cssClass="e-multi-column"
        dataSource={data}
        floatLabelType="Auto"
        fields={fields}
        placeholder={placeholder}
        itemTemplate={itemTemplate}
        filtering={onFiltering}
        allowFiltering={true}
        popupHeight="270px"
      
        // popupWidth={'fitContent'}
        enabled={enabled === undefined ? true : enabled}
        //headerTemplate={headerTemplate}
        autofill={true}
        //open={onOpen}
        select={onChangeID}
        change={onChangeID}
        value={values?.Multicolumn ? values.Multicolumn : ""}
      />
      <TextBoxComponent
        className="e-input"
        type="hidden"
        placeholder="Yarn Contract Id"
        name={idFieldName}
        floatLabelType="Auto"
        value={contractID ? contractID : values?.FabricContractID}
      />
    </>
  );
};

export default MultiDropDown;
