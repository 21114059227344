import http from '../../services/http-common';

class ShopTransferService {
    
    getShopTransferForList(shopID,listType) {
        return http.get(`/ShopTransfer/list/${shopID}/${listType}`);
        }
    addShopTransfer(data) {
        return http.post(`/ShopTransfer/add`, data);
    }
    addGridShopTransfer(data) {
        return http.post(`/ShopTransfer/grid/add`, data);
    }
    editShopTransfer(data) {
        return http.put(`/ShopTransfer/edit`, data);
    }
    editGridShopTransfer(data) {
        return http.put(`/ShopTransfer/grid/edit`, data);
    }
    getShopTransferIDValue(shopID) {
        return http.get(`/ShopTransfer/ShopTransferValue/${shopID}`);
    }
    getGridDataSource(data) {
        return http.post(`/ShopTransfer/detail/list` , data);
    }
    getMultiDropDownSource(ReportType ) {
        console.log(ReportType)

        let ReportTypeSplit = ReportType.split(',')
        console.log(ReportTypeSplit)
    let data ={
        ReportType :ReportTypeSplit[0]
       ,MainInvoiceID :ReportTypeSplit[1]
       ,FromDate:ReportTypeSplit[2]
       ,ToDate:ReportTypeSplit[3]
       ,BrandID:ReportTypeSplit[4]
       ,ProductID:ReportTypeSplit[5]
       ,ShopID:ReportTypeSplit[6]
    }
    console.log(data);
 
    return http.post(`/Invoice/product/multidropdown`, data);
}  
getUnitOfMeasurement(id) {
    return http.get(`/Invoice/unitOfMeasurement/${id}`);
}  
       
       
       
       
       
       
       
       
       
       
       
       
       
       
       
       
       
       
       
       
        insert(data) {
            return http.post(`/ShopTransfer/add`,data);
        }
        update(data) {
            return http.put(`/ShopTransfer/edit`,data);
        }





getShopTransferIDforFirst(id) {
    return http.get(`/ShopTransfer/first/shopTransferID/${id}`);
}
getShopTransferIDOtherEntry(id) {
    return http.get(`/ShopTransfer/otherEntry/shopTransferID/${id}`);
}
}

export default new ShopTransferService();