import http from './http-common';

class ChartOfAccountService {
  getList() {
    return http.get(`/ChartOfAccount/list`);
  }
  getIncomeTax() {
    return http.get(`/ChartOfAccount/IncomeTax/list`);
  }
  getSalesTax() {
    return http.get(`/ChartOfAccount/SalesTax/list`);
  }
  getIncomeTaxExemptionCertificate() {
    return http.get(`/ChartOfAccount/IncomeTaxExemptionCertificate/list`);
  }

  postIncomeTax(data) {
    return http.post(`/ChartOfAccount/IncomeTax/add`, data);
  }
  postSalesTax(data) {
    return http.post(`/ChartOfAccount/SalesTax/add`, data);
  }
  postIncomeTaxExemptionCertificate(data) {
    return http.post(`/ChartOfAccount/IncomeTaxExemptionCertificate/add`, data);
  }
  
  putIncomeTax(data) {
    return http.put(`/ChartOfAccount/IncomeTax/update`, data);
  }
  putSalesTax(data) {
    return http.put(`/ChartOfAccount/SalesTax/update`, data);
  }
  putIncomeTaxExemptionCertificate(data) {
    return http.put(`/ChartOfAccount/IncomeTaxExemptionCertificate/update`, data);
  }
  getAgingCheck(data) {
    return http.post(`/ChartOfAccount/AgingCheck`, data);
  }
  generateChartOfAccountCode(id ,text) {
    if(text == 'ADD'){
     return http.get(`/ChartOfAccount/generate/sameLevel/${id}`);
    }else{
    return http.get(`/ChartOfAccount/generate/${id}`);
    }
  }

  create(data) {
    console.log('create data: ', data);
    return http.post(`/ChartOfAccount/add/`, data);
  }
  update(data) {
    console.log('create data: ', data);
    return http.put(`/ChartOfAccount/update`, data);
  }

}
  export default new ChartOfAccountService();
