import http from '../../services/http-common';

class CurrencyTransferService {
getCurrencyTransferForList(id) {
    return http.get(`/CurrencyTransfer/list/${id}`);
    }
getCurrencyTransferIDforFirst(id) {
    return http.get(`/CurrencyTransfer/first/currencyTransferID/${id}`);
}
getCurrencyTransferIDOtherEntry(id) {
    return http.get(`/CurrencyTransfer/otherEntry/currencyTransferID/${id}`);
}
insert(data) {
    return http.post(`/CurrencyTransfer/add`,data);
}
update(data) {
    return http.put(`/CurrencyTransfer/edit`,data);
}
}

export default new CurrencyTransferService();