import React, { useRef, useState , useEffect,useMemo} from 'react';
import moment from 'moment';
import {
  StructuredGenerate,
  DynamicDropDownInput,
  DynamicNumericInput,
  DynamicDateInput,
  DynamicTextInput,
  DynamicCheckBoxInput,
  DynamicHiddenInput,
  DynamicButtonInput
} from '../irtiza-dynamic-input/irtiza-dynamic-main-inputs';

const EvaluationAdd = (props) => {
  
  let { DynamicDropDownInput: DropdownComponent, DynamicNumericInput: NumberComponent, DynamicDateInput: DateComponent , DynamicTextInput: TextComponent ,DynamicCheckBoxInput:CheckBoxComponent , DynamicHiddenInput : HiddenComponent , DynamicButtonInput:ButtonComponent} = { DynamicDropDownInput, DynamicNumericInput, DynamicDateInput ,DynamicTextInput , DynamicCheckBoxInput,DynamicHiddenInput,DynamicButtonInput};
  // EvaluationRateID	
  // EvaluationDate	
  // CurrencyID	
  // OpeningExchangeRate	
  // AverageExchangeRate	
  // ClosingExchangeRate
  
  const [inputProps, setInputProps] = useState({
  
    EvaluationRateID:{name: 'EvaluationRateID',   value: props.EvaluationRateID || '' ,enabled:false},
    EvaluationDate:{name: 'EvaluationDate',   value: props.EvaluationDate || 'currentDate' },
    CurrencyName:{
      name:{id:'CurrencyID',value:'CurrencyName'},
      caption: 'Select Currency',
      value: {id: props.CurrencyID || '2' ,value:props.CurrencyName || ''},
      dataSource: {type:'Route',route:'Currency'},
      //   addNew:{Route:'sldf',Security:'df'},
      // isControlBlur:false
    },
    OpeningExchangeRate:{name: 'OpeningExchangeRate', value: props.OpeningExchangeRate || 0.00 ,decimal:2},
    AverageExchangeRate:{name: 'AverageExchangeRate', value: props.AverageExchangeRate || 0.00 ,decimal:2},
    ClosingExchangeRate:{name: 'ClosingExchangeRate', value: props.ClosingExchangeRate || 0.00 ,decimal:2},

    });
// EXTRA CODE START

// EXTRA CODE END

const handleInputCreated = async (e, name) => {
  console.log(e);
    console.log(`Input ${name} changed to ${e}`);
  };
const handleInputFocus = async (e, name) => {
  
    console.log(e);
    console.log(`Input ${name} focus to ${e}`);
  };
const handleInputChange = async (value, name) => {
  
    console.log(value);
    console.log(`Input ${name} focus to ${value}`);
  };
const handleInputBlur = async  (value, name) => {
    console.log(value);
    console.log(`Input ${name} focus to ${value}`);
  };
 
  let inputsList = [
    {Input: HiddenComponent ,Props: {...inputProps.EvaluationRateID, setInputProps },ColumnSize:0},

    {Input: DateComponent,Props: {...inputProps.EvaluationDate,setInputProps }},
    
    {Input: DropdownComponent,Props: {...inputProps.CurrencyName, setInputProps }},
    
    {Input: NumberComponent,Props: {...inputProps.OpeningExchangeRate,setInputProps }},
    {Input: NumberComponent,Props: {...inputProps.AverageExchangeRate,setInputProps }},
    {Input: NumberComponent,Props: {...inputProps.ClosingExchangeRate,setInputProps }},

    
    
  ];
  return (
    <div onKeyDown={(e)=>e.stopPropagation()}>
      {/* <FirstGridComponent/> */}
      <StructuredGenerate   mainProps={props} title={`Evaluation Rate  ${props.isAdd == true ? `Add` : `Edit`}`} Inputs={inputsList}/>
      {/* <pre>{JSON.stringify(inputProps, null, 2)}</pre> This is to display current state for debugging */}
      </div>
  );
}; 

export default EvaluationAdd;
