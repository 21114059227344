import http from '../../services/http-common';

class BrandService {
    getBrandForList() {
    return http.get(`/Brand/list`);
    }
    insert(data) {
        return http.post(`/Brand/insert`,data);
    }
    update(data) {
        return http.put(`/Brand/update`,data);
    }
}

export default new BrandService();