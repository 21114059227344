import { createSlice } from '@reduxjs/toolkit'

const fabricProcessingContract =  createSlice({
    name: 'fabricProcessingContract',
    initialState:{
        IsAutoIssue: false,
        },
    reducers:{
        setIsAutoIssue(state , action){
            state.IsAutoIssue = action.payload

            console.log('action' ,action)
            console.log('state' ,state)
        },
    }
 
})
export const { setIsAutoIssue } = fabricProcessingContract.actions
export default fabricProcessingContract.reducer