import http from '../../services/http-common';

class EvaluationRateService {
    getEvaluationRateForList() {
    return http.get(`/EvaluationRate/list`);
    }
insert(data) {
    return http.post(`/EvaluationRate/insert`,data);
}
update(data) {
    return http.put(`/EvaluationRate/update`,data);
}
}

export default new EvaluationRateService();