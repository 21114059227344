import http from '../../services/http-common';

class UnitOfMeasurementService {
    getUnitOfMeasurementForList() {
    return http.get(`/setup/UnitOfMeasurement`);
    }
    insert(data) {
        return http.post(`/setup/UnitOfMeasurement/insert`,data);
    }
    update(data) {
        return http.put(`/setup/UnitOfMeasurement/update`,data);
    }
}

export default new UnitOfMeasurementService();