import http from '../../services/http-common';

class SystemLockService {
    getSystemLockForList() {
    return http.get(`/SystemLock/list`);
    }
    update(data) {
    return http.put(`/SystemLock/update`,data);
    }
}

export default new SystemLockService();