import http from './http-common';

class UserTheme {
  getByID(id) {
    return http.get(`/userTheme/${id}`);
  }
  
  update(data) {
    return http.put(`/userTheme/update`,data);
  }
  
}

export default new UserTheme();
