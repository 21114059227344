import React, { useEffect, useRef,  useState } from 'react';
import PropTypes from 'prop-types';
import SvgIcon from '@mui/material/SvgIcon';
import { alpha, styled } from '@mui/material/styles';
import TreeView from '@mui/lab/TreeView';
import TreeItem, { treeItemClasses } from '@mui/lab/TreeItem';
import Collapse from '@mui/material/Collapse';
import { useSpring, animated } from '@react-spring/web';
import './chart-of-account.scss'
import chartOfAccountService from '../../services/chart-of-account.service';
import ChartOfAccountAdd from './chart-of-account-add';
import Grid from '@material-ui/core/Grid';

function MinusSquare(props) {
  return (
    <SvgIcon fontSize="inherit" style={{ width: 14, height: 14 }} {...props}>
      {/* tslint:disable-next-line: max-line-length */}
      <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 11.023h-11.826q-.375 0-.669.281t-.294.682v0q0 .401.294 .682t.669.281h11.826q.375 0 .669-.281t.294-.682v0q0-.401-.294-.682t-.669-.281z" />
    </SvgIcon>
  );
}

function PlusSquare(props) {
  return (
    <SvgIcon fontSize="inherit" style={{ width: 14, height: 14 }} {...props}>
      {/* tslint:disable-next-line: max-line-length */}
      <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 12.977h-4.923v4.896q0 .401-.281.682t-.682.281v0q-.375 0-.669-.281t-.294-.682v-4.896h-4.923q-.401 0-.682-.294t-.281-.669v0q0-.401.281-.682t.682-.281h4.923v-4.896q0-.401.294-.682t.669-.281v0q.401 0 .682.281t.281.682v4.896h4.923q.401 0 .682.281t.281.682v0q0 .375-.281.669t-.682.294z" />
    </SvgIcon>
  );
}

function CloseSquare(props) {
  return (
    <SvgIcon
      className="close"
      fontSize="inherit"
      style={{ width: 14, height: 14 }}
      {...props}
    >
      {/* tslint:disable-next-line: max-line-length */}
      <path d="M17.485 17.512q-.281.281-.682.281t-.696-.268l-4.12-4.147-4.12 4.147q-.294.268-.696.268t-.682-.281-.281-.682.294-.669l4.12-4.147-4.12-4.147q-.294-.268-.294-.669t.281-.682.682-.281.696 .268l4.12 4.147 4.12-4.147q.294-.268.696-.268t.682.281 .281.669-.294.682l-4.12 4.147 4.12 4.147q.294.268 .294.669t-.281.682zM22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0z" />
    </SvgIcon>
  );
}

function TransitionComponent(props) {
  const style = useSpring({
    from: {
      opacity: 0,
      transform: 'translate3d(20px,0,0)',
    },
    to: {
      opacity: props.in ? 1 : 0,
      transform: `translate3d(${props.in ? 0 : 20}px,0,0)`,
    },
  });

  return (
    <animated.div style={style}>
      <Collapse {...props} />
    </animated.div>
  );
}

TransitionComponent.propTypes = {
  /**
   * Show the component; triggers the enter or exit states
   */
  in: PropTypes.bool,
};

const StyledTreeItem = styled((props) => (
  <TreeItem {...props} TransitionComponent={TransitionComponent} />
))(({ theme }) => ({
  [`& .${treeItemClasses.iconContainer}`]: {
    '& .close': {
      opacity: 0.3,
    },
  },
  [`& .${treeItemClasses.group}`]: {
    marginLeft: 15,
    paddingLeft: 18,
    borderLeft: `1px dashed ${alpha(theme.palette.text.primary, 0.4)}`,
  },
}));

export default function ChartOfAccount(props) {
console.log(props)
  const [accountCodes, setAccountCodes] = useState([]);
  const [outerText, setOuterText] = useState(undefined);
  useEffect(() => {
    // window.screenTop = 0
    // let ele = document.getElementsByClassName('chart-of-account-wrapper')[0]
    // console.log(ele)
    getAccessoryTransfer();

  
    // setAccountCodes([
      
    //     { AccountCode: '1',AccountTitleShort: 'ASSETS' ,isLink:false},
    //     { AccountCode: '2',AccountTitleShort: 'LIABILITIES' ,isLink:false},
    //     { AccountCode: '3',AccountTitleShort: 'CAPITAL' ,isLink:true},
    //     { AccountCode: '4',AccountTitleShort: 'REVENUE' ,isLink:false},
    //     { AccountCode: '5',AccountTitleShort: 'EXPENSES' ,isLink:false},
    //     { AccountCode: '101', AccountTitleShort: 'CURRENT ASSETS' ,isLink:false},
    //     { AccountCode: '102', AccountTitleShort: 'FIXED ASSETS' ,isLink:false},
    //     { AccountCode: '10101', AccountTitleShort: 'CASH & BANKS' ,isLink:true},
    //     { AccountCode: '10102', AccountTitleShort: 'EXPORT RECEIVABLES' ,isLink:false},
    //     { AccountCode: '10101001', AccountTitleShort: 'CASH' ,isLink:false},
    //     { AccountCode: '10101002', AccountTitleShort: 'PETTY CASH' ,isLink:false},
    //     { AccountCode: '101010010001', AccountTitleShort: 'CASH IN HAND' ,isLink:false},
    //     { AccountCode: '101010010002', AccountTitleShort: 'PETTY CASH' ,isLink:false},
    //     { AccountCode: '101010020001', AccountTitleShort: 'BANK AL HABIB A/C 5555' ,isLink:false},
    //     { AccountCode: '101010020002', AccountTitleShort: 'HABIB METRO A/C 9999' ,isLink:false},
    //     { AccountCode: '201', AccountTitleShort: 'CURRENT LIABILITIES' ,isLink:false},
    //     { AccountCode: '20101', AccountTitleShort: 'PAYABLES' ,isLink:false},
    //     { AccountCode: '20101001', AccountTitleShort: 'YARN PAYABLES' ,isLink:false},
    //     { AccountCode: '201010010001', AccountTitleShort: 'AM SONS' ,isLink:false},
    //     { AccountCode: '201010010002', AccountTitleShort: 'Ahmed Fine Textiles' ,isLink:false},
    //   ]

    // )
    
    // localStorage.removeItem('gridAccessoryDisplayGrid');
}, []);


const getAccessoryTransfer = async (type: string) => {
    chartOfAccountService.getList()
        .then((response) => {
          console.log( response.data.recordset);
          
          setAccountCodes(response.data.recordset)
          // return response.data.recordset
        })
        .catch((e) => {
            console.log(e);
        });
};

useEffect(() => {

    console.log(outerText)


  
 
}, [outerText]);

function createTreeData(accountCodes) {
  const treeData = [];

  function findChildren(parentCode, parentLevel) {
    const children = [];

    accountCodes.forEach((account) => {
      if (
        account.AccountCode.startsWith(parentCode) &&
        account.AccountCode.length === parentLevel + 2
      ) {
        const child = {
          id: account.AccountCode,
          label: account.AccountTitleShort,
        };

        const childChildren = findChildren(account.AccountCode, account.AccountCode.length);
        if (childChildren.length > 0) {
          child.children = childChildren;
        }

        children.push(child);
      }
      if (
        account.AccountCode.startsWith(parentCode) &&
        account.AccountCode.length == 8 &&
        account.AccountCode.length === parentLevel + 3
      ) {
        const child = {
          id: account.AccountCode,
          label: account.AccountTitleShort,
        };

        const childChildren = findChildren(account.AccountCode, account.AccountCode.length);
        if (childChildren.length > 0) {
          child.children = childChildren;
        }

        children.push(child);
      }
      if (
        account.AccountCode.startsWith(parentCode) &&
        account.AccountCode.length == 12 &&
        account.AccountCode.length === parentLevel + 4
      ) {
        const child = {
          id: account.AccountCode,
          label: account.AccountTitleShort,
        };

        const childChildren = findChildren(account.AccountCode, account.AccountCode.length);
        if (childChildren.length > 0) {
          child.children = childChildren;
        }

        children.push(child);
      }
    });

    return children;
  }

  accountCodes.forEach((account) => {
    if (account.AccountCode.length < 2) {
      const parent = {
        id: account.AccountCode,
        label: account.AccountTitleShort,
      };
      console.log(account.AccountCode, account.AccountCode.length)
      const parentChildren = findChildren(account.AccountCode, account.AccountCode.length);
      if (parentChildren.length > 0) {
        parent.children = parentChildren;
      }

      treeData.push(parent);
    }
  });

  return treeData;
}



// // --LEVEL 1
//  const level1 = accountCodes.filter((val)=> val.AccountCode.length == 1 || val.AccountCode.length ==2).map((item)=> {return {id:item.AccountCode, label:item.AccountTitleShort}});
// // --LEVEL 2
// const level2 = accountCodes.filter((val,key)=>val.AccountCode.length == 3 &&  level1.some((vals)=>val.AccountCode[0] == vals.id))
// // --LEVEL 3
// const level3 = accountCodes.filter((val,key)=>val.AccountCode.length == 5 &&  level2.some((vals)=>vals.AccountCode == val.AccountCode.substring(0,3)))
// // --LEVEL 4
// console.log(accountCodes)

// const level4 = accountCodes.filter((val,key)=>val.AccountCode.length == 8 &&  level3.some((vals)=>vals.AccountCode == val.AccountCode.substring(0,5)))
// // --LEVEL 5
// const level5 = accountCodes.filter((val,key)=>val.AccountCode.length == 12 &&  level4.some((vals)=>vals.AccountCode == val.AccountCode.substring(0,8)))




//  console.log(level1)
//  console.log(level2)
//  console.log(level3)
//  console.log(level4)
//  console.log(level5)



const treeData = createTreeData(accountCodes);
console.log(...treeData);
const renderTree = (nodes) => (
  <TreeItem key={nodes.id} nodeId={nodes.id} title={nodes.label} label={nodes.label}  onClick={(e)=>{
    console.log(e.target.outerText)
    // setTimeout(()=>{
      console.log(e.target.outerText)
      // setOuterText(e.target.outerText)
    // },1500)
  }}>
    {Array.isArray(nodes.children)
      ? nodes.children.map((node) => renderTree(node))
      : null}
  </TreeItem>
);
const [expanded, setExpanded] = useState([]);
const handleToggle = (event, nodeIds) => {
  console.log(event.target.tagName)
  if (event.target.tagName === 'svg' || event.target.tagName === 'path') {
    // If the user clicked on the text, prevent expansion or collapse
    // return event.preventDefault();
    setExpanded(nodeIds);
  }
};
  return (
    <div id='chart-of-account'>
       <Grid item xs={12}>
          <div className='heading'>
            <h3 style={{ textAlign:'center', width: '100%' , padding: '0px'}}>Chart Of Account</h3>
          </div>
          
        </Grid>
    <div className='chart-of-account-wrapper'>
      <div className='tree-view-chart-of-accoutn'>
    <TreeView
      aria-label="customized"
      // defaultExpanded={['1']}
      expanded={expanded}
      defaultCollapseIcon={<MinusSquare />}
      defaultExpandIcon={<PlusSquare />}
      defaultEndIcon={<CloseSquare />}
      sx={{ height: 360, flexGrow: 1, minWidth: '30%', overflowY: 'auto', }}
      onNodeToggle={handleToggle}
      onNodeFocus={
        (e)=>{
        console.log(e)
        let me  = e.target.title;
        console.log(me)
        if(me != undefined || me != ''){

          setOuterText(me)
        }

      }
    }     
      // onClick={(e)=>{
      //   console.log(e)
      // }}                      
    >

{                 
  treeData.map((res,key)=>{

  return  renderTree(res)                        
  })                                                                                                                                                                                                                                                                                                          

}
    </TreeView>
    </div>
        <ChartOfAccountAdd TreeViewValue={outerText}  callTreeView={getAccessoryTransfer} dataList={accountCodes}  />
    
    
    </div>
    </div>
  );
}