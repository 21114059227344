import { createSlice } from '@reduxjs/toolkit'

const userInput =  createSlice({
    name: 'userInput',
    initialState:{
        DomainEmail: '',
        },
    reducers:{
        domainEmail(state , action){
            state.DomainEmail = action.payload

            console.log('action' ,action)
            console.log('state' ,state)
        },
    }
 
})
export const { domainEmail } = userInput.actions
export default userInput.reducer