import http from '../../services/http-common';

class CurrencyService {
getCurrencyForList() {
    return http.get(`/Currency/list`);
    }
insert(data) {
    return http.post(`/Currency/insert`,data);
}
update(data) {
    return http.put(`/Currency/update`,data);
}
}

export default new CurrencyService();