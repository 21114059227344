import React, { useState } from 'react';
import axios from 'axios';

const ExportToExcel = () => {
  const [pdfUrl, setPdfUrl] = useState('');
  const [excelUrl, setExcelUrl] = useState('');

  const handleConvert = async () => {
    try {
      // Send PDF URL to backend for conversion
      const response = await axios.post('http://localhost:5000/api/v1/convert/pdf-to-excel', { pdfUrl });
      
      // Assuming backend returns the converted Excel file URL or blob
      setExcelUrl(response.data);
    } catch (error) {
      console.error('Failed to convert PDF to Excel:', error);
    }
  };

  const handleDownload = async () => {
    if (excelUrl) {
      // Create a temporary anchor element to trigger download
      const link = document.createElement('a');
      link.href = excelUrl;
      link.setAttribute('download', 'converted.xlsx');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  return (
    <div>
      <input type="text" value={pdfUrl} onChange={(e) => setPdfUrl(e.target.value)} placeholder="Enter PDF URL" />
      <button onClick={handleConvert}>Convert to Excel</button>
      {excelUrl && (
        <div>
          <button onClick={handleDownload}>Download Excel</button>
        </div>
      )}
    </div>
  );
};

// export default App;


export default ExportToExcel;
