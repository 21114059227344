import React, { useRef, useState , useEffect,useMemo} from 'react';
import currencyTransferService from './currency-transfer.service';
import AddNewDialog from '../irtiza-dynamic-input/irtiza-dynamic-addnew';
import { DatePickerComponent } from '@syncfusion/ej2-react-calendars';
import { TextBoxComponent } from '@syncfusion/ej2-react-inputs';
import {
  StructuredGenerate,
  DynamicDropDownInput,
  DynamicNumericInput,
  DynamicDateInput,
  DynamicTextInput,
  DynamicCheckBoxInput,
  DynamicHiddenInput,
  DynamicButtonInput
} from '../irtiza-dynamic-input/irtiza-dynamic-main-inputs';


import { AutoCompleteComponent } from '@syncfusion/ej2-react-dropdowns';
import DropdownService from '../irtiza-dynamic-input/dropdown-api.service';

import {
  CheckBoxComponent,
  ButtonComponent,
} from "@syncfusion/ej2-react-buttons";



const CurrencyTransferAdd = (props) => {
  let { DynamicDropDownInput: DropdownComponent, DynamicNumericInput: NumberComponent, DynamicDateInput: DateComponent , DynamicTextInput: TextComponent ,DynamicCheckBoxInput:CheckBoxComponent ,DynamicHiddenInput:HiddenComponent} = 
  { DynamicDropDownInput, DynamicNumericInput, DynamicDateInput ,DynamicTextInput , DynamicCheckBoxInput,DynamicHiddenInput};

  const [inputProps, setInputProps] = useState({
  
    CurrencyTransferID:{name: 'CurrencyTransferID',   value: props.CurrencyTransferID || '' },
    // CurrencyTransferID:{name: 'CurrencyTransferID', caption:'Currency Transfer ID',  value: props.CurrencyTransferID || '' , enabled:false},

    CurrencyTransferDate:{name: 'CurrencyTransferDate',   value: props.CurrencyTransferDate || 'currentDate' },

    CurrencyNameFrom:{
      name:{id:'CurrencyIDFrom',value:'CurrencyNameFrom'},
      caption: 'Select From Currency',
      value: {id: props.CurrencyIDFrom || 'undefined' ,value:props.CurrencyNameFrom || ''},
      dataSource: {type:'Route',route:'Currency'}},
   
      CurrencyNameTo:{
      name:{id:'CurrencyIDTo',value:'CurrencyNameTo'},
      caption: 'Select To Currency',
      value: {id: props.CurrencyIDTo || 'undefined' ,value:props.CurrencyNameTo || ''},
      dataSource: {type:'Route',route:'Currency'}},

      AmountFrom:{name: 'AmountFrom', caption: 'From Amount',  value: props.AmountFrom || 0 },

      AmountTo:{name: 'AmountTo', caption: 'To Amount',  value: props.AmountTo || 0 },

      ExchangeRate:{name: 'ExchangeRate',  value: props.ExchangeRate || 0 , decimal:2},
    
    });
// EXTRA CODE START
const getCurrencyTransferIDValue = async ()=>{
    try {
      console.log(props.dataList.some(res=>res.ShopID == props.ShopID))
      const response = props.dataList.some(res=>res.ShopID == props.ShopID)  ?   await currencyTransferService.getCurrencyTransferIDOtherEntry(props.ShopID) :  await currencyTransferService.getCurrencyTransferIDforFirst(props.ShopID) ;
      if (response.data || response.data.recordset) {
        console.log(response)
        setInputProps((prevValues) => ({
          ...prevValues,
          ['CurrencyTransferID']: {...prevValues['CurrencyTransferID'] ,value :response.data.recordset[0]?.CurrencyTransferID || '' }
    
        }));
      }
        else {
        console.error("Invalid response structure:", response);
      }
     } catch (error) {
      }
  }
const updateExchangeRate = (AmountFrom , AmountTo)=>{
  let fromCurrencyID =  inputProps.CurrencyNameFrom.value.id;
  let fromAmount = AmountFrom > 0 ? AmountFrom :inputProps.AmountFrom.value;
  let toAmount = AmountTo > 0 ? AmountTo  :inputProps.AmountTo.value;

  console.log(fromCurrencyID)
  console.log(fromAmount)
  console.log(toAmount)
  if(fromCurrencyID == 2 && fromAmount > 0 && toAmount > 0){
    console.log(fromAmount / toAmount)
    let devideVal =parseFloat(fromAmount / toAmount).toFixed(2)
    console.log(devideVal)
    setInputProps((prevValues) => ({
      ...prevValues,
      ['ExchangeRate']: {...prevValues['ExchangeRate'] ,value :devideVal}

    }));
  }else if(fromCurrencyID == 1 && fromAmount > 0 && toAmount > 0){
    console.log(toAmount / fromAmount )
    let devideVal2 =parseFloat(toAmount / fromAmount ).toFixed(2)
    setInputProps((prevValues) => ({
      ...prevValues,
      ['ExchangeRate']: {...prevValues['ExchangeRate'] ,value :devideVal2 }

    }));
  }
}
// EXTRA CODE END

const handleInputCreated = async (e, name) => {
  if(name == 'CurrencyTransferID'  && props.isAdd  == true){
    return await getCurrencyTransferIDValue()
  }
  console.log(e);
    console.log(`Input ${name} changed to ${e}`);
  };
const handleInputFocus = async (e, name) => {
  
    console.log(e);
    console.log(`Input ${name} focus to ${e}`);
  };
const handleInputChange = async (value, name) => {

    console.log(value);
    console.log(`Input ${name} focus to ${value}`);
  };
const handleInputBlur = async  (value, name) => {
  if(name == 'CurrencyNameFrom'  || name == 'AmountFrom' || name == 'AmountTo' ){
    setTimeout(() => {
      return updateExchangeRate(name == 'AmountFrom' ? value : 0 , name == 'AmountTo' ? value:0)
    }, 800);
  }
    console.log(value);
    console.log(`Input ${name} focus to ${value}`);
  };
 
  let inputsList = [
    {
      Input: HiddenComponent,
      Props: {
        ...inputProps.CurrencyTransferID, 
        onCreated: handleInputCreated,
        setInputProps },ColumnSize:0
      },
    {
      Input: DateComponent,
      Props: {
        ...inputProps.CurrencyTransferDate, 
        onChange: handleInputChange,
        onBlur: handleInputBlur,
        setInputProps },
      },
    {
      Input: DropdownComponent,
      Props: {
        ...inputProps.CurrencyNameFrom, 
        onChange: handleInputChange,
        onBlur: handleInputBlur,
        setInputProps },
      },
    {
      Input: DropdownComponent,
      Props: {
        ...inputProps.CurrencyNameTo, 
        onChange: handleInputChange,
        onBlur: handleInputBlur,
        setInputProps },
      },
    {
      Input: NumberComponent,
      Props: {
        ...inputProps.AmountFrom, 
        onChange:handleInputChange,
        onBlur: handleInputBlur,
        setInputProps },
      },
    {
      Input: NumberComponent,
      Props: {
        ...inputProps.AmountTo, 
        onChange: handleInputChange,
        onBlur: handleInputBlur,
        setInputProps },
    },
    {
      Input: NumberComponent,
      Props: {
        ...inputProps.ExchangeRate, 
        onBlur: handleInputBlur,
        setInputProps },
    },
    
  ];
//   const FirstGridComponent = useMemo(()=>()=>{
//  return( <StructuredGenerate   mainProps={props} title={'Invoice Evaluation Add'} Inputs={inputsList}/>)
//   },[inputProps])
  return (
    <>
      {/* <FirstGridComponent/> */}
      <StructuredGenerate   mainProps={props} title={`Currency Transfer ${props.isAdd == true ? `Add` : `Edit`}`} Inputs={inputsList}/>
      {/* <pre>{JSON.stringify(inputProps, null, 2)}</pre> This is to display current state for debugging */}
    </>
  );
}; 

export default CurrencyTransferAdd;
