import React ,{useState} from 'react';

 const SvgColorTheme = (props) => {
 

    return (
        <>
        {
            props.Condition ? (
                <svg id="moon" width='20' height='20' viewBox="-6 -6 12 12">
    <title>Dark mode</title>
    <defs>
        <mask id="earth">
            <rect fill="white" x="-5" y="-5" width="10" height="10"></rect>
            <circle fill="black" cx="3.141592654" r="5"/>
        </mask>
    </defs>
    <circle r="5" fill="white" mask="url(#earth)"  stroke="black" stroke-width="0.1" transform="rotate(-23.5)"/>
</svg> )
:
(
<svg class="sun-and-moon" aria-hidden="true" width="24" height="24" viewBox="0 0 24 24">
    <mask class="moon"  id="moon-mask">
      <rect x="0" y="0" width="45" height="45" fill="white" />
      <circle cx="30" cy="18" r="6" fill="black" />
    </mask>
    <circle class="sun" cx="12" cy="12" r="6" mask="url(#moon-mask)" fill="currentColor" />
    <g class="sun-beams" stroke="currentColor">
      <line x1="12" y1="1" x2="12" y2="3" />
      <line x1="12" y1="21" x2="12" y2="23" />
      <line x1="4.22" y1="4.22" x2="5.64" y2="5.64" />
      <line x1="18.36" y1="18.36" x2="19.78" y2="19.78" />
      <line x1="1" y1="12" x2="3" y2="12" />
      <line x1="21" y1="12" x2="23" y2="12" />
      <line x1="4.22" y1="19.78" x2="5.64" y2="18.36" />
      <line x1="18.36" y1="5.64" x2="19.78" y2="4.22" />
     </g>
  </svg>
)
            
        }
        </>
    );
}

export default SvgColorTheme;
