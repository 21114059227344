
import React , {useState , useEffect , useRef} from 'react';
import UserService from '../../services/users.service';
import SecurityService from '../security/security.service';
import { TextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { AutoCompleteComponent } from '@syncfusion/ej2-react-dropdowns';
import './change-password.scss';
const UserReplicate = (props) => {
    let fromusersRef = useRef(null)
    let tousersRef = useRef(null)
    const [loadingUser, setLoadingUser] = useState(true);
    const [users, setUsers] = useState([]);
    const [formUserValue, setFromUserValue] = useState('');
    const [fromUserID, setFromUserID] = useState(0);
    const [toUserValue, setToUserValue] = useState('');
    const [toUserID, setToUserID] = useState(0);
    const [password, setPassword] = useState('');
    const [repeatPassword, setRepeatPassword] = useState('');
    const [isError, setIsError] = useState('');

    const ddlFields: object = { value: 'value' };

    useEffect(() => {
        getUsers();
      console.log(SecurityService.getLoggedInUser())
    }, []);
    
    useEffect(() => {
        if(fromUserID == toUserID && toUserID != 0 &&  fromUserID != 0 ){
            document.getElementById('same-user-id').style.display = 'block'
            setTimeout(()=>{
                document.getElementById('same-user-id').style.display = 'none'
            },1500)
        }

    }, [fromUserID , toUserID]);
    const getUsers = async (type: string) => {
        setLoadingUser(true);
        UserService.getForList()
          .then((response) => {
            console.log('Users get response', response.data.recordset);
            setUsers(response.data.recordset);
            setLoadingUser(false);
          })
          .catch((e) => {
            console.log(e);
          });
      };

    const fromUsersChange = (e)=>{
        
        console.log(e)
        setFromUserValue(e?.itemData?.value ? e?.itemData?.value : '');
        setFromUserID(0);
    if (e?.e?.srcElement?.id) {
      console.log('from user change: ', e?.itemData?.id);
      setFromUserID(e?.itemData?.id);
    }
    }
    const toUsersChange = (e)=>{
        
        console.log(e)
        setToUserValue(e?.itemData?.value ? e?.itemData?.value : '');
        setToUserID(0);
    if (e?.e?.srcElement?.id) {
      console.log('from user change: ', e?.itemData?.id);
      setToUserID(e?.itemData?.id);
    }
    }

    const formSubmit = (e) =>{
        e.preventDefault()
        if(password !=  '' && repeatPassword != ''){
          let username = SecurityService.getLoggedInUser()
          console.log(username)
          let loginDetails ={
            username: username.toUpperCase(),
            password: password,
            isTemp: 0
          }
          console.log(loginDetails)
          SecurityService.changePassword(loginDetails)
          .then(() => {
            setPassword('')
            setIsError(false)
            setRepeatPassword('')
            document.getElementById('user-replicate').style.display = 'block'
            setTimeout(()=>{
                document.getElementById('user-replicate').style.display = 'none'
            },1500)
            document.getElementById('Password').style.borderColor ='#c8c8c8'
          })
            
        }else{
          document.getElementById('same-user-id').style.display = 'block'
          setTimeout(()=>{
              document.getElementById('same-user-id').style.display = 'none'
          },1500)

        }
        console.log(e)
    }

      const isStrongPassword = (password) => {
    // Ensure the length is 8 characters or more
    const lengthRegex = /^.{8,}$/;

    // Ensure one or more symbols
    const symbolRegex = /[\W_]/;

    // Ensure one or more numeric fields
    const numericRegex = /\d/;

    // Ensure one or more Upper Case Alphanumeric Characters
    const upperCaseRegex = /[A-Z\d]/;

    // Ensure one or more Lower Case Alphanumeric Characters
    const lowerCaseRegex = /[a-z\d]/;

    // Check all conditions
    return (
        lengthRegex.test(password) &&
        symbolRegex.test(password) &&
        numericRegex.test(password) &&
        upperCaseRegex.test(password) &&
        lowerCaseRegex.test(password)
    );
}


    return (
        (props.isDialog) ? (
        
        <div>
            
        </div>
        ) : 
        (
            <div className="content-wrapper">
                  <div>
                    <h3> Change Password</h3>
                    <form onSubmit={formSubmit}>

                    
                    <div className='user-replicate'>
                  <div className='first-dropdown'>

                    <mat-form-field>
                      <TextBoxComponent 
                    className="e-input"
                    name="Password"
                    floatLabelType="Auto"
                    type='Password'
                    placeholder='Password'
                    id='Password'
                    value={password}
                    change={(e)=>{
                      console.log(e)
                      if(e.value){
                        console.log(e)
                        setPassword(e.value)
                        setIsError(false)
                        document.getElementById('Password').style.borderColor ='#c8c8c8'
                      }else{
                        setIsError(true)
                        document.getElementById('Password').style.borderColor ='red'
                        setPassword(e.value)

                      }
                    }}
                      />
                    </mat-form-field>
                        </div>
                  <div className='second-dropdown'>

                    <mat-form-field>
                    <TextBoxComponent 
                    className="e-input"
                    name="RepeatPassword"
                    floatLabelType="Auto"
                    type='Password'
                    id='RepeatPassword'
                    placeholder='Repeat Password'
                    value={repeatPassword}
                    change={(e)=>{
                      console.log(e)
                      console.log(password)
                      if(password == e.value){
                        console.log(e)
                        setIsError(false)
                        setRepeatPassword(e.value)
                        document.getElementById('RepeatPassword').style.borderColor ='#c8c8c8'
                      }else{
                        setIsError(true)
                        document.getElementById('RepeatPassword').style.borderColor ='red'
                        setRepeatPassword(e.value)

                      }
                      

                    }}
                    
                      />
                    </mat-form-field>
                        </div>
                        <div className='div-btn'>
                        <button 
                        type='submit'
                        id='get-data-btn'
                        className='user-replicate-btn' 
                        disabled={
                            isError
                        }
                        > CHANGE PASSWORD</button>
                        <p id='same-user-id'>Strong Password Please!</p>
                        <p id='user-replicate'> Password Change SuccessFully!</p>
                        </div>

                      </div>
                      </form>
                      <div className='strong-password-ul'  style={{color : isError ? 'red':'#28a745'}}>
                      {/* <ul className=''>
                        <li>Length of password should be 8 characters and above</li>
                        <li>One or more symbols</li>
                        <li>One or more numeric fields</li>
                        <li>One or more Upper Case Alphanumeric Characters</li>
                        <li>One or more Lower Case Alphanumeric Characters</li>
                    </ul> */}
                      </div>
                 </div>
            </div>
        )
    );
}

export default UserReplicate;
