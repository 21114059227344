import React, { useState, useEffect, useRef } from 'react'
import './change-access.scss'
import {
  GridComponent,
  Inject,
  Toolbar,
  ToolbarItems,
  Edit,
  EditSettingsModel,
  Page,
  Sort,
  PdfExport,
  PdfExportProperties,
  ExcelExport,
  Grid,
  Resize,
  FilterSettingsModel,
  Filter,
  Group,
  GroupSettingsModel,
  AggregateColumnDirective,
  ColumnDirective,
  ColumnsDirective,
  AggregateColumnsDirective,
  AggregateDirective,
  AggregatesDirective,
  Aggregate,
  Freeze,
  ColumnChooser,
  Reorder,
  DialogEditEventArgs,
  CommandColumn,
  CommandModel,
} from '@syncfusion/ej2-react-grids';
import { AutoCompleteComponent } from '@syncfusion/ej2-react-dropdowns';
import { CheckBoxComponent } from '@syncfusion/ej2-react-buttons';
import { DialogUtility } from '@syncfusion/ej2-popups';
import SecurityService from '../security.service';
import moment from 'moment';
import usersService from '../../../services/users.service';
let ListIds = []
let MenuIds = []
let AddIds = []
let EditIds = []
let DeleteIds = []
let userSecurityItemGrid: Grid;
export default function ChangeAccess(props) {
  const [userSecurityItems, setUserSecurityItems] = useState([]);
  const [userIDs, setUserIDs] = useState([]);
  const [userID, setUserID] = useState(0);
  let headerCheckbox = useRef();
  let headerCheckboxList = useRef();
  let headerCheckboxAdd = useRef();
  let headerCheckboxEdit = useRef();
  let headerCheckboxDelete = useRef();


  useEffect(() => {
    getUserIDs();
  }, []);

  const getUserSecurityItems = async (id) => {
    SecurityService.getUserAccessListSingleLine(id)
      .then((response) => {
        userSecurityItemGrid?.refresh();
        console.log(response)
        setUserSecurityItems(response.data.recordset);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const getUserIDs = async () => {
    usersService.getForList()
      .then((response) => {
        setUserIDs(response?.data?.recordset);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const userIDChange = (e) => {
    if (e?.itemData?.id || e?.itemData?.id == 0) {
      getUserSecurityItems(e?.itemData?.id)
      setUserID(e.itemData.id);
    }
  }

  const toolbarClick = (args: ClickEventArgs) => {
    // console.log(args);
    if (
      userSecurityItemGrid &&
      args.item.properties.id === 'UserSecurityItemGrid_PDFExport'
    ) {

      const exportProperties: PdfExportProperties = {
        pageOrientation: 'Landscape',
        pageSize: 'A3',
        fileName: `${moment().format('YYYYMMDD')}_yarn_purchase_contract.pdf`
      };

      // console.log('clicked on pdf export');
      userSecurityItemGrid.pdfExport(exportProperties);

    }

    if (
      userSecurityItemGrid &&
      args.item.properties.id === 'UserSecurityItemGrid_excelexport'
    ) {
      const exportProperties: PdfExportProperties = {
        fileName: `${moment().format('YYYYMMDD')}_yarn_purchase_contract.xlsx`
      };
      userSecurityItemGrid.excelExport(exportProperties);
    }
    // if (
    //   userSecurityItemGrid &&
    //   args.item.properties.id === 'UserSecurityItemGrid_columnchooser'
    // ) {
    // }
    if (args?.item?.properties?.id === 'Cancel') {
      // console.log(userSecurityItems);
      // to reload the grid
      setUserSecurityItems([]);
      getUserSecurityItems(userID);

      // userSecurityItemGrid?.refresh();
      // userSecurityItemGrid?.refreshDataSource();
      userSecurityItemGrid?.toolbarModule?.enableItems(['Update', 'Cancel'], false);
    }
    if (args?.item?.properties?.id === 'Update') {
      // console.log(userSecurityItemGrid?.getCurrentViewRecords());
      userSecurityItemGrid?.editModule?.batchSave();
      // console.log(userSecurityItemGrid?.getCurrentViewRecords());
      // userSecurityItemGrid?.toolbarModule?.enableItems(['Update', 'Cancel'], false);
    }
  };

  const toolbarOptions: ToolbarItems[] = [
    // 'Edit',
    // 'Update',
    // 'Cancel',
    // 'PDFExport',
    // 'ExcelExport',
    // 'Search',
    // 'ColumnChooser',
    {
      text: "Cancel", tooltipText: "Cancel", id: "Cancel", disabled: true
    },
    {
      text: "Update", tooltipText: "Update", id: "Update", disabled: true
    }
  ];

  const editSettings: EditSettingsModel = {
    allowEditing: true,
    mode: 'Batch',
    showConfirmDialog: false
  };

  const filterOptions: FilterSettingsModel = {
    type: 'Excel',
    // columns: [
    //   {
    //     field: 'IsDeleted',
    //     operator: 'equal',
    //     value: 'False'
    //   }
    // ]
  };

  const groupOptions: GroupSettingsModel = {
    showDroopArea: true,
  };

  const sortingOptions: SortSettingsModel = {
    columns: [{ field: 'UserSecurityItemID', direction: 'Descending' }]
  }

  const formatOptions = { type: 'date', format: 'dd/MM/yy' }

  const actionComplete = (args: DialogEditEventArgs) => {
    if (args?.requestType === 'columnstate') {
      // console.log('adjusting column');
      userSecurityItemGrid?.autoFitColumns();
    }
    if (args?.requestType === 'paging' || args?.requestType === 'refresh') {
      const currentData = userSecurityItemGrid.getCurrentViewRecords();
      if (currentData.every((el) => el.CanAccessList === true)) {
        headerCheckboxList.current.checked = true;
        // document.getElementById('userCanAcessCheckBox').checked = true;
      } else {
        headerCheckboxList.current.checked = false;
        // document.getElementById('userCanAcessCheckBox').checked = false;
      }
      if (currentData.every((el) => el.CanAccessAdd === true)) {
        headerCheckboxAdd.current.checked = true;
        // document.getElementById('userCanAcessCheckBox').checked = true;
      } else {
        headerCheckboxAdd.current.checked = false;
        // document.getElementById('userCanAcessCheckBox').checked = false;
      }
      if (currentData.every((el) => el.CanAccessEdit === true)) {
        headerCheckboxEdit.current.checked = true;
        // document.getElementById('userCanAcessCheckBox').checked = true;
      } else {
        headerCheckboxEdit.current.checked = false;
        // document.getElementById('userCanAcessCheckBox').checked = false;
      }
      if (currentData.every((el) => el.CanAccessDelete === true)) {
        headerCheckboxDelete.current.checked = true;
        // document.getElementById('userCanAcessCheckBox').checked = true;
      } else {
        headerCheckboxDelete.current.checked = false;
        // document.getElementById('userCanAcessCheckBox').checked = false;
      }
    }
    // if (args?.requestType === 'paging') {
    //   alert('Confirm changes');
    // }
    // console.log('check', args);
    if (args.form && args.form.ej2_instances) {
      // if (args.requestType === 'beginEdit' || args.requestType === 'add') {
      //   /** Add Validation Rules */
      //   // args.form.ej2_instances[0].addRules('OrderID', { max: 500 });
      //   args.form.ej2_instances[0].addRules('OrderDate', {
      //     required: true,
      //   });
      //   args.form.ej2_instances[0].addRules('ContractStartDate', {
      //     required: true,
      //   });
      //   args.form.ej2_instances[0].addRules('ContractEndDate', {
      //     required: true,
      //   });
      //   args.form.ej2_instances[0].addRules('CompanyName', { required: true });
      //   args.form.ej2_instances[0].addRules('FullYarnCount', {
      //     required: true,
      //   });
      //   args.form.ej2_instances[0].addRules('YarnBrand', { required: true });
      //   args.form.ej2_instances[0].addRules('ImportLCNo', { required: true });
      //   args.form.ej2_instances[0].addRules('OrderBags', { required: true });
      //   args.form.ej2_instances[0].addRules('WeightPerBag', { required: true });
      //   args.form.ej2_instances[0].addRules('ProcurementType', {
      //     required: true,
      //   });
      //   args.form.ej2_instances[0].addRules('Currency', { required: true });
      //   args.form.ej2_instances[0].addRules('ForeignCurrencyRate', {
      //     required: true,
      //   });
      //   args.form.ej2_instances[0].addRules('ForeignCurrencyAmount', {
      //     required: true,
      //   });
      //   args.form.ej2_instances[0].addRules('ContractExchangeRate', {
      //     required: true,
      //   });
      //   args.form.ej2_instances[0].addRules('Rate', { required: true });
      //   args.form.ej2_instances[0].addRules('RatePer', { required: true });

      //   args.form.ej2_instances[0].addRules('TaxType', { required: true });
      //   args.form.ej2_instances[0].addRules('TaxPercent', { required: true });
      //   args.form.ej2_instances[0].addRules('PaymentTerms', {
      //     required: true,
      //   });

      //   args.form.ej2_instances[0].addRules('DeliveryStatus', {
      //     required: true,
      //   });

      //   args.form.ej2_instances[0].addRules('PaymentDays', {
      //     required: true,
      //   });
      // }
    }
    if (args.requestType === 'batchsave') {
      const data = {
        rows: 1
      };
      // console.log('current data:', userSecurityItemGrid?.dataSource);
      // console.log('data: ', args.rows.map((elem) => elem.data));


  // old api of change access start
      // SecurityService.changeAccess(userSecurityItemGrid?.dataSource)
      //   .then((response) => {
      //     // setUserSecurityItems([]);
      //     // getUserSecurityItems(userID);
      //     userSecurityItemGrid?.toolbarModule?.enableItems(['Update', 'Cancel'], false);
      //   });
      // old api of change access end
      
      console.log('list ids'   , menuIDs , MenuIds)
      console.log('list ids'   , listIDs , ListIds)
      console.log('add  ids'   , addIDs , AddIds)
      console.log('edit ids'   , editIDs , EditIds)
      console.log('delete ids' , deleteIDs , DeleteIds)

      
      
      
      
      

      // menu
      let totalMenuID = [...menuIDs , ...MenuIds]
      let uniqueArrayMenu = [...new Set(totalMenuID)];
      console.log(uniqueArrayMenu)
      let arrayofobjectsMenu = userSecurityItemGrid?.dataSource;
      let filterObjMenu = arrayofobjectsMenu.filter((res)=>{ return  uniqueArrayMenu.includes(res.UserSecurityItemIDMenu)})
      
      // list
      let totalListID = [...listIDs , ...ListIds]
      let uniqueArrayList = [...new Set(totalListID)];
      console.log(uniqueArrayList)
      let arrayofobjectsList = userSecurityItemGrid?.dataSource;
      let filterObjList = arrayofobjectsList.filter((res)=>{ return  uniqueArrayList.includes(res.UserSecurityItemIDList)})
      
      // add      
      let totalAddID = [...addIDs , ...AddIds]
      const uniqueArrayAdd = [...new Set(totalAddID)];
      console.log(uniqueArrayAdd)
      let arrayofobjectsAdd = userSecurityItemGrid?.dataSource;
      let filterObjAdd = arrayofobjectsAdd.filter((res)=>{ return  uniqueArrayAdd.includes(res.UserSecurityItemIDAdd)})
      console.log(filterObjAdd)

      // edit
      let totalEditID = [...editIDs , ...EditIds]
      const uniqueArrayEdit = [...new Set(totalEditID)];
      console.log(uniqueArrayEdit)
      let arrayofobjectsEdit = userSecurityItemGrid?.dataSource;
      let filterObjEdit = arrayofobjectsEdit.filter((res)=>{ return  uniqueArrayEdit.includes(res.UserSecurityItemIDEdit)})
      console.log(filterObjEdit)
      // delete
      let totalDeleteID = [...deleteIDs , ...DeleteIds]
      const uniqueArrayDelete = [...new Set(totalDeleteID)];
      console.log(uniqueArrayDelete)
      let arrayofobjectsDelet = userSecurityItemGrid?.dataSource;
      let filterObjDelete = arrayofobjectsDelet.filter((res)=>{ return  uniqueArrayDelete.includes(res.UserSecurityItemIDDelete)})

      console.log(filterObjMenu)
      console.log(filterObjList)
      console.log(filterObjAdd)
      console.log(filterObjEdit)
      console.log(filterObjDelete)

      if(filterObjMenu != []){
      console.log(filterObjMenu)
      SecurityService.changeAccessMenu(filterObjMenu ,SecurityService.getLoggedInUserId())
        .then((response) => {
          // setUserSecurityItems([]);
          // getUserSecurityItems(userID);
          userSecurityItemGrid?.toolbarModule?.enableItems(['Update', 'Cancel'], false);
        });
      }
      if(filterObjList != []){
      console.log(filterObjList)
      SecurityService.changeAccessList(filterObjList ,SecurityService.getLoggedInUserId())
        .then((response) => {
          // setUserSecurityItems([]);
          // getUserSecurityItems(userID);
          userSecurityItemGrid?.toolbarModule?.enableItems(['Update', 'Cancel'], false);
        });
      }
      if(filterObjAdd != []){
      console.log(filterObjAdd)
      SecurityService.changeAccessAdd(filterObjAdd ,SecurityService.getLoggedInUserId())
      .then((response) => {
        // setUserSecurityItems([]);
        // getUserSecurityItems(userID);
        userSecurityItemGrid?.toolbarModule?.enableItems(['Update', 'Cancel'], false);
      });
    }
    if(filterObjEdit != []){
      console.log(filterObjEdit.length)
      // if(filterObjEdit.length )
      SecurityService.changeAccessEdit(filterObjEdit ,SecurityService.getLoggedInUserId())
      .then((response) => {
        // setUserSecurityItems([]);
        // getUserSecurityItems(userID);
        userSecurityItemGrid?.toolbarModule?.enableItems(['Update', 'Cancel'], false);
      });
    }
    if(filterObjDelete != []){
      console.log(filterObjDelete)
      SecurityService.changeAccessDelete(filterObjDelete ,SecurityService.getLoggedInUserId())
      .then((response) => {
        // setUserSecurityItems([]);
        // getUserSecurityItems(userID);
        userSecurityItemGrid?.toolbarModule?.enableItems(['Update', 'Cancel'], false);
      });
    }
      userSecurityItemGrid?.toolbarModule?.enableItems(['Update', 'Cancel'], false);

    }
  };

  const actionBegin = (args: DialogEditEventArgs) => {
    // console.log(userSecurityItemGrid?.toolbarModule?.toolbar?.tbarEle[1]);
    if (args.requestType === 'paging' &&
      userSecurityItemGrid?.toolbarModule?.toolbar?.tbarEle[0]?.getAttribute("aria-disabled") == "false") {
      args.cancel = true;
      // alert('Save changes before paging');
      DialogUtility.confirm({
        title: 'Save Changes',
        content: 'Save Changes Before Paging!',
        position: { Y: 100 },
        okButton: { text: 'Save', click: () => document.getElementById('Update').click() },
        cancelButton: { text: 'Cancel', click: () => document.getElementById('Cancel').click() },
        showCloseIcon: true,
        closeOnEscape: true
      });

      // userSecurityItemGrid?.toolbarModule?.enableItems(['Update', 'Cancel'], false);
    }
  }

  const ddlFields: object = { value: 'value' };

  const [listIDs, setListIDs] = useState([]);
  const [menuIDs, setMenuIDs] = useState([]);
  const checkboxChangeList = (args) => {
    let UserSecurityItemIDs =  userSecurityItemGrid?.getCurrentViewRecords().map((val)=>{
      return val.UserSecurityItemIDList;
    })
    let UserSecurityItemIDsMenu =  userSecurityItemGrid?.getCurrentViewRecords().map((val)=>{
      return val.UserSecurityItemIDMenu;
    })
    console.log(UserSecurityItemIDs)
    console.log(userSecurityItemGrid?.getCurrentViewRecords())
    // UserSecurityItemIDs
    setMenuIDs([...menuIDs , ...UserSecurityItemIDsMenu])
    setListIDs([...listIDs , ...UserSecurityItemIDs])
    console.log(listIDs)
    let currentData = userSecurityItemGrid?.getCurrentViewRecords();
    currentData && currentData.map((el) => {
      el.CanAccessList = args.checked
    });
    currentData && currentData.map((el) => {
      el.CanAccessMenu = args.checked
    });
    // console.log(currentData);
    userSecurityItemGrid.refreshColumns();
    userSecurityItemGrid?.toolbarModule?.enableItems(['Update', 'Cancel'], true);
    // userSecurityItemGrid.editModule.batchSave();
  }
  
  const [addIDs, setAddIDs] = useState([]);
  const checkboxChangeAdd = (args) => {
    let UserSecurityItemIDs =  userSecurityItemGrid?.getCurrentViewRecords().map((val)=>{
      return val.UserSecurityItemIDAdd;
    })
    console.log(UserSecurityItemIDs)
    console.log(userSecurityItemGrid?.getCurrentViewRecords())
    // UserSecurityItemIDs
    setAddIDs([...addIDs , ...UserSecurityItemIDs])
    console.log(addIDs)
    let currentData = userSecurityItemGrid?.getCurrentViewRecords();
    currentData && currentData.map((el) => {
      el.CanAccessAdd = args.checked
    });
    // console.log(currentData);
    userSecurityItemGrid.refreshColumns();
    userSecurityItemGrid?.toolbarModule?.enableItems(['Update', 'Cancel'], true);
    // userSecurityItemGrid.editModule.batchSave();
  }
  
  const [editIDs, setEditIDs] = useState([]);
  const checkboxChangeEdit = (args) => {
    let UserSecurityItemIDs =  userSecurityItemGrid?.getCurrentViewRecords().map((val)=>{
      return val.UserSecurityItemIDEdit;
    })
    console.log(UserSecurityItemIDs)
    console.log(userSecurityItemGrid?.getCurrentViewRecords())
    // UserSecurityItemIDs
    setEditIDs([...editIDs , ...UserSecurityItemIDs])
    console.log(editIDs)
    let currentData = userSecurityItemGrid?.getCurrentViewRecords();
    currentData && currentData.map((el) => {
      el.CanAccessEdit = args.checked
    });
    // console.log(currentData);
    userSecurityItemGrid.refreshColumns();
    userSecurityItemGrid?.toolbarModule?.enableItems(['Update', 'Cancel'], true);
    // userSecurityItemGrid.editModule.batchSave();
  }
  
  const [deleteIDs, setDeleteIDs] = useState([]);
  const checkboxChangeDelete = (args) => {
    let UserSecurityItemIDs =  userSecurityItemGrid?.getCurrentViewRecords().map((val)=>{
      return val.UserSecurityItemIDDelete;
    })
    console.log(UserSecurityItemIDs)
    console.log(userSecurityItemGrid?.getCurrentViewRecords())
    // UserSecurityItemIDs
    setDeleteIDs([...deleteIDs , ...UserSecurityItemIDs])
    console.log(deleteIDs)
    let currentData = userSecurityItemGrid?.getCurrentViewRecords();
    currentData && currentData.map((el) => {
      el.CanAccessDelete = args.checked
    });
    // console.log(currentData);
    userSecurityItemGrid.refreshColumns();
    userSecurityItemGrid?.toolbarModule?.enableItems(['Update', 'Cancel'], true);
    // userSecurityItemGrid.editModule.batchSave();
  }

  const headerTemplateList = () => (
    <div>
      <CheckBoxComponent ref={headerCheckboxList} id='userCanAcessCheckBox' change={checkboxChangeList} />
      &nbsp;
      <span> List</span>
    </div>
  )
  const headerTemplateAdd = () => (
    <div>
      <CheckBoxComponent ref={headerCheckboxAdd} id='userCanAcessCheckBox' change={checkboxChangeAdd} />
      &nbsp;
      <span> Add</span>
    </div>
  )
  const headerTemplateEdit = () => (
    <div>
      <CheckBoxComponent ref={headerCheckboxEdit} id='userCanAcessCheckBox' change={checkboxChangeEdit} />
      &nbsp;
      <span> Edit</span>
    </div>
  )
  const headerTemplateDelete = () => (
    <div>
      <CheckBoxComponent ref={headerCheckboxDelete} id='userCanAcessCheckBox' change={checkboxChangeDelete} />
      &nbsp;
      <span> Delete</span>
    </div>
  )

  const spaceOutItemName = (field: string, data: object, column: Column) => {
    const value = data[column?.field];
    return value?.match(/[A-Z][a-z]+/g)?.join(" ");
  };

  return (
    <div className='content-wrapper'>
      <div>
        <h3 style={{ textAlign: 'left' }}> Change Access - Edit</h3>
        <div className='user-selection-container'>
          <AutoCompleteComponent
            id="User" dataSource={userIDs}
            placeholder="Select User" fields={ddlFields}
            floatLabelType='Auto' highlight={true}
            select={userIDChange}
            change={userIDChange}
          />
        </div>
        <br />
        <GridComponent
          id="UserSecurityItemGrid"
          ref={(grid) => userSecurityItemGrid = grid}
          dataSource={userSecurityItems}
          editSettings={editSettings}
          toolbar={toolbarOptions}
          pageSettings={{ pageCount: 4, pageSizes: true }}
          filterSettings={filterOptions}
          groupSettings={groupOptions}
          actionComplete={actionComplete}
          allowPaging={true}
          allowSorting={true}
          toolbarClick={toolbarClick}
          allowFiltering={true}
          allowGrouping={true}
          showColumnChooser={true}
          allowReordering={true}
          allowTextWrap='wrap'
          allowResizing={true}
          sortSettings={sortingOptions}
          created={() => {
            userSecurityItemGrid?.toolbarModule?.enableItems(['Update', 'Cancel'], false)
          }}
        // actionBegin={actionBegin}
        >
          <ColumnsDirective>
            <ColumnDirective
              field="ModuleID"
              headerText="ID"
              width="40"
              textAlign="Right"
              allowEditing={false}
              isPrimaryKey={true}
            />
            <ColumnDirective
              field="ModuleName"
              headerText="Module Name"
              width="90"
              // valueAccessor={spaceOutItemName}
              allowEditing={false}
            />
            <ColumnDirective
              // field="InputName"
              field="ItemCategory"
              headerText="Item Name"
              width="90"
              // valueAccessor={spaceOutItemName}
              allowEditing={false}
            />
            {/* <ColumnDirective
              field="ItemTypeName"
              headerText="Item Type"
              width="40"
              allowEditing={false}
            />
            <ColumnDirective
              field="UserID"
              headerText="User ID"
              width="70"
              textAlign="Right"
              allowEditing={false}
            /> */}
            {/* <ColumnDirective
              field="UserName"
              headerText="User Name"
              width="70"
              allowEditing={false}
            /> */}
            <ColumnDirective
              field="CanAccessList"
              headerText="List"
              width="70"
              // allowEditing={true}
              // displayAsCheckBox={true}
              // editType="booleanedit"
              template={(data) => (
                <CheckBoxComponent checked={data.CanAccessList} change={(args) => {
                  //userSecurityItemGrid.editModule.updateCell(args.event.target.closest('tr').rowIndex, 'CanAccessList', args.checked);
                  // userSecurityItemGrid.editModule.saveCell();
                  MenuIds =  [...MenuIds , data.UserSecurityItemIDMenu]
                  ListIds =  [...ListIds , data.UserSecurityItemIDList]
                  const currentData = userSecurityItemGrid?.getCurrentViewRecords();
                  console.log(currentData[args.event.target.closest('tr').rowIndex])
                  if (currentData) {
                    currentData[args.event.target.closest('tr').rowIndex].CanAccessList = args.checked;
                    currentData[args.event.target.closest('tr').rowIndex].CanAccessMenu = args.checked;
                    userSecurityItemGrid?.toolbarModule?.enableItems(['Update', 'Cancel'], true);
                    // userSecurityItemGrid?.editModule?.batchSave();
                  }
                }} />
              )}
              allowFiltering={false}
              allowSorting={false}
              headerTemplate={headerTemplateList}
            />
            <ColumnDirective
              field="CanAccessAdd"
              headerText="Add"
              width="70"
              // allowEditing={true}
              // displayAsCheckBox={true}
              // editType="booleanedit"
              template={(data) => (
                <CheckBoxComponent checked={data.CanAccessAdd} change={(args) => {
                  //userSecurityItemGrid.editModule.updateCell(args.event.target.closest('tr').rowIndex, 'CanAccessAdd', args.checked);
                  // userSecurityItemGrid.editModule.saveCell();
                  AddIds =  [...AddIds , data.UserSecurityItemIDAdd]
                  const currentData = userSecurityItemGrid?.getCurrentViewRecords();
                  if (currentData) {
                    currentData[args.event.target.closest('tr').rowIndex].CanAccessAdd = args.checked;
                    userSecurityItemGrid?.toolbarModule?.enableItems(['Update', 'Cancel'], true);
                    // userSecurityItemGrid?.editModule?.batchSave();
                  }
                }} />
              )}
              allowFiltering={false}
              allowSorting={false}
              headerTemplate={headerTemplateAdd}
            />
            <ColumnDirective
              field="CanAccessEdit"
              headerText="Edit"
              width="70"
              // allowEditing={true}
              // displayAsCheckBox={true}
              // editType="booleanedit"
              template={(data) => (
                <CheckBoxComponent checked={data.CanAccessEdit} change={(args) => {
                  //userSecurityItemGrid.editModule.updateCell(args.event.target.closest('tr').rowIndex, 'CanAccessEdit', args.checked);
                  // userSecurityItemGrid.editModule.saveCell();
                  EditIds =  [...EditIds , data.UserSecurityItemIDEdit]
                  const currentData = userSecurityItemGrid?.getCurrentViewRecords();
                  if (currentData) {
                    currentData[args.event.target.closest('tr').rowIndex].CanAccessEdit = args.checked;
                    userSecurityItemGrid?.toolbarModule?.enableItems(['Update', 'Cancel'], true);
                    // userSecurityItemGrid?.editModule?.batchSave();
                  }
                }} />
              )}
              allowFiltering={false}
              allowSorting={false}
              headerTemplate={headerTemplateEdit}
            />
            <ColumnDirective
              field="CanAccessDelete"
              headerText="Delete"
              width="70"
              // allowEditing={true}
              // displayAsCheckBox={true}
              // editType="booleanedit"
              template={(data) => (
                <CheckBoxComponent checked={data.CanAccessDelete} change={(args) => {
                  //userSecurityItemGrid.editModule.updateCell(args.event.target.closest('tr').rowIndex, 'CanAccessDelete', args.checked);
                  // userSecurityItemGrid.editModule.saveCell();
                  DeleteIds =  [...DeleteIds , data.UserSecurityItemIDDelete]
                  const currentData = userSecurityItemGrid?.getCurrentViewRecords();
                  if (currentData) {
                    currentData[args.event.target.closest('tr').rowIndex].CanAccessDelete = args.checked;
                    userSecurityItemGrid?.toolbarModule?.enableItems(['Update', 'Cancel'], true);
                    // userSecurityItemGrid?.editModule?.batchSave();
                  }
                }} />
              )}
              allowFiltering={false}
              allowSorting={false}
              headerTemplate={headerTemplateDelete}
            />
          </ColumnsDirective>
          <Inject services={[
            Resize,
            Edit,
            Toolbar,
            Page,
            Sort,
            PdfExport,
            ExcelExport,
            Filter,
            Group,
            Aggregate,
            Freeze,
            ColumnChooser,
            Reorder,
            CommandColumn,
          ]} />
        </GridComponent>
      </div>
    </div >
  )
}
