import { createSlice } from '@reduxjs/toolkit'

const Head =  createSlice({
    name: 'head',
    initialState:{
        Title:'Home'
        },
    reducers:{
        setTitle(state , action){
            state.Title = action.payload
            console.log('action' ,action)
            console.log('state' ,state)
        },
    }
 
})
export const { setTitle } = Head.actions
export default Head.reducer