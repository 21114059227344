import { React, useRef, useState, useEffect } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import './signin.scss'
import securityService from '../security.service';
import Alert from '@mui/material/Alert';
import moment from 'moment';
import SRC from './dalal-industries.jpeg'
import Cookies from 'js-cookie';


const useStyles = makeStyles((theme) => ({
    root: {
        // height: '93vh',
        height: 'calc(100vh - 53px)'
    },
    paperRoot:{
        backgroundColor: Cookies.get('login-bg-color'),
         
    },
    image: {
        // backgroundImage: `url(${SRC})`,
        backgroundRepeat: 'no-repeat',
        backgroundColor:
            theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
        backgroundSize: 'contain',
        backgroundPosition: 'center',
        position: 'relative',
        //opacity: '0.5'
    },
    paper: {
        margin: theme.spacing(8, 4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        color: '#fff'
    },
    avatar: {
        margin: theme.spacing(1),
        // backgroundColor: '#00cc67',
        backgroundColor: `${Cookies.get('login-av-color')} !important`,
        // backgroundColor: theme.palette.success.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
        borderRadius: '25px',
        borderColor:'#000',
        backgroundColor: '#fff',
        color:'#000',
        // borderColor: theme.palette.success.main,
        // backgroundColor: theme.palette.success.main,
        padding: '10px 50px',
        '&:hover': {
            // backgroundColor: theme.palette.success.light
            backgroundColor:Cookies.get('login-bg-color'),
        },
        '&:active': {
            backgroundColor: Cookies.get('login-bg-color'),
            borderColor:  Cookies.get('login-tx-color'),
            border: '0'
        }
    },
}));

export default function SignInSide(props) {
    const classes = useStyles();
    const [authFail, setAuthFail] = useState(false);
    const [resetSuccessful, setResetSuccessful] = useState(false);
    const [passMatch, setPassMatch] = useState(true);
    const linkElem = useRef(null);
    const [isRemembered, setIsRemembered] = useState(false);
    const [isChangePassScreen, setIsChangePassScreen] = useState(false);
    const [isResetPassScreen, setIsResetPassScreen] = useState(false);
    const [username, setUsername] = useState('');
    const [newPassword, setNewPassword] = useState('');

    useEffect(() => {
        // localStorage.removeItem('login');
        isAuthenticated();
        setTimeout(()=>{
            document.querySelector('.MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-12.MuiGrid-grid-sm-8.MuiGrid-grid-md-5').style.backgroundColor = Cookies.get('login-bg-color') || '#00cc67'
        },100)

    }, [])

    const handleSignInSubmit = (event) => {
        event.preventDefault();
        setTimeout(()=>{
            document.querySelector('.MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-12.MuiGrid-grid-sm-8.MuiGrid-grid-md-5').style.backgroundColor = Cookies.get('login-bg-color') || '#00cc67'
        },100)
        const data = new FormData(event.currentTarget);

        if (data.get('remember')) {
            setIsRemembered(true);
        } else {
            setIsRemembered(false);
        }
        const loginDetails = {
            username: data.get('username').toString().toUpperCase(),
            password: data.get('password').toString(),
        };
        setUsername(data.get('username'));
        securityService.authenticateUser(loginDetails).then((response) => {
            console.log('response: ', response);
            if (response?.data?.recordset?.length === 1) {
                if (response?.data?.recordset[0]?.IsTempPassword) {
                    setIsResetPassScreen(false);
                    setIsChangePassScreen(true);
                } else {
                    const loginInfo = {
                        username: data.get('username'),
                        userId: response?.data?.recordset[0]?.UserID,
                        time: moment().format('DD/MM/YYYY HH:mm:ss'),
                        remembered: data.get('remember') ? true : false,
                        ShopID:response.data.recordset[0]?.ShopID
                    }
                    signInUser(loginInfo);
                    getUserAccessList(loginInfo?.userId);
                    // setTimeout(linkElem.current.click(), 10000);

                }
            } else {
                setAuthFail(true);
                setTimeout(() => setAuthFail(false), 5000);
            }
        });
    };

    const handleResetPassword = (event) => {
        const data = new FormData(event.currentTarget);
        setTimeout(()=>{
            document.querySelector('.MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-12.MuiGrid-grid-sm-8.MuiGrid-grid-md-5').style.backgroundColor = Cookies.get('login-bg-color') || '#00cc67'
        },100)
        const resetDetails = {
            username: data.get('username').toUpperCase(),
            email: data.get('email').toUpperCase(),
            password: Math.random().toString(36).slice(2),
            isTemp: 1
        };
        securityService.verifyEmail(resetDetails).then((response) => {
            if (response?.data?.recordset[0]?.verification) {
                // verified
                setIsResetPassScreen(false);
                setIsChangePassScreen(true);
                // setResetSuccessful(true);
                // setTimeout(() => setResetSuccessful(false), 5000);
                // securityService.emailSend({pass:resetDetails.password , email: resetDetails.email}).then(res=>{
                //     console.log(res)
                // })
            } else {
                setAuthFail(true);
                setTimeout(() => setAuthFail(false), 5000);
            }
        });
        event.preventDefault();
    };
    const [Iserror, setIserror] = useState(false);
    const handleChangePassword = (event) => {
        const data = new FormData(event.currentTarget);
        setTimeout(()=>{
            document.querySelector('.MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-12.MuiGrid-grid-sm-8.MuiGrid-grid-md-5').style.backgroundColor = `${Cookies.get('login-bg-color')} !important` || '#00cc67'
            document.querySelector('.MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-12.MuiGrid-grid-sm-8.MuiGrid-grid-md-5').style.backgroundColor = `${Cookies.get('login-bg-color')} !important` || '#00cc67'
        },100)
        const loginDetails = {
            username: username.toUpperCase(),
            password: data.get('newPassword'),
            isTemp: 0
        };
        if (loginDetails.password === data.get('rePassword') ) {
            securityService.changePassword(loginDetails)
                .then(() => {
                    // setIsResetPassScreen(false);
                    // setResetSuccessful(false);
                    // setTimeout(() => setResetSuccessful(false), 5000);
                    
                    // linkElem.current.click()
                    // const loginInfo = {
                    //     username: username.toUpperCase(),
                    //     time: moment().format('DD/MM/YYYY HH:mm:ss'),
                    //     remembered: isRemembered
                    // }
                    // signInUser(loginInfo);
                    // getUserAccessList(loginInfo?.userId);
                    // setAuthFail(false);
                    // setIsResetPassScreen(false);
                    setIsResetPassScreen(false)
                    setIsChangePassScreen(false)

                    // setTimeout(linkElem.current.click(), 5000);
                });
        } else {
            setPassMatch(false);
            setTimeout(() => setPassMatch(true), 5000);
        }
        event.preventDefault();
    };

    const isAuthenticated = () => {
        var authentication = localStorage.getItem('login');
        if (authentication !== null) {
            props.history.push('/home');
            // auth.login(()=> {
            //     // linkElem.current.click();
            //     props.history.push('/home');
            // })        
        }
    };
    // const rememberMeChange = (event) => {
    //     //event.preventDefault();
    //     console.log('remember me: ', event?.target?.checked);
    //     setIsRemembered(event?.target?.checked);
    // };

    const resetPassClickHandler = (event) => {
        setAuthFail(false);
        setIsResetPassScreen(true);
    };

    const backClickHandler = (event) => {
        setAuthFail(false);
        setIsResetPassScreen(false);
    };

    const signInUser = (loginInfo) => {
        // I don't think we need to clear all localstorage??
        // localStorage.clear();
        console.log(loginInfo)
        localStorage.removeItem('login');
        localStorage.setItem('login', JSON.stringify(loginInfo));
    }
    const isStrongPassword = (password) => {
        // Ensure the length is 8 characters or more
        const lengthRegex = /^.{8,}$/;
    
        // Ensure one or more symbols
        const symbolRegex = /[\W_]/;
    
        // Ensure one or more numeric fields
        const numericRegex = /\d/;
    
        // Ensure one or more Upper Case Alphanumeric Characters
        const upperCaseRegex = /[A-Z\d]/;
    
        // Ensure one or more Lower Case Alphanumeric Characters
        const lowerCaseRegex = /[a-z\d]/;
    
        // Check all conditions
        return (
            lengthRegex.test(password) &&
            symbolRegex.test(password) &&
            numericRegex.test(password) &&
            upperCaseRegex.test(password) &&
            lowerCaseRegex.test(password)
        );
    }
    const SignInComponent = () => (
        <Grid item xs={12} sm={8} md={5} elevation={6} className={classes.paperRoot} >
            <div className={classes.paper}>
                <Avatar className={classes.avatar}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5" className='common_colour'>
                    Sign in
                </Typography>
                <form className={classes.form} onSubmit={handleSignInSubmit}>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="username"
                        label="User Name"
                        name="username"
                        autoComplete="username"
                        autoFocus
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                    />
                    {/* <FormControlLabel
                        control={<Checkbox value="remember" />}
                        label="Remember me"
                        name='remember'
                    /> */}
                    <div className='button_container'>
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                        >
                            Sign In
                        </Button>
                    </div>
                    <Grid container>
                        <Grid item xs>
                            <Link variant="body2" onClick={resetPassClickHandler} className='link'>
                                <span className="common_colour">Reset password</span>
                            </Link>
                        </Grid>
                    </Grid>
                    <Box mt={5}>
                        {authFail && <Alert elevation={6} variant="filled" severity="error" >Incorrect Username or Password</Alert>}
                        <br />
                        {/* {resetSuccessful && <Alert elevation={6} variant="filled" >Password successfully reseted and a temporary password has been sent to your email</Alert>} */}
                    </Box>
                </form>
            </div>
        </Grid>
    );

    const ResetPasswordComponent = () => (
        <Grid item xs={12} sm={8} md={5} elevation={6} className={classes.paperRoot} >
            <div className={classes.paper}>
                <Avatar className={classes.avatar}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5" className='common_colour'>
                    Email Verification
                </Typography>
                <form className={classes.form} onSubmit={handleResetPassword}>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="username"
                        label="User Name"
                        name="username"
                        autoComplete="username"
                        autoFocus
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        //color="success"
                        required
                        fullWidth
                        id="email"
                        label="Email"
                        type="email"
                        name="email"
                        autoComplete="email"
                    />
                    <div className='button_container'>
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                        >
                            Verify
                        </Button>
                        &nbsp;
                        <Button
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                            onClick={backClickHandler}
                        >
                            Back
                        </Button>
                    </div>
                    <Box mt={5}>
                        {authFail && <Alert elevation={6} variant="filled" severity="error" color="error">Provided Email did not match our records</Alert>}
                        <br />
                    </Box>
                </form>
            </div>
        </Grid>
    );

    const ChangePasswordComponent = () => (
        <Grid item xs={12} sm={8} md={5} elevation={6} className={classes.paperRoot} >
            <div className={classes.paper}>
                <Avatar className={classes.avatar}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5" className='common_colour' >
                    Change Password
                </Typography>
                <form className={classes.form} onSubmit={handleChangePassword} >
                    <TextField
                    
                        variant="outlined"
                        margin="normal"
                        required
                        type='password'
                        fullWidth
                        name="newPassword"
                        label="New Password"
                        typpe="password"
                        id="newPassword"
                        autoComplete="current-password"
                        // value={newPassword}
                        // ={(e)=>{
                        //     console.log(e)
                        //     console.log(e.target.value)
                        //     setNewPassword(e.target.value)
                        //   console.log(isStrongPassword(e.target.value));
                        //     if(isStrongPassword(e.target.value) ){
                        //     //  let passwordValue = (e.value).toUpperCase()
                        //     //   console.log((e.value).toUpperCase())
                        //     //   console.log(passwordValue)
                        //     //   setPassword(passwordValue)
                        //       setIserror(false)
                              
                        //     //   console.log('ok')
                        //     //   setError('')
                        //       document.getElementById('newPassword-label').style.color = 'white'
                        //     }else{
                        //       console.log('not ok')
                        //       // setPassword('')
                        //       document.getElementById('newPassword-label').style.color = 'red'
                        //       setIserror(true)
                              
                              
                        //     }
                        //                             console.log(e)
                        //   }}
                        
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="rePassword"
                        label="Re-enter Password"
                        type="password"
                        id="rePassword"
                        autoComplete="current-password"
                    />
                    {/* <ul className='strong-password-ul'>
                        <li>Length of password should be 8 characters and above</li>
                        <li>One or more symbols</li>
                        <li>One or more numeric fields</li>
                        <li>One or more Upper Case Alphanumeric Characters</li>
                        <li>One or more Lower Case Alphanumeric Characters</li>
                    </ul> */}
                    <div className='button_container'>
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                            // disabled={Iserror}
                        >
                            {/* {Iserror == false ? `Change` : `Strong Password`} */}
                            Change

                        </Button>
                    </div>
                    <Box mt={5}>
                        {!passMatch && <Alert elevation={6} variant="filled" severity="error" >Passwords Don't Match</Alert>}
                        <br />
                    </Box>
                </form>
            </div>
        </Grid>
    );

    const getUserAccessList = (userId) => {
        if (userId  || userId == '0') {
            securityService.getUserAccessList(userId)
                .then((response) => {
                    console.log('response: ', response);
                    if (response?.data?.recordset?.length === 0) {
                        console.log('No security items found: ');
                    }
                    else {
                        console.log(JSON.stringify(response?.data?.recordset));
                        localStorage.setItem('userAccessList', JSON.stringify(response?.data?.recordset));
                    }
                    linkElem.current.click()
                });
        }
        else {
            console.log('could not read user id: ');
        }
    };

    return (
        <Grid container component="main" className={classes.root}>
            <CssBaseline />
            <Grid item xs={false} sm={4} md={7} className={classes.image}>
                {/* <Typography variant="h1" align="center" className='company'>
                    Dalal Industries
                </Typography> */}
            </Grid>
            {isResetPassScreen ?
                <ResetPasswordComponent />
                : isChangePassScreen ?
                    <ChangePasswordComponent /> :
                    <SignInComponent />
            }
            <Link href='/home' variant="body2" ref={linkElem}> </Link>
        </Grid>
    );
}
