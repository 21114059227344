import React, { useEffect, useState, useMemo } from 'react';
import moment from 'moment';
import DropdownService from '../../irtiza-dynamic-input/dropdown-api.service';
import invoiceService from '../../invoice/invoice.service';
import { Page, Text, View, Document, StyleSheet, PDFViewer } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  page: {
    // margin: 20,  // Increased margin for more space around the page
    padding: 20,
    fontSize: 10
  },
  // section: {
  //   margin: 10,
  //   padding: 10,
  //   flexGrow: 1
  // },
  table: {
    display: 'table',
    width: 'auto',
    borderStyle: 'none',
    borderWidth: 0, 
    borderRightWidth: 0,
    borderBottomWidth: 0,
  },
  tableRow: {
    flexDirection: 'row'
  },
  tableCol: {
    width: '10%',
    borderStyle: 'none',
    borderWidth: 0,
    borderLeftWidth: 0,
    borderTopWidth: 0
  },
  tableCell: {
    marginRight: 'auto',
    marginTop: 5,
    padding: 5,
    fontSize: 8
  },
  tableCellHeader: {
    margin: 'auto',
    marginTop: 5,
    padding: 5,
    fontSize: 8
  },
  title: {
    fontSize: 12,
    marginBottom: 10,
    textAlign: 'center'
  }
});

const Report = React.memo(({ data, dynamicColumn ,grandTotals }) => {
  
console.log(grandTotals)
  
  

  return(
  <Document title='Stock Shop Wise'>
    <Page style={styles.page} orientation={'landscape'} size={'A4'}>
      <Text style={styles.title}>Shop Wise Stock</Text>
      <View style={styles.table}>
        <View style={{ ...styles.tableRow, borderBottom: 'solid', borderBottomWidth: 1 }} fixed>
          <View style={{ ...styles.tableCol, width: '20%' }}><Text style={styles.tableCellHeader}>PRODUCT NAME</Text></View>
          {dynamicColumn.map((val, key) => (
            <View style={styles.tableCol} key={key}><Text style={styles.tableCellHeader}>{val}</Text></View>
          ))}
          <View style={styles.tableCol}><Text style={styles.tableCellHeader}>TOTAL STOCK QTY</Text></View>
        </View>
        {data.map((group, groupKey) => (
          <View key={groupKey}>
            <View style={{ width: '100%', padding: '5px', textAlign: 'center' }}>
              <Text>{group.products[0]?.BrandName}</Text>
            </View>
            {group.products.map(product => (
              <View style={styles.tableRow} key={product.ProductID}>
                <View style={{ ...styles.tableCol, width: '20%' }}><Text style={styles.tableCell}>{product.ProductName}</Text></View>
                {Object.keys(group.totals).filter(key => key.startsWith('Shop')).map(key => (
                  <View style={{ ...styles.tableCol, textAlign: 'right' }} key={key}><Text style={styles.tableCell}>{product[key].toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}</Text></View>
                ))}
                <View style={{ ...styles.tableCol, textAlign: 'right' }}><Text style={styles.tableCell}>{product.TotalStockQty.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}</Text></View>
              </View>
            ))}
            <View style={{ ...styles.tableRow, borderTop: 'solid', borderTopWidth: 1 }}>
              <View style={{ ...styles.tableCol, width: '20%', padding: 0 }}><Text style={styles.tableCellHeader}>GROUP TOTAL</Text></View>
              {Object.keys(group.totals).filter(key => key.startsWith('Shop')).map(key => (
                <View style={{ ...styles.tableCol, textAlign: 'right' }} key={key}><Text style={styles.tableCell}>{group.totals[key].toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}</Text></View>
              ))}
              <View style={{ ...styles.tableCol, textAlign: 'right' }}><Text style={styles.tableCell}>{group.totals.TotalStockQty.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}</Text></View>
            </View>
          </View>
        ))}
    <View style={{ ...styles.tableRow, borderBottom: 'solid', borderBottomWidth: 1 ,borderTop: 'solid', borderTopWidth: 1 }}>
              <View style={{ ...styles.tableCol, width: '20%', padding: 0 }}><Text style={styles.tableCellHeader}>REPORT TOTAL</Text></View>
              {Object.keys(grandTotals).filter(key => key.startsWith('Shop')).map(key => (
                <View style={{ ...styles.tableCol, textAlign: 'right' }} key={key}><Text style={styles.tableCell}>{grandTotals[key]}</Text></View>
              ))}
              <View style={{ ...styles.tableCol, textAlign: 'right' }}><Text style={styles.tableCell}>{grandTotals.TotalStockQty}</Text></View>
            </View>
        </View>
    </Page>
  </Document>
)});

const Inventory = (props) => {
  // const [dynamicColumns, setDynamicColumns] = useState([]);
  // const [dataSource, setDataSource] = useState(undefined);
  // const [reportTotal, setReportTotal] = useState({});

  // useEffect(() => {
  //   if (document.getElementById('pushmenu-bar')) {
  //     document.getElementById('pushmenu-bar').click();
  //   }
  //   reportDataSource();
  //   getDynamicColumns();
  // }, []);

  // const filterDataByID = (data, id) => data.filter(res => res.id === id)[0]?.value;

  // const getDynamicColumns = async () => {
  //   try {
  //     const response = await DropdownService.getAllForList('Shop');
  //     if (response.data && response.data.recordset) {
  //       setDynamicColumns(
  //         [
  //           `${filterDataByID(response.data.recordset, 1)} BAL QTY`,
  //           `${filterDataByID(response.data.recordset, 2)} BAL QTY`,
  //           `${filterDataByID(response.data.recordset, 3)} BAL QTY`,
  //           `${filterDataByID(response.data.recordset, 4)} BAL QTY`,
  //           `${filterDataByID(response.data.recordset, 5)} BAL QTY`,
  //           `${filterDataByID(response.data.recordset, 6)} BAL QTY`,
  //           `${filterDataByID(response.data.recordset, 7)} BAL QTY`,
  //           `${filterDataByID(response.data.recordset, 8)} BAL QTY`,
  //           `${filterDataByID(response.data.recordset, 9)} BAL QTY`,
  //           `${filterDataByID(response.data.recordset, 10)} BAL QTY`,
  //           `${filterDataByID(response.data.recordset, 15)} BAL QTY`
  //         ]
  //       );
  //     } else {
  //       console.error("Invalid response structure:", response);
  //     }
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  // const groupProductsByBrand = (products) => {
  //   const grouped = products.reduce((acc, product) => {
  //     const { BrandID, TotalStockQty, ...shopBalances } = product;
  //     if (!acc[BrandID]) {
  //       acc[BrandID] = {
  //         BrandID,
  //         products: [],
  //         totals: {
  //           TotalStockQty: 0,
  //           ...Object.keys(shopBalances).reduce((totalsAcc, key) => {
  //             if (key.startsWith('Shop') && key.endsWith('BalQty')) {
  //               totalsAcc[key] = 0;
  //             }
  //             return totalsAcc;
  //           }, {})
  //         }
  //       };
  //     }

  //     acc[BrandID].products.push(product);
  //     acc[BrandID].totals.TotalStockQty += TotalStockQty;

  //     Object.keys(shopBalances).forEach(key => {
  //       if (key.startsWith('Shop') && key.endsWith('BalQty')) {
  //         acc[BrandID].totals[key] += shopBalances[key];
  //       }
  //     });

  //     return acc;
  //   }, {});

  //   return Object.values(grouped);
  // };

  // const reportDataSource = async () => {
  //   const data = {
  //     ReportType: 'StockShopWise',
  //     // MainInvoiceID: props.match.params.MainInvoiceID,
  //     MainInvoiceID:0,
  //     FromDate:  `${props.match.params.FromDate}`,
  //     ToDate:  `${props.match.params.ToDate}`,
  //     BrandID: props.match.params.BrandID,
  //     ProductID: props.match.params.ProductID,
  //     ShopID: props.match.params.ShopID
  //   };
  //   const response = await invoiceService.getMultiDropDownSource(
  //     `${data.ReportType},${data.MainInvoiceID},${data.FromDate},${data.ToDate},${data.BrandID},${data.ProductID},${data.ShopID}`
  //   );
  //   const dataSorce = groupProductsByBrand(response.data.recordset);
  //   setDataSource(dataSorce);

  //   const calculateTotals = (data) => {
  //     const totals = {
  //       Shop1BalQty: 0,
  //       Shop2BalQty: 0,
  //       Shop3BalQty: 0,
  //       Shop4BalQty: 0,
  //       Shop5BalQty: 0,
  //       Shop6BalQty: 0,
  //       Shop7BalQty: 0,
  //       Shop8BalQty: 0,
  //       Shop9BalQty: 0,
  //       Shop10BalQty: 0,
  //       Shop15BalQty: 0,
  //       TotalStockQty: 0
  //     };
    
  //     data.forEach(item => {
  //       totals.Shop1BalQty += item.Shop1BalQty;
  //       totals.Shop2BalQty += item.Shop2BalQty;
  //       totals.Shop3BalQty += item.Shop3BalQty;
  //       totals.Shop4BalQty += item.Shop4BalQty;
  //       totals.Shop5BalQty += item.Shop5BalQty;
  //       totals.Shop6BalQty += item.Shop6BalQty;
  //       totals.Shop7BalQty += item.Shop7BalQty;
  //       totals.Shop8BalQty += item.Shop8BalQty;
  //       totals.Shop9BalQty += item.Shop9BalQty;
  //       totals.Shop10BalQty += item.Shop10BalQty;
  //       totals.Shop15BalQty += item.Shop15BalQty;
  //       totals.TotalStockQty += item.TotalStockQty;
  //     });
    
  //     // Formatting totals
  //     for (let key in totals) {
  //       totals[key] = totals[key].toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 });
  //     }
    
  //     return totals;
  //   };
    
  //   console.log(calculateTotals(response.data.recordset));
    
  //   setReportTotal(calculateTotals(response.data.recordset))
  // };

  return (
    // <div className='content-wrapper'>
    //   {dataSource === undefined ? (
    //     <h2>...Loading Report</h2>
    //   ) : (
    //     <PDFViewer width="100%" height="1000" fileName='Voucher-Report'>
    //       <Report data={dataSource} dynamicColumn={dynamicColumns} grandTotals={reportTotal}/>
    //     </PDFViewer>
    //   )}
    // </div>
    <>
          <Report data={props.dataSource} dynamicColumn={props.dynamicColumns} grandTotals={props.reportTotal}/>
      
    </>
  );
};

export default Inventory;
