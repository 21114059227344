import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { Page, Text, View, Document, StyleSheet, PDFViewer ,PDFDownloadLink ,pdf} from '@react-pdf/renderer';
import invoiceService from '../../invoice/invoice.service';
import { saveAs } from 'file-saver';

// Create styles
const styles = StyleSheet.create({
  page: {
    padding: 20,
  },
  header: {
    textAlign: 'center',
    fontSize: 18,
    marginBottom: 20,
  },
  section: {
    marginBottom: 10,
  },
  table: {
    display: 'table',
    width: 'auto',
    borderStyle: 'solid',
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
    marginTop: '5px',
  },
  tableRow: {
    margin: 'auto',
    flexDirection: 'row',
  },
  tableCol: {
    width: '25%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableCell: {
    margin: 5,
    fontSize: 10,
    textAlign:'center'
  },
  footer: {
    textAlign: 'center',
    fontSize: 12,
    marginTop: 50,
    display: 'flex',
    width: '100%',
    justifyContent: 'space-around',
    flexDirection: 'row',
  },
  boxes: {
    border: '1px solid #000',
    padding: '10px 20px',
    fontWeight: 'bold',
  },

  belowBox:{
    marginTop:'15px',
    flexDirection:'row'
  }
});

// Create Document Component
const calculateTotals = (data) => {
    let totalQty = 0;
    let totalWeight = 0;
    let totalAmtGF = 0;
    let totalCostUS = 0;
    let totalCostMTS = 0;
    console.log(data)
    data.forEach(item => {
      totalQty += parseFloat(item.InvoiceQuantity);
      totalWeight += parseFloat(item.InvoiceWeight);
      totalAmtGF += parseFloat(item.TotalAmtGF);
      totalCostUS += parseFloat(item.CostUS);
      totalCostMTS += parseFloat(item.UnitCostGF);
    });
  
    return {
    //   totalDebit: totalDebit.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
    totalWeight: totalWeight.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 }),
    totalQty: totalQty.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 }),
    totalAmtGF: totalAmtGF.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 }),
    totalCostUS: totalCostUS.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 }),
    totalCostMTS: totalCostMTS.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 })
    };
  };
  
const calculatedetailDataTotals = (data) => {
    let totalUSAmt = 0;
    
    console.log(data)
    data.forEach(item => {
      totalUSAmt += parseFloat(item.USAmt);
    });
  
    return {
    //   totalDebit: totalDebit.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
    totalUSAmt: totalUSAmt.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 }),
    };
  };
  
const Report = ({ detailData,invoiceValueTotalData,invoiceCostingData }) => {
    console.log(detailData)
    console.log(invoiceValueTotalData)
    console.log(invoiceCostingData)
    const { totalQty ,totalWeight ,totalAmtGF ,totalCostUS ,totalCostMTS } = calculateTotals(invoiceCostingData);
    const {totalUSAmt} = calculatedetailDataTotals(detailData);
    const expensePerLb= invoiceValueTotalData[0]?.ExpensePerLB
console.log(invoiceValueTotalData[0])
console.log(invoiceValueTotalData[0].ExpensePerLb.toString())

  const partyName = invoiceCostingData[0]?.AccountTitle;
  const invoiceNo = invoiceCostingData[0]?.InvoiceNo;
  const landingDate = invoiceCostingData[0]?.InvoiceDate;

  return (
    // <PDFViewer width="100%" height="1000" fileName='InvoiceCosting-Report'>
      <Document title='InvoiceCosting'>
        <Page style={styles.page}>
        <Text style={styles.header}>Invoice Costing</Text>
        <View style={{ ...styles.tableRow, margin: '0px' }}>
            <View style={{ ...styles.tableCol, width: '18%', borderWidth: 0 }}>
              <Text style={{ ...styles.tableCell,fontSize:'11px', fontWeight: 'bold' }}>PARTY NAME:</Text>
            </View>
            <View style={{ ...styles.tableCol, width: '20%', borderWidth: 0 }}>
              <Text style={{...styles.tableCell,textAlign:'left'}}>{partyName}</Text>
            </View>
            <View style={{ ...styles.tableCol, width: '18%', borderWidth: 0 }}>
              <Text style={{ ...styles.tableCell,fontSize:'11px', fontWeight: 'bold' }}>INVOICE NO:</Text>
            </View>
            <View style={{ ...styles.tableCol, width: '13%', borderWidth: 0 }}>
              <Text style={{...styles.tableCell,textAlign:'left'}}>{invoiceNo}</Text>
            </View>
            <View style={{ ...styles.tableCol, width: '17%', borderWidth: 0 }}>
              <Text style={{ ...styles.tableCell,fontSize:'11px', fontWeight: 'bold' }}>LANDING DATE:</Text>
            </View>
            <View style={{ ...styles.tableCol, width: '12%', borderWidth: 0 }}>
              <Text style={{...styles.tableCell,textAlign:'left'}}>{moment(landingDate).format('DD-MMM-YY')}</Text>
            </View>
          </View>

          {/* <Text style={styles.header}>{`Party Name: ${partyName}`}</Text>
          <Text style={styles.header}>{`Invoice No: ${invoiceNo}`}</Text>
          <Text style={styles.header}>{`Landing Date: ${landingDate}`}</Text> */}

          <View style={styles.table}>
            <View style={styles.tableRow} fixed>
              <View style={{ ...styles.tableCol, width: '7%',borderTopWidth:1 }}>
                <Text style={{...styles.tableCell,fontSize:'12px', fontWeight: 'bold'}}>S.No.</Text>
              </View>
              <View style={{ ...styles.tableCol, width: '24%',borderTopWidth:1 }}>
                <Text style={{...styles.tableCell,fontSize:'12px', fontWeight: 'bold'}}>Description</Text>
              </View>
              <View style={{ ...styles.tableCol, width: '10%',borderTopWidth:1 }}>
                <Text style={{...styles.tableCell,fontSize:'12px', fontWeight: 'bold'}}>Qty</Text>
              </View>
              <View style={{ ...styles.tableCol, width: '8%',borderTopWidth:1 }}>
                <Text style={{...styles.tableCell,fontSize:'12px', fontWeight: 'bold'}}>Rate</Text>
              </View>
              <View style={{ ...styles.tableCol, width: '10%',borderTopWidth:1 }}>
                <Text style={{...styles.tableCell,fontSize:'12px', fontWeight: 'bold'}}>Exp</Text>
              </View>
              <View style={{ ...styles.tableCol, width: '10%',borderTopWidth:1 }}>
                <Text style={{...styles.tableCell,fontSize:'12px', fontWeight: 'bold'}}>Cost (MTS)</Text>
              </View>
              <View style={{ ...styles.tableCol, width: '11%',borderTopWidth:1 }}>
                <Text style={{...styles.tableCell,fontSize:'12px', fontWeight: 'bold'}}>Cost ($)</Text>
              </View>
              <View style={{ ...styles.tableCol, width: '9%',borderTopWidth:1 }}>
                <Text style={{...styles.tableCell,fontSize:'12px', fontWeight: 'bold'}}>Weight</Text>
              </View>
              <View style={{ ...styles.tableCol, width: '11%',borderTopWidth:1 }}>
                <Text style={{...styles.tableCell,fontSize:'12px', fontWeight: 'bold'}}>Total Amt (MTS)</Text>
              </View>
            </View>

            {invoiceCostingData.map((item, index) =>{
              let Sno = index + 1;
                    console.log(item)
                return (
              <View style={styles.tableRow} key={index}>
                <View style={{ ...styles.tableCol, width: '7%' }}>
                  <Text style={styles.tableCell}>{Sno}</Text>
                </View>
                <View style={{ ...styles.tableCol, width: '24%' }}>
                  <Text style={styles.tableCell}>{item.ProductName}</Text>
                </View>
                <View style={{ ...styles.tableCol, width: '10%' }}>
                  <Text style={{...styles.tableCell, textAlign:'right'}}>{item?.InvoiceQuantity.toLocaleString('en-US', { minimumFractionDigits:1 , maximumFractionDigits: 1 })}</Text>
                </View>
                <View style={{ ...styles.tableCol, width: '8%' }}>
                  <Text style={{...styles.tableCell, textAlign:'right'}}>{item.InvoiceRate.toLocaleString('en-US', { minimumFractionDigits: 2 , maximumFractionDigits: 2 })}</Text>
                </View>
                <View style={{ ...styles.tableCol, width: '10%' }}>
                  <Text style={{...styles.tableCell, textAlign:'right'}}>{item.Exp.toLocaleString('en-US', { minimumFractionDigits:0 , maximumFractionDigits: 0 })}</Text>
                </View>
                <View style={{ ...styles.tableCol, width: '10%' }}>
                  <Text style={{...styles.tableCell, textAlign:'right'}}>{item.UnitCostGF ? item.UnitCostGF.toLocaleString('en-US', { minimumFractionDigits:0 , maximumFractionDigits: 0 }) : 0}</Text>
                </View>
                <View style={{ ...styles.tableCol, width: '11%' }}>
                  <Text style={{...styles.tableCell, textAlign:'right'}}>{item.CostUS.toLocaleString('en-US', { minimumFractionDigits:0 , maximumFractionDigits: 0 })}</Text>
                </View>
                <View style={{ ...styles.tableCol, width: '9%' }}>
                  <Text style={{...styles.tableCell, textAlign:'right'}}>{item.InvoiceWeight.toLocaleString('en-US', { minimumFractionDigits:0 , maximumFractionDigits: 0 })}</Text>
                </View>
                <View style={{ ...styles.tableCol, width: '11%' }}>
                  <Text style={{...styles.tableCell, textAlign:'right'}}>{item.TotalAmtGF.toLocaleString('en-US', { minimumFractionDigits:0 , maximumFractionDigits: 0 })}</Text>
                </View>
              </View>
            )})}
             <View style={styles.tableRow}>
              <View style={{ ...styles.tableCol, width: '41%' }}>
                <Text style={{ ...styles.tableCell, textAlign:'right',fontWeight: 'bold',fontSize:'11px' ,borderRightWidth: 'transparent' }}>REPORT TOTAL</Text>
              </View>
              <View style={{ ...styles.tableCol, width: '8%', textAlign: 'right' }}>
                <Text style={{ ...styles.tableCell, textAlign:'right',fontWeight: 'bold',fontSize:'11px' }}>{totalQty}</Text>
              </View>
              <View style={{ ...styles.tableCol, width: '20%', textAlign: 'right' }}>
                <Text style={{ ...styles.tableCell, textAlign:'right',fontWeight: 'bold',fontSize:'11px' }}>{totalCostMTS}</Text>
              </View>
              <View style={{ ...styles.tableCol, width: '11%', textAlign: 'right' }}>
                <Text style={{ ...styles.tableCell, textAlign:'right',fontWeight: 'bold',fontSize:'11px' }}>{totalCostUS}</Text>
              </View>
              <View style={{ ...styles.tableCol, width: '9%', textAlign: 'right' }}>
                <Text style={{ ...styles.tableCell, textAlign:'right',fontWeight: 'bold',fontSize:'11px' }}>{totalWeight}</Text>
              </View>
              <View style={{ ...styles.tableCol, width: '11%', textAlign: 'right' }}>
                <Text style={{ ...styles.tableCell, textAlign:'right',fontWeight: 'bold',fontSize:'11px' }}>{totalAmtGF}</Text>
              </View>
            </View>
          

          </View>
          <View style={{flexDirection:'row', gap:'5px', marginTop: '10px'}}>
              <View style={{  width: '65%',border:'solid',borderWidth:'1px' }}>
              <View style={{flexDirection:'row',flexWrap:'wrap',width:'auto',padding:'5px' ,paddingRight:'10px' ,justifyContent:'space-between'}}>
                <View style={{...styles.tableCol,border:'none',width:'25%'}}><Text style={{marginTop:'10px',textAlign:'right',fontSize:'12px', fontWeight:'bold'}}>Direct Cost</Text></View>
                <View style={{...styles.tableCol,border:'none',width:'25%'}}><Text style={{marginTop:'10px',textAlign:'right',fontSize:'12px', fontWeight:'bold'}}>{invoiceValueTotalData[0]?.DirectCost.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}</Text></View>
                <View style={{...styles.tableCol,border:'none',width:'25%'}}><Text style={{marginTop:'10px',textAlign:'right',fontSize:'12px', fontWeight:'bold'}}>Total Wt</Text></View>
                <View style={{...styles.tableCol,border:'none',width:'25%'}}><Text style={{marginTop:'10px',textAlign:'right',fontSize:'12px', fontWeight:'bold'}}>{invoiceValueTotalData[0]?.TotalInvoiceWeight.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}</Text></View>
                <View style={{...styles.tableCol,border:'none',width:'25%'}}><Text style={{marginTop:'10px',textAlign:'right',fontSize:'12px', fontWeight:'bold',paddingRight:'2px'}}>Freight</Text></View>
                <View style={{...styles.tableCol,border:'none',width:'25%'}}><Text style={{marginTop:'10px',textAlign:'right',fontSize:'12px', fontWeight:'bold',borderBottom:'solid' , borderBottomWidth: 1}}>{invoiceValueTotalData[0]?.FreightAmountFC.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}</Text></View>
                <View style={{...styles.tableCol,border:'none',width:'25%'}}><Text style={{marginTop:'10px',textAlign:'right',fontSize:'12px', fontWeight:'bold'}}>Costing @</Text></View>
                <View style={{...styles.tableCol,border:'none',width:'25%'}}><Text style={{marginTop:'10px',textAlign:'right',fontSize:'12px', fontWeight:'bold'}}>{invoiceValueTotalData[0]?.ExchangeRate.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}</Text></View>
                <View style={{...styles.tableCol,border:'none',width:'25%'}}><Text style={{marginTop:'10px',textAlign:'right',fontSize:'12px', fontWeight:'bold'}}>Invoice Value</Text></View>
                <View style={{...styles.tableCol,border:'none',width:'25%'}}><Text style={{marginTop:'10px',textAlign:'right',fontSize:'12px', fontWeight:'bold'}}>{invoiceValueTotalData[0]?.InvoiceValue.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}</Text></View>
                <View style={{...styles.tableCol,border:'none',width:'25%'}}><Text style={{marginTop:'10px',textAlign:'right',fontSize:'12px', fontWeight:'bold'}}>Exp/Lbs</Text></View>
                <View style={{...styles.tableCol,border:'none',width:'25%'}}><Text style={{marginTop:'10px',textAlign:'right',fontSize:'12px', fontWeight:'bold'}}>{
              invoiceValueTotalData[0]?.ExpensePerLb < 1
              ? invoiceValueTotalData[0]?.ExpensePerLb.toLocaleString('en-US', { minimumFractionDigits: 4, maximumFractionDigits: 4 })
              : invoiceValueTotalData[0]?.ExpensePerLb.toLocaleString('en-US', { minimumFractionDigits: 4, maximumFractionDigits: 4 })
              }</Text></View>
                <View style={{...styles.tableCol,border:'none',width:'25%'}}><Text style={{marginTop:'10px',textAlign:'right',fontSize:'12px', fontWeight:'bold',paddingRight:'2px'}}>Add Other Exp</Text></View>
                <View style={{...styles.tableCol,border:'none',width:'25%'}}><Text style={{marginTop:'10px',textAlign:'right',fontSize:'12px', fontWeight:'bold',borderBottom:'solid' , borderBottomWidth: 1}}>{invoiceValueTotalData[0]?.TotalExpense.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}</Text></View>
                <View style={{...styles.tableCol,border:'none',width:'25%'}}><Text style={{marginTop:'10px',textAlign:'right',fontSize:'12px', fontWeight:'bold'}}></Text></View>
                <View style={{...styles.tableCol,border:'none',width:'25%'}}><Text style={{marginTop:'10px',textAlign:'right',fontSize:'12px', fontWeight:'bold'}}></Text></View>
                <View style={{...styles.tableCol,border:'none',width:'25%'}}><Text style={{marginTop:'10px',textAlign:'right',fontSize:'12px', fontWeight:'bold',paddingRight:'2px'}}>Total</Text></View>
                <View style={{...styles.tableCol,border:'none',width:'25%',borderBottom:'solid' , borderBottomWidth: 1,paddingBottom:'2px'}}><Text style={{marginTop:'10px',textAlign:'right',fontSize:'12px', fontWeight:'bold',borderBottom:'solid' , borderBottomWidth: 1}}>{invoiceValueTotalData[0]?.TotalCost.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}</Text></View>
                <View style={{...styles.tableCol,border:'none',width:'25%'}}><Text style={{marginTop:'10px',textAlign:'right',fontSize:'12px', fontWeight:'bold'}}></Text></View>
                <View style={{...styles.tableCol,border:'none',width:'25%'}}><Text style={{marginTop:'10px',textAlign:'right',fontSize:'12px', fontWeight:'bold'}}></Text></View>
              
              </View>
              
              </View>

              <View style={{border:'solid',borderWidth:'1px', width: '35%', padding:'5px'}}>
                <View style={{flexDirection:'row',flexWrap:'wrap',width:'auto',margin:'auto' }}>
                <View style={{width:'100%'}}><Text style={{marginTop:'10px',fontSize:'12px', fontWeight:'bold'}}>Freight Expense Detail</Text></View>
                <View style={{width:'60%'}}><Text style={{marginTop:'10px',fontSize:'12px', fontWeight:'bold'}}>Adjustment Cost</Text></View>
                <View style={{width:'40%'}}><Text style={{marginTop:'10px',textAlign:'right',fontSize:'12px', fontWeight:'bold'}}>{invoiceCostingData[0]?.AdjustmentAmountFC.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}</Text></View>
                <View style={{width:'60%'}}><Text style={{marginTop:'10px',fontSize:'12px', fontWeight:'bold'}}>Freight Cost</Text></View>
                <View style={{width:'40%', borderBottomWidth: 1,paddingBottom:'2px'}}><Text style={{marginTop:'10px',textAlign:'right',fontSize:'12px', fontWeight:'bold'}}>{invoiceCostingData[0]?.FreightAmountFC.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}</Text></View>
                <View style={{width:'60%'}}><Text style={{marginTop:'10px',fontSize:'12px', fontWeight:'bold'}}>Total Freight Cost</Text></View>
                <View style={{width:'40%',borderBottom:'solid' , borderBottomWidth: 1,paddingBottom:'2px'}}><Text style={{marginTop:'10px',textAlign:'right',fontSize:'12px', fontWeight:'bold', borderBottom:'solid' , borderBottomWidth: 1}}>{invoiceCostingData[0]?.FreightCost.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}</Text></View>
              </View>
              </View>
              
            </View>

            <View style={{width:'60%' , marginHorizontal:'auto' , marginTop:'15px'}}>
              <Text style={{fontSize:'13px', fontWeight:'bold'}}>Other Expense Detail</Text>
            <View style={styles.table}>
            <View style={styles.tableRow} fixed>
              <View style={{ ...styles.tableCol, width: '60%',borderTopWidth:1 }}>
                <Text style={{...styles.tableCell ,fontSize:'13px', fontWeight:'bold'}}>Expense Head</Text>
              </View>
              <View style={{ ...styles.tableCol, width: '40%',borderTopWidth:1 }}>
                <Text style={{...styles.tableCell,fontSize:'13px', fontWeight:'bold'}}>US$</Text>
              </View>
            </View>

            {detailData.map((item, index) =>{
              console.log(item.USAmt)
              let amount =item.USAmt == null ? 0 :item.USAmt.toLocaleString('en-US', { minimumFractionDigits:0 , maximumFractionDigits: 0 })
                return (
              <View style={styles.tableRow} key={index}>
                <View style={{ ...styles.tableCol, width: '60%' }}>
                  <Text style={{...styles.tableCell, textAlign:'left'}}>{item.AccountTitle}</Text>
                </View>
                <View style={{ ...styles.tableCol, width: '40%' }}>
                  <Text style={{...styles.tableCell, textAlign:'right'}}>{amount}</Text>
                </View>
              </View>
            )})}
             <View style={styles.tableRow}>
              <View style={{ ...styles.tableCol, width: '60%' }}>
                <Text style={{ ...styles.tableCell, textAlign:'right',fontWeight: 'bold',fontSize:'13px' ,borderRightWidth: 'transparent' }}>TOTAL</Text>
              </View>
              <View style={{ ...styles.tableCol, width: '40%', textAlign: 'right' }}>
                <Text style={{ ...styles.tableCell, textAlign:'right',fontSize:'13px',fontWeight: 'bold' }}>{totalUSAmt}</Text>
              </View>
              </View>
          
              </View>
          </View>
        </Page>
      </Document>
    // </PDFViewer>
  );
};

// Main component to render the PDF in a viewer
const InvoiceCosting = (props) => {
  console.log(props)
  
  // const handleDownload = async () => {
  //   const doc =<Report 
  //   invoiceCostingData={invoiceCostingData} 
  //   invoiceValueTotalData={invoiceValueTotalData} 
  //   detailData={detailData} 
  //   />;
  //   const asPdf = pdf([]); // Creating a new PDF instance
  //   asPdf.updateContainer(doc); // Update the container with the document

  //   const blob = await asPdf.toBlob(); // Generate the PDF as a Blob
  //   saveAs(blob, 'example.pdf'); // Use file-saver to save the PDF
  // };
  // return (
  //   <Document>
  //     <Page size="A4" style={styles.page}>
  //       <View style={styles.section}>
  //         <Text>Report 2 Content</Text>
  //       </View>
  //     </Page>
  //   </Document>
  // );
  return (

    <>
    <Report 
        invoiceCostingData={props.invoiceCostingData} 
        invoiceValueTotalData={props.invoiceValueTotalData} 
        detailData={props.detailData} 
        />
    </>
  
    
  );
};
  // <div className='content-wrapper'>
      {/* {detailData == null ? (
        <h2>...Loading Report</h2>
      ) : (
        <> */}
        {/* <PDFDownloadLink document={<Report 
        invoiceCostingData={invoiceCostingData} 
        invoiceValueTotalData={invoiceValueTotalData} 
        detailData={detailData} 
        />} fileName="example.pdf">
        {({ blob, url, loading, error }) =>
          loading ? 'Loading document...' : 'Download PDF'
        }
      </PDFDownloadLink> */}
            {/* <button onClick={handleDownload}>Download PDF</button>

        </>
      )} */}
    // </div>
export default InvoiceCosting;
