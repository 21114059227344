import React from 'react';


const IrtizaDynamicInputAdd = (props) => {
    console.log(props)
    return (
        <div>
            
        </div>
    );
}



export default IrtizaDynamicInputAdd;
